import { FC, ReactNode } from 'react';

import { ID } from '@core/models';
import { GradientColorsEnum } from '@core/enums/gradientEnum';

import './SearchNavigation.styles.scss';
import { NavigationItem } from './NavigationItem';
import SearchNavigationMobile from './SearchNavigationMobile';

export interface iNavigationItem {
    id: ID;
    title: ReactNode;
    isActive?: boolean;
    onClick?: (item: iNavigationItem) => void;
    subItems?: iNavigationItem[];
    testId?: string;
    baseColor?: GradientColorsEnum;
    icon?: React.ReactNode;
    filters?: Array<{
        title: string;
        id: ID;
        isActive?: boolean;
        testId: string;
    }>;
}

const SearchNavigation: FC<{
    title: ReactNode;
    items: iNavigationItem[];
    isSmallScreen?: boolean;
}> = ({
    title,
    items,
    isSmallScreen = false
}) => {
    if (isSmallScreen) {
        return <SearchNavigationMobile items={items} title={title} />;
    }

    return (
        <div className="SearchNavigation">
            <div className="SearchNavigation__header">
                <div className="SearchNavigation__title" data-testid="SearchNavChooseYourCriteria">
                    {title}
                </div>
            </div>
            {items.map((item) => (
                <NavigationItem key={item.id} item={item} level={0} testId={item?.testId} />
            ))}
        </div>
    );
};

export default SearchNavigation;
