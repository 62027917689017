import { useCallback, useMemo, ReactNode } from 'react';

import { FCX, ID, iOption } from '@core/models';
import { formatTestIds } from '@core/utils/string';
import { toggleInArray } from '@core/helpers/helpers';
import { GradientColorsEnum } from '@enums/gradientEnum';
import SwitchPill from '@components/utility/SwitchPill/SwitchPill';
import HierarchyIndicator from '@components/utility/HierarchyIndicator/HierarchyIndicator';

import './SwitchGroup.styles.scss';

export type iSwitchGroupOption = iOption & {
    isDisabled?: boolean;
};

const SwitchGroup: FCX<{
    title: ReactNode;
    options: iSwitchGroupOption[];
    value: ID[];
    onChange: (value: ID[]) => void;
    color: GradientColorsEnum;
    isDisabled?: boolean;
}> = ({
    title,
    options,
    value,
    onChange,
    color,
    isDisabled = false,
    id,
    testId
}) => {
    const isAllChecked: boolean = useMemo(() => {
        return value.length === options.length;
    }, [value, options]);

    const formattedTestId = useMemo(() => formatTestIds(testId), [testId]);

    const handleToggleAll = useCallback(() => {
        if (isAllChecked) {
            onChange([]);
        } else {
            onChange(options.map((option) => option.id));
        }
    }, [isAllChecked, onChange, options]);

    const handleToggle = useCallback(
        (id: ID) => {
            onChange(toggleInArray(value, id));
        },
        [value, onChange],
    );

    return (
        <div className="SwitchGroup" data-testid={`SwitchGroup${formattedTestId}`}>
            <SwitchPill
                id={id}
                title={title}
                onChange={handleToggleAll}
                color={color}
                isChecked={isAllChecked}
                className="SwitchGroup__header"
                hasIcon
                isDisabled={isDisabled}
                testId={`Header${formattedTestId}`}
            />
            <div>
                <HierarchyIndicator>
                    {options.map((option) => (
                        <SwitchPill
                            key={option.id}
                            id={option?.id.toString()}
                            title={option.title}
                            onChange={() => handleToggle(option.id)}
                            color={color}
                            isChecked={value.includes(option.id)}
                            className="SwitchGroup__option"
                            isDisabled={isDisabled || option?.isDisabled}
                            testId={option.id as string}
                        />
                    ))}
                </HierarchyIndicator>
            </div>
        </div>
    );
};

export default SwitchGroup;
