import { FC } from 'react';
import Box from '@mui/material/Box';

import { useAdvancedSearch } from '@core/contexts';
import Pagination from '@components/utility/Pagination/Pagination';
import { TableProvider } from '@components/utility/Table/TableContext';
import TableWithMobileFallback from '@components/utility/Table/TableWithMobileFallback';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';

import OperatorResultsTableMobile from './OperatorResultsTableMobile';
import useOperatorResultsTable, { OP_TARGETING_RESULTS_TABLE_TEST_ID } from '../../hooks/useOperatorResultsTable';

const OperatorTargetingResultsTable: FC = () => {
    const {
        operatorResultsList: {
            loading,
            resultsCount,
            operatorResults
        },
    } = useOperatorTargetingResultsContext();

    const {
        config,
        rowConfig,
        sortConfig
    } = useOperatorResultsTable(operatorResults?.search);

    const {
        pagination: {
            activePage,
            perPage,
            onChangePage,
            onChangePerPage
        },
    } = useAdvancedSearch();

    return (
        <>
            <TableProvider config={config} rowConfig={rowConfig} sortConfig={sortConfig}>
                <TableWithMobileFallback
                    rowsCount={operatorResults?.search?.operators?.length ?? 0}
                    testId={OP_TARGETING_RESULTS_TABLE_TEST_ID}
                    loading={loading}
                >
                    <OperatorResultsTableMobile
                        data={operatorResults?.search}
                        testId={OP_TARGETING_RESULTS_TABLE_TEST_ID}
                        loading={loading}
                    />
                </TableWithMobileFallback>
            </TableProvider>

            <Box marginTop={'32px'}>
                <Pagination
                    activePage={activePage}
                    onChangePage={onChangePage}
                    perPage={perPage}
                    onChangePerPage={onChangePerPage}
                    totalResultsCount={resultsCount}
                    totalPagesCount={operatorResults?.search?._metadata?.page_count ?? 0}
                />
            </Box>
        </>
    );
};

export default OperatorTargetingResultsTable;
