import React, { createContext } from 'react';

import { TableConfigItem, TableConfigRow } from './Table.helpers';
import { SavedSearchEnum, TableColumnsEnum } from '@core/enums/localeEnum';
import { useTableSortReturn } from '@core/hooks/useTableSort';

type CombinedEnum = TableColumnsEnum | SavedSearchEnum;

type TableProviderProps = {
    children: React.ReactNode;
    config: TableConfigItem<CombinedEnum>[];
    sortConfig?: useTableSortReturn;
    rowConfig?: TableConfigRow;
};

export const TableContext = createContext<Omit<TableProviderProps, 'children'> | undefined>(undefined);

export function TableProvider({
    children,
    config,
    rowConfig,
    sortConfig
}: TableProviderProps) {
    return <TableContext.Provider value={{ config, rowConfig, sortConfig }}>{children}</TableContext.Provider>;
}

export function useTableContext(): Omit<TableProviderProps, 'children'> {
    const context = React.useContext(TableContext);
    if (context === undefined) {
        throw new Error('useTableContext must be used within a TableProvider');
    }
    return context;
}
