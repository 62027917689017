import { ReactNode } from 'react';

import { ID, iOption } from '@core/models';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { FilterTypeEnum, OperatorProfilePhrasesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';

type NavFilter = {
    id: ID;
    testId: string;
    title: string;
};

export interface OperatorTargetingNavItem extends iOption {
    testId: string;
    subItems?: OperatorTargetingNavItem[];
    filters?: NavFilter[];
    content?: ReactNode;
    baseColor?: GradientColorsEnum;
    icon?: React.ReactNode;
}

export type NavigationTestIdsMap = {
    [key in FilterTypeEnum]?: string;
} & {
    [key in OperatorProfilePhrasesEnum]?: string;
} & {
    [key in OperatorTargetingPhrasesEnum]?: string;
};

export const SEGMENT_NAV_TYPES = [
    FilterTypeEnum.RestaurantsAndBars,
    FilterTypeEnum.LodgingAndRecreation,
    FilterTypeEnum.Retail,
    FilterTypeEnum.NonCommercial,
    FilterTypeEnum.OtherFoodservice,
];

export const NAV_TEST_IDS_MAP: NavigationTestIdsMap = {
    [FilterTypeEnum.LodgingAndRecreation]: 'SidebarSubnavLodgingAndRecreation',
    [FilterTypeEnum.Retail]: 'SidebarSubnavRetail',
    [FilterTypeEnum.NonCommercial]: 'SidebarSubnavNonCommercial',
    [FilterTypeEnum.OtherFoodservice]: 'SidebarSubnavOtherFoodservice',
    [FilterTypeEnum.Location]: 'SidebarNavLocation',
    [FilterTypeEnum.Segments]: 'SidebarNavSegments',
    [FilterTypeEnum.SegmentsCriteria]: 'SidebarNavSegmentsCriteria',
    [FilterTypeEnum.Tags]: 'SidebarNavTags',
    [FilterTypeEnum.ClientFilters]: 'SidebarSubnavClientFilters',
    [FilterTypeEnum.ClientStatus]: 'SidebarFilterClientStatus',
    [FilterTypeEnum.General]: 'SidebarSubnavGeneral',
    [FilterTypeEnum.ChainIndependent]: 'SidebarFilterChainIndependent',
    [FilterTypeEnum.Business]: 'SidebarFilterYearsinBusiness',
    [OperatorProfilePhrasesEnum.EstimatedAnnualSales]: 'SidebarFilterEstimatedAnnualSales',
    [FilterTypeEnum.NumberOfEmployees]: 'SidebarFilterNumberOfEmployees',
    [FilterTypeEnum.TotalPurchasePotential]: 'SidebarFilterTotalPurchasePotential',
    [OperatorProfilePhrasesEnum.ConfidenceLevel]: 'SidebarFilterConfidenceLevel',
    [FilterTypeEnum.RestaurantsAndBars]: 'SidebarSubnavRestaurantsAndBarsCuisines',
    [FilterTypeEnum.CuisineTypes]: 'SidebarFilterCuisineTypes',
    [OperatorProfilePhrasesEnum.EstMealsPerDay]: 'SidebarFilterEstMealsPerDayTypes',
    [FilterTypeEnum.Regional]: 'SidebarSubnavRegional',
    [FilterTypeEnum.Radius]: 'SidebarSubnavRadius',
    [FilterTypeEnum.NumberofRooms]: 'SidebarFilterNumberofRooms',
    [OperatorProfilePhrasesEnum.HotelClass]: 'SidebarFilterHotelClass',
    [OperatorProfilePhrasesEnum.FoodCuisine]: 'SidebarSubnavFoodTags',
    [OperatorProfilePhrasesEnum.Place]: 'SidebarSubnavPlaceTags',
    [OperatorTargetingPhrasesEnum.AverageCheck]: 'SidebarFilterAverageCheckRangeType',
};
