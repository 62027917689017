import cn from 'classnames';

import { FCX } from '@core/models';
import { GradientColorsEnum } from '@core/enums/gradientEnum';

import './BadgeIcon.styles.scss';

export enum BadgeIconSizeEnum {
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
}

const BadgeIcon: FCX<{
    icon: React.ReactNode;
    baseColor: GradientColorsEnum;
    countBadge?: number;
    size?: BadgeIconSizeEnum;
}> = ({
    baseColor,
    countBadge,
    icon,
    size = BadgeIconSizeEnum.Medium,
    ...rest
}) => {
    return (
        <div className={cn('BadgeIcon', `size-${size}`, `BadgeIcon--${baseColor}`)} {...rest}>
            {icon}
            {!!countBadge && countBadge > 0 && <div className={cn('BadgeIcon--count')}>{countBadge}</div>}
        </div>
    );
};

export default BadgeIcon;
