import { ReactNode } from 'react';
import cn from 'classnames';

import { FCX } from '@core/models';

import './Typography.styles.scss';

export enum TextVariant {
    B1 = 'body-1',
    B2 = 'body-2',
    Link = 'link',
}

const Text: FCX<{
    children: ReactNode;
    variant?: TextVariant;
    title?: string;
}> = ({
    children,
    variant = TextVariant.B1,
    className,
    style,
    testId,
    title
}) => {
    return (
        <span style={style} className={cn('Text', variant, className)} data-testid={testId} title={title}>
            {children}
        </span>
    );
};

export default Text;
