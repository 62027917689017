import { FCX } from '@core/models';

import './PageTitle.styles.scss';
import Skeleton from '@mui/material/Skeleton/Skeleton';

const PageTitleSkeleton: FCX = () => {
    return (
        <div className="PageTitle">
            <Skeleton variant="rectangular" width={300} height={34} />
        </div>
    );
};

export default PageTitleSkeleton;
