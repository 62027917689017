import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';

import { ID } from '@core/models';
import { generateAnchorId } from '@core/utils/string';
import { CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';

import {
    ClassificationDTO,
    mapSegmentToDropdownOption,
    groupSegmentLevelsByIdsList,
} from '../../helpers/Classification.helpers';
import { SEGMENT_CRITERION } from './SegmentsContent';
import SegmentsSubChannel from './SegmentsSubChannel';
import ChannelActionButton from './ChannelActionButton';

const SegmentsChannel: FC<{
    channel: ClassificationDTO;
    onClearSearch: () => void;
    searchSelectedValue: SelectOption | null;
}> = ({
    channel,
    onClearSearch,
    searchSelectedValue
}) => {
    const [selectedSegments, setselectedSegments] = useState<ID[][]>([]);
    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey,
        onDeleteCriterionParam
    } = useAdvancedSearch();

    useEffect(() => {
        const segmentParams = getCriterionByFilterKey(SEGMENT_CRITERION, CriterionParamFilter.Segment);

        const isChannelSelected = segmentParams?.some((segment) => segment?.value === channel?.external_id);

        if (isChannelSelected) {
            setselectedSegments(getAllSegmentsIds(channel));
        } else {
            setselectedSegments(getSegmentIdsFromSubChannels(segmentParams, channel));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channel, channel?.children, channel?.external_id, getCriterionByFilterKey]);

    function getAllSegmentsIds(channel: ClassificationDTO) {
        return (
            channel?.children?.map((subChannel) => {
                return subChannel?.children?.map((segment) => segment?.external_id as ID) ?? [];
            }) ?? []
        );
    }

    function matchesSegmentId(segmentId: ID) {
        return (segment: { value?: ID }) => segment?.value === segmentId;
    }

    function getSegmentIdsFromSubChannels(segmentParams: CriterionParam[] | undefined, channel: ClassificationDTO) {
        return (
            channel?.children?.map((subChannel) => {
                const isSubChannelSelected = segmentParams?.some(
                    (segment) => segment?.value === subChannel?.external_id,
                );

                const allSubChannelsSegmentsIds =
                    subChannel?.children?.map((segment) => segment?.external_id as ID) ?? [];

                if (isSubChannelSelected) {
                    return allSubChannelsSegmentsIds;
                }

                return allSubChannelsSegmentsIds.filter((segmentId: ID) =>
                    segmentParams?.some(matchesSegmentId(segmentId)),
                );
            }) ?? []
        );
    }

    const onClearAllChannelSegments = useCallback(
        (channel: ClassificationDTO) => {
            const subChannels = channel?.children?.map((subChannel) => subChannel?.external_id as string) ?? [];
            const segments =
                channel?.children
                    ?.map((subChannel) => {
                        return subChannel?.children?.map((segment) => segment?.external_id as string) ?? [];
                    })
                    ?.flat() ?? [];
            const allChannelExernalIds = [channel?.external_id ?? '', ...subChannels, ...segments];

            allChannelExernalIds?.forEach((channelSegmentId) => {
                onDeleteCriterionParam(SEGMENT_CRITERION, channelSegmentId);
            });
        },
        [onDeleteCriterionParam],
    );

    const onAddChannelSegments = useCallback(
        (channelSegmentsList: ClassificationDTO[]) => {
            channelSegmentsList?.forEach((channelSegment) => {
                const channelSegmentPill: DropdownOption = mapSegmentToDropdownOption(channelSegment);
                addCriterionOnOptionSelected(SEGMENT_CRITERION, CriterionParamFilter.Segment)(channelSegmentPill);
            });
        },
        [addCriterionOnOptionSelected],
    );

    const onUpdateSelectedSegments = useCallback(
        (index: number) => (selectedIds: ID[]) => {
            const newSelectedSegments = [...selectedSegments];
            newSelectedSegments[index] = selectedIds;
            setselectedSegments(newSelectedSegments);

            onClearAllChannelSegments(channel);

            const flattenSelectedSegments = newSelectedSegments?.flat();
            const groupedSegments = groupSegmentLevelsByIdsList(channel, flattenSelectedSegments);
            onAddChannelSegments(groupedSegments);
        },
        [channel, onAddChannelSegments, onClearAllChannelSegments, selectedSegments],
    );

    return (
        <div
            key={channel?.external_id}
            className="CriteriaContent__section"
            id={generateAnchorId(channel?.description)}
            data-observable
        >
            <Widget type={WidgetType.Section} className="criteria">
                <div className="CriteriaContent__segment-button">
                    <Heading variant={HeadingVariant.H4}>{channel?.description}</Heading>

                    <ChannelActionButton
                        channel={channel}
                        onClearAllChannelSegments={onClearAllChannelSegments}
                        setselectedSegments={setselectedSegments}
                    />
                </div>
                <div className={cn('CriteriaContent__segment-columns')}>
                    {channel?.children?.map((subChannel, index) => {
                        return (
                            <div key={subChannel?.external_id} className="CriteriaContent__segment-column">
                                <SegmentsSubChannel
                                    searchSelectedValue={searchSelectedValue}
                                    onClearSearch={onClearSearch}
                                    onChangeSegments={onUpdateSelectedSegments(index)}
                                    selectedValues={selectedSegments[index] ?? []}
                                    subChannel={subChannel}
                                />
                            </div>
                        );
                    })}
                </div>
            </Widget>
        </div>
    );
};

export default SegmentsChannel;
