import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { useInput } from '@core/hooks/useInput';
import Modal from '@components/utility/Modal/Modal';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import useRadioGroup from '@components/utility/RadioGroup/useRadioGroup';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import useUpdateSaveSearch from '@core/api/SaveSearch/useUpdateSaveSearch';

import './SaveSearchModal.styles.scss';
import { SaveSearchBody } from './SaveSearchBody';
import { SuccessComponent } from './SaveSearchSuccess';
import { PUBLICLY_AVAILABLE_RADIO_VALUE, radioOptions } from './SaveSearchModal';

const EditSearchModal: FC<{
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    data: SaveSearchResponse;
    shouldUpdateActiveSavedSearch?: boolean;
}> = ({
    isOpen,
    handleClose,
    title,
    data,
    shouldUpdateActiveSavedSearch = false
}) => {
    const { t } = useLocale();
    const {
        value,
        onChange
    } = useInput();
    const {
        value: radioValue,
        handleChange
    } = useRadioGroup(data.publicly_available ? 2 : 1);
    const [inputValue, setInputValue] = useState(data.name);

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }
    }, [value]);

    const {
        success,
        error,
        doFetch,
        loading
    } = useUpdateSaveSearch();

    const {
        savedSearch: {
            savedSearchDoFetch,
            updateActiveSavedSearch
        },
    } = useAdvancedSearch();

    const onEdit = useCallback(() => {
        doFetch({
            id: data.id,
            name: inputValue ?? '',
            query: data.query,
            publicly_available: radioValue === PUBLICLY_AVAILABLE_RADIO_VALUE,
        }).then((response) => {
            if (!response?.data) {
                return;
            }

            if (shouldUpdateActiveSavedSearch) {
                updateActiveSavedSearch(response.data);
            }

            savedSearchDoFetch();
            setTimeout(handleClose, 2000);
        });
    }, [
        doFetch,
        data.id,
        data.query,
        inputValue,
        radioValue,
        shouldUpdateActiveSavedSearch,
        savedSearchDoFetch,
        handleClose,
        updateActiveSavedSearch,
    ]);

    const isSaveButtonDisabled = useMemo(
        () => typeof inputValue === 'undefined' || inputValue.length <= 2 || !radioValue || loading,
        [inputValue, loading, radioValue],
    );

    return (
        <Modal title={title} isOpen={isOpen} handleClose={handleClose}>
            <div className="SaveSearchModal">
                {success ? (
                    <SuccessComponent value={value} t={t} />
                ) : (
                    <SaveSearchBody
                        value={inputValue}
                        onChange={onChange}
                        hasError={!!error}
                        radioValue={radioValue}
                        handleChange={handleChange}
                        isSaveButtonDisabled={isSaveButtonDisabled}
                        onSave={onEdit}
                        handleClose={handleClose}
                        t={t}
                        radioOptions={radioOptions}
                    />
                )}
            </div>
        </Modal>
    );
};

export default EditSearchModal;
