import { FC, memo } from 'react';
import cn from 'classnames';
import { IconArrowCollapse } from '@assets/icons';

const SelectIcon: FC<{
    isOpen: boolean;
}> = ({ isOpen = false }) => {
    return <IconArrowCollapse className={cn('SelectIcon', isOpen && 'SelectIcon--open')} />;
};

export default memo(SelectIcon);
