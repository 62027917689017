import { FC } from 'react';
import cn from 'classnames';

import { ID } from '@core/models';
import { IconClose } from '@assets/icons';
import { GradientColorsEnum } from '@core/enums/gradientEnum';

import './Chip.styles.scss';

const Chip: FC<{
    baseColor?: GradientColorsEnum;
    description?: string;
    name: string;
    onChipDelete?: (id: ID) => void;
    id: ID;
    testId?: string;
}> = ({
    baseColor,
    description,
    name,
    onChipDelete,
    id,
    testId
}) => {
    const parsedChipName = testId ?? `${name.split(' ').join('').trim()}`;

    return (
        <div className="Chip" data-testid={`chip${parsedChipName}`}>
            {!!baseColor && <div className={cn('Chip__dot', `is-${baseColor}`)} />}
            <div>
                <span className="Chip__description">{description && `${description}: `}</span>
                <span className="Chip__name">{name}</span>
            </div>

            {onChipDelete && (
                <div
                    className="Chip__icon"
                    data-testid={`chipDelete${parsedChipName}`}
                    onClick={() => onChipDelete(id)}
                >
                    <IconClose width={8} />
                </div>
            )}
        </div>
    );
};

export default Chip;
