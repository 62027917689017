import { ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiMenu, { MenuProps } from '@mui/material/Menu';

import { FCX } from '@core/models';

import './DropdownMenu.styles.scss';

export type DropdownMenuProps = {
    anchorOrigin: NonNullable<MenuProps['anchorOrigin']>;
    transformOrigin: NonNullable<MenuProps['transformOrigin']>;
    open: boolean;
    anchorEl: Element | null;
    onClose: MenuProps['onClose'];
    options: {
        label: ReactNode;
        icon?: ReactNode;
        onClick: () => void;
    }[];
};

const DropdownMenu: FCX<DropdownMenuProps> = ({
    anchorEl,
    anchorOrigin,
    transformOrigin,
    open,
    onClose,
    options
}) => {
    if (options.length === 0) {
        return null;
    }

    return (
        <MuiMenu
            open={open}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            className="DropdownMenu"
            anchorEl={anchorEl}
            onClose={onClose}
            classes={{
                paper: 'DropdownMenu__container',
                list: 'DropdownMenu__list',
            }}
        >
            {options?.map((option, index) => (
                <MenuItem
                    className="DropdownMenuItem"
                    classes={{
                        root: 'DropdownMenuItem__container',
                    }}
                    key={index}
                    onClick={option.onClick}
                    disableRipple
                >
                    {option?.icon && <div className="DropdownMenuItem__icon">{option.icon}</div>}
                    {option.label}
                </MenuItem>
            ))}
        </MuiMenu>
    );
};

export default DropdownMenu;
