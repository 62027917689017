/**
 * The ISO-3166-2 codes (administrative divisions and dependent territories) are commented as they are not part of scope yet.
 * They were added because their flags were provided in the design system as part of a future scope.
 **/
export enum CountryCodesEnum {
    AD = 'AD', // ANDORRA
    AE = 'AE', // UNITED ARAB EMIRATES
    AF = 'AF', // AFGHANISTAN
    AG = 'AG', // ANTIGUA AND BARBUDA
    AI = 'AI', // ANGUILLA
    AL = 'AL', // ALBANIA
    AM = 'AM', // ARMENIA
    AO = 'AO', // ANGOLA
    AR = 'AR', // ARGENTINA
    AS = 'AS', // AMERICAN SAMOA
    AT = 'AT', // AUSTRIA
    AU = 'AU', // AUSTRALIA
    AW = 'AW', // ARUBA
    AX = 'AX', // ALAND ISLANDS
    AZ = 'AZ', // AZERBAIJAN
    BA = 'BA', // BOSNIA AND HERZEGOVINA
    BB = 'BB', // BARBADOS
    BD = 'BD', // BANGLADESH
    BE = 'BE', // BELGIUM
    BF = 'BF', // BURKINA FASO
    BG = 'BG', // BULGARIA
    BH = 'BH', // BAHRAIN
    BI = 'BI', // BURUNDI
    BJ = 'BJ', // BENIN
    BL = 'BL', // ST BARTS
    BM = 'BM', // BERMUDA
    BN = 'BN', // BRUNEI
    BO = 'BO', // BOLIVIA
    BQ = 'BQ', // BONAIRE
    BR = 'BR', // BRAZIL
    BS = 'BS', // BAHAMAS
    BT = 'BT', // BHUTAN
    BW = 'BW', // BOTSWANA
    BY = 'BY', // BELARUS
    BZ = 'BZ', // BELIZE
    CA = 'CA', // CANADA
    // CA_BC = 'CA_BC', // BRITISH COLUMBIA
    CC = 'CC', // COCOS ISLAND
    CD = 'CD', // DEMOCRATIC REPUBLIC OF CONGO
    CF = 'CF', // CENTRAL AFRICAN REPUBLIC
    CG = 'CG', // REPUBLIC OF THE CONGO
    CH = 'CH', // SWITZERLAND
    CI = 'CI', // IVORY COAST
    CK = 'CK', // COOK ISLANDS
    CL = 'CL', // CHILE
    // CL_2 = 'CL_2', // RAPA NUI
    CM = 'CM', // CAMEROON
    CN = 'CN', // CHINA
    // CN_XZ = 'CN_XZ', // TIBET
    CO = 'CO', // COLOMBIA
    CR = 'CR', // COSTA RICA
    CU = 'CU', // CUBA
    CV = 'CV', // CAPE VERDE
    CW = 'CW', // CURACAO
    CX = 'CX', // CHRISTMAS ISLAND
    CY = 'CY', // CYPRUS
    // CY_2 = 'CY_2', // NORTHERN CYPRUS
    CZ = 'CZ', // CZECH REPUBLIC
    DE = 'DE', // GERMANY
    DJ = 'DJ', // DJIBOUTI
    DK = 'DK', // DENMARK
    DM = 'DM', // DOMINICA
    DO = 'DO', // DOMINICAN REPUBLIC
    DZ = 'DZ', // ALGERIA
    EC = 'EC', // ECUADOR
    // EC_W = 'EC_W', // GALAPAGOS ISLANDS
    EE = 'EE', // ESTONIA
    EG = 'EG', // EGYPT
    EH = 'EH', // SAHRAWI ARAB DEMOCRATIC REPUBLIC
    ER = 'ER', // ERITREA
    ES = 'ES', // SPAIN
    // ES_CE = 'ES_CE', // CEUTA
    // ES_CN = 'ES_CN', // CANARY ISLANDS
    // ES_IB = 'ES_IB', // BALEARIC ISLANDS
    // ES_ML = 'ES_ML', // MELILLA
    // ES_PV = 'ES_PV', // BASQUE COUNTRY
    ET = 'ET', // ETHIOPIA
    EU = 'EU', // EUROPEAN UNION
    FI = 'FI', // FINLAND
    FJ = 'FJ', // FIJI
    FK = 'FK', // FALKLAND ISLANDS
    FM = 'FM', // MICRONESIA
    FO = 'FO', // FAROE ISLANDS
    FR = 'FR', // FRANCE
    // FR_20R = 'FR_20R', // CORSICA
    GA = 'GA', // GABON
    GB = 'GB', // ENGLAND
    // GB_ORK = 'GB_ORK', // ORKNEY ISLANDS
    // GB_SCT = 'GB_SCT', // SCOTLAND
    // GB_WLS = 'GB_WLS', // WALES
    GD = 'GD', // GRENADA
    GE = 'GE', // GEORGIA
    // GE_2 = 'GE_2', // OSSETIA
    // GE_AB = 'GE_AB', // ABKHAZIA
    GG = 'GG', // GUERNSEY
    GH = 'GH', // GHANA
    GI = 'GI', // GIBRALTAR
    GL = 'GL', // GREENLAND
    GM = 'GM', // GAMBIA
    GN = 'GN', // GUINEA
    GQ = 'GQ', // EQUATORIAL GUINEA
    GR = 'GR', // GREECE
    GT = 'GT', // GUATEMALA
    GU = 'GU', // GUAM
    GW = 'GW', // GUINEA BISSAU
    GY = 'GY', // GUYANA
    HK = 'HK', // HONG KONG
    HN = 'HN', // HONDURAS
    HR = 'HR', // CROATIA
    HT = 'HT', // HAITI
    HU = 'HU', // HUNGARY
    ID = 'ID', // INDONESIA
    IE = 'IE', // IRELAND
    IL = 'IL', // ISRAEL
    IM = 'IM', // ISLE OF MAN
    IN = 'IN', // INDIA
    IO = 'IO', // BRITISH INDIAN OCEAN TERRITORY
    IQ = 'IQ', // IRAQ
    IR = 'IR', // IRAN
    IS = 'IS', // ICELAND
    IT = 'IT', // ITALY
    // IT_88 = 'IT_88', // SARDINIA
    JE = 'JE', // JERSEY
    JM = 'JM', // JAMAICA
    JO = 'JO', // JORDAN
    JP = 'JP', // JAPAN
    KE = 'KE', // KENYA
    KG = 'KG', // KYRGYZSTAN
    KH = 'KH', // CAMBODIA
    KI = 'KI', // KIRIBATI
    KM = 'KM', // COMOROS
    KN = 'KN', // SAINT KITTS AND NEVIS
    KP = 'KP', // NORTH KOREA
    KR = 'KR', // SOUTH KOREA
    KW = 'KW', // KUWAIT
    KY = 'KY', // CAYMAN ISLANDS
    KZ = 'KZ', // KAZAKHSTAN
    LA = 'LA', // LAOS
    LB = 'LB', // LEBANON
    LC = 'LC', // ST LUCIA
    LI = 'LI', // LIECHTENSTEIN
    LK = 'LK', // SRI LANKA
    LR = 'LR', // LIBERIA
    LS = 'LS', // LESOTHO
    LT = 'LT', // LITHUANIA
    LU = 'LU', // LUXEMBOURG
    LV = 'LV', // LATVIA
    LY = 'LY', // LIBYA
    MA = 'MA', // MOROCCO
    MC = 'MC', // MONACO
    MD = 'MD', // MOLDOVA
    // MD_2 = 'MD_2', // TRANSNISTRIA
    ME = 'ME', // MONTENEGRO
    MG = 'MG', // MADAGASCAR
    MH = 'MH', // MARSHALL ISLAND
    MK = 'MK', // REPUBLIC OF MACEDONIA
    ML = 'ML', // MALI
    MM = 'MM', // MYANMAR
    MN = 'MN', // MONGOLIA
    MO = 'MO', // MACAO
    MP = 'MP', // NORTHERN MARIANAS ISLANDS
    MQ = 'MQ', // MARTINIQUE
    MR = 'MR', // MAURITANIA
    MS = 'MS', // MONTSERRAT
    MT = 'MT', // MALTA
    MU = 'MU', // MAURITIUS
    MV = 'MV', // MALDIVES
    MW = 'MW', // MALAWI
    MX = 'MX', // MEXICO
    MY = 'MY', // MALAYSIA
    MZ = 'MZ', // MOZAMBIQUE
    NA = 'NA', // NAMIBIA
    NE = 'NE', // NIGER
    NF = 'NF', // NORFOLK ISLAND
    NG = 'NG', // NIGERIA
    NI = 'NI', // NICARAGUA
    NL = 'NL', // NETHERLANDS
    // NL_BQ2 = 'NL_BQ2', // SABA ISLAND
    // NL_BQ3 = 'NL_BQ3', // SINT EUSTATIUS
    NO = 'NO', // NORWAY
    NP = 'NP', // NEPAL
    NR = 'NR', // NAURU
    NU = 'NU', // NIUE
    NZ = 'NZ', // NEW ZEALAND
    OM = 'OM', // OMAN
    PA = 'PA', // PANAMA
    PE = 'PE', // PERU
    PF = 'PF', // FRENCH POLYNESIA
    PG = 'PG', // PAPUA NEW GUINEA
    PH = 'PH', // PHILIPPINES
    PK = 'PK', // PAKISTAN
    PL = 'PL', // POLAND
    PN = 'PN', // PITCAIRN ISLANDS
    PR = 'PR', // PUERTO RICO
    PS = 'PS', // PALESTINE
    PT = 'PT', // PORTUGAL
    // PT_20 = 'PT_20', // AZORES ISLANDS
    // PT_30 = 'PT_30', // MADEIRA
    PW = 'PW', // PALAU
    PY = 'PY', // PARAGUAY
    QA = 'QA', // QATAR
    RO = 'RO', // ROMANIA
    RS = 'RS', // SERBIA
    RU = 'RU', // RUSSIA
    RW = 'RW', // RWANDA
    SA = 'SA', // SAUDI ARABIA
    SB = 'SB', // SOLOMON ISLANDS
    SC = 'SC', // SEYCHELLES
    SD = 'SD', // SUDAN
    SE = 'SE', // SWEDEN
    SG = 'SG', // SINGAPORE
    SI = 'SI', // SLOVENIA
    SK = 'SK', // SLOVAKIA
    SL = 'SL', // SIERRA LEONE
    SM = 'SM', // SAN MARINO
    SN = 'SN', // SENEGAL
    SO = 'SO', // SOMALIA
    // SO_2 = 'SO_2', // SOMALILAND
    SR = 'SR', // SURINAME
    SS = 'SS', // SOUTH SUDAN
    ST = 'ST', // SAO TOME AND PRINCE
    SV = 'SV', // EL SALVADOR
    SX = 'SX', // SINT MAARTEN
    SY = 'SY', // SYRIA
    SZ = 'SZ', // SWAZILAND
    TC = 'TC', // TURKS AND CAICOS
    TD = 'TD', // CHAD
    TG = 'TG', // TOGO
    TH = 'TH', // THAILAND
    TJ = 'TJ', // TAJIKISTAN
    TK = 'TK', // TOKELAU
    TL = 'TL', // EAST TIMOR
    TM = 'TM', // TURKMENISTAN
    TN = 'TN', // TUNISIA
    TO = 'TO', // TONGA
    TR = 'TR', // TURKEY
    TT = 'TT', // TRINIDAD AND TOBAGO
    TV = 'TV', // TUVALU
    TW = 'TW', // TAIWAN
    TZ = 'TZ', // TANZANIA
    UA = 'UA', // UKRAINE
    UG = 'UG', // UGANDA
    UK = 'UK', // UNITED KINGDOM
    UN = 'UN', // UNITED NATIONS
    US = 'US', // UNITED STATES
    // US_HI = 'US_HI', // HAWAII
    UY = 'UY', // URUGUAY
    UZ = 'UZ', // UZBEKISTÁN
    VA = 'VA', // VATICAN CITY
    VC = 'VC', // ST VINCENT AND THE GRENADINES
    VE = 'VE', // VENEZUELA
    VG = 'VG', // BRITISH VIRGIN ISLANDS
    VI = 'VI', // VIRGIN ISLANDS
    VN = 'VN', // VIETNAM
    VU = 'VU', // VANUATU
    WS = 'WS', // SAMOA
    XK = 'XK', // KOSOVO
    XT = 'XT', // NATO
    YE = 'YE', // YEMEN
    ZA = 'ZA', // SOUTH AFRICA
    ZM = 'ZM', // ZAMBIA
    ZW = 'ZW', // ZIMBABWE
}

export type Country = {
    name: string;
    code: CountryCodesEnum;
};
