import { ID } from '@core/models';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import { components } from '@core/api/api';

export type ClassificationDTO = components['schemas']['ClassificationDTO'];

export function findSegmentByExternalId(subChannel: ClassificationDTO | undefined, externalId: ID) {
    if (!subChannel) return undefined;

    if (subChannel?.external_id === externalId) return subChannel;

    return subChannel?.children?.find((segment) => segment?.external_id === externalId);
}

export function filterAndSortSegments(segment: ClassificationDTO[] | undefined) {
    return segment
        ?.filter((segment) => {
            return segment?.description && segment?.active !== false;
        })
        ?.sort((a, b) => {
            return a?.order && b?.order ? a?.order - b?.order : 0;
        });
}

export function filterChannelLevel(channel: ClassificationDTO): ClassificationDTO {
    const subchannels = filterAndSortSegments(channel?.children)
        ?.map((subchannel) => {
            const segments = filterAndSortSegments(subchannel?.children);

            if (segments?.length === 0) {
                return undefined;
            }

            return {
                ...subchannel,
                children: segments,
            };
        })
        .filter((subchannel) => subchannel !== undefined);

    return {
        ...channel,
        children: subchannels as ClassificationDTO[],
    };
}
export function filterAndParseSegmentsChannels(channels: ClassificationDTO[] | undefined): ClassificationDTO[] {
    const parsedChannels = filterAndSortSegments(channels)?.map((channel) => {
        return filterChannelLevel(channel);
    });

    const channelsWithChildren = parsedChannels?.filter(
        (channel) => channel?.children && channel?.children?.length > 0,
    );

    return channelsWithChildren ?? [];
}

export function mapSegmentToDropdownOption(segment: ClassificationDTO): DropdownOption {
    return {
        id: segment?.external_id ?? segment?.description ?? '',
        label: segment?.description ?? '',
        value: segment?.external_id ?? segment?.description ?? '',
    };
}

/**
 *
 * This function handles the logic of selecting segments, subchannels and channels. they follow a tree structure of ClassificationDTOs, where a channel can have subchannels, and subchannels can have segments.
 * Whenever all the segments of a subchannel are on the selectedIds list, the subchannel should be returned instead of the segments, and the same goes for channels when all the subchannels are selected.
 * By doing this, we can reduce the number of selected segments, and make the UI more user friendly by grouping the segments.
 *
 * @param channel - An ClassificationDTO object that represents a channel of any level
 * @param selectedIds - An array of IDs that represent the selected segments, The IDs are the external_id of the segments, the lowest level of the segment tree.
 * @returns An array of ClassificationDTO objects that represent the selected segments, the array can contain channels, subchannels and segments.
 */
export function groupSegmentLevelsByIdsList(channel: ClassificationDTO, selectedIds: ID[]): ClassificationDTO[] {
    if (channel.level === 'CHANNEL' || channel.level === 'GROUP') {
        const selectedChildren: ClassificationDTO[] = [];

        channel.children?.forEach((subchannel) => {
            const subchannelResults = groupSegmentLevelsByIdsList(subchannel, selectedIds);

            if (subchannelResults.length > 0) {
                selectedChildren.push(...subchannelResults);
            }
        });

        if (selectedChildren.length === 0) {
            return [];
        }

        // Check if all segments are selected, if so, return the channel
        const allSegmentsSelected = channel.children?.every((subchannel) => {
            return subchannel.children
                ?.filter((segment) => segment.active === true)
                ?.every((segment) => selectedIds.includes(segment.external_id || ''));
        });

        return allSegmentsSelected ? [channel] : selectedChildren;
    }

    if (channel.level === 'SUBCHANNEL' || channel.level === 'PRIMARY') {
        const selectedSegments: ClassificationDTO[] = [];

        channel.children
            ?.filter((segment) => segment.active === true)
            ?.forEach((segment) => {
                if (selectedIds.includes(segment.external_id || '')) {
                    selectedSegments.push(segment);
                }
            });

        if (selectedSegments.length === 0) {
            return [];
        }

        // Check if all segments are selected, if so, return the subchannel
        if (selectedSegments.length === channel.children?.length) {
            return [channel];
        }

        return selectedSegments;
    }

    // It's a segment, return it if it's selected.
    if (selectedIds.includes(channel.external_id || '')) {
        return [channel];
    }

    return [];
}

export function mapChannelListToSelectOptions(channels: ClassificationDTO[]): SelectOption[] {
    const flattenedSegments = channels.flatMap((channel) => {
        return channel?.children?.flatMap((subChannel) => {
            const segments = subChannel?.children || [];

            return [subChannel, ...segments];
        });
    });

    const options: SelectOption[] = flattenedSegments.map((option) => ({
        label: option?.description || '',
        id: option?.external_id || '',
        isDisabled: option?.allowed === false,
    }));

    return options;
}
