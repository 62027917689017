import React from 'react';
import cn from 'classnames';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';

import { useLocale } from '@utils/locale';
import { removeWhitespaces } from '@core/utils/string';
import Tooltip from '@components/utility/Tooltip/Tooltip';

import './Sidebar.styles.scss';
import { INavLink } from './navLinks';

const Link: React.FC<INavLink & { isCollapsed: boolean; onLinkClick: () => void }> = ({
    isCollapsed,
    Icon,
    IconActive,
    link,
    sublinks,
    title,
    onLinkClick,
    testId,
}): JSX.Element => {
    const { pathname } = useLocation();
    const { t } = useLocale();

    const hasActiveSublink = sublinks?.some((sublink) => matchPath(sublink, pathname));
    const isLinkActive = !!matchPath(link, pathname) || hasActiveSublink;

    return (
        <div onClick={onLinkClick} className="Sidebar__item">
            <Tooltip
                content={t(title)}
                placement="right"
                disableHoverListener={!isCollapsed}
                testId={`Tooltip${removeWhitespaces(title)}`}
            >
                <RouterLink
                    to={link}
                    className={cn('Sidebar__link', { 'Sidebar__link--active': isLinkActive })}
                    data-testid={testId}
                >
                    {isLinkActive ? <IconActive /> : <Icon />}
                    <span>{t(title)}</span>
                </RouterLink>
            </Tooltip>
        </div>
    );
};

export default Link;
