import cn from 'classnames';

import { FCX } from '@core/models';
import MuiTableCell from '@mui/material/TableCell';
import { TableColumnsEnum } from '@core/enums/localeEnum';

import { TableConfigItem } from './Table.helpers';

const TableCell: FCX<{
    align?: TableConfigItem<TableColumnsEnum>['align'];
    onClick?: () => void;
}> = ({
    children,
    align = 'left',
    className,
    onClick,
    testId,
    style
}) => {
    return (
        <MuiTableCell
            onClick={onClick}
            className={cn('Table__Cell', { 'Table__Cell--right': align === 'right' }, className)}
            data-testid={testId}
            style={style}
        >
            {children}
        </MuiTableCell>
    );
};

export default TableCell;
