import React, { FC } from 'react';
import cn from 'classnames';
import MuiPagination from '@mui/material/Pagination';

import './Pagination.styles.scss';
import { PaginationItem } from '@mui/material';

const PaginationButtons: FC<{
    page: number;
    totalPagesCount: number;
    onChangePage: (page: number) => void;
}> = ({
    totalPagesCount,
    page,
    onChangePage
}) => {
    return (
        <div className={cn('PaginationButtons')}>
            <MuiPagination
                count={totalPagesCount}
                page={page}
                onChange={(_, page) => onChangePage(page)}
                variant="outlined"
                shape="rounded"
                siblingCount={5}
                hideNextButton
                hidePrevButton
                renderItem={(item) => <PaginationItem {...item} data-testid={`buttonPagination${item.page}`} />}
            />
        </div>
    );
};

export default PaginationButtons;
