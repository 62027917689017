import { FC, ReactNode } from 'react';

import './Tabs.styles.scss';

const TabPanel: FC<{
    children?: ReactNode;
    index: number;
    value: number;
    hasSharedContent?: boolean;
}> = ({
    children,
    value,
    index,
    hasSharedContent
}) => {
    if (hasSharedContent)
        return (
            <div role="tabpanel" data-testid={`tabpanel-${index}`}>
                {children}
            </div>
        );

    return (
        <div role="tabpanel" hidden={value !== index} data-testid={`tabpanel-${index}`}>
            {value === index && <div className="Tabs__panel">{children}</div>}
        </div>
    );
};

export default TabPanel;
