import { useEffect, useMemo } from 'react';

const OUTER_BOUNDS = [
    { lat: 85, lng: 180 },
    { lat: 85, lng: 90 },
    { lat: 85, lng: 0 },
    { lat: 85, lng: -90 },
    { lat: 85, lng: -180 },
    { lat: 0, lng: -180 },
    { lat: -85, lng: -180 },
    { lat: -85, lng: -90 },
    { lat: -85, lng: 0 },
    { lat: -85, lng: 90 },
    { lat: -85, lng: 180 },
    { lat: 0, lng: 180 },
    { lat: 85, lng: 180 },
];

const useGetRadiusMapPolygons = (
    map: google.maps.Map | null,
    radius: number | null,
    selectedAddressCoords: google.maps.LatLngLiteral | null,
) => {
    const google = window.google;
    const circleRadius = useMemo(() => (radius ? kmToMiles(radius) : null), [radius]);

    const mapBounds = useMemo(() => {
        if (!google?.maps?.LatLngBounds) {
            return null;
        }

        return new google.maps.LatLngBounds();
    }, [google?.maps?.LatLngBounds]);

    // const onDragHighlightPoly = useCallback((highlightPoly?: google.maps.Polygon, shadowPoly?: google.maps.Polygon) => {
    //     if (!highlightPoly || !shadowPoly) {
    //         return;
    //     }

    //     const newCenter = highlightPoly.getPaths().getAt(0).getArray();

    //     const paths = newCenter.map((point) => {
    //         return { lat: point.lat(), lng: point.lng() };
    //     });

    //     shadowPoly.setPaths([...OUTER_BOUNDS, ...paths]);
    // }, []);

    useEffect(() => {
        if (!map || !google?.maps || !mapBounds || !selectedAddressCoords || !circleRadius) {
            return;
        }

        const initialCenter = new google.maps.LatLng(selectedAddressCoords.lat, selectedAddressCoords.lng);

        const shadowPoly = new google.maps.Polygon({
            strokeOpacity: 0,
            fillColor: '#000000',
            fillOpacity: 0.35,
            map: map,
            clickable: false,
            paths: [OUTER_BOUNDS, drawCirclePolygon(initialCenter, circleRadius)],
        });

        const highlighCircle = drawCirclePolygon(initialCenter, circleRadius);
        const highlightPoly = new google.maps.Polygon({
            strokeColor: '#66deb2',
            strokeOpacity: 1,
            strokeWeight: 3,
            fillOpacity: 0,
            map: map,
            // draggable: true,
            paths: [highlighCircle],
        });

        const newMapBounds = getPolygonBounds(highlightPoly);
        map.fitBounds(newMapBounds);

        // const onDragListener = highlightPoly.addListener('drag', () => onDragHighlightPoly(highlightPoly, shadowPoly));

        return () => {
            highlightPoly.setPaths([]);
            highlightPoly.setMap(null);
            shadowPoly.setPaths([]);
            shadowPoly.setMap(null);

            // onDragListener.remove();
        };
    }, [mapBounds, circleRadius, google?.maps, map, selectedAddressCoords]);
};

function kmToMiles(miles: number) {
    return miles * 0.621;
}

function getPolygonBounds(polygon: google.maps.Polygon) {
    const bounds = new google.maps.LatLngBounds();

    polygon.getPath().forEach(function (element, _i) {
        bounds.extend(element);
    });

    return bounds;
}

/**
 * Draws a circle polygon based on the center point and radius in miles.
 *
 * @param point - The center point of the circle
 * @param radius - The radius of the circle in miles
 * @param dir - The direction in which the circle is drawn
 * @param bounds - The bounds of the map
 * @returns - An array of points that make up the circle
 */
function drawCirclePolygon(point: google.maps.LatLng, radius: number): google.maps.LatLng[] {
    const D2R = Math.PI / 180; // Degrees to radians
    const R2D = 180 / Math.PI; // Radians to degrees
    const EarthRadius = 3963; // 3963 is the radius of the earth in miles
    const AmountOfPolygonPoints = 64;

    // find the radius in lat/lon
    const RadiusLat = (radius / EarthRadius) * R2D;
    const Radiuslng = RadiusLat / Math.cos(point.lat() * D2R);

    const extp = [];

    const start = AmountOfPolygonPoints + 1; // One extra to close the circle
    const end = 0;

    for (let i = start; i > end; i = i - 1) {
        const theta = Math.PI * (i / (AmountOfPolygonPoints / 2));
        const ey = point.lng() + Radiuslng * Math.cos(theta); // Center lng formula = lng + radius x * cos(theta)
        const ex = point.lat() + RadiusLat * Math.sin(theta); // Center lat formula = lat + radius y * sin(theta)
        extp.push(new google.maps.LatLng(ex, ey));
    }

    return extp;
}

export default useGetRadiusMapPolygons;
