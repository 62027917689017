import React from 'react';
import { Link } from 'react-router-dom';

const HeadquarterTargeting: React.FC = () => {
    return (
        <div>
            <br />
            Headquarter Targeting!
            <br />
            <Link to={'/'}>To Home</Link>
        </div>
    );
};

export default HeadquarterTargeting;
