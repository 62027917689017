import { FC } from 'react';

import { useLocale } from '@core/utils/locale';
import Zip from '@components/utility/Inputs/Zip/Zip';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { LocalesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';

const PostalCode: FC<{
    inputLabel: string;
    country: CountryCodesEnum;
}> = ({
    inputLabel,
    country
}) => {
    const {
        t,
        locale
    } = useLocale();

    return (
        <Zip
            countryCode={country}
            inputLabel={inputLabel}
            placeholder={
                locale === LocalesEnum.En
                    ? `${t(OperatorTargetingPhrasesEnum.Enter)} ${inputLabel}${t(OperatorTargetingPhrasesEnum.SelectPlural)}`
                    : `${t(OperatorTargetingPhrasesEnum.EnterZip)}`
            }
        />
    );
};

export default PostalCode;
