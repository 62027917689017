import { FC } from 'react';

import { IconArrowCollapse } from '@assets/icons';
import Button from '@components/utility/Button/Button';

import SearchResultsList from './SearchResultsList';
import { OperatorSummary } from '@graphql/generated/graphql';
import { OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';

const DropdownListResults: FC<{
    operators: OperatorSummary[];
    onBackClick: () => void;
    maxHeight?: string;
}> = ({
    operators,
    onBackClick,
    maxHeight
}) => {
    const { t } = useLocale();
    return (
        <div className="ListResults SearchCategory" style={{ maxHeight }}>
            <div className="ListResults__header">
                <div className="ListResults__header-wrapper">
                    <Button modifiers={['naked']} className="caret" onClick={onBackClick} testId="DropdownBackButton">
                        <IconArrowCollapse className="ListResults__header-icon" />
                        <div className="ListResults__header-text">{t(OperatorTargetingPhrasesEnum.Back)}</div>
                    </Button>
                </div>
            </div>
            <div className="SearchCategory__title" data-testid="QuickSearchCategoryTitleOperator">
                Operators
            </div>
            <div className="SearchCategory__list" data-testid="ListQuickSearchResults">
                <SearchResultsList operators={operators} />
            </div>
        </div>
    );
};

export default DropdownListResults;
