import { FC } from 'react';
import { IconNoFood } from '@assets/icons';

import './SavedSearch.styles.scss';
import { useLocale } from '@core/utils/locale';
import { CommonPhrasesEnum } from '@core/enums/localeEnum';

const NoSavedSearch: FC = () => {
    const { t } = useLocale();

    return (
        <div className="NoSavedSearch" data-testid="ContainerNoSavedSearch">
            <IconNoFood />
            <div className="NoSavedSearch__title" data-testid="TitleNoSavedSearch">
                {t(CommonPhrasesEnum.NoSavedSearch)}
            </div>
            <div className="NoSavedSearch__subtitle" data-testid="SubtitleNoSavedSearch">
                {t(CommonPhrasesEnum.NoSavedSearchDescription)}
            </div>
        </div>
    );
};

export default NoSavedSearch;
