import { FC } from 'react';
import { IconNoFood } from '@assets/icons';

import './Inputs.styles.scss';
import { useLocale } from '@core/utils/locale';
import { CommonPhrasesEnum } from '@core/enums/localeEnum';

const NoResultsFound: FC = () => {
    const { t } = useLocale();

    return (
        <div className="NoResultsFound" data-testid="ContainerNoResultsFound">
            <IconNoFood />
            <div className="NoResultsFound__title" data-testid="TitleNoResultsFound">
                {t(CommonPhrasesEnum.NoResultsFound)}
            </div>
            <div className="NoResultsFound__subtitle" data-testid="SubtitleNoResultsFound">
                {t(CommonPhrasesEnum.NoResultsFoundDescription)}
            </div>
        </div>
    );
};

export default NoResultsFound;
