import { createTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { autocompleteClasses } from '@mui/material/Autocomplete';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#66DEB2',
        },
    },
    typography: {
        fontFamily: 'Montserrat',
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: '1px solid rgba(85, 85, 85, 0.1)',
                },
                root: {
                    minHeight: '42px',
                    height: '42px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',

                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: '#66deb2',
                    },
                    [`&.${outlinedInputClasses.disabled}`]: {
                        backgroundColor: '#fafafa',
                        caretColor: 'transparent',
                    },
                    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
                        borderWidth: '1px',
                    },
                    [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'rgba(85, 85, 85, 0.1)',
                    },
                    [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: '#F52D00',
                    },
                },
                input: {
                    padding: '0 14px',
                    fontSize: '14px',
                    color: '#232020',
                    fontWeight: 500,

                    [`&.${outlinedInputClasses.disabled}`]: {
                        color: '#8e8d8d',
                        WebkitTextFillColor: '#8e8d8d',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    [`& .${outlinedInputClasses.root}`]: {
                        padding: '0 9px',
                    },
                    [`&.${autocompleteClasses.hasClearIcon} .${outlinedInputClasses.root}`]: {
                        padding: '0 9px',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: 0,
                    marginTop: '8px',
                    fontSize: '11px',
                    fontWeight: 500,
                    lineHeight: '13px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#232020',
                    marginBottom: '12px',
                    transition: 'none',

                    [`&.${outlinedInputClasses.error}`]: {
                        color: '#D2000A',
                    },
                },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#232020',
                    borderRadius: '8px',

                    ['&:hover']: {
                        cursor: 'pointer',
                    },
                },
                message: {
                    fontWeight: 500,
                },
            },
        },
    },
});

export default theme;
