import { FCX } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { IconFilterClient } from '@assets/icons';
import { FilterTypeEnum } from '@enums/localeEnum';
import { generateAnchorId } from '@core/utils/string';
import Heading from '@components/ui/Typography/Heading';
import { GradientColorsEnum } from '@enums/gradientEnum';
import { CriterionParamFilter } from '@core/models/Criterion';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { CustomerMetadataResponse } from '@core/api/useCustomerMetadata';
import { FILTER_ID_PREFIX } from '@pages/OperatorTargetingCriteria/hooks/useSearchNavigation';

import ClientSwitchList from './ClientSwitchList';

const ClientFiltersCriteria: FCX<{
    customerMetadata: CustomerMetadataResponse | null;
}> = ({ customerMetadata }) => {
    const { t } = useLocale();

    if (!customerMetadata) {
        return null;
    }

    return (
        <div className="CriteriaContent with-gap" id={generateAnchorId(t(FilterTypeEnum.ClientFilters))}>
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterClient />} baseColor={GradientColorsEnum.PURPLE} />
                <Heading testId="ClientSection">{t(FilterTypeEnum.ClientFilters)}</Heading>
            </div>
            <div className="CriteriaContent__section">
                <Widget
                    type={WidgetType.Section}
                    className="criteria"
                    id={generateAnchorId(t(FilterTypeEnum.ClientStatus), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <ClientSwitchList
                        clientFilter={customerMetadata}
                        testId={FilterTypeEnum.ClientStatus}
                        title={t(FilterTypeEnum.ClientStatus)}
                        criterionFilterKey={CriterionParamFilter.CustomerStatus}
                    />
                </Widget>
            </div>
        </div>
    );
};
export default ClientFiltersCriteria;
