import { ID } from '@core/models';
import { PopoverPositionProps } from '../Popover/Popover';

export type SelectOption = {
    label: string;
    id: ID;
    isDisabled?: boolean;
};

export const DEFAULT_POPOVER_POSITION: PopoverPositionProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: -8,
        horizontal: 'left',
    },
};
