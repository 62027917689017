import { FC, useCallback, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { containsSubstring, removeWhitespaces } from '@core/utils/string';
import { OperatorDelivery } from '@graphql/generated/graphql';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import Tooltip from '@components/utility/Tooltip/Tooltip';

import WoltLogo from '@assets/third-party-logo/wolt.jpg';
import GlovoLogo from '@assets/third-party-logo/glovo.svg';
import FoodoraLogo from '@assets/third-party-logo/foodora.png';
import GrubhubLogo from '@assets/third-party-logo/grubhub.png';
import JustEatLogo from '@assets/third-party-logo/just-eat.png';
import TheForkLogo from '@assets/third-party-logo/the-fork.jpeg';
import DoordashLogo from '@assets/third-party-logo/doordash.png';
import UberEatsLogo from '@assets/third-party-logo/uber-eats.png';
import DeliverooLogo from '@assets/third-party-logo/deliveroo.webp';
import LieferandoLogo from '@assets/third-party-logo/lieferando.png';
import ThuisbezorgdLogo from '@assets/third-party-logo/thuisbezorgd.png';
import PostmatesLogo from '@assets/third-party-logo/postmates.png';

enum ThirdPartyDeliveryEnum {
    Foodora = 'foodora',
    Doordash = 'doordash',
    Deliveroo = 'deliveroo',
    JustEat = 'just',
    Lieferando = 'lieferando',
    Thuisbezorgd = 'thuisbezorgd',
    Glovo = 'glovo',
    Wolt = 'wolt',
    UberEats = 'uber',
    TheFork = 'thefork',
    Grubhub = 'grubhub',
    Postmates = 'postmates',
}

type DeliveryData = {
    name: ThirdPartyDeliveryEnum;
    icon: string;
    title: string;
};

const deliveryIcons: DeliveryData[] = [
    { title: 'Foodora', name: ThirdPartyDeliveryEnum.Foodora, icon: FoodoraLogo },
    { title: 'DoorDash', name: ThirdPartyDeliveryEnum.Doordash, icon: DoordashLogo },
    { title: 'Deliveroo', name: ThirdPartyDeliveryEnum.Deliveroo, icon: DeliverooLogo },
    { title: 'Just Eat', name: ThirdPartyDeliveryEnum.JustEat, icon: JustEatLogo },
    { title: 'Lieferando', name: ThirdPartyDeliveryEnum.Lieferando, icon: LieferandoLogo },
    { title: 'Thuisbezorgd', name: ThirdPartyDeliveryEnum.Thuisbezorgd, icon: ThuisbezorgdLogo },
    { title: 'Glovo', name: ThirdPartyDeliveryEnum.Glovo, icon: GlovoLogo },
    { title: 'Wolt', name: ThirdPartyDeliveryEnum.Wolt, icon: WoltLogo },
    { title: 'Uber Eats', name: ThirdPartyDeliveryEnum.UberEats, icon: UberEatsLogo },
    { title: 'The Fork', name: ThirdPartyDeliveryEnum.TheFork, icon: TheForkLogo },
    { title: 'Grubhub', name: ThirdPartyDeliveryEnum.Grubhub, icon: GrubhubLogo },
    { title: 'Postmates', name: ThirdPartyDeliveryEnum.Postmates, icon: PostmatesLogo },
];

const ThirdPartyDelivery: FC<{
    delivery: OperatorDelivery[] | null;
}> = ({ delivery }) => {
    const { t } = useLocale();

    const findDeliveryByUrl = useCallback((deliveryUrl: string): DeliveryData | undefined => {
        return deliveryIcons.find((item) => containsSubstring(deliveryUrl, item.name));
    }, []);

    const renderDeliveries = useMemo(() => {
        if (!delivery) {
            return null;
        }

        return delivery
            .filter((item) => item?.url)
            .map((item) => {
                const deliveryData = findDeliveryByUrl(item?.url as string);

                if (!deliveryData) {
                    return null;
                }

                return (
                    <Tooltip
                        key={item.type}
                        content={deliveryData.title}
                        placement="bottom"
                        testId={`Tooltip${removeWhitespaces(deliveryData.title)}`}
                    >
                        <a
                            href={item?.url as string}
                            className="ThirdPartyDelivery__item"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img className="ThirdPartyDelivery__icon" src={deliveryData.icon} alt="plugin" />
                        </a>
                    </Tooltip>
                );
            });
    }, [delivery, findDeliveryByUrl]);

    if (!delivery || !delivery.length) {
        return null;
    }

    return (
        <div className="OperatorProfileLocation__info-column">
            <Heading variant={HeadingVariant.H5}>{t(OperatorProfilePhrasesEnum.ThirdPartyDelivery)}</Heading>
            <div className="ThirdPartyDelivery">{renderDeliveries}</div>
        </div>
    );
};

export default ThirdPartyDelivery;
