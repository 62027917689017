import { FC } from 'react';

import { useLocale } from '@core/utils/locale';
import { LocalesEnum, PhrasesEnum } from '@core/enums/localeEnum';
import { capitalize, formatNumberByLocale } from '@core/utils/string';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { DonutSlice, GradientStyles } from '@components/utility/Charts/DonutChart/DonutChart';

const LEVEL_PRIORITY_ORDER = ['HIGH', 'MEDIUM', 'LOW'];

const ConfidenceLevelChartLegend: FC<{
    aggregations: DonutSlice[] | null | undefined;
    locale: LocalesEnum;
    styles: GradientStyles;
    showPercents: boolean;
}> = ({
    aggregations,
    locale,
    styles,
    showPercents
}) => {
    const { t } = useLocale();
    return (
        <div className="OperatorResultsSummary__legend">
            {LEVEL_PRIORITY_ORDER.map((key) => {
                const item = aggregations?.find((item) => item.id?.toString().toUpperCase() === key) || {
                    id: key,
                    value: 0,
                    percent: 0,
                };
                return item;
            }).map((item, index) => (
                <div key={index} className="OperatorResultsSummary__legend-item">
                    <Heading variant={HeadingVariant.H4}>
                        {showPercents ? item.percent.toFixed(1) + '%' : formatNumberByLocale(item.value, locale)}
                    </Heading>
                    <div className="OperatorResultsSummary__legend-details">
                        <div
                            className="OperatorResultsSummary__legend-color"
                            style={{
                                background: `linear-gradient(
                                                to right,
                                                ${styles[`gradient${item.id?.toString()}`].startColor},
                                                ${styles[`gradient${item.id?.toString()}`].endColor}
                                            )`,
                            }}
                        />
                        <span className="OperatorResultsSummary__legend-label">
                            {t(capitalize(item.id?.toString() ?? '') as PhrasesEnum)}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ConfidenceLevelChartLegend;
