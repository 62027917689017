import { useCallback, useMemo } from 'react';

import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';
import useFetch from '@hooks/useFetch';

import { usePermissions } from '@core/hooks/usePermissions';
import { useAppContext } from '../../App.context';

const GET_COUNTRIES_URL = '/countries';

type GetCountriesEndpoint = paths[typeof GET_COUNTRIES_URL][MethodsEnum.GET];
type CountriesParams = GetCountriesEndpoint[OperationsEnum.PARAMETERS]['query'];
export type AllCountriesResponse = GetCountriesEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];

export default function useCountries(lazy = false, params?: CountriesParams) {
    const {
        getAllowedItemsFromArray,
        hasPermissionToValue
    } = usePermissions();
    const {
        userInfo: { defaultCountry },
    } = useAppContext();

    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<AllCountriesResponse>({
        url: GET_COUNTRIES_URL,
        method: 'GET',
        lazy,
        initialConfig: {
            params,
        },
    });

    const doFetchWithParams = useCallback(
        (queryParams?: CountriesParams) => {
            doFetch({
                params: queryParams,
            });
        },
        [doFetch],
    );

    const countryInfo = useMemo(() => {
        const sortedCountries = sortCountries(data?.countries);

        if (defaultCountry && hasPermissionToValue('countries', defaultCountry)) {
            return {
                sortedCountries,
                defaultOrFirstAllowedCountry: sortedCountries?.find(
                    ({ abbreviation }) => abbreviation === defaultCountry,
                ),
            };
        }

        const countryAbbreviations =
            data?.countries?.map(({ abbreviation }) => abbreviation ?? '').filter(Boolean) ?? [];

        const availableCountries = getAllowedItemsFromArray('countries', countryAbbreviations);

        const defaultOrFirstAllowedCountry = sortedCountries?.find(({ abbreviation }) =>
            availableCountries.includes(abbreviation ?? ''),
        );

        return {
            sortedCountries,
            defaultOrFirstAllowedCountry,
        };
    }, [data?.countries, defaultCountry, getAllowedItemsFromArray, hasPermissionToValue]);

    return {
        data: countryInfo.sortedCountries,
        defaultOrFirstAllowedCountry: countryInfo.defaultOrFirstAllowedCountry,
        loading,
        error,
        doFetch: doFetchWithParams,
    };
}

function sortCountries(countries: AllCountriesResponse['countries']): AllCountriesResponse['countries'] {
    if (!countries) return undefined;

    const newCountries = [...countries];

    return newCountries.sort((a, b) => {
        if (a?.name && b?.name) {
            return a.name > b.name ? 1 : -1;
        }
        return 0;
    });
}
