import { FC } from 'react';

import { useLocale } from '@core/utils/locale';
import { truncateString } from '@core/utils/string';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { getClickableLink, getFormattedPhoneNumber } from '@core/helpers/helpers';

import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import { IconInternet, IconLocation, IconMail, IconPhone } from '@assets/icons';
import { OperatorDelivery, OperatorLocationDetails, OperatorSocialMedia } from '@graphql/generated/graphql';

import WorkingHours from './WorkingHours';
import './OperatorProfileLocation.styles.scss';
import SocialLinks from './SocialLinks/SocialLinks';
import ThirdPartyDelivery from './ThirdPartyDelivery';
import OperatorProfileMap from './OperatorProfileMap/OperatorProfileMap';
import { buildAddress, parseSocialMedia } from '../OperatorProfile.helpers';

const OperatorProfileLocation: FC<{
    location?: OperatorLocationDetails;
    website?: string | null;
    phone?: string | null;
    email?: string | null;
    delivery?: OperatorDelivery[] | null;
    workingHours?: string | null;
    socialMedia?: OperatorSocialMedia[] | null;
    operatorName: string;
}> = ({
    operatorName,
    location,
    website,
    phone,
    email,
    workingHours,
    socialMedia = [],
    delivery = []
}) => {
    const socialMediaItems = parseSocialMedia(socialMedia);
    const DEFAULT_WEBSITE_LEN = 30;
    const DEFAULT_PHONE_COUNTRY = 'US';
    const { t } = useLocale();

    const getLocalizedAreaType = (areaType: string | undefined) => {
        const areaTypeMap: { [key: string]: string } = {
            rural: t(OperatorProfilePhrasesEnum.Rural),
            urban: t(OperatorProfilePhrasesEnum.Urban),
            suburban: t(OperatorProfilePhrasesEnum.Suburban),
        };

        return areaType ? areaTypeMap[areaType.toLowerCase()] || '' : '';
    };

    return (
        <div className="OperatorProfileLocation">
            <Widget type={WidgetType.Section}>
                <div className="OperatorProfileLocation__container">
                    <section className="OperatorProfileLocation__data-section">
                        <div className="OperatorProfileLocation__info">
                            <div className="OperatorProfileLocation__info-column body-2 body-2--black">
                                {location && (
                                    <div className="flex" style={{ whiteSpace: 'pre-line' }}>
                                        <div className="OperatorProfileLocation__icon">
                                            <IconLocation />
                                        </div>
                                        <span data-testid="textAddress">
                                            {buildAddress(location, location?.country ?? '')}
                                        </span>
                                    </div>
                                )}
                                {getLocalizedAreaType(location?.area_type ?? '') && (
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconLocation />
                                        </div>
                                        <span style={{ textTransform: 'capitalize' }} data-testid="textAreaType">
                                            {getLocalizedAreaType(location?.area_type ?? '')}
                                        </span>
                                    </div>
                                )}
                                {location?.metro?.name && (
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconLocation />
                                        </div>
                                        <span style={{ textTransform: 'capitalize' }} data-testid="textDMA">
                                            {location?.metro?.name}
                                        </span>
                                    </div>
                                )}
                                {phone && phone.length > 2 && (
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconPhone />
                                        </div>
                                        <Button
                                            type={ButtonTypeEnum.Anchor}
                                            modifiers={['naked', 'auto']}
                                            link={`tel:${phone}`}
                                            testId="textLinkPhone"
                                        >
                                            {getFormattedPhoneNumber(phone, location?.country ?? DEFAULT_PHONE_COUNTRY)}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="OperatorProfileLocation__info-column">
                                {email && (
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconMail />
                                        </div>
                                        <Button
                                            type={ButtonTypeEnum.Anchor}
                                            modifiers={['naked', 'auto']}
                                            link={`mailto:${email}`}
                                            testId="textLinkEmail"
                                        >
                                            {email}
                                        </Button>
                                    </div>
                                )}

                                {website && (
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconInternet />
                                        </div>
                                        <Button
                                            type={ButtonTypeEnum.Anchor}
                                            modifiers={['naked', 'auto']}
                                            link={getClickableLink(website)}
                                            testId="textLinkWebsite"
                                        >
                                            {truncateString(website, DEFAULT_WEBSITE_LEN)}
                                        </Button>
                                    </div>
                                )}
                                {socialMediaItems?.length > 0 && <SocialLinks socialMedia={socialMedia} />}
                            </div>
                        </div>
                        {(workingHours || (delivery && delivery?.length > 0)) && (
                            <>
                                <div className="OperatorProfileLocation__separator" />
                                <div className="OperatorProfileLocation__info">
                                    <WorkingHours workingHours={workingHours} />

                                    <ThirdPartyDelivery delivery={delivery} />
                                </div>
                            </>
                        )}
                    </section>

                    {location?.latitude && location?.longitude && (
                        <section className="OperatorProfileLocation__map-section">
                            <OperatorProfileMap
                                name={operatorName}
                                coords={{
                                    lat: location?.latitude,
                                    lng: location?.longitude,
                                }}
                            />
                        </section>
                    )}
                </div>
            </Widget>
        </div>
    );
};

export default OperatorProfileLocation;
