import { FCX } from '@core/models';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';
import './Button.styles.scss';
import { MouseEvent } from 'react';

export enum ButtonTypeEnum {
    Button,
    Link,
    NavLink,
    Anchor,
}

export type ButtonModifiers =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'dropdown'
    | 'naked'
    | 'auto'
    | 'error-border'
    | 'success-border';

const Button: FCX<{
    type?: ButtonTypeEnum;
    link?: string;
    onClick?: (e?: MouseEvent<HTMLElement>) => void;
    isDisabled?: boolean;
    modifiers?: Array<ButtonModifiers>;
}> = ({
    type = ButtonTypeEnum.Button,
    link,
    onClick,
    className = '',
    children,
    isDisabled = false,
    modifiers = [],
    style,
    testId,
}) => {
    const classNames = cn(
        'Button',
        modifiers.map((m) => `Button--${m}`),
        className,
        isDisabled ? 'Button--disabled' : '',
    );

    const props = {
        onClick,
        className: classNames,
        children,
        style,
        ...(testId ? { 'data-testid': testId } : {}),
    };

    switch (type) {
        case ButtonTypeEnum.Button: {
            return <button {...props} disabled={isDisabled} />;
        }
        case ButtonTypeEnum.Link: {
            return <Link to={link || '#'} {...props} />;
        }
        case ButtonTypeEnum.NavLink: {
            return <NavLink to={link || '#'} {...props} />;
        }
        case ButtonTypeEnum.Anchor: {
            return <a target="_blank" href={link || '#'} {...props} rel="noreferrer" />;
        }
        default:
            return null;
    }
};

export default Button;
