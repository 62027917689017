import { FC, useCallback } from 'react';
import cn from 'classnames';

import { ID } from '@core/models';
import { toggleInArray } from '@core/helpers/helpers';
import { GradientColorsEnum } from '@enums/gradientEnum';
import SwitchPill from '@components/utility/SwitchPill/SwitchPill';

import './SwitchList.styles.scss';

export type SwitchListOption = {
    id: ID;
    title: string;
    subtitle?: string;
    min?: number;
    max?: number;
};

const SwitchList: FC<{
    options: SwitchListOption[];
    value: ID[];
    onChange: (value: ID[]) => void;
    color: GradientColorsEnum;
    columns: number;
    isSmall?: boolean;
}> = ({
    options,
    value,
    onChange,
    color,
    columns,
    isSmall = false
}) => {
    const handleToggle = useCallback(
        (id: ID) => {
            onChange(toggleInArray(value, id));
        },
        [value, onChange],
    );
    const adjustedColumns = isSmall ? 2 : columns;

    return (
        <div className={cn('SwitchList', `SwitchList__col-${adjustedColumns}`)} data-testid="SwitchList">
            {options.map((option) => (
                <SwitchPill
                    key={option.id}
                    hasIcon
                    testId={option.id as string}
                    color={color}
                    isChecked={value.includes(option.id)}
                    onChange={() => handleToggle(option.id)}
                    title={
                        <>
                            {option.title}
                            {option?.subtitle && <div className="SwitchList__subtitle">{option.subtitle}</div>}
                        </>
                    }
                    className={cn(
                        'SwitchList__list-item',
                        isSmall && 'SwitchList__list-item--small',
                        option?.subtitle && 'SwitchList__list-item--bold',
                    )}
                />
            ))}
        </div>
    );
};

export default SwitchList;
