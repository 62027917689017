import { FC } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { IconMinusSign, IconPlusSign } from '@assets/icons';

const CustomZoomControl: FC = () => {
    const map = useGoogleMap();

    if (!map) {
        return null;
    }

    return (
        <div className="ZoomControl">
            <div
                data-testid="ZoomControlButton"
                className="ZoomControl__button"
                onClick={() => {
                    const zoom = map.getZoom();
                    if (zoom !== undefined) {
                        map.setZoom(zoom + 1);
                    }
                }}
            >
                <IconPlusSign className="ZoomControl__button-icon" />
            </div>
            <div
                className="ZoomControl__button"
                onClick={() => {
                    const zoom = map.getZoom();
                    if (zoom) {
                        map.setZoom(zoom - 1);
                    }
                }}
            >
                <IconMinusSign className="ZoomControl__button-icon" />
            </div>
        </div>
    );
};

export { CustomZoomControl };
