import { FC } from 'react';

import './SocialLinks.styles.scss';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import { useLocale } from '@core/utils/locale';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { OperatorSocialMedia } from '@graphql/generated/graphql';
import { parseSocialMedia } from '../../OperatorProfile.helpers';

const SocialLinks: FC<{
    socialMedia?: OperatorSocialMedia[] | null;
}> = ({ socialMedia = [] }) => {
    const { t } = useLocale();
    const socialMediaItems = parseSocialMedia(socialMedia);

    return (
        <div className="SocialLinks">
            <span data-testid="textSocialLinks" className="SocialLinks__heading-description">
                {t(OperatorProfilePhrasesEnum.SocialLinks)}
            </span>
            <div className="OperatorProfileLocation__media-icons">
                {socialMediaItems.map((item) => (
                    <Button
                        key={item.url}
                        type={ButtonTypeEnum.Anchor}
                        link={item.url}
                        modifiers={['auto']}
                        testId={`iconSocialMedia${item.type}`}
                    >
                        {item.icon}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default SocialLinks;
