import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { LocalesEnum } from '@core/enums/localeEnum';

export function getCurrencySymbol(countryCode: CountryCodesEnum): string {
    const currencyMap: { [key in CountryCodesEnum]?: string } = {
        [CountryCodesEnum.US]: '$',
        [CountryCodesEnum.CA]: '$',
        [CountryCodesEnum.AR]: '$',
        [CountryCodesEnum.BB]: '$',
        [CountryCodesEnum.CL]: '$',
        [CountryCodesEnum.CO]: '$',
        [CountryCodesEnum.DO]: '$',
        [CountryCodesEnum.EC]: '$',
        [CountryCodesEnum.HK]: '$',
        [CountryCodesEnum.PA]: '$',
        [CountryCodesEnum.PR]: '$',
        [CountryCodesEnum.MX]: '$',
        [CountryCodesEnum.SG]: '$',
        [CountryCodesEnum.SV]: '$',
        [CountryCodesEnum.AU]: '$',
        [CountryCodesEnum.UY]: '$',
        [CountryCodesEnum.NZ]: '$',

        [CountryCodesEnum.FR]: '€',
        [CountryCodesEnum.IT]: '€',
        [CountryCodesEnum.ES]: '€',
        [CountryCodesEnum.DE]: '€',
        [CountryCodesEnum.AT]: '€',
        [CountryCodesEnum.BE]: '€',
        [CountryCodesEnum.PT]: '€',
        [CountryCodesEnum.FI]: '€',
        [CountryCodesEnum.GR]: '€',
        [CountryCodesEnum.IE]: '€',
        [CountryCodesEnum.LU]: '€',
        [CountryCodesEnum.NL]: '€',
        [CountryCodesEnum.HR]: '€',
        [CountryCodesEnum.CY]: '€',
        [CountryCodesEnum.EE]: '€',
        [CountryCodesEnum.LT]: '€',
        [CountryCodesEnum.LV]: '€',
        [CountryCodesEnum.ME]: '€',
        [CountryCodesEnum.MT]: '€',
        [CountryCodesEnum.SI]: '€',
        [CountryCodesEnum.SK]: '€',

        [CountryCodesEnum.NO]: 'kr',
        [CountryCodesEnum.SE]: 'kr',
        [CountryCodesEnum.IS]: 'kr',

        [CountryCodesEnum.PL]: 'zł',

        [CountryCodesEnum.CH]: '₣',

        [CountryCodesEnum.TR]: '₺',

        [CountryCodesEnum.UK]: '£',
        [CountryCodesEnum.EG]: '£',
        [CountryCodesEnum.LB]: '£',

        [CountryCodesEnum.DK]: 'kr.',

        [CountryCodesEnum.AZ]: '₼',

        [CountryCodesEnum.BG]: 'лв',

        [CountryCodesEnum.BR]: 'R$',

        [CountryCodesEnum.BS]: 'B$',

        [CountryCodesEnum.CR]: '₡',

        [CountryCodesEnum.CZ]: 'Kč',

        [CountryCodesEnum.GE]: '₾',

        [CountryCodesEnum.GT]: 'Q',

        [CountryCodesEnum.HN]: 'L',

        [CountryCodesEnum.HU]: 'Ft',

        [CountryCodesEnum.IL]: '₪',

        [CountryCodesEnum.JP]: '¥',

        [CountryCodesEnum.KW]: 'KD',

        [CountryCodesEnum.KZ]: '₸',

        [CountryCodesEnum.MY]: 'RM',

        [CountryCodesEnum.RO]: 'lei',

        [CountryCodesEnum.RS]: 'Дин.',

        [CountryCodesEnum.TH]: '฿',

        [CountryCodesEnum.UA]: '₴',

        [CountryCodesEnum.VN]: '₫',

        [CountryCodesEnum.PH]: '₱',

        [CountryCodesEnum.ID]: '₹',

        [CountryCodesEnum.PK]: 'Rs',

        [CountryCodesEnum.UZ]: 'лв',
    };

    return currencyMap[countryCode] || '€';
}

export function getCurrencyCode(countryCode: CountryCodesEnum): string {
    const currencyMap: { [key in CountryCodesEnum]?: string } = {
        [CountryCodesEnum.US]: 'USD',
        [CountryCodesEnum.EC]: 'USD',
        [CountryCodesEnum.PA]: 'USD',
        [CountryCodesEnum.PR]: 'USD',
        [CountryCodesEnum.SV]: 'USD',
        [CountryCodesEnum.CA]: 'CAD',
        [CountryCodesEnum.FR]: 'EUR',
        [CountryCodesEnum.IT]: 'EUR',
        [CountryCodesEnum.ES]: 'EUR',
        [CountryCodesEnum.DE]: 'EUR',
        [CountryCodesEnum.AT]: 'EUR',
        [CountryCodesEnum.BE]: 'EUR',
        [CountryCodesEnum.PT]: 'EUR',
        [CountryCodesEnum.FI]: 'EUR',
        [CountryCodesEnum.GR]: 'EUR',
        [CountryCodesEnum.IE]: 'EUR',
        [CountryCodesEnum.LU]: 'EUR',
        [CountryCodesEnum.NL]: 'EUR',
        [CountryCodesEnum.HR]: 'EUR',
        [CountryCodesEnum.CY]: 'EUR',
        [CountryCodesEnum.EE]: 'EUR',
        [CountryCodesEnum.LT]: 'EUR',
        [CountryCodesEnum.LV]: 'EUR',
        [CountryCodesEnum.ME]: 'EUR',
        [CountryCodesEnum.MT]: 'EUR',
        [CountryCodesEnum.SI]: 'EUR',
        [CountryCodesEnum.SK]: 'EUR',
        [CountryCodesEnum.NO]: 'NOK',
        [CountryCodesEnum.PL]: 'PLN',
        [CountryCodesEnum.SE]: 'SEK',
        [CountryCodesEnum.CH]: 'CHF',
        [CountryCodesEnum.TR]: 'TRY',
        [CountryCodesEnum.UK]: 'GBP',
        [CountryCodesEnum.EG]: 'GBP',
        [CountryCodesEnum.LB]: 'GBP',
        [CountryCodesEnum.DK]: 'DKK',
        [CountryCodesEnum.AE]: 'AED',
        [CountryCodesEnum.AR]: 'ARS',
        [CountryCodesEnum.AZ]: 'AZN',
        [CountryCodesEnum.BB]: 'BBD',
        [CountryCodesEnum.BG]: 'BGN',
        [CountryCodesEnum.BH]: 'BHD',
        [CountryCodesEnum.BR]: 'BRL',
        [CountryCodesEnum.BS]: 'BSD',
        [CountryCodesEnum.CL]: 'CLP',
        [CountryCodesEnum.CO]: 'COP',
        [CountryCodesEnum.CR]: 'CRC',
        [CountryCodesEnum.CZ]: 'CZK',
        [CountryCodesEnum.DO]: 'DOP',
        [CountryCodesEnum.GE]: 'GEL',
        [CountryCodesEnum.GT]: 'GTQ',
        [CountryCodesEnum.HK]: 'HKD',
        [CountryCodesEnum.HN]: 'HNL',
        [CountryCodesEnum.HU]: 'HUF',
        [CountryCodesEnum.IL]: 'ILS',
        [CountryCodesEnum.IS]: 'ISK',
        [CountryCodesEnum.JP]: 'JPY',
        [CountryCodesEnum.KW]: 'KWD',
        [CountryCodesEnum.KZ]: 'KZT',
        [CountryCodesEnum.MA]: 'MAD',
        [CountryCodesEnum.MX]: 'MXN',
        [CountryCodesEnum.MY]: 'MYR',
        [CountryCodesEnum.QA]: 'QAR',
        [CountryCodesEnum.RO]: 'RON',
        [CountryCodesEnum.RS]: 'RSD',
        [CountryCodesEnum.SG]: 'SGD',
        [CountryCodesEnum.TH]: 'THB',
        [CountryCodesEnum.UA]: 'UAH',
        [CountryCodesEnum.UY]: 'UYU',
        [CountryCodesEnum.VN]: 'VND',
        [CountryCodesEnum.PH]: 'PHP',
        [CountryCodesEnum.ID]: 'INR',
        [CountryCodesEnum.PK]: 'PKR',
        [CountryCodesEnum.AU]: 'AUD',
        [CountryCodesEnum.NZ]: 'NZD',
        [CountryCodesEnum.UZ]: 'UZS',
    };

    return currencyMap[countryCode] || 'EUR';
}

export function localizeNumber(number: number, locale = LocalesEnum.En): string {
    const formatOptions = {
        minimumFractionDigits: Number.isInteger(number) ? 0 : 2,
        maximumFractionDigits: 2,
    };

    if (locale === LocalesEnum.En) {
        return number.toLocaleString('en-US', formatOptions);
    }

    return number.toLocaleString('fr-FR', formatOptions);
}

export function formatPrice(countryCode: CountryCodesEnum, price?: number, locale?: LocalesEnum): string | undefined {
    const symbol = getCurrencySymbol(countryCode);

    if (price === undefined) {
        return undefined;
    }

    return `${symbol}${localizeNumber(price, locale)}`;
}

export function formatSimplePriceRange(
    countryCode: CountryCodesEnum,
    min: number,
    max: number,
    locale?: LocalesEnum,
): string {
    const symbol = getCurrencySymbol(countryCode);
    const currencyCode = getCurrencyCode(countryCode);

    const separator = ' - ';
    const minPrice = `${symbol}${localizeNumber(min, locale)}`;
    const maxPrice = `${symbol}${localizeNumber(max, locale)} ${currencyCode}`;

    return `${minPrice}${separator}${maxPrice}`;
}

export function formatUnifiedRange(
    countryCode: CountryCodesEnum,
    min: number,
    max: number,
    locale?: LocalesEnum,
): string {
    const symbol = getCurrencySymbol(countryCode);
    const currencyCode = getCurrencyCode(countryCode);

    if (!max) {
        return `> ${symbol}${localizeNumber(min, locale)} ${currencyCode}`;
    }

    if (!min) {
        return `< ${symbol}${localizeNumber(max, locale)} ${currencyCode}`;
    }

    return `${symbol}${localizeNumber(min, locale)} - ${symbol}${localizeNumber(max, locale)} ${currencyCode}`;
}
