import { FC, useCallback, useMemo } from 'react';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { CriterionParamFilter } from '@core/models/Criterion';
import { OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';

import {
    filterAndSortSegments,
    ClassificationDTO,
    mapSegmentToDropdownOption,
} from '../../helpers/Classification.helpers';
import { SEGMENT_CRITERION } from './SegmentsContent';

const ChannelActionButton: FC<{
    channel: ClassificationDTO;
    onClearAllChannelSegments: (channel: ClassificationDTO) => void;
    setselectedSegments: React.Dispatch<React.SetStateAction<ID[][]>>;
}> = ({
    channel,
    onClearAllChannelSegments,
    setselectedSegments
}) => {
    const {
        getCriterionByFilterKey,
        addCriterionOnOptionSelected
    } = useAdvancedSearch();
    const { t } = useLocale();

    const onSelectAllSegments = useCallback(
        (currChannel: ClassificationDTO) => {
            onClearAllChannelSegments(currChannel);

            const channelPill = mapSegmentToDropdownOption(currChannel);
            addCriterionOnOptionSelected(SEGMENT_CRITERION, CriterionParamFilter.Segment)(channelPill);

            const segments: ID[][] =
                filterAndSortSegments(currChannel?.children)?.map((subChannel) => {
                    return (
                        filterAndSortSegments(subChannel?.children)?.map((segment) => segment?.external_id as ID) ?? []
                    );
                }) ?? [];

            setselectedSegments(segments);
        },
        [addCriterionOnOptionSelected, onClearAllChannelSegments, setselectedSegments],
    );

    const areAllSegmentsSelected = useMemo(() => {
        const segmentParams = getCriterionByFilterKey(SEGMENT_CRITERION, CriterionParamFilter.Segment);

        return !!segmentParams?.some((segment) => segment?.value === channel?.external_id);
    }, [channel?.external_id, getCriterionByFilterKey]);

    const onClearAll = (channelToClear: ClassificationDTO) => {
        onClearAllChannelSegments(channelToClear);
        setselectedSegments((prevSelected) => {
            return prevSelected.map((_) => []);
        });
    };

    const areAllSegmentsDisabled =
        channel?.children?.every(
            (subchannel) =>
                subchannel?.children
                    ?.filter((segment) => segment?.active === true)
                    .every((segment) => segment?.allowed === false) ?? true,
        ) ?? true;

    return (
        <Button
            modifiers={['naked']}
            type={ButtonTypeEnum.Link}
            onClick={() => {
                areAllSegmentsSelected ? onClearAll(channel) : onSelectAllSegments(channel);
            }}
            style={{ width: 'auto', marginLeft: '32px' }}
            isDisabled={areAllSegmentsDisabled}
            testId={areAllSegmentsSelected ? 'ButtonSegmentsClearAll' : 'ButtonSegmentsSelectAll'}
        >
            {areAllSegmentsSelected
                ? t(OperatorTargetingPhrasesEnum.ClearAll)
                : t(OperatorTargetingPhrasesEnum.SelectAll)}
        </Button>
    );
};
export default ChannelActionButton;
