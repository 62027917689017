import { FC } from 'react';
import MuiModal, { ModalProps } from '@mui/material/Modal';

import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import CloseButton from '@components/utility/Button/CloseButton/CloseButton';

import './Modal.styles.scss';

const Modal: FC<{
    children: React.ReactNode;
    title: string;
    isOpen: boolean;
    handleClose: () => void;
    modalProps?: Partial<ModalProps>;
}> = ({
    children,
    handleClose,
    isOpen,
    title,
    modalProps = {}
}) => (
    <MuiModal open={isOpen} onClose={() => handleClose()} {...modalProps}>
        <div className="Modal">
            <Widget type={WidgetType.Modal}>
                <div className="Modal__header">
                    <div className="Modal__title" data-testid={`ModalTitle${title}`}>
                        {title}
                    </div>
                    <CloseButton onClick={handleClose} />
                </div>
                {children}
            </Widget>
        </div>
    </MuiModal>
);

export default Modal;
