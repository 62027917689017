import { useCallback } from 'react';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { capitalize } from '@core/utils/string';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { ConfidenceLevel } from '@graphql/generated/graphql';
import { CRITERIA } from '@core/hooks/useAdvancedSearchCriteria';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { CriteriaData, CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';

export type useCriteriaDrilldownReturnType = {
    onDrillDownSegments: (segmentId: ID, segmentName: string) => void;
    onDrillDownChainsVsIndependents: (id: ID) => void;
    onDrillDownAnnualSalesRange: (annualSalesRangeID: ID, annualSalesRangeName: string) => void;
    onDrillDownConfidenceLevel: (level: ConfidenceLevel) => void;
    onDrillDownCuisine: (cuisineId: ID, cuisineName: string) => void;
};

export const DRILL_DOWN_LOCAL_STORAGE = 'DrillDownResults';
const INDEPENDENTS_ID = 'independent';
const INDEPENDENTS_CODE = 'R06.WW98';

function useCriteriaDrilldown(chainParams: CriterionParam[]): useCriteriaDrilldownReturnType {
    const { t } = useLocale();

    const {
        criteria,
        getCriterionByFilterKey
    } = useAdvancedSearch();

    const onDrillDownSegments = useCallback(
        (segmentId: ID, segmentName: string) => {
            const segmentCriteria = getCriterionByFilterKey(CriterionEnum.Segments, CriterionParamFilter.Segment) ?? [];

            if (segmentCriteria?.length > 0) {
                const subChannelId = segmentId ? segmentId.toString().split('.')[0] : '';
                const segmentSubchannel = segmentCriteria.filter((param) => param.value.toString() === subChannelId);
                const filteredSegments = segmentCriteria.filter((param) =>
                    param.value.toString().startsWith(segmentId.toString()),
                );

                const drillDownCriterionParams =
                    segmentSubchannel.length > 0
                        ? [
                            {
                                value: segmentId,
                                name: segmentName,
                                filterKey: CriterionParamFilter.Segment,
                                additionalData: undefined,
                            },
                        ]
                        : [...filteredSegments, ...segmentSubchannel];

                const drillDownCriteria = mergeDrillDownCriteria(
                    criteria,
                    CriterionEnum.Segments,
                    drillDownCriterionParams,
                );

                return openDrillDownResults(drillDownCriteria);
            }

            const drillDownCriteria = mergeDrillDownCriteria(criteria, CriterionEnum.Segments, [
                {
                    value: segmentId,
                    name: segmentName,
                    filterKey: CriterionParamFilter.Segment,
                    additionalData: undefined,
                },
            ]);

            const newCriteria = parseMockedCriteria(drillDownCriteria);

            openDrillDownResults(newCriteria);
        },
        [criteria, getCriterionByFilterKey],
    );

    const onDrillDownAnnualSalesRange = useCallback(
        (annualSalesRangeID: ID, annualSalesRangeName: string) => {
            const annualSalesRangeCriteria =
                getCriterionByFilterKey(CriterionEnum.General, CriterionParamFilter.AnnualSalesRange) ?? [];

            if (annualSalesRangeCriteria?.length > 0) {
                return openDrillDownResults(criteria);
            }

            const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];

            const drillDownCriterionParams = [
                ...allGeneralCriteria,
                {
                    value: annualSalesRangeID,
                    name: `${t(OperatorProfilePhrasesEnum.EstimatedAnnualSales)}: ${annualSalesRangeName}`,
                    filterKey: CriterionParamFilter.AnnualSalesRange,
                    additionalData: undefined,
                },
            ];

            const drillDownCriteria = mergeDrillDownCriteria(criteria, CriterionEnum.General, drillDownCriterionParams);

            const newCriteria = parseMockedCriteria(drillDownCriteria);

            return openDrillDownResults(newCriteria);
        },
        [criteria, getCriterionByFilterKey, t],
    );

    const onDrillDownChainsVsIndependents = useCallback(
        (id: ID) => {
            const isIndependents = id === INDEPENDENTS_ID;

            const chainSizeCriteria =
                getCriterionByFilterKey(CriterionEnum.General, CriterionParamFilter.ChainSizeRange) ?? [];

            if (chainSizeCriteria?.length > 0) {
                const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];
                const filteredGeneralCriteria = allGeneralCriteria.filter((criterion) => {
                    if (criterion.filterKey === CriterionParamFilter.ChainSizeRange) {
                        if (isIndependents) {
                            return criterion.value === INDEPENDENTS_CODE;
                        }

                        return criterion.value !== INDEPENDENTS_CODE;
                    }
                    return true;
                });

                const drillDownCriteria = mergeDrillDownCriteria(
                    criteria,
                    CriterionEnum.General,
                    filteredGeneralCriteria,
                );

                const newCriteria = parseMockedCriteria(drillDownCriteria);

                return openDrillDownResults(newCriteria);
            }

            const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];

            const ChainSizeRangesToBeAdded = isIndependents
                ? chainParams.filter((range) => range.value === INDEPENDENTS_CODE)
                : chainParams.filter((range) => range.value !== INDEPENDENTS_CODE);

            const drillDownCriteria = mergeDrillDownCriteria(criteria, CriterionEnum.General, [
                ...allGeneralCriteria,
                ...ChainSizeRangesToBeAdded,
            ]);

            const newCriteria = parseMockedCriteria(drillDownCriteria);

            return openDrillDownResults(newCriteria);
        },
        [chainParams, criteria, getCriterionByFilterKey],
    );

    const onDrillDownConfidenceLevel = useCallback(
        (level: ConfidenceLevel) => {
            const ConfidenceLevelCriteria =
                getCriterionByFilterKey(CriterionEnum.General, CriterionParamFilter.ConfidenceLevel) ?? [];

            if (ConfidenceLevelCriteria?.length > 0) {
                return openDrillDownResults(criteria);
            }

            const allGeneralCriteria = criteria?.[CriterionEnum.General]?.CriterionParams ?? [];

            const drillDownCriterionParams = [
                ...allGeneralCriteria,
                {
                    value: level,
                    name: `${t(OperatorProfilePhrasesEnum.ConfidenceLevel)}: ${capitalize(level)}`,
                    filterKey: CriterionParamFilter.ConfidenceLevel,
                    additionalData: undefined,
                },
            ];

            const drillDownCriteria = mergeDrillDownCriteria(criteria, CriterionEnum.General, drillDownCriterionParams);

            const newCriteria = parseMockedCriteria(drillDownCriteria);

            return openDrillDownResults(newCriteria);
        },
        [criteria, getCriterionByFilterKey, t],
    );

    const onDrillDownCuisine = useCallback(
        (cuisineId: ID, cuisineName: string) => {
            const cuisineCriteria =
                getCriterionByFilterKey(CriterionEnum.CuisineTypes, CriterionParamFilter.Cuisine) ?? [];

            if (cuisineCriteria?.length > 0) {
                return openDrillDownResults(criteria);
            }

            const allCuisineCriteria = criteria?.[CriterionEnum.CuisineTypes]?.CriterionParams ?? [];

            const drillDownCriterionParams = [
                ...allCuisineCriteria,
                {
                    value: cuisineId,
                    name: cuisineName,
                    filterKey: CriterionParamFilter.Cuisine,
                    additionalData: undefined,
                },
            ];

            const drillDownCriteria = mergeDrillDownCriteria(
                criteria,
                CriterionEnum.CuisineTypes,
                drillDownCriterionParams,
            );

            const newCriteria = parseMockedCriteria(drillDownCriteria);

            return openDrillDownResults(newCriteria);
        },
        [criteria, getCriterionByFilterKey],
    );

    return {
        onDrillDownSegments,
        onDrillDownChainsVsIndependents,
        onDrillDownAnnualSalesRange,
        onDrillDownConfidenceLevel,
        onDrillDownCuisine,
    };
}

export default useCriteriaDrilldown;

function mergeDrillDownCriteria(
    currentCriteria: CriteriaData | undefined,
    criterionEnum: CriterionEnum,
    criterionParams: CriterionParam[],
): Partial<CriteriaData> {
    const newCriteria: Partial<CriteriaData> = {
        ...currentCriteria,
        [criterionEnum]: {
            ...CRITERIA[criterionEnum],
            CriterionParams: criterionParams,
        },
    };

    return newCriteria;
}

function openDrillDownResults(drilldownCriteria: Partial<CriteriaData> | undefined) {
    localStorage.setItem(DRILL_DOWN_LOCAL_STORAGE, JSON.stringify(drilldownCriteria));

    return setTimeout(() => {
        window.open(RoutesEnum.OperatorTargetingResults, '_blank', 'noopener,noreferrer');
    }, 200);
}

function parseMockedCriteria(mergedMockCriteria: Partial<CriteriaData>) {
    return Object.entries(mergedMockCriteria).reduce((acc, [key, value]) => {
        if (value.CriterionParams.length > 0) {
            acc[key as CriterionEnum] = value;
        }

        return acc;
    }, {} as CriteriaData);
}
