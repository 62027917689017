import cn from 'classnames';

import { FCX } from '@core/models';

import './Widget.styles.scss';

export enum WidgetType {
    Primary = 'primary',
    Table = 'table',
    Modal = 'modal',
    Dropdown = 'dropdown',
    Collapse = 'collapse',
    Section = 'section',
}

const Widget: FCX<{
    type?: WidgetType;
    dataObservable?: boolean;
}> = ({
    children,
    type = WidgetType.Primary,
    testId,
    className,
    style,
    id,
    dataObservable = false
}) => {
    return (
        <div
            className={cn('Widget', `Widget--${type}`, className)}
            data-testid={testId}
            style={style}
            id={id}
            {...(dataObservable && { 'data-observable': true })}
        >
            {children}
        </div>
    );
};

export default Widget;
