import React from 'react';
import { ConfidenceLevel, useGetAggregationsLazyQuery } from '../../graphql/generated/graphql';

const TestPage: React.FC = () => {
    const [fetchAggregations, {
        data,
        loading
    }] = useGetAggregationsLazyQuery({
        variables: {
            filter: {
                or: [{ confidence_level: [ConfidenceLevel.Low] }],
            },
        },
    });

    return (
        <div>
            <h1>Aggregations Test</h1>
            <h3>Check aggregations for US</h3>
            <button
                onClick={() =>
                    fetchAggregations({
                        variables: {
                            filter: {
                                or: [{ confidence_level: [ConfidenceLevel.Low] }],
                            },
                        },
                    })
                }
            >
                Fetch Aggregations
            </button>
            <hr />

            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    data: {JSON.stringify(data?.aggregations?.confidence_level)}
                    <h3>Confidence Level Count:</h3>
                    {data?.aggregations?.confidence_level?.map((item, index) => (
                        <div key={index}>
                            <p>
                                <strong>{item?.key}:</strong> {item?.count}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TestPage;
