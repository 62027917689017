import { useCallback, useEffect, useMemo, useState } from 'react';

import { ID } from '@core/models';
import { useAdvancedSearch } from '@core/contexts';
import { PhrasesEnum } from '@core/enums/localeEnum';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { CriterionParamFilter } from '@core/models/Criterion';
import { useGetUserFieldPermissionsLazyQuery } from '@graphql/generated/graphql';
import { LOCATION_CRITERION } from '@pages/OperatorTargetingCriteria/hooks/useLocationCriteria';

import {
    getErrorMessages,
    getValidation,
    mapCriterionParamsToZipItem,
    mapZipToDropdownOption,
    maskZip,
} from './Zip.helpers';

type useZipReturn = {
    addZip: (zipValue: string) => void;
    removeZip: (zip: ZipItem) => void;
    zips: ZipItem[];
    error: PhrasesEnum | undefined;
    inputValue: string;
    onChangeZipInput: (inputValue: string) => void;
    clearZips: () => void;
    isLoading: boolean;
};

export type ZipItem = {
    value: ID;
    formattedValue: string;
};

const useZip = (country: CountryCodesEnum): useZipReturn => {
    const [zips, setZips] = useState<ZipItem[]>([]);
    const [error, setError] = useState<PhrasesEnum | undefined>();
    const [inputValue, setInputValue] = useState<string>('');

    const {
        addCriterionOnOptionSelected,
        onDeleteCriterionParam,
        onResetCriteriaByFilterKey,
        getCriterionByFilterKey,
    } = useAdvancedSearch();
    const [fetchOperatorsCounts, { loading: isLoading }] = useGetUserFieldPermissionsLazyQuery();

    const addZipCriterion = useMemo(
        () => addCriterionOnOptionSelected(LOCATION_CRITERION, CriterionParamFilter.PostalCode),
        [addCriterionOnOptionSelected],
    );

    const onChangeZipInput = useCallback(
        (inputValue: string) => {
            if (isLoading) {
                return;
            }

            if (error === getErrorMessages(country).noPermission) {
                setError(undefined);
            }

            const maskedZip = maskZip(inputValue, country);
            setInputValue(maskedZip);
        },
        [country, error, isLoading],
    );

    const validateZip = useCallback(
        async (zip: ZipItem) => {
            const { regex } = getValidation(country);
            const errorMessages = getErrorMessages(country);

            if (zips.find((item) => item.value === zip.value)) {
                setError(errorMessages.duplicate);
                return false;
            }

            if (!regex.test(zip.formattedValue)) {
                setError(errorMessages.invalid);
                return false;
            }

            return fetchOperatorsCounts({
                variables: {
                    filter: {
                        postal_code: { equals: zip.value.toString() },
                    },
                },
            })
                .then(({ data }) => {
                    if (
                        data?.get_user_field_permissions?.has_data === true &&
                        data?.get_user_field_permissions?.has_permission === false
                    ) {
                        setError(errorMessages.noPermission);
                        return false;
                    }

                    return true;
                })
                .catch(() => {
                    setError(errorMessages.noPermission);
                    return false;
                });
        },
        [country, fetchOperatorsCounts, zips],
    );

    const addZip = useCallback(
        async (zipValue: string) => {
            const newZip = {
                value: zipValue,
                formattedValue: zipValue,
            };

            const isValid = await validateZip(newZip);

            if (isValid) {
                addZipCriterion(mapZipToDropdownOption(newZip));
                setError(undefined);
                setInputValue('');
            }
        },
        [addZipCriterion, validateZip],
    );

    const removeZip = useCallback(
        (zip: ZipItem) => onDeleteCriterionParam(LOCATION_CRITERION, zip.value),
        [onDeleteCriterionParam],
    );

    const clearZips = useCallback(() => {
        onResetCriteriaByFilterKey(LOCATION_CRITERION, CriterionParamFilter.PostalCode);
    }, [onResetCriteriaByFilterKey]);

    useEffect(() => {
        const zipParams = getCriterionByFilterKey(LOCATION_CRITERION, CriterionParamFilter.PostalCode);
        const optionsList = mapCriterionParamsToZipItem(zipParams ?? []);
        setZips(optionsList);
    }, [getCriterionByFilterKey]);

    useEffect(() => {
        setInputValue('');
        setError(undefined);
    }, [country]);

    return {
        addZip,
        removeZip,
        clearZips,
        zips,
        error,
        onChangeZipInput,
        inputValue,
        isLoading,
    };
};

export default useZip;
