import { RefObject, useEffect } from 'react';

type OutsideClick = {
    ref: RefObject<HTMLDivElement>;
    isDisabled: boolean;
    fn: (e: MouseEvent) => void;
};
const useOutsideClick = ({
    ref,
    isDisabled = false,
    fn
}: OutsideClick) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref && ref.current && !ref.current.contains(event.target as Node)) {
                fn(event);
            }
        };
        if (!isDisabled) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [fn, ref, isDisabled]);
};

export default useOutsideClick;
