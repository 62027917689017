import { useState, useEffect, useCallback } from 'react';

function useLocalStorage<T>(key: string, defaultValue?: T) {
    const [value, setValue] = useState<T>(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : defaultValue;
        } catch (error) {
            return defaultValue;
        }
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    const deleteValue = useCallback(() => {
        localStorage.removeItem(key);
    }, [key]);

    return { value, setValue, deleteValue };
}

export default useLocalStorage;
