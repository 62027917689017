import { FC, ReactNode } from 'react';
import cn from 'classnames';
import './Toggle.styles.scss';

export enum ToggleType {
    Checkbox = 'checkbox',
    Radio = 'radio',
    Switch = 'switch',
}

const Toggle: FC<{
    type: ToggleType;
    isChecked: boolean;
    isDisabled?: boolean;
    className?: string;
    testId?: string;
    setIsChecked: (value: boolean) => void;
    children: ReactNode;
}> = ({
    type,
    setIsChecked,
    isDisabled = false,
    isChecked,
    className,
    testId,
    children
}) => {
    return (
        <label
            role="toggle"
            className={cn(className, 'Toggle', isDisabled && 'is-disabled', isChecked && 'is-checked')}
            data-testid={testId}
        >
            <input
                type="checkbox"
                checked={isChecked}
                disabled={isDisabled}
                className="Toggle__input"
                onChange={() => setIsChecked(!isChecked)}
            />
            <div role="icon" className={cn('Toggle__icon', `is-${type}`)} data-testid={`${testId}Icon`} />
            <div role="label" className="Toggle__label" data-testid={`${testId}Label`}>
                {children}
            </div>
        </label>
    );
};

export default Toggle;
