import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { FCX } from '@core/models';
import theme from '@core/utils/theme';

import './Tooltip.styles.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const Tooltip: FCX<{
    content?: string | ReactNode;
    placement: TooltipProps['placement'];
    children: TooltipProps['children'];
    disableHoverListener?: boolean;
    disableInteractive?: boolean;
}> = ({
    content,
    children,
    placement,
    testId,
    disableHoverListener = false,
    disableInteractive = false,
}): JSX.Element => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);

    const handleClick = useCallback(() => {
        if (isMobile) {
            setOpen((prevOpen) => !prevOpen);
        }
    }, [isMobile]);

    const tooltipProps = useMemo(
        () => ({
            children,
            content,
            isMobile,
            open,
            handleClick,
            placement,
            disableHoverListener,
            disableInteractive,
            testId,
        }),
        [children, content, isMobile, open, handleClick, placement, disableHoverListener, disableInteractive, testId],
    );

    const handleClickAway = () => {
        if (isMobile) {
            setOpen(false);
        }
    };

    const tooltip = useMemo(() => {
        if (!tooltipProps.content) {
            return tooltipProps.children;
        }

        return (
            <MuiTooltip
                arrow
                title={tooltipProps.content}
                open={tooltipProps.isMobile ? tooltipProps.open : undefined}
                onClick={tooltipProps.handleClick}
                placement={tooltipProps.placement}
                disableHoverListener={tooltipProps.isMobile ? false : tooltipProps.disableHoverListener}
                disableInteractive={tooltipProps.disableInteractive}
                classes={{
                    popper: 'TooltipComponent',
                    tooltipArrow: 'Tooltip--wrapper',
                    arrow: 'Tooltip--arrow',
                }}
                data-testid={tooltipProps.testId}
                componentsProps={{
                    tooltip: {
                        // @ts-expect-error - MuiTooltipProps doesn't have data-testid
                        'data-testid': tooltipProps.testId,
                    },
                }}
            >
                {tooltipProps.children}
            </MuiTooltip>
        );
    }, [tooltipProps]);

    return isMobile ? <ClickAwayListener onClickAway={handleClickAway}>{tooltip}</ClickAwayListener> : tooltip;
};

export default Tooltip;
