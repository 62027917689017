import { FC } from 'react';

import './ReviewBox.styles.scss';
import { useLocale } from '@core/utils/locale';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

const ReviewBox: FC<{
    name?: string;
    rating?: number;
    reviewCount: number;
    testId?: string;
}> = ({
    name,
    rating,
    reviewCount,
    testId
}) => {
    const { t } = useLocale();
    if (!name || !rating || !reviewCount) {
        return null;
    }

    return (
        <div className="ReviewBox">
            <div className="ReviewBox__name" data-testid={testId && `heading${testId}Name`}>
                {name}
            </div>
            <div className="ReviewBox__rating" data-testid={testId && `text${testId}Rating`}>
                {rating.toFixed(1)}
            </div>
            <div className="ReviewBox__review-count" data-testid={testId && `text${testId}ReviewCount`}>
                {reviewCount} {t(OperatorProfilePhrasesEnum.Reviews)}
            </div>
        </div>
    );
};

export default ReviewBox;
