import { ReactNode, useCallback } from 'react';
import cn from 'classnames';

import { FCX, ID, iOption } from '@core/models';
import { capitalize } from '@core/utils/string';

import './ToggleButton.styles.scss';

export type ToggleOption = iOption & { icon?: ReactNode };

export type ToggleButtonProps = {
    options: [ToggleOption, ToggleOption];
    selectedOptionId: ID;
    onToggle: (option: ID) => void;
    testId: string;
};

const ToggleButton: FCX<ToggleButtonProps> = ({
    options,
    selectedOptionId,
    onToggle,
    testId
}) => {
    const handleToggle = useCallback(
        (option: ToggleOption) => {
            onToggle(option.id);
        },
        [onToggle],
    );

    return (
        <div className="Toggle-button">
            {options.map((option) => (
                <button
                    key={option.id}
                    className={cn('Toggle-button__option', {
                        'Toggle-button__option--selected': selectedOptionId === option.id,
                    })}
                    onClick={() => handleToggle(option)}
                    data-testid={`toggleButon${capitalize(testId)}${capitalize(option.id.toString())}`}
                >
                    {option.icon}
                    {option.title}
                </button>
            ))}
        </div>
    );
};

export default ToggleButton;
