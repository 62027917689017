export enum TableSearchKeysEnum {
    AdministrativeArea1 = 'administrative_area_1',
    AdministrativeArea2 = 'administrative_area_2',
    Cuisine = 'cuisine',
    Segmentation = 'segmentation',
    PostalCode = 'postal_code',
    Name = 'name',
    Address = 'address',
    City = 'city',
}
