import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { ID } from '@core/models';
import { FilterTypeEnum } from '@enums/localeEnum';
import { CriterionEnum } from '@core/models/Criterion';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import useGetAllSaveSearch, { AllSaveSearchResponse } from '@core/api/SaveSearch/useGetAllSaveSearch';
import { CriteriaData, CriterionParam, CriterionParamFilter, CriterionParamsManager } from '@models/Criterion';

import { Config } from './useFetch';

export type onAddCriterionParamArgs = (criterionKey: CriterionEnum, criterionParam: CriterionParam) => void;
export type onDeleteCriterionParamArgs = (criterionKey: CriterionEnum, criterionParamId: ID) => void;
export type getCriterionByFilterKeyArgs = (
    criterionKey: CriterionEnum,
    filterKey: CriterionParamFilter,
) => CriterionParam[] | undefined;
export type onResetCriteriaByFilterKeyArgs = (criterionKey: CriterionEnum, filterKey: CriterionParamFilter) => void;
export type onResetCriteriaParams = () => void;
export type onMergeCriteriaParams = (criteriaToMerge: CriteriaData) => void;
export type updateActiveSavedSearchArgs = (updateActiveSavedSearch: SaveSearchResponse) => void;
export type updateIsDirtyArgs = (value: boolean) => void;
export type onResetCriterionParamsByKeyArgs = (criterionKey: CriterionEnum) => void;
export type SavedSearchType = {
    isDirty: boolean;
    updateIsDirty: updateIsDirtyArgs;
    activeSaveSearch?: SaveSearchResponse;
    updateActiveSavedSearch: updateActiveSavedSearchArgs;
    savedSearchData: AllSaveSearchResponse | null;
    savedSearchDoFetch: (
        newConfig?: Config<unknown> | undefined,
    ) => Promise<void | AxiosResponse<AllSaveSearchResponse>>;
    savedSearchLoading: boolean;
};

export const CRITERIA: CriteriaData = {
    [CriterionEnum.Location]: {
        id: 1,
        name: FilterTypeEnum.Location,
        baseColor: GradientColorsEnum.GREEN,
        CriterionParams: [],
    },
    [CriterionEnum.Segments]: {
        id: 2,
        name: FilterTypeEnum.Segments,
        baseColor: GradientColorsEnum.BLUE,
        CriterionParams: [],
    },
    [CriterionEnum.Tags]: {
        id: 3,
        name: FilterTypeEnum.Tags,
        baseColor: GradientColorsEnum.ORANGE,
        CriterionParams: [],
    },
    [CriterionEnum.General]: {
        id: 4,
        name: FilterTypeEnum.General,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
    [CriterionEnum.CuisineTypes]: {
        id: 5,
        name: FilterTypeEnum.CuisineTypes,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
    [CriterionEnum.LodgingRecreation]: {
        id: 6,
        name: FilterTypeEnum.LodgingAndRecreation,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
    [CriterionEnum.ClientFilters]: {
        id: 7,
        name: FilterTypeEnum.ClientFilters,
        baseColor: GradientColorsEnum.PURPLE,
        CriterionParams: [],
    },
};

function useAdvancedSearchCriteria() {
    const [criteria, setCriteria] = useState<CriteriaData>(CRITERIA);
    const [activeCriteria, setActiveCriteria] = useState<CriteriaData>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [activeSaveSearch, setActiveSaveSearch] = useState<SaveSearchResponse | undefined>();

    useEffect(() => {
        const filteredCriteriaData = Object.entries(criteria).reduce((acc, [key, value]) => {
            if (value.CriterionParams.length > 0) {
                acc[key as CriterionEnum] = value;
            }

            return acc;
        }, {} as CriteriaData);

        setActiveCriteria(filteredCriteriaData);
    }, [criteria]);

    const {
        loading: savedSearchLoading,
        data: savedSearchData,
        doFetch: savedSearchDoFetch
    } = useGetAllSaveSearch();

    const updateActiveSavedSearch: updateActiveSavedSearchArgs = useCallback((savedSearchObj?: SaveSearchResponse) => {
        setActiveSaveSearch(savedSearchObj);
    }, []);

    const updateIsDirty: updateIsDirtyArgs = useCallback((value) => {
        setIsDirty(value);
    }, []);

    const onResetCriteria: onResetCriteriaParams = useCallback(() => {
        setActiveSaveSearch(undefined);
        setIsDirty(false);
        setCriteria(() => CRITERIA);
    }, []);

    const onMergeCriteria: onMergeCriteriaParams = useCallback((criteriaToMerge: CriteriaData) => {
        setIsDirty(false);
        setCriteria(() => ({ ...CRITERIA, ...criteriaToMerge }));
    }, []);

    const onResetCriterionParamsExceptByFilterKey: onResetCriteriaByFilterKeyArgs = useCallback(
        (criterionKey: CriterionEnum, filterKey: CriterionParamFilter) => {
            setCriteria((prevCriteria) => {
                const currentCriterion = prevCriteria[criterionKey];

                return {
                    ...CRITERIA,
                    [criterionKey]: {
                        ...currentCriterion,
                        CriterionParams: currentCriterion.CriterionParams.filter((criterionParam) => {
                            return criterionParam.filterKey === filterKey;
                        }),
                    },
                };
            });
        },
        [],
    );

    const onResetCriterionParamsByKey: onResetCriterionParamsByKeyArgs = useCallback((criterionKey: CriterionEnum) => {
        setCriteria((prevCriteria) => {
            const currentCriterion = prevCriteria[criterionKey];

            return {
                ...prevCriteria,
                [criterionKey]: {
                    ...currentCriterion,
                    CriterionParams: [],
                },
            };
        });
    }, []);

    const onResetCriteriaByFilterKey: onResetCriteriaByFilterKeyArgs = useCallback(
        (criterionKey: CriterionEnum, filterKey: CriterionParamFilter) => {
            setCriteria((prevCriteria) => {
                const currentCriterion = prevCriteria[criterionKey];

                return {
                    ...prevCriteria,
                    [criterionKey]: {
                        ...currentCriterion,
                        CriterionParams: currentCriterion.CriterionParams.filter((criterionParam) => {
                            return criterionParam.filterKey !== filterKey;
                        }),
                    },
                };
            });
        },
        [],
    );

    const onDeleteCriterionParam: onDeleteCriterionParamArgs = useCallback((criterionKey, criterionParamId) => {
        function filterCriterionParams(criterionParams: CriterionParam[]) {
            return criterionParams.filter((criterionParam) => {
                return criterionParam.value !== criterionParamId;
            });
        }
        setIsDirty(true);
        setCriteria((prevCriteria) => {
            const currentCriterion = prevCriteria[criterionKey];

            return {
                ...prevCriteria,
                [criterionKey]: {
                    ...currentCriterion,
                    CriterionParams: filterCriterionParams(currentCriterion.CriterionParams),
                },
            };
        });
    }, []);

    const onAddCriterionParam: onAddCriterionParamArgs = useCallback((criterionKey, criterionParam) => {
        setIsDirty(true);
        setCriteria((prevCriteria) => {
            const criterion = prevCriteria[criterionKey];
            return {
                ...prevCriteria,
                [criterionKey]: {
                    ...criterion,
                    CriterionParams: CriterionParamsManager.updateCriterionParams(
                        criterion.CriterionParams,
                        criterionParam,
                    ),
                },
            };
        });
    }, []);

    const getCriterionByFilterKey: getCriterionByFilterKeyArgs = useCallback(
        (criterionKey: CriterionEnum, filterKey: CriterionParamFilter) => {
            const criterionParams = criteria[criterionKey].CriterionParams.filter((criterionParam) => {
                return criterionParam.filterKey === filterKey;
            });

            return criterionParams.length > 0 ? criterionParams : undefined;
        },
        [criteria],
    );

    return {
        criteria: activeCriteria,
        onDeleteCriterionParam,
        onAddCriterionParam,
        getCriterionByFilterKey,
        onResetCriteria,
        onMergeCriteria,
        // TODO: Refactor this to a saved search context since it's not related to criteria.
        savedSearch: {
            updateActiveSavedSearch,
            isDirty,
            activeSaveSearch,
            savedSearchData,
            savedSearchDoFetch,
            savedSearchLoading,
            updateIsDirty,
        },
        onResetCriterionParamsByKey,
        onResetCriteriaByFilterKey,
        onResetCriterionParamsExceptByFilterKey,
    };
}

export default useAdvancedSearchCriteria;
