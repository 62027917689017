import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';

import Page from '@components/ui/Page/Page';
import Sidebar from '@components/ui/Sidebar/Sidebar';
import useTitle from '@hooks/useDocumentTitle';

import './Layout.styles.scss';
import LayoutSkeleton from './Layout.skeleton';
import { useAppContext } from '../../../App.context';
import { useScrollToTop } from '@core/hooks/useScrollToTop';

const Layout: FC = (): JSX.Element => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const {
        userInfo: { fullName },
        isLoading,
    } = useAppContext();
    useTitle();
    useScrollToTop();

    if (isLoading) {
        return <LayoutSkeleton />;
    }

    return (
        <div className="Layout">
            <Sidebar userName={fullName} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            <Page isMenuOpen={isMenuOpen}>
                <Outlet />
            </Page>
        </div>
    );
};

export default Layout;
