import { FCX } from '@core/models';

import MultiSelectDropdown from './MultiSelectDropdown';
import { DropdownCommonProps } from '../Dropdowns.helpers';
import SelectWithCustomDropdown from '../SelectWithCustomDropdown/SelectWithCustomDropdown';

const DropdownMultiSelect: FCX<
    DropdownCommonProps & {
        inputLabel?: string;
        dropdownPlaceholder?: string;
    }
> = (props) => <SelectWithCustomDropdown {...props} DropdownComponent={MultiSelectDropdown} unscrollable isMulti />;

export default DropdownMultiSelect;
