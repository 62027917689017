import { useInput } from '@core/hooks/useInput';
import { OperatorType, useQuickSearchQuery } from '@graphql/generated/graphql';
import { MIN_SEARCH_LENGTH } from './QuickSearch.helpers';
import { useLocale } from '@core/utils/locale';
import { useState } from 'react';

export function useQuickSearch() {
    const [countryCode, setCountryCode] = useState<string | undefined>();
    const {
        value,
        onChange
    } = useInput();
    const { locale } = useLocale();

    const {
        data,
        loading
    } = useQuickSearchQuery({
        fetchPolicy: 'no-cache',
        skip: !countryCode || !value || value.length < MIN_SEARCH_LENGTH,
        variables: {
            locale,
            searchString: value,
            country: countryCode,
            type: [OperatorType.Unit],
        },
    });

    const onUpdateCountryCode = (code: string) => {
        setCountryCode(code);
    };

    return {
        value,
        loading,
        onChange,
        onUpdateCountryCode,
        data: data?.quick_search,
    };
}
