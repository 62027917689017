import { useState } from 'react';

import { ID } from '@core/models';

const useRadioGroup = (defaultOption: ID) => {
    const [value, setValue] = useState<ID>(defaultOption);

    const handleChange = (value: ID) => {
        setValue(value);
    };

    return {
        value,
        handleChange,
    };
};

export default useRadioGroup;
