import Avatar from '@mui/material/Avatar';
import { Skeleton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IconDSFullLogoWhite } from '@assets/icons';

import './Sidebar.styles.scss';

const SidebarSkeleton = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <nav className="Sidebar">
            <div className="Sidebar__content">
                <div className="Sidebar__logo">
                    {isMobile ? (
                        <>
                            <IconDSFullLogoWhite />
                            <div className="flex flex-col SidebarSkeleton__mobile-icon">
                                <Skeleton variant="rounded" width={15} height={3} />
                                <Skeleton variant="rounded" width={18} height={3} />
                                <Skeleton variant="rounded" width={15} height={3} />
                            </div>
                        </>
                    ) : (
                        <IconDSFullLogoWhite className="logo" />
                    )}
                </div>

                {Array.from({ length: 4 }).map((_, i) => (
                    <div className="flex flex-row" key={i}>
                        <Skeleton className="SidebarSkeleton__nav-item" variant="rounded" width={22} height={20} />
                        <Skeleton variant="rounded" width={200} height={20} />
                    </div>
                ))}
            </div>
            <div className="Sidebar__avatar">
                <div>
                    <Skeleton variant="circular" component="div">
                        <Avatar />
                    </Skeleton>
                    <Skeleton variant="rounded" width={140} height={20} />
                </div>
                <Skeleton variant="rounded" component="div" height={20} width={20} />
            </div>
        </nav>
    );
};

export default SidebarSkeleton;
