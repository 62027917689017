import { FC } from 'react';

import { IconNoFood } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import AnimatedLoader from '@components/ui/AnimatedLoader/AnimatedLoader';

import './TableFeedback.styles.scss';

const TableFeedback: FC<{
    isLoading: boolean;
    isEmpty: boolean;
}> = ({
    isLoading,
    isEmpty
}) => {
    const { t } = useLocale();
    if (isLoading) {
        return (
            <div>
                <AnimatedLoader className="TableFeedback__loading" />
                <div className="TableFeedback__loading-title">{t(OperatorTargetingPhrasesEnum.Loading)}...</div>
            </div>
        );
    }

    if (isEmpty) {
        return (
            <div className="TableFeedback">
                <div className="TableFeedback__icon">
                    <IconNoFood />
                </div>
                <div className="TableFeedback__title">No results found</div>
                <div className="TableFeedback__subtitle">Check your spelling or try a different keyword</div>
            </div>
        );
    }

    return null;
};

export default TableFeedback;
