import { ReactNode } from 'react';
import cn from 'classnames';
import './Typography.styles.scss';
import { FCX } from '@core/models';
import { formatTestIds } from '@core/utils/string';

export enum HeadingVariant {
    H1 = 'heading-1',
    H2 = 'heading-2',
    H3 = 'heading-3',
    H4 = 'heading-4',
    H5 = 'heading-5',
}

const Heading: FCX<{
    children: ReactNode;
    variant?: HeadingVariant;
}> = ({
    children,
    variant = HeadingVariant.H2,
    className,
    style,
    testId
}) => {
    return (
        <div
            className={cn('Heading', variant, className)}
            data-testid={testId && `heading${formatTestIds(testId)}`}
            style={style}
        >
            {children}
        </div>
    );
};

export default Heading;
