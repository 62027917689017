import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Cluster, MarkerClusterer, Renderer, SuperClusterViewportAlgorithm } from '@googlemaps/markerclusterer';

import {
    GetOperatorTooltipDataQuery,
    OperatorFilter,
    useGetOperatorTooltipDataLazyQuery,
} from '@graphql/generated/graphql';
import useSearchSlim, { SearchSlimBatchResponse } from '@core/api/useSearchSlim';
import { useLocale } from '@core/utils/locale';
import { renderToStaticMarkup } from 'react-dom/server';
import { IconGreenMarker, IconGreenMarkerShadow, IconMapCluster } from '@assets/icons';

function getIconUri(icon: ReactElement) {
    const svgString = encodeURIComponent(renderToStaticMarkup(icon));
    return `data:image/svg+xml,${svgString}`;
}

const markerDataUri = getIconUri(<IconGreenMarker />);
const markerShadowUri = getIconUri(<IconGreenMarkerShadow />);
const clusterUri = getIconUri(<IconMapCluster />);

export type OperatorMarker = {
    marker: google.maps.Marker | null;
    data: GetOperatorTooltipDataQuery['get'] | undefined;
};

const handleMarkerClick = async (
    marker: google.maps.Marker,
    operator: SearchSlimBatchResponse,
    fetchOperator: (options: {
        variables: { operatorIdentifier: string; locale: string };
    }) => Promise<{ data: { get: GetOperatorTooltipDataQuery['get'] } | undefined }>,
    locale: string,
    setActiveMarker: (markerData: OperatorMarker) => void,
) => {
    const { data } = await fetchOperator({
        variables: {
            operatorIdentifier: operator.operator_identifier ?? '',
            locale,
        },
    });

    if (data) {
        setActiveMarker({
            marker,
            data: data.get,
        });
    }
};

const createMarker = (
    operator: SearchSlimBatchResponse,
    markerDataUri: string,
    markerShadowUri: string,
    fetchOperator: (options: {
        variables: { operatorIdentifier: string; locale: string };
    }) => Promise<{ data: { get: GetOperatorTooltipDataQuery['get'] } | undefined }>,
    locale: string,
    setActiveMarker: (markerData: OperatorMarker) => void,
): google.maps.Marker => {
    const marker = new google.maps.Marker({
        position: {
            lat: operator.latitude ?? 0,
            lng: operator.longitude ?? 0,
        },
        icon: markerDataUri,
    });

    marker.addListener('mouseover', () => {
        marker.setIcon(markerShadowUri);
        marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
    });

    marker.addListener('mouseout', () => {
        marker.setIcon(markerDataUri);
        marker.setZIndex(1000);
    });

    marker.addListener('click', () => handleMarkerClick(marker, operator, fetchOperator, locale, setActiveMarker));

    return marker;
};

type AddMarkersParams = {
    markerBatch: SearchSlimBatchResponse[];
    markerDataUri: string;
    markerShadowUri: string;
    fetchOperator: (options: {
        variables: { operatorIdentifier: string; locale: string };
    }) => Promise<{ data: { get: GetOperatorTooltipDataQuery['get'] } | undefined }>;
    locale: string;
    setActiveMarker: (markerData: OperatorMarker) => void;
    map: google.maps.Map;
    clusterRenderer: Renderer;
};

const addMarkers = ({
    markerBatch,
    markerDataUri,
    markerShadowUri,
    fetchOperator,
    locale,
    setActiveMarker,
    map,
    clusterRenderer,
}: AddMarkersParams) => {
    const allMarkers = markerBatch.map((operator) =>
        createMarker(operator, markerDataUri, markerShadowUri, fetchOperator, locale, setActiveMarker),
    );

    const markerCluster = new MarkerClusterer({
        algorithm: new SuperClusterViewportAlgorithm({
            viewportPadding: 0,
        }),
        markers: allMarkers,
        renderer: clusterRenderer,
    });

    markerCluster.setMap(map);
};

export default function useDrawOperatorMarkers(map: google.maps.Map | null, searchFilters: OperatorFilter | undefined) {
    const google = window.google;
    const { locale } = useLocale();
    const {
        doFetchStream,
        loading: isMapLoading
    } = useSearchSlim();

    const [activeMarker, setActiveMarker] = useState<OperatorMarker | null>(null);

    const [fetchOperator, { loading: isTooltipLoading }] = useGetOperatorTooltipDataLazyQuery({
        fetchPolicy: 'no-cache',
    });

    const wrappedFetchOperator = useCallback(
        async (options: { variables: { operatorIdentifier: string; locale: string } }) => {
            const result = await fetchOperator(options);
            return {
                data: result.data?.get ? { get: result.data.get } : undefined,
            };
        },
        [fetchOperator],
    );

    const clusterRenderer: Renderer = useMemo(
        () => ({
            render: ({
                count,
                position
            }: Cluster) =>
                new google.maps.Marker({
                    label: {
                        text: String(count),
                        color: '#232020',
                        fontSize: '13px',
                        fontWeight: 'bold',
                        fontFamily: 'Montserrat',
                    },
                    icon: clusterUri,
                    position,
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                }),
        }),
        [google?.maps],
    );

    useEffect(() => {
        if (google?.maps && map && searchFilters) {
            doFetchStream(
                (markerBatch) =>
                    addMarkers({
                        markerBatch,
                        markerDataUri,
                        markerShadowUri,
                        fetchOperator: wrappedFetchOperator,
                        locale,
                        setActiveMarker,
                        map,
                        clusterRenderer,
                    }),
                searchFilters,
            );
        }
    }, [doFetchStream, google?.maps, map, searchFilters, wrappedFetchOperator, locale, clusterRenderer]);

    const clearActiveMarker = useCallback(() => setActiveMarker(null), []);

    return {
        isLoading: isMapLoading || isTooltipLoading,
        activeMarker,
        clearActiveMarker,
    };
}
