import React, { FC, ReactNode, MouseEvent, useState } from 'react';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';

import { IconMenuSettings } from '@assets/icons';

export type BasicMenuProps = {
    anchorOrigin: NonNullable<MenuProps['anchorOrigin']>;
    transformOrigin: NonNullable<MenuProps['transformOrigin']>;
    children: ReactNode;
    isMenuOpen: boolean;
    setisMenuOpen: (value: boolean) => void;
};

const BasicMenu: FC<BasicMenuProps> = ({
    anchorOrigin,
    transformOrigin,
    children,
    isMenuOpen,
    setisMenuOpen
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl) && isMenuOpen;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setisMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                style={{ padding: 0 }}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <IconMenuSettings />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                className="Menu__item"
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {children}
            </Menu>
        </div>
    );
};

export default BasicMenu;
