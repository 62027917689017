import useFetch, { Config } from '@hooks/useFetch';
import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content } from '@core/enums/api';
import { useCallback, useEffect, useState } from 'react';
import { SaveSearchParams } from './useSaveSearch';

const PUT_SAVE_SEARCH = '/saved_searches';

type GetSaveSearchEndpoint = paths[typeof PUT_SAVE_SEARCH][MethodsEnum.PUT];
type SaveSearchResponse = GetSaveSearchEndpoint[OperationsEnum.RESPONSES]['201'][Content]['application/json'];

function useUpdateSaveSearch() {
    const [success, setSuccess] = useState(false);

    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<SaveSearchResponse>({
        url: PUT_SAVE_SEARCH,
        method: 'PUT',
        lazy: true,
    });

    useEffect(() => {
        if (data) {
            setSuccess(true);
        }
    }, [data]);

    const doFetchWithParams = useCallback(
        (payload?: SaveSearchParams, newConfig?: Config) => {
            return doFetch({
                data: payload,
                ...newConfig,
            });
        },
        [doFetch],
    );

    return { data, loading, success, error, doFetch: doFetchWithParams } as const;
}

export default useUpdateSaveSearch;
