import { FC, useMemo } from 'react';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

import RatingStar from './Star';
import './RatingStars.styles.scss';

const MIN = 0;
const MAX = 5;

const RatingStars: FC<{
    rating: number;
}> = ({ rating }) => {
    /**
     * @description
     * This function takes a rating and returns an array of 5 numbers, each representing a star.
     * The Math floor of the rating is the number of full stars, and the decimal is the percentage of the last star.
     * The last star is the only one that can be partially filled.
     * If the rating is 3.5, the stars array will be [100, 100, 100, 50]. If the length of the array is less than 5, the remaining stars will be 0.
     *
     * @example
     * parseRating(2.5) => [100, 100, 50, 0, 0]
     * parseRating(4.8) => [100, 100, 100, 100, 80]
     *
     * @param rating
     * @returns number[]
     **/
    function parseRating(rating: number): number[] {
        const clampedRating = Math.max(MIN, Math.min(MAX, rating));
        const whole = Math.floor(clampedRating);
        const fraction = clampedRating - whole;
        const stars: number[] = Array(whole).fill(100);

        if (fraction > 0) {
            stars.push(Math.round(fraction * 100));
        }

        while (stars.length < 5) {
            stars.push(0);
        }

        return stars;
    }

    const renderStars = useMemo(() => {
        const stars = parseRating(rating);

        return stars.map((percentage, index) => {
            return <RatingStar percentage={percentage} key={index} starid={`${index}`} />;
        });
    }, [rating]);

    return (
        <div className="RatingStars">
            <Heading variant={HeadingVariant.H1} testId="RatingStarsValue">
                {rating}
            </Heading>
            <div className="RatingStars__stars-container " data-testid="iconRatingStars">
                {renderStars}
            </div>
        </div>
    );
};

export default RatingStars;
