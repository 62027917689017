import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { InputAdornment, OutlinedInput, Popper } from '@mui/material';
import cn from 'classnames';

import { IconClose, IconSearch } from '@assets/icons';
import useOutsideClick from '@core/hooks/useOutsideClick';

import '../Inputs.styles.scss';
import './QuickSearch.styles.scss';
import { useQuickSearch } from './useQuickSearch';
import SelectCountryModal from './SelectCountryModal/SelectCountryModal';
import QuickSearchDropdown from './QuickSearchDropdown/QuickSearchDropdown';
import { MIN_SEARCH_LENGTH, useQuickSearchCountries } from './QuickSearch.helpers';
import { OperatorSummary } from '@graphql/generated/graphql';

export enum QuickSearchTypes {
    MEDIUM = 'medium',
    LARGE = 'large',
}

const QuickSearch: FC<{
    isDisabled?: boolean;
    placeholder: string;
    height?: number;
    type?: QuickSearchTypes;
    onAnimate: () => void;
    hasAnimated: boolean;
}> = ({
    placeholder,
    height,
    isDisabled = false,
    type = QuickSearchTypes.MEDIUM,
    onAnimate,
    hasAnimated
}) => {
    const container = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [quickSearchOnFocus, setQuickSearchOnFocus] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const {
        value,
        onChange,
        data,
        loading,
        onUpdateCountryCode
    } = useQuickSearch();

    const {
        isModalOpen,
        setIsModalOpen,
        countriesList,
        selectedCountry,
        handleCountryChange
    } =
        useQuickSearchCountries();

    useEffect(() => {
        if (selectedCountry?.code) {
            onUpdateCountryCode(selectedCountry.code);
        }
    }, [selectedCountry, onUpdateCountryCode]);

    const onClose = useCallback(
        (event: MouseEvent) => {
            const modalElement = document.querySelector('.MuiModal-root');

            if (modalElement?.contains(event.target as Node)) return;

            if (anchorEl?.contains(event.target as Node)) return;

            setIsDropdownOpen(false);
        },
        [anchorEl],
    );

    useOutsideClick({
        ref: container,
        isDisabled: !isDropdownOpen,
        fn: onClose,
    });

    const onOptionSelectedCallback = useCallback(() => {
        setIsDropdownOpen(false);
    }, []);

    const onInputClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!anchorEl) {
                setAnchorEl(e.currentTarget);
            }
        },
        [anchorEl],
    );

    const shouldShowCountryFooter = useCallback(() => {
        if (!isDisabled && !loading) {
            setQuickSearchOnFocus(true);
        }
    }, [isDisabled, loading]);

    const shouldOpenDropdown = useCallback(() => {
        if (value && value.length >= MIN_SEARCH_LENGTH && !isDisabled && !loading) {
            if (!hasAnimated) {
                return onAnimate();
            }

            return setTimeout(() => {
                setIsDropdownOpen(true);
            }, 600);
        }
        setIsDropdownOpen(false);
    }, [value, isDisabled, loading, hasAnimated, onAnimate]);

    useEffect(() => {
        shouldOpenDropdown();
    }, [shouldOpenDropdown]);

    return (
        <div
            className={cn('QuickSearch', type === QuickSearchTypes.LARGE && 'QuickSearch--large')}
            data-testid="QuickSearchContainer"
        >
            <OutlinedInput
                fullWidth
                value={value ?? ''}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                sx={{ height: height ?? 44 }}
                onClick={onInputClick}
                onFocus={() => {
                    shouldOpenDropdown();
                    shouldShowCountryFooter();
                }}
                disabled={isDisabled}
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment
                        classes={{
                            root: 'QuickSearch__start-adornment',
                        }}
                        position="start"
                        disablePointerEvents={true}
                        sx={{ marginLeft: '5px' }}
                    >
                        <IconSearch />
                    </InputAdornment>
                }
                classes={{
                    root: 'QuickSearch__root',
                    input: 'QuickSearch__input',
                }}
                endAdornment={
                    <InputAdornment
                        classes={{
                            root: cn('QuickSearch__end-adornment', {
                                'QuickSearch__end-adornment--visible': value && value.length >= 1,
                            }),
                        }}
                        position="end"
                        onClick={() => onChange('')}
                    >
                        {loading ? (
                            <div className="QuickSearch__loader" data-testid="QuickSearchLoadingAnimation" />
                        ) : (
                            <IconClose height={10} data-testid="QuickSearchClearIcon" />
                        )}
                    </InputAdornment>
                }
            />
            {anchorEl && (
                <Popper
                    ref={container}
                    open={isDropdownOpen || quickSearchOnFocus}
                    anchorEl={anchorEl}
                    placement={'bottom-start'}
                    sx={{
                        zIndex: 1000,
                    }}
                >
                    <QuickSearchDropdown
                        width={anchorEl?.clientWidth}
                        data={(data?.operators as OperatorSummary[]) ?? []}
                        onOptionSelected={onOptionSelectedCallback}
                        onOpenModal={() => setIsModalOpen(true)}
                        inputValueLength={value?.length ?? 0}
                        selectedCountry={selectedCountry}
                        loading={loading}
                    >
                        <SelectCountryModal
                            isOpen={isModalOpen}
                            onChangeCountry={handleCountryChange}
                            handleClose={() => setIsModalOpen(false)}
                            countries={countriesList}
                            selectedCountry={selectedCountry}
                        />
                    </QuickSearchDropdown>
                </Popper>
            )}
        </div>
    );
};

export default QuickSearch;
