import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocale } from '@core/utils/locale';
import { CriteriaData } from '@models/Criterion';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Button from '@components/utility/Button/Button';
import Collapse from '@components/utility/Collapse/Collapse';
import Criteria from '@components/searchCriteria/Criteria/Criteria';
import { onDeleteCriterionParamArgs } from '@core/hooks/useAdvancedSearchCriteria';
import { CommonPhrasesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import CollapsedCriteria from '@components/searchCriteria/CollapsedCriteria/CollapsedCriteria';

import './SearchCriteriaResults.styles.scss';

const SearchCriteriaResults: FC<{
    criteria: CriteriaData;
    onDeleteCriterionParam: onDeleteCriterionParamArgs;
}> = ({
    criteria,
    onDeleteCriterionParam
}) => {
    const { t } = useLocale();
    const navigate = useNavigate();

    return (
        <Collapse
            title={t(OperatorTargetingPhrasesEnum.Criteria)}
            collapsedContentSlot={<CollapsedCriteria data={criteria} />}
            actionsSlot={
                <div className="SearchCriteriaResults__button">
                    <Button
                        modifiers={['naked']}
                        onClick={() => {
                            navigate(RoutesEnum.OperatorTargetingCriteria);
                        }}
                        testId="buttonEditCriteria"
                    >
                        {t(CommonPhrasesEnum.Edit).toLocaleLowerCase()}
                    </Button>
                </div>
            }
        >
            <div className="SearchCriteriaResults__criteria">
                <Criteria data={criteria} onDelete={onDeleteCriterionParam} />
            </div>
        </Collapse>
    );
};

export default SearchCriteriaResults;
