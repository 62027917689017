import { FC, useMemo } from 'react';

import { findPatternIndex } from '@core/utils/string';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { useLocale } from '@core/utils/locale';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

const WEEKDAYS = ['Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon'];

const WorkingHours: FC<{
    workingHours?: string | null;
}> = ({ workingHours }) => {
    const { t } = useLocale();

    /**
     * The function will iterate over the WEEKDAYS array and will try to find
     * the first occurrence of the weekday in the workingHours string.
     *
     * If the weekday is found, the function will extract the substring from the
     * beginning of the workingHours string until the first occurrence of the weekday.
     *
     * The extracted substring will be pushed to the days array and the substring will be
     * removed from the workingHours string.
     *
     * The function will return the days array.
     *
     * @param workingHours string
     * @returns string[]
     */
    const workingDays = useMemo(() => {
        if (!workingHours) {
            return [];
        }

        const days: string[] = [];
        let rawHours = workingHours;
        WEEKDAYS.forEach((weekday) => {
            const patternIndex = findPatternIndex(rawHours, weekday);
            if (patternIndex === null || patternIndex === 0) {
                return;
            }

            const extractedSubstring = rawHours.slice(0, patternIndex === -1 ? rawHours.length : patternIndex);

            days.push(extractedSubstring.replace(',', '').trim());

            rawHours = rawHours.replace(extractedSubstring, '');
        });
        return days;
    }, [workingHours]);

    if (!workingHours) {
        return null;
    }

    return (
        <div className="OperatorProfileLocation__info-column">
            <div className="WorkingHours">
                <Heading variant={HeadingVariant.H5} testId="OperatingHours">
                    {t(OperatorProfilePhrasesEnum.OperatingHours)}
                </Heading>
                <div>
                    {workingDays.map((workingDay) => (
                        <div className="WorkingHours__day" key={workingDay} data-testid="textWorkingHoursDay">
                            <Text variant={TextVariant.B1} style={{ lineHeight: '24px' }}>
                                {workingDay}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WorkingHours;
