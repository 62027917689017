import useFetch from '@hooks/useFetch';
import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content } from '@core/enums/api';

const GET_ALL_SAVE_SEARCHES = '/saved_searches';

type GetAllSaveSearchEndpoint = paths[typeof GET_ALL_SAVE_SEARCHES][MethodsEnum.GET];
export type AllSaveSearchResponse =
    GetAllSaveSearchEndpoint[OperationsEnum.RESPONSES]['200'][Content]['application/json'];

export default function useGetAllSaveSearch() {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<AllSaveSearchResponse>({
        url: GET_ALL_SAVE_SEARCHES,
        method: 'GET',
    });

    return { data, loading, error, doFetch } as const;
}
