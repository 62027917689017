import { useCallback } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import { FCX } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { CountryCodesEnum } from '@core/enums/flagsEnum';

import useZip from './useZip';
import ZipChips from './ZipChips';
import InputHelperText from '../InputHelperText';

const ZIP_TEST_ID = 'DropdownPostalCodes';

const Zip: FCX<{
    countryCode: CountryCodesEnum;
    isDisabled?: boolean;
    placeholder?: string;
    inputLabel?: string;
}> = ({
    countryCode,
    isDisabled,
    placeholder,
    inputLabel
}) => {
    const { t } = useLocale();
    const {
        addZip,
        removeZip,
        zips,
        error,
        clearZips,
        inputValue,
        onChangeZipInput,
        isLoading
    } = useZip(countryCode);

    const onKeyDownEvent = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
                e.preventDefault();
                if (inputValue) {
                    addZip(inputValue);
                }
            }
        },
        [addZip, inputValue],
    );

    return (
        <div className="Zip">
            {!!inputLabel && (
                <InputLabel data-testid={`label${ZIP_TEST_ID}`} error={!!error}>
                    {inputLabel}
                </InputLabel>
            )}
            <TextField
                value={inputValue}
                onChange={(e) => {
                    e.preventDefault();
                    onChangeZipInput(e.target.value);
                }}
                onKeyDown={onKeyDownEvent}
                variant="outlined"
                fullWidth
                disabled={isDisabled}
                error={!!error}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: (
                        <Box sx={{ display: zips.length > 0 ? 'flex' : 'none', paddingLeft: '14px' }}>
                            <ZipChips zips={zips} removeZip={removeZip} clearZips={clearZips} />
                        </Box>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {isLoading && <div className="Inputs__loader" />}
                        </InputAdornment>
                    ),
                    // This is necessary because the TextField component does not have a data-testid prop, but we need to add it to the InputProps element for testing purposes.
                    // @ts-expect-error "data-testid" does not exist on type 'Partial<InputProps>'
                    'data-testid': ZIP_TEST_ID,
                    sx: {
                        paddingLeft: '0px',
                    },
                }}
                data-testid={`${ZIP_TEST_ID}Container`}
            />
            <InputHelperText hasError={!!error} errorMessage={error && t(error)} />
        </div>
    );
};

export default Zip;
