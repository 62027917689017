import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { FCX, ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import SwitchList, { SwitchListOption } from '@components/utility/SwitchList/SwitchList';

import { ParsedRangeResponse, mapRangeToDropdownOption } from './General.helpers';
import { formatTestIds } from '@core/utils/string';

const GeneralSwitchList: FCX<{
    range: ParsedRangeResponse;
    title: string;
    criterionFilterKey: CriterionParamFilter;
}> = ({
    range,
    title,
    criterionFilterKey,
    testId
}) => {
    const [selectedRanges, setselectedRanges] = useState<ID[]>([]);

    const { locale } = useLocale();
    const isSmallScreen = useMediaQuery('(max-width:1200px)');

    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey
    } = useAdvancedSearch();

    useEffect(() => {
        const rangeParams = getCriterionByFilterKey(CriterionEnum.General, criterionFilterKey);

        const selectedRangesIds =
            rangeParams
                ?.map((rangeParam) => {
                    return range?.ranges?.filter((range) => rangeParam?.value === range?.id);
                })
                .flat()
                .map((range) => range?.id as ID) ?? [];

        setselectedRanges(selectedRangesIds);
    }, [criterionFilterKey, getCriterionByFilterKey, range?.ranges]);

    const onAddListItemsCriteria = useCallback(
        (rangesList: SwitchListOption[]) => {
            rangesList?.forEach((range) => {
                const rangePill: DropdownOption = mapRangeToDropdownOption(range, criterionFilterKey, locale);
                addCriterionOnOptionSelected(CriterionEnum.General, criterionFilterKey)(rangePill);
            });
        },
        [addCriterionOnOptionSelected, criterionFilterKey, locale],
    );

    const onUpdateSelectedItems = useCallback(
        (values: ID[]) => {
            const symmetricDiff = selectedRanges
                .filter((x) => !values.includes(x))
                .concat(values.filter((x) => !selectedRanges.includes(x)))[0];

            const optionToToggle = range?.ranges.find((range) => range?.id === symmetricDiff);

            if (!optionToToggle) {
                return;
            }

            onAddListItemsCriteria([optionToToggle]);
        },
        [onAddListItemsCriteria, range?.ranges, selectedRanges],
    );

    return (
        <div>
            <div className="CriteriaContent__columns no-mt col-1">
                <div className="CriteriaContent__column col-1">
                    <Heading
                        className="GeneralCriteria__range-title"
                        variant={HeadingVariant.H5}
                        testId={formatTestIds(testId)}
                    >
                        {title}
                    </Heading>
                    <SwitchList
                        options={range?.ranges}
                        value={selectedRanges}
                        onChange={onUpdateSelectedItems}
                        color={GradientColorsEnum.PURPLE}
                        isSmall={isSmallScreen}
                        columns={5}
                    />
                </div>
            </div>
        </div>
    );
};

export default GeneralSwitchList;
