import { useEffect } from 'react';
import { useLocation, matchRoutes } from 'react-router-dom';

import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@enums/RoutesEnum';
import { ErroPagePhrasesEnum, OperatorTargetingPhrasesEnum, PhrasesEnum, SidebarPhrasesEnum } from '@enums/localeEnum';

const titleFromLocation: Record<string, PhrasesEnum> = {
    [RoutesEnum.Home]: SidebarPhrasesEnum.Home,
    [RoutesEnum.OperatorTargetingCriteria]: SidebarPhrasesEnum.OperatorTargeting,
    [RoutesEnum.HeadquarterTargeting]: SidebarPhrasesEnum.HeadquarterTargeting,
    [RoutesEnum.OperatorTargetingResults]: SidebarPhrasesEnum.OperatorTargeting,
    [RoutesEnum.OperatorProfile]: OperatorTargetingPhrasesEnum.Operators,
    [RoutesEnum.NotFound]: ErroPagePhrasesEnum.NotFound,
    [RoutesEnum.AccessDenied]: ErroPagePhrasesEnum.AccessDenied,
};

const routesList = Object.values(RoutesEnum).map((route) => ({
    path: route,
}));

function useTitle() {
    const { t } = useLocale();
    const location = useLocation();

    useEffect(() => {
        const [firstMatch] = matchRoutes(routesList, location) ?? [];

        if (firstMatch?.route?.path) {
            const titleFromPath = titleFromLocation[firstMatch.route.path];
            const newTitle = titleFromPath ? t(titleFromPath) : 'Sales Intelligence Platform';

            document.title = newTitle;
        }
    }, [location, t]);
}

export default useTitle;
