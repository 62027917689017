import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import { TabsContent } from '@components/utility/Tabs/Tabs';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import DropdownListResults from './QuickSearchDropdown/DropdownListResults';
import SearchResultsList from './QuickSearchDropdown/SearchResultsList';
import useCountries from '@core/api/useCountries';
import { useModal } from '@core/hooks/useModal';
import { Country, CountryCodesEnum } from '@core/enums/flagsEnum';
import { usePermissions } from '@core/hooks/usePermissions';
import { Maybe, OperatorLocationDetails, OperatorLocationSummary, OperatorSummary } from '@graphql/generated/graphql';
import { HomePhrasesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';
import { capitalize } from '@core/utils/string';

export const MIN_SEARCH_LENGTH = 2;

export type QuickSearchResults = {
    title: string;
    subtitle: string;
    logoUrl?: string;
    DefaultIcon: ReactNode;
};

export enum CategoryTypesEnum {
    LOCATION = 'location',
}

// Please consider that all commented code is regarding an additional functionality that will be added in the future.
// export type QuickSearchCategory = {
//     label: string;
//     results: QuickSearchResults[];
//     type?: 'location';
// };

export function useQuickSearchCountries() {
    const [selectedCountry, setSelectedCountry] = useState<Country>();
    const [countriesList, setCountriesList] = useState<Country[]>([]);
    const { getAllowedItemsFromArray } = usePermissions();
    const { locale } = useLocale();

    const {
        isOpen,
        setIsOpen
    } = useModal();

    const {
        data: rawCountriesList,
        defaultOrFirstAllowedCountry,
        doFetch
    } = useCountries();

    useEffect(() => {
        doFetch({
            locale,
        });
    }, [doFetch, locale]);

    useEffect(() => {
        if (rawCountriesList) {
            const countryAbbreviationsList = rawCountriesList
                .map((country) => country.abbreviation ?? '')
                .filter(Boolean);

            const availableCountries = getAllowedItemsFromArray('countries', countryAbbreviationsList);
            const mappedCountries: Country[] = rawCountriesList
                .filter((country) => availableCountries.includes(country.abbreviation ?? ''))
                .map((country) => {
                    return {
                        name: country.name,
                        code: country.abbreviation,
                    } as Country;
                });

            const defaultCountry = mappedCountries.find(
                (country) => country.code === defaultOrFirstAllowedCountry?.abbreviation,
            );

            setCountriesList(mappedCountries);
            setSelectedCountry(defaultCountry);
        }
    }, [rawCountriesList, defaultOrFirstAllowedCountry, getAllowedItemsFromArray]);

    const handleCountryChange = useCallback(
        (country: Country) => {
            setSelectedCountry(country);
            setIsOpen(false);
        },
        [setIsOpen],
    );

    return {
        isModalOpen: isOpen,
        setIsModalOpen: setIsOpen,
        countriesList,
        selectedCountry,
        handleCountryChange,
    };
}

export function useQuickSearchResults(data: OperatorSummary[], onOptionSelected: () => void, maxHeight: string) {
    const [tabsContent, setTabsContent] = useState<TabsContent[]>([]);
    const [showAll, setShowAll] = useState(true);
    const [showListCategory, setShowListCategory] = useState(false);

    const { t } = useLocale();

    const renderTabLabel = useCallback((label: string, resultsAmount: number) => {
        return (
            <div className="QuickSearchDropdown__tab">
                <span>{label}</span>
                <span className="QuickSearchDropdown__tab-counter">{resultsAmount}</span>
            </div>
        );
    }, []);

    const renderCategory = useCallback(
        (operators: OperatorSummary[]) => {
            return (
                <div className="SearchCategory">
                    <div className="SearchCategory__title" data-testid="QuickSearchCategoryTitleOperator">
                        {t(OperatorTargetingPhrasesEnum.Operators)}
                    </div>
                    <div className="SearchCategory__list" data-testid="ListQuickSearchResults">
                        <SearchResultsList operators={operators.slice(0, 5)} />
                        {operators.length > 5 && (
                            <div
                                className="SearchCategory__all-results"
                                data-testid="QuickSearchButtonSeeAll"
                                onClick={() => {
                                    // handleSeeAllButtonClick(operators);
                                    setShowListCategory(true);
                                }}
                            >
                                <Button type={ButtonTypeEnum.Link} modifiers={['naked']}>
                                    {`${t(HomePhrasesEnum.SeeAll)} ${operators.length} ${t(OperatorTargetingPhrasesEnum.Operators)}`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            );
        },
        [t],
    );

    const AllResultsTab: TabsContent = useMemo(() => {
        const totalResults = data?.length ?? 0;

        return {
            label: renderTabLabel(t(HomePhrasesEnum.All), totalResults),
            title: 'All',
            content: renderCategory(data),
            onClick: () => setShowAll(true),
        };
    }, [data, renderCategory, renderTabLabel, t]);

    useEffect(() => {
        setTabsContent([AllResultsTab]);
    }, [AllResultsTab]);

    const renderListResults = useMemo(() => {
        // if (!listCategory) {
        //     return <></>;
        // }

        return (
            <DropdownListResults
                operators={data}
                maxHeight={maxHeight}
                onBackClick={() => {
                    setShowListCategory(false);
                    setShowAll(true);
                }}
            />
        );
    }, [data, maxHeight]);

    // const [listCategory, setListCategory] = useState<OperatorSummary>();

    // const handleSeeAllButtonClick = useCallback(
    //     (category: QuickSearchCategory) => {
    //         if (CategoryTypesEnum.LOCATION === category.type) {
    //             setShowListCategory(true);
    //             setListCategory(category);
    //             return;
    //         }

    //         onOptionSelected();
    //     },
    //     [onOptionSelected],
    // );

    // const serializeData = useCallback(() => {
    //     const parsed: TabsContent[] = data.map((category) => {
    //         return {
    //             label: renderTabLabel(category.label, category.results.length),
    //             content: renderCategory(category),
    //             onClick: () => setShowAll(false),
    //         };
    //     });

    //     parsed.unshift(AllResultsTab);

    //     return setTabsContent(parsed);
    // }, [data, AllResultsTab, renderTabLabel, renderCategory]);

    // useEffect(() => {
    //     serializeData();
    // }, [serializeData]);

    return {
        tabsContent,
        showAll,
        showListCategory,
        renderListResults,
    };
}

export function addAddressSeparator(str?: Maybe<string>, capsAll?: boolean) {
    return capitalize(str ? `, ${str}` : '', capsAll);
}

export function buildQuickSearchAddress(location?: Maybe<OperatorLocationSummary> | OperatorLocationDetails) {
    if (!location) return '';

    switch (location.country) {
        case CountryCodesEnum.US:
        case CountryCodesEnum.CA:
        case CountryCodesEnum.AU:
        case CountryCodesEnum.NZ:
            return `${capitalize(location?.address_1 ?? '')}${addAddressSeparator(
                location?.address_2,
            )}${addAddressSeparator(location?.locality_1)}${addAddressSeparator(
                location?.administrative_area_1,
                true,
            )}${addAddressSeparator(location?.postal_code_1, true)}`;

        case CountryCodesEnum.MY:
            return `${capitalize(location?.address_1 ?? '')}${addAddressSeparator(
                location?.postal_code_1,
            )}${addAddressSeparator(location?.locality_1)}${addAddressSeparator(
                location?.administrative_area_1,
            )}${addAddressSeparator(location?.country)}`;

        case CountryCodesEnum.SG:
            return `${capitalize(location?.address_1 ?? '')}${addAddressSeparator(
                location?.locality_1,
            )}${addAddressSeparator(location?.postal_code_1)}`;

        default:
            return `${capitalize(location?.address_1 ?? '')}${addAddressSeparator(
                location?.address_2,
            )}${addAddressSeparator(location?.postal_code_1, true)}${addAddressSeparator(location?.locality_1)}`;
    }
}
