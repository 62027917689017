import { FC, useCallback } from 'react';

import { RoutesEnum } from '@enums/RoutesEnum';
import { useLocale } from '@core/utils/locale';
import { useModal } from '@core/hooks/useModal';
import Criteria from '@components/searchCriteria/Criteria/Criteria';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import NumberAnimation from '@components/utility/NumberAnimation/NumberAnimation';
import SaveSearchModal from '@pages/SavedSearches/SaveSearchModal/SaveSearchModal';
import { OperatorFilter, useCountOperatorsQuery } from '@graphql/generated/graphql';
import UpdateSaveSearchModal from '@pages/SavedSearches/SaveSearchModal/UpdateSaveSearchModal';
import { CommonPhrasesEnum, OperatorTargetingPhrasesEnum, SidebarPhrasesEnum } from '@enums/localeEnum';

import './OperatorTargetingSearchHeader.styles.scss';
import { useAppContext } from '../../../../App.context';
import CollapsedCriteria from '@components/searchCriteria/CollapsedCriteria/CollapsedCriteria';
import Collapse from '@components/utility/Collapse/Collapse';
import { WidgetType } from '@components/utility/Widget/Widget';

const OperatorTargetingSearchHeader: FC = () => {
    const { t } = useLocale();
    const {
        searchFilters,
        criteria,
        onDeleteCriterionParam,
        onResetCriteria,
        advancedSearchSort: { onClearSort },
        savedSearch,
        pagination: { onResetPagination },
    } = useAdvancedSearch();

    const {
        isDirty,
        activeSaveSearch
    } = savedSearch;

    const {
        isOpen: isUpdateModalOpen,
        setIsOpen: setUpdateModalOpen
    } = useModal();

    const {
        isOpen: isSaveModalOpen,
        setIsOpen: setSaveModalOpen
    } = useModal();

    const { data } = useCountOperatorsQuery({
        variables: { filter: searchFilters as OperatorFilter },
        skip: !searchFilters,
    });

    const { isUserId } = useAppContext();

    const onSearch = () => {
        onClearSort();
        onResetPagination();
    };

    const isUserSavedSearch = activeSaveSearch ? isUserId(activeSaveSearch.user_external_id) : false;

    const onSaveSearch = useCallback(() => {
        if (isDirty && isUserSavedSearch) {
            return setUpdateModalOpen(true);
        }

        return setSaveModalOpen(true);
    }, [isUserSavedSearch, isDirty, setSaveModalOpen, setUpdateModalOpen]);

    const handleOpenSaveModal = () => {
        setSaveModalOpen(true);
    };

    return (
        <div className="OperatorTargetingSearchHeader">
            <div className="OperatorTargetingSearchHeader__section">
                <Heading testId="OperatorTargeting" variant={HeadingVariant.H1}>
                    {t(SidebarPhrasesEnum.OperatorTargeting)}
                </Heading>
                <div style={{ display: 'flex', columnGap: 10 }}>
                    <Button
                        testId="buttonNewSearch"
                        modifiers={['tertiary']}
                        style={{ width: 'auto' }}
                        onClick={onResetCriteria}
                    >
                        {t(CommonPhrasesEnum.NewSearch)}
                    </Button>

                    <Button modifiers={['tertiary']} style={{ width: 'auto' }} onClick={onSaveSearch}>
                        {t(CommonPhrasesEnum.SaveSearch)}
                    </Button>
                </div>
            </div>
            <div className="OperatorTargetingSearchHeader__section OperatorTargetingSearchHeader__section--secondary">
                {criteria && Object.keys(criteria).length > 0 && (
                    <Collapse
                        testId="CollapsibleCriteriaButton"
                        collapsedContentSlot={<CollapsedCriteria data={criteria} />}
                        collapseType="bottom-arrow"
                        startCollapsed={false}
                        widgetProps={{
                            type: WidgetType.Section,
                            className: 'section-bottom',
                        }}
                    >
                        <div className="OperatorTargetingSearchHeader__criteria">
                            <Criteria data={criteria} onDelete={onDeleteCriterionParam} />
                        </div>
                    </Collapse>
                )}

                <div className="OperatorTargetingSearchHeader__results">
                    <div className="OperatorTargetingSearchHeader__operators-count">
                        <NumberAnimation
                            className="OperatorTargetingSearchHeader__operators-count-numbers"
                            count={data?.count ?? 0}
                        />
                        {t(OperatorTargetingPhrasesEnum.Operators)}{' '}
                    </div>
                    <Button
                        type={ButtonTypeEnum.Link}
                        modifiers={['primary']}
                        link={RoutesEnum.OperatorTargetingResults}
                        style={{ width: 132 }}
                        isDisabled={!data?.count}
                        onClick={onSearch}
                        testId="buttonSeeResults"
                    >
                        {t(OperatorTargetingPhrasesEnum.SeeResults)}
                    </Button>
                </div>
            </div>

            {isUpdateModalOpen && (
                <UpdateSaveSearchModal
                    isOpen={isUpdateModalOpen}
                    handleClose={() => setUpdateModalOpen(false)}
                    title={t(CommonPhrasesEnum.SaveSearch)}
                    criteria={criteria}
                    handleOpenSaveModal={handleOpenSaveModal}
                />
            )}

            {isSaveModalOpen && (
                <SaveSearchModal
                    title={t(CommonPhrasesEnum.SaveSearch)}
                    isOpen={isSaveModalOpen}
                    handleClose={() => setSaveModalOpen(false)}
                    criteria={criteria}
                />
            )}
        </div>
    );
};

export default OperatorTargetingSearchHeader;
