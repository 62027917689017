import { FC } from 'react';

import './SaveSearchModal.styles.scss';
import Button from '@components/utility/Button/Button';
import { CommonPhrasesEnum, OperatorTargetingPhrasesEnum, PhrasesEnum, SavedSearchEnum } from '@core/enums/localeEnum';
import Input from '@components/utility/Inputs/Input/Input';
import RadioGroup from '@components/utility/RadioGroup/RadioGroup';
import { ID } from '@core/models';

type SaveSearchBodyProps = {
    value: string | undefined;
    onChange: React.Dispatch<React.SetStateAction<string | undefined>>;
    radioValue: ID;
    hasError?: boolean;
    handleChange: (value: ID) => void;
    isSaveButtonDisabled: boolean;
    onSave: () => void;
    handleClose: () => void;
    t: (key: PhrasesEnum) => string;
    radioOptions: { id: number; title: string }[];
};

export const SaveSearchBody: FC<SaveSearchBodyProps> = ({
    value,
    onChange,
    radioValue,
    hasError,
    handleChange,
    isSaveButtonDisabled,
    onSave,
    handleClose,
    t,
    radioOptions,
}) => {
    const localizedRadioOptions = radioOptions.map((option) => {
        if (option.title === SavedSearchEnum.Private) {
            return { ...option, title: t(SavedSearchEnum.Private) };
        } else if (option.title === SavedSearchEnum.Public) {
            return { ...option, title: t(SavedSearchEnum.Public) };
        } else {
            return option;
        }
    });

    return (
        <div className="SaveSearchModal__body">
            <Input
                value={value}
                onChange={onChange}
                hasError={hasError}
                errorMessage={t(SavedSearchEnum.NameAlreadyExists)}
                placeholder={t(OperatorTargetingPhrasesEnum.SaveSearchModalPlaceholder)}
            />
            <RadioGroup options={localizedRadioOptions} value={radioValue} onChange={handleChange} />
            <div className="SaveSearchModal__footer">
                <Button modifiers={['secondary']} testId="buttonSavedSearchCancel" onClick={handleClose}>
                    {t(CommonPhrasesEnum.Cancel)}
                </Button>
                <Button
                    modifiers={['primary']}
                    testId="buttonSavedSearchSave"
                    isDisabled={isSaveButtonDisabled}
                    onClick={onSave}
                >
                    {t(CommonPhrasesEnum.Save)}
                </Button>
            </div>
        </div>
    );
};
