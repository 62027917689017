import useFetch from '@hooks/useFetch';
import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';
import { useMemo } from 'react';

const GET_ADMIN_AREA_1 = '/administrative_area_1';

type GetAdminAreaOneEndpoint = paths[typeof GET_ADMIN_AREA_1][MethodsEnum.GET];
type AdminAreaOneResponse = GetAdminAreaOneEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];
type AdminAreaOneParams = GetAdminAreaOneEndpoint[OperationsEnum.PARAMETERS]['query'];

export default function useAdminAreaOne(country: AdminAreaOneParams['country']) {
    const params = useMemo(() => ({ country }), [country]);
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<AdminAreaOneResponse>({
        url: GET_ADMIN_AREA_1,
        method: 'GET',
        params,
    });

    return { data, loading, error, doFetch } as const;
}
