import { useState } from 'react';

function useModal() {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return {
        isOpen,
        setIsOpen,
    };
}

export { useModal };
