import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

type SkeletonContextType = {
    isSkeleton: boolean;
    toggleSkeleton: () => void;
};

const SkeletonContext = createContext<SkeletonContextType | undefined>(undefined);

export const useSkeleton = () => {
    const context = useContext(SkeletonContext);
    if (context === undefined) {
        throw new Error('useSkeleton must be used within a SkeletonProvider');
    }
    return context;
};

type SkeletonProviderProps = {
    children: ReactNode;
};

export const SkeletonProvider: React.FC<SkeletonProviderProps> = ({ children }) => {
    const [isSkeleton, setIsSkeleton] = useState(true);

    const toggleSkeleton = useCallback(() => setIsSkeleton((prevIsSkeleton) => !prevIsSkeleton), []);

    const value = useMemo(() => ({ isSkeleton, toggleSkeleton }), [isSkeleton, toggleSkeleton]);

    return <SkeletonContext.Provider value={value}>{children}</SkeletonContext.Provider>;
};
