import useFetch from '@hooks/useFetch';
import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';
import { useMemo } from 'react';

const GET_ADMIN_AREA_2 = '/administrative_area_2';

type GetAdminAreaTwoEndpoint = paths[typeof GET_ADMIN_AREA_2][MethodsEnum.GET];
type AdminAreaTwoResponse = GetAdminAreaTwoEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];
type AdminAreaTwoParams = GetAdminAreaTwoEndpoint[OperationsEnum.PARAMETERS]['query'];

export default function useAdminAreaTwo(country: AdminAreaTwoParams['country']) {
    const params = useMemo(() => ({ country }), [country]);
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<AdminAreaTwoResponse>({
        url: GET_ADMIN_AREA_2,
        method: 'GET',
        params,
    });

    return { data, loading, error, doFetch } as const;
}
