import { MouseEvent, useCallback, useState } from 'react';

const useAnchorElement = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onAnchorClick = useCallback((event?: MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
            setAnchorEl(event.currentTarget);
        }
    }, []);

    const onClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return { anchorEl, open, onAnchorClick, onClose };
};

export default useAnchorElement;
