import { ReactElement, FC, useCallback } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Map from './Map';
import { Skeleton } from '@mui/material';
import './Map.styles.scss';

const DEFAULT_ZOOM = 17;
const REACT_APP_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

const OperatorProfileMap: FC<{
    coords: {
        lat: number;
        lng: number;
    };
    name?: string;
}> = ({
    coords,
    name = ''
}) => {
    const renderMap = useCallback(
        (status: Status): ReactElement => {
            if (status === Status.LOADING) {
                return <Skeleton className="Map" animation="pulse" />;
            }

            if (status === Status.FAILURE) {
                return <h3>{status} ...</h3>;
            }

            return <Map center={coords} marker={coords} operatorName={name} zoom={DEFAULT_ZOOM} />;
        },
        [coords, name],
    );

    if (!coords) {
        return null;
    }

    return <Wrapper apiKey={REACT_APP_GOOGLE_MAPS_KEY} libraries={['streetView']} render={renderMap} />;
};

export default OperatorProfileMap;
