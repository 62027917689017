import { FC, useEffect } from 'react';

import { useLocale } from '@core/utils/locale';
import Modal from '@components/utility/Modal/Modal';
import Button from '@components/utility/Button/Button';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import AnimatedLoader from '@components/ui/AnimatedLoader/AnimatedLoader';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { CommonPhrasesEnum, ExportPhrasesEnum } from '@core/enums/localeEnum';

import './ExportModal.styles.scss';

const ExportModal: FC<{
    isOpen: boolean;
    handleClose: () => void;
    onCancelExport: () => void;
    hasExported: boolean;
}> = ({
    isOpen,
    handleClose,
    hasExported = false,
    onCancelExport
}) => {
    const { t } = useLocale();

    useEffect(() => {
        if (hasExported) {
            setTimeout(handleClose, 500);
        }
    }, [handleClose, hasExported]);

    return (
        <Modal title={t(ExportPhrasesEnum.ExportModalTitle)} isOpen={isOpen} handleClose={handleClose}>
            <div className="ExportModal">
                <div className="ExportModal__body">
                    <div className="ExportModal__text">
                        <Heading variant={HeadingVariant.H5}>{t(ExportPhrasesEnum.ExportModalHeading)}</Heading>
                        <Text style={{ fontWeight: 400 }} variant={TextVariant.B2}>
                            {t(ExportPhrasesEnum.ExportModalText)}
                        </Text>
                    </div>
                    <AnimatedLoader className="ExportModal__loading" />

                    <div className="ExportModal__footer">
                        <Button
                            modifiers={['secondary']}
                            onClick={() => {
                                onCancelExport();
                                handleClose();
                            }}
                        >
                            {t(CommonPhrasesEnum.Cancel)}
                        </Button>
                        <Button
                            modifiers={['primary']}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            {t(CommonPhrasesEnum.Ok)}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ExportModal;
