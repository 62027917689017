import { useState, useRef, useEffect, useCallback } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export type Config<P = unknown> = Partial<AxiosRequestConfig<P>> & {
    cancelRunningRequest?: boolean;
};

type useFetchArgs<P> = {
    method?: Method;
    url: string;
    payload?: P;
    params?: AxiosRequestConfig['params'];
    lazy?: boolean;
    initialConfig?: Config<P>;
};

export type useFetchReturn<R, P> = {
    cancel: () => void;
    data: R | null;
    error: string | AxiosError<R, P>;
    loading: boolean;
    doFetch: (newConfig?: Config<P>) => Promise<void | AxiosResponse<R>>;
};

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
    },
});

const useFetch = <R = unknown, P = unknown>({
    initialConfig,
    method = 'GET',
    url,
    payload,
    params,
    lazy,
}: useFetchArgs<P>): useFetchReturn<R, P> => {
    const [data, setData] = useState<R | null>(null);
    const [error, setError] = useState<string | AxiosError<R, P>>('');
    const [loading, setLoading] = useState(false);

    const defaultConfig = useRef<Config<P> | undefined>(initialConfig);
    const abortControllerRef = useRef<AbortController | null>(null);

    const isLazy = lazy ?? false;

    const cancelRunningRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    };

    const doFetch = useCallback(
        async (newConfig: Config<P> = {}) => {
            if (newConfig.cancelRunningRequest !== false) {
                cancelRunningRequest();
            }

            abortControllerRef.current = new AbortController();

            setLoading(true);

            const mergedConfig = {
                ...defaultConfig?.current,
                ...newConfig,
            };

            try {
                const response = await api.request<R>({
                    data: payload,
                    params,
                    signal: abortControllerRef.current.signal,
                    method,
                    url,
                    ...mergedConfig,
                });

                setData(response.data);
                setError('');

                return response;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (axios.isCancel(error)) return;

                    return setError(error);
                }

                return Promise.reject(error);
            } finally {
                abortControllerRef.current = null;
                setLoading(false);
            }
        },
        [method, params, payload, url],
    );

    useEffect(() => {
        if (isLazy) {
            return;
        }

        doFetch();
    }, [doFetch, isLazy]);

    useEffect(() => {
        return () => {
            cancelRunningRequest();
        };
    }, []);

    return { doFetch, data, error, loading, cancel: cancelRunningRequest };
};

export default useFetch;
