import { useCallback, useMemo, useState } from 'react';

export type Sort = {
    field: string;
    order: SortOrder;
};

export enum SortOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

export type useTableSortReturn = {
    sortedColumns: Sort[];
    onClearSort: () => void;
    onSort: (field: string) => void;
};

type useTableSort = (defaultSortField: string) => useTableSortReturn;

const NEXT_SORT_DIRECTION = {
    [SortOrder.Asc]: SortOrder.Desc,
    [SortOrder.Desc]: SortOrder.Asc,
};

const useTableSort: useTableSort = (defaultSortField: string) => {
    const DEFAULT_SORT: Sort = useMemo(
        () => ({
            field: defaultSortField,
            order: SortOrder.Asc,
        }),
        [defaultSortField],
    );

    const [sortedColumns, setSortedColumns] = useState<Sort[]>([DEFAULT_SORT]);

    const onSort = useCallback(
        (field: string) => {
            setSortedColumns((prevSort) => {
                const isSameField = prevSort[0].field === field;

                const newSort = {
                    field,
                    order: isSameField ? NEXT_SORT_DIRECTION[prevSort[0].order as SortOrder] : SortOrder.Asc,
                };

                if (field === DEFAULT_SORT.field) {
                    return [newSort];
                }

                return [newSort, DEFAULT_SORT];
            });
        },
        [DEFAULT_SORT],
    );

    const onClearSort = useCallback(() => {
        setSortedColumns([DEFAULT_SORT]);
    }, [DEFAULT_SORT]);

    return {
        sortedColumns,
        onClearSort,
        onSort,
    };
};

export default useTableSort;
