import { FC, useMemo } from 'react';
import cn from 'classnames';

import { useLocale } from '@core/utils/locale';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { formatPrice, getCurrencyCode } from '@core/utils/currency';
import { OperatorPurchasePotential } from '@graphql/generated/graphql';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import VerticalProgressBar from '@components/utility/Charts/VerticalProgressBar/VerticalProgressBar';

import {
    DEFAULT_PURCHASE_POTENTIAL_GROUPS,
    DEFAULT_SORT_ORDER,
    EXPANDED_PURCHASE_POTENTIAL_GROUPS,
    mapPurchasePotentialNames,
    ParsedPurchasePotentialData,
    PotentialsEnum,
} from './PurchasePotential.constants';
import './PurchasePotential.styles.scss';
import { useAppContext } from '../../../../../App.context';

const PurchasePotential: FC<{
    purchaseData: OperatorPurchasePotential[];
    countryCode: CountryCodesEnum;
}> = ({
    purchaseData,
    countryCode
}) => {
    const {
        t,
        locale
    } = useLocale();

    const {
        userInfo: { features },
    } = useAppContext();

    const hasExpandedPurchasePotentialEnabled = useMemo(() => {
        const premiumFields = features?.premium_field ?? [];

        if (countryCode === CountryCodesEnum.CA || countryCode === CountryCodesEnum.US) {
            return false;
        }

        const availablePremiumFields = premiumFields.filter((field) => Object.keys(PotentialsEnum).includes(field));

        return availablePremiumFields.length >= 5;
    }, [countryCode, features?.premium_field]);

    const parsedPurchasePotentialData = useMemo(() => {
        const total =
            purchaseData.filter((item) => item.name === PotentialsEnum.PURCHASE_POTENTIAL_SUM_TOTAL)?.[0]?.value ?? 0;

        if (hasExpandedPurchasePotentialEnabled) {
            return EXPANDED_PURCHASE_POTENTIAL_GROUPS.reduce((acc, group) => {
                const availablePurchaseData = purchaseData.filter((data) =>
                    group.items.includes(data.name as PotentialsEnum),
                );

                if (availablePurchaseData.length > 0) {
                    acc[group.name] = availablePurchaseData.map((item) => {
                        const castedName = item.name as PotentialsEnum;

                        return {
                            name: t(mapPurchasePotentialNames[castedName]),
                            value: formatPrice(countryCode, item.value as number, locale) ?? '',
                            percentage: Math.round(((item.value as number) / total) * 100),
                        };
                    });
                }

                return acc;
            }, {} as ParsedPurchasePotentialData);
        }

        return DEFAULT_PURCHASE_POTENTIAL_GROUPS.reduce((acc, group) => {
            const availablePurchaseData = purchaseData.filter((data) =>
                group.items.includes(data.name as PotentialsEnum),
            );

            if (availablePurchaseData.length > 0) {
                acc[group.name] = availablePurchaseData.map((item) => {
                    const castedName = item.name as PotentialsEnum;

                    return {
                        name: t(mapPurchasePotentialNames[castedName]),
                        value: formatPrice(countryCode, item.value as number, locale) ?? '',
                        percentage: Math.round(((item.value as number) / total) * 100),
                    };
                });
            }

            return acc;
        }, {} as ParsedPurchasePotentialData);
    }, [countryCode, hasExpandedPurchasePotentialEnabled, locale, purchaseData, t]);

    const totalPurchasePotential = useMemo(() => {
        return purchaseData.find((item) => item.name === PotentialsEnum.PURCHASE_POTENTIAL_SUM_TOTAL);
    }, [purchaseData]);

    return (
        <div
            className={cn('ProfileDetails__section', {
                'col-4': hasExpandedPurchasePotentialEnabled,
                'col-2': !hasExpandedPurchasePotentialEnabled,
            })}
        >
            <Heading
                variant={HeadingVariant.H4}
                testId="DetailsPurchasePotential"
                className={cn({
                    'ProfileDetails__section-title': hasExpandedPurchasePotentialEnabled,
                })}
            >
                {t(OperatorProfilePhrasesEnum.PurchasePotential)}
            </Heading>
            <div className="PurchasePotential">
                <div className="PurchasePotential__total-container">
                    <Heading variant={HeadingVariant.H3} testId="DetailsPurchasePotentialTotal">
                        {formatPrice(countryCode, totalPurchasePotential?.value as number, locale) ?? 0}
                    </Heading>
                    <span data-testid="textDetailsPurchasePotentialTotalValue">
                        {`${t(OperatorProfilePhrasesEnum.Total)} (${getCurrencyCode(countryCode)})`}
                    </span>
                </div>
                <div className="PurchasePotential__charts-container">
                    {Object.entries(parsedPurchasePotentialData).map(([groupName, purchasePotentials]) => (
                        <div key={groupName} className="PurchasePotential__chart-group">
                            {hasExpandedPurchasePotentialEnabled && (
                                <Heading variant={HeadingVariant.H4} testId={`DetailsPurchasePotential${groupName}`}>
                                    {t(groupName as OperatorProfilePhrasesEnum)}
                                </Heading>
                            )}
                            <div className="PurchasePotential__charts">
                                {[...purchasePotentials]
                                    .sort((a, b) => {
                                        if (hasExpandedPurchasePotentialEnabled) {
                                            return 0;
                                        }

                                        const orderA = DEFAULT_SORT_ORDER[a.name] || 0;
                                        const orderB = DEFAULT_SORT_ORDER[b.name] || 0;

                                        return orderA - orderB;
                                    })
                                    .map((purchasePotential) => (
                                        <VerticalProgressBar
                                            key={purchasePotential.name}
                                            title={purchasePotential.name}
                                            value={purchasePotential.value}
                                            progress={purchasePotential.percentage}
                                            testId={`PurchasePotential${purchasePotential.name}`}
                                        />
                                    ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PurchasePotential;
