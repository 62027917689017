import { IconButton } from '@mui/material';

import { FCX } from '@core/models';
import { Country } from '@core/enums/flagsEnum';
import { IconArrowCollapse } from '@assets/icons';
import { OperatorSummary } from '@graphql/generated/graphql';
import Tabs, { TabsVariant } from '@components/utility/Tabs/Tabs';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';

import NoResultsFound from '../../NoResultsFound';
import { MIN_SEARCH_LENGTH, useQuickSearchResults } from '../QuickSearch.helpers';
import QuickSearchCountryFooter from '../QuickSearchCountryFooter';

const MIN_DROPDOWN_WIDTH = 342;
const MAX_DROPDOWN_HEIGHT = '60vh';

const QuickSearchDropdown: FCX<{
    children: React.ReactNode;
    data: OperatorSummary[];
    onOptionSelected: () => void;
    inputValueLength: number;
    onOpenModal: () => void;
    selectedCountry?: Country;
    width?: number;
    loading?: boolean;
}> = ({
    children,
    data,
    onOptionSelected,
    onOpenModal,
    inputValueLength,
    selectedCountry,
    width,
    loading
}) => {
    const {
        tabsContent,
        showAll,
        showListCategory,
        renderListResults
    } = useQuickSearchResults(
        data,
        onOptionSelected,
        MAX_DROPDOWN_HEIGHT,
    );

    const dropdownWidth = Math.max(width ?? 0, MIN_DROPDOWN_WIDTH);
    return (
        <div style={{ width: dropdownWidth, marginTop: 8 }}>
            {!data.length && inputValueLength >= MIN_SEARCH_LENGTH && !loading ? (
                <Widget type={WidgetType.Collapse} style={{ borderRadius: '12px 12px 0 0' }}>
                    <NoResultsFound />
                </Widget>
            ) : (
                <div
                    className="QuickSearchDropdown Inputs__dropdown Inputs__dropdown--spaced"
                    style={{ width: dropdownWidth }}
                >
                    {data.length > 0 && (
                        <div>
                            {showListCategory ? (
                                renderListResults
                            ) : (
                                <Tabs
                                    tabsContent={tabsContent}
                                    hasSharedContent={showAll}
                                    variant={TabsVariant.Scrollable}
                                    MuiTabsProps={{
                                        scrollButtons: true,
                                        variant: 'scrollable',
                                        ScrollButtonComponent: (props) => {
                                            if (props.direction === 'left' && !props.disabled) {
                                                return (
                                                    <IconButton
                                                        {...props}
                                                        className="ScrollButton on-left"
                                                        disableRipple
                                                    >
                                                        <IconArrowCollapse className="ScrollButton__icon on-left" />
                                                    </IconButton>
                                                );
                                            }

                                            if (props.direction === 'right' && !props.disabled) {
                                                return (
                                                    <IconButton
                                                        {...props}
                                                        className="ScrollButton on-right"
                                                        disableRipple
                                                    >
                                                        <IconArrowCollapse className="ScrollButton__icon on-right" />
                                                    </IconButton>
                                                );
                                            }
                                            return null;
                                        },
                                    }}
                                    maxHeight={MAX_DROPDOWN_HEIGHT}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
            <QuickSearchCountryFooter selectedCountry={selectedCountry} onOpenModal={onOpenModal} />
            {children}
        </div>
    );
};

export default QuickSearchDropdown;
