import { useCallback } from 'react';

import useFetch from '@core/hooks/useFetch';
import { MethodsEnum } from '@core/enums/api';
import { OperatorFilter } from '@graphql/generated/graphql';
import { stringifyObjectForGraphQL } from '@core/utils/string';

import { components } from './api';

const POST_SEARCH_SLIM = '/search-slim';

export type SearchSlimBatchResponse = components['schemas']['OperatorSummarySlimDTO'];

export default function useSearchSlim() {
    const {
        doFetch,
        loading
    } = useFetch({
        lazy: true,
        url: POST_SEARCH_SLIM,
        method: MethodsEnum.POST,
        initialConfig: {
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'text/plain',
                Accept: '*/*',
            },
            responseType: 'stream',
        },
    });
    const doFetchStreamDefault = useCallback(
        (batchCallback: (batch: SearchSlimBatchResponse[]) => void, filters: OperatorFilter) => {
            doFetch({
                data: buildSearchSlimQuery(filters),
            }).then(async (res) => {
                const data = res?.data as string;
                if (!data) {
                    return;
                }

                const parsedString = data.replace(/}\n{/g, '},{');
                const wrappedString = `{"data": [${parsedString}]}`;
                const parsedData = JSON.parse(wrappedString);

                batchCallback(parsedData.data as SearchSlimBatchResponse[]);
            });
        },
        [doFetch],
    );

    // Keep this code for future reference since an strategy for batch processing will be implemented
    // const doFetchStream = useCallback(
    //     async (batchCallback: (batch: SearchSlimBatchResponse[]) => void, filters: OperatorFilter) => {
    //         const bodyQuery = buildSearchSlimQuery(filters);

    //         const response = await fetch(`${process.env.REACT_APP_API}${POST_SEARCH_SLIM}`, {
    //             method: MethodsEnum.POST,
    //             credentials: 'include',
    //             body: bodyQuery,
    //             headers: {
    //                 'Content-Type': 'text/plain',
    //             },
    //         });

    //         const reader = response.body?.getReader();

    //         if (!reader) {
    //             return;
    //         }

    //         // eslint-disable-next-line no-constant-condition
    //         while (true) {
    //             const {
    //                 done,
    //                 value
    //             } = await reader.read();

    //             if (done) {
    //                 // Do something with last chunk of data then exit reader
    //                 console.log('Stream Done, closing');

    //                 return;
    //             }
    //             const decodedString = new TextDecoder().decode(new Uint8Array(value));
    //             console.log('Stream value:', decodedString);
    //             // const parsedString = decodedString.replace(/}\n{/g, '},{');
    //             // const wrappedString = `{"data": [${parsedString}]}`;

    //             try {
    //                 // const batchData = JSON.parse(wrappedString);
    //                 // batchCallback(batchData.data as SearchSlimBatchResponse[]);
    //                 // console.log('Batch data:', batchData);
    //             } catch (error) {
    //                 console.error('Error decoding value:', error);
    //                 // console.warn('string error value:', wrappedString);
    //             }
    //         }
    //     },
    //     [],
    // );

    return {
        doFetchStream: doFetchStreamDefault,
        loading,
    } as const;
}

export function buildSearchSlimQuery(filters: OperatorFilter): string {
    const stringifiedFilters = stringifyObjectForGraphQL(filters);

    return `
        query searchSlim {
            search_es(pageSize: 5, filter: ${stringifiedFilters}) {
                operators {
                    operator_identifier
                    latitude
                    longitude
                }

                size
                total_count
                previous_search_last_id
                previous_search_operator_identifier
            }
        }
    `;
}
