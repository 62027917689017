import React, { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Table from './Table';

const TableWithMobileFallback: FC<{
    children?: React.ReactNode;
    rowsCount: number;
    testId: string;
    loading: boolean;
}> = ({
    children,
    rowsCount,
    loading,
    testId
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (isMobile) {
        return <>{children}</>;
    }

    return <Table rowsCount={rowsCount} testId={testId} loading={loading} />;
};

export default TableWithMobileFallback;
