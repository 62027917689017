import cn from 'classnames';

import { FCX } from '@core/models';
import { GradientColorsEnum } from '@core/enums/gradientEnum';

import '../Chip.styles.scss';

const TableTag: FCX<{
    baseColor?: GradientColorsEnum;
    name: string;
}> = ({
    baseColor = GradientColorsEnum.ORANGE,
    name,
    testId
}) => {
    return (
        <div
            className={cn('Chip', 'Chip--tag', 'is-table-tag', 'is-gradient', `is-${baseColor}`, 'is-active')}
            data-testid={`tableTag${testId}`}
        >
            <div className="Chip__name">{name}</div>
        </div>
    );
};

export default TableTag;
