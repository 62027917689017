import { FCX, ID } from '@core/models';

import HorizontalStackedBarChart, { HorizontalStackedBarChartProps } from './HorizontalStackedBarChart';

const GroupedHorizontalStackedBarChart: FCX<{
    groups: HorizontalStackedBarChartProps[];
    onBarClick: (barId: ID, barName: string) => void;
}> = ({
    groups,
    onBarClick
}) => {
    if (!groups?.length) {
        return null;
    }

    return (
        <div className="GroupedHorizontalStackedBarChart">
            {groups.map((group) => (
                <HorizontalStackedBarChart key={group.value} {...group} onBarClick={onBarClick} />
            ))}
        </div>
    );
};

export default GroupedHorizontalStackedBarChart;
