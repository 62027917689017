import { ConfidenceLevel } from '@graphql/generated/graphql';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

import { ParsedRangeResponse } from './General.helpers';

export const CONFIDENCE_LEVEL_FILTER_RANGE: ParsedRangeResponse = {
    ranges: [
        {
            title: OperatorProfilePhrasesEnum.High,
            id: ConfidenceLevel.High,
        },
        {
            title: OperatorProfilePhrasesEnum.Medium,
            id: ConfidenceLevel.Medium,
        },
        {
            title: OperatorProfilePhrasesEnum.Low,
            id: ConfidenceLevel.Low,
        },
    ],
};

export const HOTEL_CLASS_FILTER_RANGE: ParsedRangeResponse = {
    ranges: [
        {
            title: '1 Star',
            id: 1,
        },
        {
            title: '2 Star',
            id: 2,
        },
        {
            title: '3 Star',
            id: 3,
        },
        {
            title: '4 Star',
            id: 4,
        },
        {
            title: '5 Star',
            id: 5,
        },
    ],
};
