import { FC, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { formatTestIds } from '@core/utils/string';
import Tag from '@components/utility/Chips/Tag/Tag';
import { Maybe, OperatorTags } from '@graphql/generated/graphql';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { FilterTypeEnum, OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

import './ProfileTags.styles.scss';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';

enum TagTypesEnum {
    FOOD = 'FOOD',
    PLACES = 'PLACES',
}

type ParsedTagsData = {
    [key in TagTypesEnum]: {
        name: string;
    }[];
};

const ProfileTags: FC<{
    tagsData: Maybe<Maybe<OperatorTags>[]>;
}> = ({ tagsData }) => {
    const { t } = useLocale();
    const isProfileTagsEnabled = useFeatureFlag(FeatureFlags.ProfileTags);

    const mapTagTypeNames = {
        [TagTypesEnum.FOOD]: t(OperatorProfilePhrasesEnum.FoodCuisine),
        [TagTypesEnum.PLACES]: t(OperatorProfilePhrasesEnum.Place),
    };

    const parsedTagsData: ParsedTagsData | null = useMemo(() => {
        if (!tagsData || tagsData.length === 0) {
            return null;
        }

        return tagsData.reduce(
            (acc, tag) => {
                if (tag?.type && tag?.description) {
                    const tagType = tag.type as TagTypesEnum;

                    acc[tagType].push({
                        name: tag.description,
                    });
                }

                return acc;
            },
            {
                [TagTypesEnum.FOOD]: [],
                [TagTypesEnum.PLACES]: [],
            } as ParsedTagsData,
        );
    }, [tagsData]);

    if (!parsedTagsData || !isProfileTagsEnabled) {
        return null;
    }

    return (
        <div className="ProfileTags ProfileDetails__section col-2">
            <Heading variant={HeadingVariant.H4} className="ProfileDetails__section-title" testId="headingTags">
                {t(FilterTypeEnum.Tags)}
            </Heading>
            <div className="ProfileTags__content">
                {Object.entries(parsedTagsData).map(([type, tags]) => {
                    if (!tags.length) {
                        return null;
                    }

                    return (
                        <div key={type} className="ProfileTags__group-container">
                            <Text variant={TextVariant.B2} testId={formatTestIds(`textProfileTagsType${type}`)}>
                                {mapTagTypeNames[type as TagTypesEnum]}
                            </Text>
                            <div className="ProfileTags__group-list">
                                {tags.map((tag) => (
                                    <Tag key={tag.name} name={tag.name} />
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProfileTags;
