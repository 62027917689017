import { useEffect, useMemo, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import useTags, { TagsCategoryEnum, TagsResponseContent } from '@core/api/useTags';
import { isEmpty } from '@core/helpers/helpers';

type TagsData = {
    [key: string]: TagsResponseContent;
};

function useTagsCriteria() {
    const [tags, setTags] = useState<TagsData | null>(null);

    const {
        t,
        locale
    } = useLocale();

    const { doFetch: fetchTags } = useTags();
    const { getCriterionByFilterKey } = useAdvancedSearch();

    const selectedCountry = useMemo(
        () => getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Country)?.[0],
        [getCriterionByFilterKey],
    );

    useEffect(() => {
        if (selectedCountry) {
            const tagPromises = Object.values(TagsCategoryEnum).map((type) => {
                return fetchTags({
                    params: {
                        country: selectedCountry.value,
                        category: type,
                        locale,
                    },
                    cancelRunningRequest: false,
                });
            });

            Promise.all(tagPromises).then((res) => {
                const newData = res.reduce((acc, curr) => {
                    const tags = curr?.data?.tags;
                    if (tags?.length === 1 && tags[0]?.children?.length > 0) {
                        acc[tags[0].category] = {
                            ...tags[0],
                        };
                    }

                    return acc;
                }, {} as TagsData);

                setTags(isEmpty(newData) ? null : newData);
            });
        }
    }, [fetchTags, locale, selectedCountry, t]);

    return { tags };
}

export default useTagsCriteria;
