import { FC, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { IconFilterLodging } from '@assets/icons';
import { generateAnchorId } from '@core/utils/string';
import { RangesTypesEnum } from '@core/api/useRanges';
import Heading from '@components/ui/Typography/Heading';
import { GradientColorsEnum } from '@enums/gradientEnum';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { FILTER_ID_PREFIX } from '@pages/OperatorTargetingCriteria/hooks/useSearchNavigation';
import { getSafeBottomMargin } from '@pages/OperatorTargetingCriteria/helpers/Criteria.helpers';
import { FilterTypeEnum, OperatorProfilePhrasesEnum, OperatorTargetingPhrasesEnum } from '@enums/localeEnum';

import { RangesData } from '../GeneralCriteria/General.helpers';
import LodgingAndRecreationSwitchList from './LodgingAndRecreationSwitchList';

const LodgingAndRecreationCriteria: FC<{
    ranges: RangesData | null;
}> = ({ ranges }) => {
    const { t } = useLocale();
    const { onResetCriterionParamsByKey } = useAdvancedSearch();

    const isNumberOfRoomsEnabled = useFeatureFlag(FeatureFlags.NumberOfRooms);
    const isHotelClassEnabled = useFeatureFlag(FeatureFlags.HotelClass);

    const safeBottomMargin = useMemo(() => getSafeBottomMargin(), []);

    if (!isNumberOfRoomsEnabled && !isHotelClassEnabled) {
        return null;
    }

    return (
        <div
            className="CriteriaContent with-gap"
            id={generateAnchorId(t(FilterTypeEnum.LodgingAndRecreation))}
            style={{ marginBottom: safeBottomMargin }}
        >
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterLodging />} baseColor={GradientColorsEnum.PURPLE} />
                <Heading>{t(FilterTypeEnum.LodgingAndRecreation)}</Heading>

                <Button
                    modifiers={['naked']}
                    type={ButtonTypeEnum.Link}
                    onClick={() => onResetCriterionParamsByKey(CriterionEnum.LodgingRecreation)}
                    style={{ width: 'auto', marginLeft: 'auto' }}
                    testId="ButtonCuisineTypesResetAll"
                >
                    {t(OperatorTargetingPhrasesEnum.ResetAll)}
                </Button>
            </div>
            {!!ranges?.[RangesTypesEnum.NumberOfRoomsRangeType] && isNumberOfRoomsEnabled && (
                <Widget
                    type={WidgetType.Section}
                    className="criteria GeneralCriteria__section"
                    id={generateAnchorId(t(FilterTypeEnum.NumberofRooms), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <LodgingAndRecreationSwitchList
                        range={ranges[RangesTypesEnum.NumberOfRoomsRangeType]}
                        testId={FilterTypeEnum.NumberofRooms}
                        title={`${t(FilterTypeEnum.NumberofRooms)}`}
                        criterionFilterKey={CriterionParamFilter.NumberOfRoomsRange}
                    />
                </Widget>
            )}
            {!!ranges?.[RangesTypesEnum.HotelClassRangeType] && isHotelClassEnabled && (
                <Widget
                    type={WidgetType.Section}
                    className="criteria GeneralCriteria__section"
                    id={generateAnchorId(t(OperatorProfilePhrasesEnum.HotelClass), FILTER_ID_PREFIX)}
                    dataObservable
                >
                    <LodgingAndRecreationSwitchList
                        range={ranges[RangesTypesEnum.HotelClassRangeType]}
                        testId={OperatorProfilePhrasesEnum.HotelClass}
                        title={t(OperatorProfilePhrasesEnum.HotelClass)}
                        criterionFilterKey={CriterionParamFilter.HotelStarLevel}
                    />
                </Widget>
            )}
        </div>
    );
};

export default LodgingAndRecreationCriteria;
