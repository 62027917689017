import {
    FilterTypeEnum,
    OperatorProfilePhrasesEnum,
    OperatorTargetingPhrasesEnum,
    PhrasesEnum,
} from '@core/enums/localeEnum';
import { CriterionParamFilter } from '@core/models/Criterion';

export const CONDENSABLE_FILTERS: CriterionParamFilter[] = [
    CriterionParamFilter.PostalCode,
    CriterionParamFilter.ChainSizeRange,
    CriterionParamFilter.YearsInBusinessRange,
    CriterionParamFilter.NumberOfEmployeesRangeNonCommercial,
    CriterionParamFilter.NumberOfEmployeesRangeCommercial,
    CriterionParamFilter.EstMealsPerDayRange,
    CriterionParamFilter.AnnualSalesRange,
    CriterionParamFilter.ConfidenceLevel,
    CriterionParamFilter.PotentialPurchaseTotal,
    CriterionParamFilter.NumberOfRoomsRange,
    CriterionParamFilter.HotelStarLevel,
    CriterionParamFilter.FoodTags,
    CriterionParamFilter.PlaceTags,
    CriterionParamFilter.AverageCheckRange,
    CriterionParamFilter.CustomerStatus,
];

export const MAP_FILTER_KEY_TO_TRANSLATION: Record<string, PhrasesEnum> = {
    [CriterionParamFilter.PostalCode]: OperatorTargetingPhrasesEnum.PostalCodes,
    [CriterionParamFilter.ChainSizeRange]: FilterTypeEnum.ChainIndependent,
    [CriterionParamFilter.YearsInBusinessRange]: OperatorProfilePhrasesEnum.YearsinBusiness,
    [CriterionParamFilter.EstMealsPerDayRange]: OperatorProfilePhrasesEnum.EstMealsPerDay,
    [CriterionParamFilter.AnnualSalesRange]: OperatorProfilePhrasesEnum.EstimatedAnnualSales,
    [CriterionParamFilter.ConfidenceLevel]: OperatorProfilePhrasesEnum.ConfidenceLevel,
    [CriterionParamFilter.PotentialPurchaseTotal]: FilterTypeEnum.TotalPurchasePotential,
    [CriterionParamFilter.NumberOfRoomsRange]: FilterTypeEnum.NumberofRooms,
    [CriterionParamFilter.HotelStarLevel]: OperatorProfilePhrasesEnum.HotelClass,
    [CriterionParamFilter.FoodTags]: OperatorProfilePhrasesEnum.FoodTags,
    [CriterionParamFilter.PlaceTags]: OperatorProfilePhrasesEnum.PlaceTags,
    [CriterionParamFilter.NumberOfEmployeesRangeNonCommercial]: OperatorTargetingPhrasesEnum.NonCommercialEmployees,
    [CriterionParamFilter.NumberOfEmployeesRangeCommercial]: OperatorTargetingPhrasesEnum.CommercialEmployees,
    [CriterionParamFilter.AverageCheckRange]: OperatorTargetingPhrasesEnum.AverageCheck,
    [CriterionParamFilter.CustomerStatus]: FilterTypeEnum.ClientStatus,
};
