import { FCX } from '@core/models';

import SingleSelectDropdown from './SingleSelectDropdown';
import { DropdownCommonProps } from '../Dropdowns.helpers';
import SelectWithCustomDropdown from '../SelectWithCustomDropdown/SelectWithCustomDropdown';

const DropdownSelect: FCX<
    DropdownCommonProps & {
        inputLabel?: string;
        dropdownPlaceholder?: string;
    }
> = (props) => <SelectWithCustomDropdown {...props} DropdownComponent={SingleSelectDropdown} unscrollable />;

export default DropdownSelect;
