import { isEmpty } from '@core/helpers/helpers';
import { useAppContext } from '../../App.context';
import { useCallback } from 'react';
import { formatPermissionString } from '@core/utils/string';

type Approach = 'includes' | 'excludes';

export type PermissionsStructure = {
    [key in Approach]: Permissions;
};

export type Permissions = {
    countries: string[];
    administrative_area1: Record<string, never> | Record<string, string[]>;
    administrative_area2: Record<string, never> | Record<string, string[]>;
};

export enum UserAccess {
    FullAccess = 'full_access',
    PartialAccess = 'partial_access',
    NoAccess = 'no_access',
}

export type PermissionInfo = {
    approach: Approach;
    values: string[] | Record<string, string[]>;
    access: UserAccess;
};

export function usePermissions() {
    const {
        userInfo: { permissions },
    } = useAppContext();

    const getPermissionInfo = useCallback(
        (key: keyof Permissions): PermissionInfo => {
            if (!permissions) {
                return {
                    approach: 'includes',
                    values: [],
                    access: UserAccess.NoAccess,
                };
            }
            const approach = permissions.includes[key] ? 'includes' : 'excludes';
            const values = permissions[approach]?.[key];

            if (isEmpty(values)) {
                return {
                    approach,
                    values: [],
                    access: approach === 'includes' ? UserAccess.NoAccess : UserAccess.FullAccess,
                };
            }

            return {
                approach,
                values,
                access: UserAccess.PartialAccess,
            };
        },
        [permissions],
    );

    const hasPermission = useCallback(
        (field: keyof Permissions) => {
            if (!permissions) return false;

            const { access } = getPermissionInfo(field);

            if (access === UserAccess.NoAccess) {
                return false;
            }

            return true;
        },
        [getPermissionInfo, permissions],
    );

    const getFieldSubPermissions = useCallback((values: Record<string, string[]>, subPermissionKey?: string) => {
        if (!subPermissionKey || !values?.[subPermissionKey]) {
            return [];
        }

        return values[subPermissionKey];
    }, []);

    const getAllowedItemsFromArray = useCallback(
        (field: keyof Permissions, list: string[], subPermissionKey?: string) => {
            if (!permissions) return [];

            const {
                approach,
                values,
                access
            } = getPermissionInfo(field);

            if (access === UserAccess.NoAccess) {
                return [];
            }

            if (access === UserAccess.FullAccess) {
                return list;
            }

            if (Array.isArray(values)) {
                const parsedValues = values.map(formatPermissionString);
                return list.filter((item) => {
                    const parsedItem = formatPermissionString(item);

                    return approach === 'includes'
                        ? parsedValues.includes(parsedItem)
                        : !parsedValues.includes(parsedItem);
                });
            }

            const fieldPermissions = getFieldSubPermissions(values, subPermissionKey).map(formatPermissionString);

            return list.filter((item) => {
                const parsedItem = formatPermissionString(item);
                return approach === 'includes'
                    ? fieldPermissions.includes(parsedItem)
                    : !fieldPermissions.includes(parsedItem);
            });
        },
        [getFieldSubPermissions, getPermissionInfo, permissions],
    );

    const hasPermissionToValue = useCallback(
        (field: keyof Permissions, value: string, subPermissionKey?: string) => {
            if (!permissions) return false;

            const {
                approach,
                values,
                access
            } = getPermissionInfo(field);

            if (access === UserAccess.NoAccess) {
                return false;
            }

            if (access === UserAccess.FullAccess) {
                return true;
            }
            const parsedValue = formatPermissionString(value);

            if (Array.isArray(values)) {
                const parsedValues = values.map(formatPermissionString);
                const isValueIncluded = parsedValues.includes(parsedValue);

                return approach === 'includes' ? isValueIncluded : !isValueIncluded;
            }

            const fieldPermissions = getFieldSubPermissions(values, subPermissionKey).map(formatPermissionString);
            const isValueIncluded = fieldPermissions.includes(parsedValue);

            return approach === 'includes' ? isValueIncluded : !isValueIncluded;
        },
        [getFieldSubPermissions, getPermissionInfo, permissions],
    );

    return { permissions, getPermissionInfo, hasPermissionToValue, hasPermission, getAllowedItemsFromArray };
}
