import { useCallback, useEffect, useState } from 'react';

const POSITION_OPTIONS: PositionOptions = {
    enableHighAccuracy: true,
};

const useGetUserLocation = () => {
    const [location, setLocation] = useState<GeolocationCoordinates | null>(null);
    const [error, setError] = useState<string | null>(null);

    const refreshLocation = useCallback(() => {
        setLocation(null);
        setError(null);
    }, []);

    useEffect(() => {
        if (!navigator.geolocation) {
            setError('Geolocation is not supported by this browser.');
            return;
        }

        const onSuccess = ({ coords }: GeolocationPosition) => {
            setError(null);
            setLocation(coords);
        };

        const onError = (error: GeolocationPositionError) => {
            setError(error.message);
        };

        function getCurrentPosition() {
            navigator.geolocation.getCurrentPosition(onSuccess, onError, POSITION_OPTIONS);
        }

        function handlePermissionStatusChange(permissionStatus: PermissionStatus) {
            if (permissionStatus.state === 'granted') {
                getCurrentPosition();
            } else if (permissionStatus.state === 'denied') {
                setError('Geolocation permission is denied by this browser.');
            } else if (permissionStatus.state === 'prompt') {
                permissionStatus.onchange = () => handlePermissionStatusChange(permissionStatus);
            }
        }

        navigator.permissions.query({ name: 'geolocation' }).then(handlePermissionStatusChange);
    }, []);

    return {
        location,
        error,
        refreshLocation,
    };
};

export default useGetUserLocation;
