import { useState, useEffect } from 'react';
import { MenuItem, MenuList } from '@mui/material';

import { FCX } from '@core/models';
import { IconSearch } from '@assets/icons';
import { useInput } from '@core/hooks/useInput';
import '@components/utility/Inputs/Inputs.styles.scss';

import '../Dropdowns.styles.scss';
import NoResultsFound from '../../NoResultsFound';
import { DropdownCommonProps, DropdownOption, filterDropdownOptions, renderOptionLabel } from '../Dropdowns.helpers';

const MultiSelectDropdown: FCX<DropdownCommonProps> = ({
    options,
    placeholder,
    selectedOptions,
    onOptionSelected,
    testId,
}) => {
    const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>(options);
    const {
        value,
        onChange
    } = useInput();

    useEffect(() => {
        setFilteredOptions(filterDropdownOptions(options, value));
    }, [options, value]);

    return (
        <div className="Dropdowns">
            <div className="Dropdowns__search">
                <input
                    data-testid={`SearchInput${testId}`}
                    className="Dropdowns__search-input"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                />
                <IconSearch className="Dropdowns__search-icon" />
            </div>
            <div className="Dropdowns__list">
                <MenuList className="Inputs__options-list" data-testid={`${testId}Ul`}>
                    {!filteredOptions.length && <NoResultsFound />}
                    {filteredOptions.map((option) => (
                        <MenuItem
                            disabled={option.isDisabled}
                            onClick={() => onOptionSelected(option)}
                            key={option.id}
                            value={option.value}
                            classes={{
                                root: 'Inputs__option',
                            }}
                        >
                            {renderOptionLabel(
                                option,
                                selectedOptions?.some((selectedOption) => {
                                    return selectedOption.value === option.value;
                                }),
                            )}
                        </MenuItem>
                    ))}
                </MenuList>
            </div>
        </div>
    );
};

export default MultiSelectDropdown;
