import { useCallback, useState, useEffect } from 'react';

import { useLocale } from '@core/utils/locale';
import { TableSearchKeysEnum } from '@core/enums/TableSearchKeysEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { InputMaybe, OperatorFilter, SearchOperatorsQuery, useSearchOperatorsQuery } from '@graphql/generated/graphql';

const MIN_TABLE_SEARCH_LENGTH = 2;

export type useOperatorResultsListReturnType = {
    operatorResults: SearchOperatorsQuery | undefined;
    loading: boolean;
    listFilters: InputMaybe<OperatorFilter>;
    resultsCount: number;
    setSearchTerm: React.Dispatch<string>;
    searchTerm: string;
};

function useOperatorResultsList(): useOperatorResultsListReturnType {
    const { locale } = useLocale();
    const {
        searchFilters,
        advancedSearchSort: { sortedColumns },
        pagination: {
            activePage,
            perPage,
            onChangePage
        },
    } = useAdvancedSearch();

    const [tableSearchFilter, setTableSearchFilter] = useState(searchFilters);

    const {
        data,
        loading,
        observable
    } = useSearchOperatorsQuery({
        variables: {
            pageNumber: activePage,
            pageSize: perPage,
            filter: tableSearchFilter,
            sort: sortedColumns,
            locale,
        },
        fetchPolicy: 'no-cache',
    });

    const [resultsCount, setResultsCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [operatorResults, setOperatorResults] = useState(data);

    const createTableSearchFilters = useCallback(
        (currentFilter: OperatorFilter | undefined, input: string): OperatorFilter => {
            const searchFilters = Object.values(TableSearchKeysEnum).map((key) => ({
                [key]: {
                    contains: input,
                },
            }));

            const combinedFilters = currentFilter ? [currentFilter, { or: searchFilters }] : [{ or: searchFilters }];

            onChangePage(1);
            return {
                and: combinedFilters,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        const filter =
            searchTerm.length >= MIN_TABLE_SEARCH_LENGTH
                ? createTableSearchFilters(searchFilters, searchTerm)
                : searchFilters;
        setTableSearchFilter(filter);
    }, [createTableSearchFilters, searchFilters, searchTerm]);

    useEffect(() => {
        return () => {
            if (searchTerm.length >= MIN_TABLE_SEARCH_LENGTH) {
                onChangePage(1);
            }
        };
        // Warning disabled because we want to trigger the effect only when the searchTerm changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        if (data) {
            const newTotalResultCount = data?.search?._metadata?.total_count;

            setOperatorResults(data);
            setResultsCount((prevCount) => {
                if (typeof newTotalResultCount === 'number' && newTotalResultCount !== prevCount) {
                    return newTotalResultCount;
                }

                return prevCount;
            });
        }
    }, [data, setResultsCount]);

    return {
        operatorResults,
        loading,
        listFilters: observable.options.variables?.filter,
        resultsCount,
        setSearchTerm,
        searchTerm,
    };
}

export default useOperatorResultsList;
