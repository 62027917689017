import cn from 'classnames';
import { InputLabel, TextField, InputAdornment } from '@mui/material';

import { FCX } from '@core/models';
import { IconClose, IconSearch } from '@assets/icons';

import InputHelperText from '../InputHelperText';

const Input: FCX<{
    hasError?: boolean;
    isDisabled?: boolean;
    inputLabel?: string;
    value?: string;
    onChange: (values: string) => void;
    helperText?: string;
    placeholder?: string;
    height?: string;
    errorMessage?: string;
    withClearIcon?: boolean;
    withSearchIcon?: boolean;
    isLoading?: boolean;
}> = ({
    hasError = false,
    isDisabled = false,
    inputLabel,
    value,
    onChange,
    placeholder,
    height,
    errorMessage,
    withClearIcon = false,
    withSearchIcon = false,
    isLoading = false,
    testId,
}) => {
    return (
        <div className="Input">
            {!!inputLabel && <InputLabel error={hasError}>{inputLabel}</InputLabel>}
            <TextField
                variant="outlined"
                fullWidth
                disabled={isDisabled}
                error={hasError}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                }}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: withSearchIcon && <IconSearch />,
                    endAdornment: withClearIcon && (
                        <InputAdornment
                            classes={{
                                root: cn('Inputs__clear-icon', {
                                    'Inputs__clear-icon--visible': value && value?.length >= 1,
                                }),
                            }}
                            position="end"
                            onClick={() => {
                                if (!isLoading) {
                                    onChange('');
                                }
                            }}
                        >
                            {isLoading ? (
                                <div className="Inputs__loader" />
                            ) : (
                                <IconClose data-testid="InputClearIcon" />
                            )}
                        </InputAdornment>
                    ),
                }}
                data-testid={`input${testId}`}
                sx={{ height: height ?? 'auto' }}
            />
            <InputHelperText hasError={hasError} errorMessage={errorMessage} />
        </div>
    );
};

export default Input;
