import { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconCheckmark } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { useModal } from '@core/hooks/useModal';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import Button from '@components/utility/Button/Button';
import { CommonPhrasesEnum } from '@core/enums/localeEnum';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import SaveSearchModal from '@pages/SavedSearches/SaveSearchModal/SaveSearchModal';
import EditSearchModal from '@pages/SavedSearches/SaveSearchModal/EditSearchModal';
import UpdateSaveSearchModal from '@pages/SavedSearches/SaveSearchModal/UpdateSaveSearchModal';

import { useAppContext } from '../../../../App.context';

const HeaderContent: FC = () => {
    const { t } = useLocale();
    const {
        isOpen: isUpdateModalOpen,
        setIsOpen: setUpdateModalOpen
    } = useModal();

    const {
        isOpen: isEditModalOpen,
        setIsOpen: setEditModalOpen
    } = useModal();

    const {
        isOpen: isSaveModalOpen,
        setIsOpen: setSaveModalOpen
    } = useModal();

    const {
        criteria,
        onResetCriteria,
        savedSearch: {
            isDirty,
            activeSaveSearch
        },
    } = useAdvancedSearch();

    const navigate = useNavigate();

    const { isUserId } = useAppContext();

    const onNewSearch = useCallback(() => {
        onResetCriteria();
        navigate(RoutesEnum.OperatorTargetingCriteria);
    }, [navigate, onResetCriteria]);

    const isUserSavedSearch = activeSaveSearch ? isUserId(activeSaveSearch.user_external_id) : false;

    const onSaveSearch = useCallback(() => {
        if (isDirty && isUserSavedSearch) {
            return setUpdateModalOpen(true);
        }

        return setSaveModalOpen(true);
    }, [isDirty, setSaveModalOpen, setUpdateModalOpen, isUserSavedSearch]);

    const onEditName = useCallback(() => {
        setEditModalOpen(true);
    }, [setEditModalOpen]);

    return (
        <>
            {isUserSavedSearch && (
                <div className="OperatorTargetingResults__naked-button">
                    <Button
                        testId="buttonEditSavedSearches"
                        modifiers={['naked']}
                        style={{ width: 'auto' }}
                        onClick={onEditName}
                    >
                        {t(CommonPhrasesEnum.EditName).toLocaleLowerCase()}
                    </Button>
                </div>
            )}
            <div className="OperatorTargetingResults__buttons">
                <Button
                    testId="buttonNewSearch"
                    modifiers={['tertiary']}
                    style={{ width: 'auto', backgroundColor: 'white' }}
                    onClick={onNewSearch}
                >
                    {t(CommonPhrasesEnum.NewSearch)}
                </Button>

                <Button
                    testId="buttonSaveSearch"
                    modifiers={['primary']}
                    style={{ width: 'auto' }}
                    onClick={onSaveSearch}
                    isDisabled={!isDirty && isUserSavedSearch}
                >
                    {!isDirty && isUserSavedSearch ? (
                        <>
                            <IconCheckmark width={14} height={14} style={{ marginRight: '6px' }} />
                            {t(CommonPhrasesEnum.SearchSaved)}
                        </>
                    ) : (
                        t(CommonPhrasesEnum.SaveSearch)
                    )}
                </Button>
            </div>

            {isUpdateModalOpen && (
                <UpdateSaveSearchModal
                    title={t(CommonPhrasesEnum.SaveSearch)}
                    isOpen={isUpdateModalOpen}
                    handleClose={() => setUpdateModalOpen(false)}
                    criteria={criteria}
                    handleOpenSaveModal={() => {
                        setSaveModalOpen(true);
                    }}
                />
            )}

            {isEditModalOpen && (
                <EditSearchModal
                    title={t(CommonPhrasesEnum.EditName)}
                    isOpen={isEditModalOpen}
                    handleClose={() => setEditModalOpen(false)}
                    data={activeSaveSearch as SaveSearchResponse}
                    shouldUpdateActiveSavedSearch
                />
            )}

            {isSaveModalOpen && (
                <SaveSearchModal
                    title={t(CommonPhrasesEnum.SaveSearch)}
                    isOpen={isSaveModalOpen}
                    handleClose={() => setSaveModalOpen(false)}
                    criteria={criteria}
                />
            )}
        </>
    );
};

export default HeaderContent;
