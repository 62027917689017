import { ReactNode, FC } from 'react';
import cn from 'classnames';
import MuiPopover, { PopoverProps } from '@mui/material/Popover';

import './Popover.styles.scss';

export type PopoverPositionProps = {
    anchorOrigin: NonNullable<PopoverProps['anchorOrigin']>;
    transformOrigin: NonNullable<PopoverProps['transformOrigin']>;
};

const Popover: FC<
    PopoverPositionProps & {
        children: ReactNode;
        open: boolean;
        anchorEl: Element | null;
        onClose: PopoverProps['onClose'];
        unscrollable?: boolean;
    }
> = ({
    open,
    children,
    anchorOrigin,
    transformOrigin,
    anchorEl,
    onClose,
    unscrollable
}): JSX.Element => (
    <MuiPopover
        open={open}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className="Popover"
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{
            paper: cn('Popover__paper', { 'Popover__paper--unscrollable': unscrollable }),
        }}
    >
        {children}
    </MuiPopover>
);

export default Popover;
