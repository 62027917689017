import { FC, useCallback, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { useInput } from '@core/hooks/useInput';
import { useAdvancedSearch } from '@core/contexts';
import Modal from '@components/utility/Modal/Modal';
import { CriteriaData } from '@core/models/Criterion';
import useSaveSearch from '@core/api/SaveSearch/useSaveSearch';
import useRadioGroup from '@components/utility/RadioGroup/useRadioGroup';

import './SaveSearchModal.styles.scss';
import { SaveSearchBody } from './SaveSearchBody';
import { SuccessComponent } from './SaveSearchSuccess';

export const radioOptions = [
    { id: 1, title: 'Private' },
    { id: 2, title: 'Public' },
];
export const PUBLICLY_AVAILABLE_RADIO_VALUE = 2;

const SaveSearchModal: FC<{
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    criteria?: CriteriaData;
}> = ({
    isOpen,
    handleClose,
    title,
    criteria
}) => {
    const { t } = useLocale();
    const {
        value,
        onChange
    } = useInput();

    const {
        value: radioValue,
        handleChange
    } = useRadioGroup(radioOptions[0].id);

    const {
        success,
        error,
        loading,
        doFetch
    } = useSaveSearch();

    const {
        savedSearch: {
            savedSearchDoFetch,
            updateActiveSavedSearch,
            updateIsDirty
        },
    } = useAdvancedSearch();

    const onSave = useCallback(() => {
        doFetch({
            name: value ?? '',
            query: JSON.stringify(criteria),
            publicly_available: radioValue === PUBLICLY_AVAILABLE_RADIO_VALUE,
        }).then((response) => {
            if (response?.data) {
                updateActiveSavedSearch(response.data);
                updateIsDirty(false);
                savedSearchDoFetch();
                setTimeout(handleClose, 2000);
            }
        });
    }, [criteria, doFetch, handleClose, radioValue, savedSearchDoFetch, updateActiveSavedSearch, updateIsDirty, value]);

    const isSaveButtonDisabled = useMemo(
        () => typeof value === 'undefined' || value.length <= 2 || !radioValue || loading,
        [value, radioValue, loading],
    );

    return (
        <Modal title={success ? '' : title} isOpen={isOpen} handleClose={handleClose}>
            <div className="SaveSearchModal">
                {success ? (
                    <SuccessComponent value={value} t={t} />
                ) : (
                    <SaveSearchBody
                        value={value}
                        onChange={onChange}
                        hasError={!!error}
                        radioValue={radioValue}
                        handleChange={handleChange}
                        isSaveButtonDisabled={isSaveButtonDisabled}
                        onSave={onSave}
                        handleClose={handleClose}
                        t={t}
                        radioOptions={radioOptions}
                    />
                )}
            </div>
        </Modal>
    );
};

export default SaveSearchModal;
