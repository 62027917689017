import { AxiosResponse, AxiosHeaders } from 'axios';

const UTF8_BOM = '\uFEFF';

export enum MIMETypes {
    CSV = 'text/csv; charset=utf-8',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

type NavigatorIncludesMS = Navigator & { msSaveBlob?: (blob: Blob, filename?: string) => void };

export function downloadFile(url: string, filename?: string): void {
    const link = document.createElement('a');

    if (typeof link.download === 'undefined') {
        window.location.href = url;
    } else {
        link.href = url;
        link.download = filename || 'true';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function downloadBlobFile(blob: Blob, filename?: string) {
    const navigator = window.navigator as NavigatorIncludesMS;

    if (navigator.msSaveBlob && typeof navigator.msSaveBlob !== 'undefined') {
        navigator.msSaveBlob(blob, filename);
    } else {
        const URL = window.URL || window.webkitURL;
        const downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            downloadFile(downloadUrl, filename);
        } else {
            window.location.href = downloadUrl;
        }

        setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
        }, 100);
    }
}

export function getFilenameFromContentDisposition(disposition: string | null): string {
    let filename = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        const matches = disposition.match(filenameRegex);
        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
}

export function getBlobFromMimeType(data: BlobPart | string, mimeType: MIMETypes): Blob {
    switch (mimeType) {
        case MIMETypes.CSV:
            return new Blob([UTF8_BOM + data], {
                type: 'text/csv; charset=utf-8',
            });

        case MIMETypes.XLSX:
            return new Blob([data]);

        default:
            return new Blob([data], {
                type: 'text/plain',
            });
    }
}

export function getFileExtensionFromMimeType(mimeType: MIMETypes): string {
    switch (mimeType) {
        case MIMETypes.CSV:
            return 'csv';

        case MIMETypes.XLSX:
            return 'xlsx';

        default:
            return 'txt';
    }
}

export async function downloadExport(
    response: void | AxiosResponse<BlobPart>,
    exportFormat: MIMETypes,
    fallbackFilename: string,
) {
    if (response && response.status === 200 && response.data) {
        const blob = getBlobFromMimeType(response.data, exportFormat);
        const disposition = (response.headers as AxiosHeaders).get('Content-Disposition');
        const filename = getFilenameFromContentDisposition(disposition as string) || fallbackFilename;
        downloadBlobFile(blob, filename);
    }
    return;
}
