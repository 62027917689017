import { FC, useEffect, useMemo } from 'react';

import { ID, iOption } from '@core/models';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import SwitchGroup from '@components/utility/SwitchGroup/SwitchGroup';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';

import { handlePillSearchHighlighting, highlightSegmentIfSelected } from '../../helpers/Criteria.helpers';
import { filterAndSortSegments, ClassificationDTO } from '../../helpers/Classification.helpers';

const SegmentsSubChannel: FC<{
    onChangeSegments: (values: ID[]) => void;
    subChannel: ClassificationDTO;
    selectedValues: ID[];
    searchSelectedValue: SelectOption | null;
    onClearSearch: () => void;
}> = ({
    onChangeSegments,
    subChannel,
    selectedValues,
    searchSelectedValue,
    onClearSearch
}) => {
    const options: iOption[] = useMemo(() => {
        return (
            filterAndSortSegments(subChannel?.children)?.map((option) => ({
                id: option?.external_id ?? '',
                title: highlightSegmentIfSelected(searchSelectedValue?.id, option?.external_id, option?.description),
                isDisabled: option?.allowed === false,
            })) ?? []
        );
    }, [searchSelectedValue?.id, subChannel?.children]);

    const isSubchannelGroupDisabled = useMemo(() => {
        const channelList = filterAndSortSegments(subChannel?.children) || [];
        return channelList?.every((channel) => {
            return channel.allowed === false;
        });
    }, [subChannel?.children]);

    useEffect(() => {
        const { abortHighlightedPillListener } = handlePillSearchHighlighting(
            subChannel,
            searchSelectedValue?.id,
            onClearSearch,
        );

        return () => {
            abortHighlightedPillListener();
        };
    }, [onClearSearch, searchSelectedValue?.id, subChannel]);

    return (
        <SwitchGroup
            title={highlightSegmentIfSelected(
                searchSelectedValue?.id,
                subChannel?.external_id,
                subChannel?.description,
            )}
            id={subChannel?.external_id}
            options={options}
            value={selectedValues}
            onChange={onChangeSegments}
            color={GradientColorsEnum.BLUE}
            isDisabled={isSubchannelGroupDisabled}
            testId={subChannel?.external_id}
        />
    );
};

export default SegmentsSubChannel;
