import {
    // IconHeadquarter,
    // IconHeadquarterActive,
    IconHome,
    IconHomeActive,
    IconOperator,
    IconOperatorActive,
    IconSavedSearchNav,
    IconSavedSearchNavActive,
} from '@assets/icons';
import { SidebarPhrasesEnum } from '@enums/localeEnum';
import { RoutesEnum } from '@enums/RoutesEnum';

export interface INavLink {
    link: RoutesEnum;
    sublinks?: RoutesEnum[];
    title: SidebarPhrasesEnum;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    IconActive: React.FC<React.SVGProps<SVGSVGElement>>;
    testId: string;
}

export const useNavLinks = () => {
    const navLinks: INavLink[] = [
        {
            link: RoutesEnum.Home,
            title: SidebarPhrasesEnum.Home,
            Icon: IconHome,
            IconActive: IconHomeActive,
            testId: 'buttonNavbarHome',
        },
        {
            link: RoutesEnum.OperatorTargetingCriteria,
            sublinks: [RoutesEnum.OperatorProfile, RoutesEnum.OperatorTargetingResults],
            title: SidebarPhrasesEnum.OperatorTargeting,
            Icon: IconOperator,
            IconActive: IconOperatorActive,
            testId: 'buttonNavbarOperatorTargeting',
        },
        // Not included in V1
        // {
        //     link: RoutesEnum.HeadquarterTargeting,
        //     title: SidebarPhrasesEnum.HeadquarterTargeting,
        //     Icon: IconHeadquarter,
        //     IconActive: IconHeadquarterActive,
        //     testId: 'buttonNavbarHeadquarterTargeting',
        // },
    ];

    navLinks.push({
        link: RoutesEnum.SavedSearches,
        title: SidebarPhrasesEnum.SavedSearches,
        Icon: IconSavedSearchNav,
        IconActive: IconSavedSearchNavActive,
        testId: 'buttonNavbarSavedSearches',
    });

    return navLinks;
};
