import { FC, ReactNode } from 'react';

import './Page.styles.scss';

const Page: FC<{
    children?: ReactNode;
    isMenuOpen: boolean;
}> = ({
    children,
    isMenuOpen
}) => {
    return <main className={`Page ${isMenuOpen ? 'hidden' : ''}`}>{children}</main>;
};

export default Page;
