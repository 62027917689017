import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from '@core/helpers/helpers';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { OperatorDetails, useGetOperatorQuery } from '@graphql/generated/graphql';
import { mapApiDayPartsServices, mapApiOffersServices } from './OperatorProfile.helpers';
import { useLocale } from '@core/utils/locale';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { capitalize } from '@core/utils/string';

export function useOperatorProfile() {
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        t,
        locale
    } = useLocale();

    const {
        data,
        loading
    } = useGetOperatorQuery({
        variables: {
            operatorIdentifier: id ?? '',
            locale,
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (id && !loading && isEmpty(data?.get)) {
            navigate(RoutesEnum.NotFound);
        }
    }, [data, loading, navigate, id]);

    const dayPartsServices = useMemo(() => {
        const dayParts: string[] = [];

        if (!data?.get) {
            return dayParts;
        }

        for (const [key, _] of Object.entries(data.get)) {
            const part = mapApiDayPartsServices[key];

            if (part) {
                dayParts.push(t(part));
            }
        }

        return dayParts;
    }, [data?.get, t]);

    const offersServices = useMemo(() => {
        const offers: string[] = [];

        if (!data?.get) {
            return offers;
        }

        for (const [key, value] of Object.entries(data.get)) {
            const part = mapApiOffersServices[key];

            if (part && value === true) {
                offers.push(t(part));
            }

            if (key === 'offers_fuel') {
                offers.push(
                    mapApiOffersServices['offers_fuel']
                        ? t(OperatorProfilePhrasesEnum.PetrolLocation)
                        : t(OperatorProfilePhrasesEnum.NonPetrolLocation),
                );
            }
        }

        return offers;
    }, [data?.get, t]);

    const alcoholServices = useMemo(() => {
        const alcoholServicesList: string[] = [];

        if (!data?.get) {
            return alcoholServicesList;
        }

        const offersAlcohol = data.get?.offers_alcohol;
        if (offersAlcohol === true) {
            const alcoholService = !data.get?.alcohol_service_type
                ? t(OperatorProfilePhrasesEnum.AlcoholUnspecified)
                : capitalize(data.get?.alcohol_service_type);

            alcoholServicesList.push(alcoholService);
        }

        if (offersAlcohol === false) {
            alcoholServicesList.push(t(OperatorProfilePhrasesEnum.NonAlcohol));
        }

        return alcoholServicesList;
    }, [data?.get, t]);

    return {
        loading,
        data: data?.get as OperatorDetails,
        services: {
            dayPartsServices,
            offersServices,
            alcoholServices,
        },
    };
}
