import { FC, useCallback } from 'react';
import cn from 'classnames';

import { ID } from '@core/models';
import { toggleInArray } from '@core/helpers/helpers';
import { GradientColorsEnum } from '@enums/gradientEnum';

import './SwitchList.styles.scss';
import StarsSwitchPill from '../SwitchPill/StarsSwitchPill';

const StarsSwitchList: FC<{
    options: {
        id: ID;
        title: string;
    }[];
    value: ID[];
    onChange: (value: ID[]) => void;
    color: GradientColorsEnum;
    columns: number;
    isSmall?: boolean;
}> = ({
    options,
    value,
    onChange,
    color,
    columns,
    isSmall = false
}) => {
    const handleToggle = useCallback(
        (id: ID) => {
            onChange(toggleInArray(value, id));
        },
        [value, onChange],
    );
    const adjustedColumns = isSmall ? 2 : columns;

    return (
        <div className={cn('SwitchList', `SwitchList__col-${adjustedColumns}`)} data-testid="SwitchList">
            {options.map((option) => (
                <StarsSwitchPill
                    key={option.id}
                    hasIcon
                    testId={option.id as string}
                    color={color}
                    isChecked={value.includes(option.id)}
                    onChange={() => handleToggle(option.id)}
                    title={<>{option.title}</>}
                    className={cn('SwitchList__list-item', isSmall && 'SwitchList__list-item--small')}
                    rating={option.id}
                />
            ))}
        </div>
    );
};

export default StarsSwitchList;
