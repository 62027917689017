import { FC, useCallback, useState } from 'react';

import './SaveSearchModal.styles.scss';
import Button from '@components/utility/Button/Button';
import { CommonPhrasesEnum, SavedSearchEnum } from '@core/enums/localeEnum';

import { useLocale } from '@core/utils/locale';
import Modal from '@components/utility/Modal/Modal';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import useDeleteSaveSearch from '@core/api/SaveSearch/useDeleteSaveSearch';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';

const DeleteSearchModal: FC<{
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    data: SaveSearchResponse;
}> = ({
    isOpen,
    handleClose,
    title,
    data
}) => {
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
    const { t } = useLocale();
    const { doFetch } = useDeleteSaveSearch(data.id);
    const {
        savedSearch: { savedSearchDoFetch },
    } = useAdvancedSearch();

    const onDelete = useCallback(() => {
        setIsDeleteButtonDisabled(true);
        doFetch()
            .then(() => {
                savedSearchDoFetch();
                setTimeout(() => {
                    handleClose();
                }, 2000);
            })
            .catch(() => {
                setIsDeleteButtonDisabled(false);
            });
    }, [doFetch, handleClose, savedSearchDoFetch]);

    return (
        <Modal title={title} isOpen={isOpen} handleClose={handleClose}>
            <div className="SaveSearchModal">
                <Text variant={TextVariant.B2} testId="textDeleteSavedSearch">
                    {t(SavedSearchEnum.DeleteConfirmation)} <b>{data.name}</b>?
                </Text>

                <div className="SaveSearchModal__footer">
                    <Button modifiers={['secondary']} onClick={handleClose} testId="buttonSavedSearchCancel">
                        {t(CommonPhrasesEnum.Cancel)}
                    </Button>
                    <Button
                        modifiers={['primary']}
                        onClick={onDelete}
                        isDisabled={isDeleteButtonDisabled}
                        testId="buttonSavedSearchDelete"
                    >
                        {t(SavedSearchEnum.DeleteSearch)}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteSearchModal;
