import { useCallback } from 'react';

import { paths } from '@core/api/api';
import useFetch, { Config } from '@hooks/useFetch';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

const GET_CUISINES = '/cuisines';

type GetCuisinesEndpoint = paths[typeof GET_CUISINES][MethodsEnum.GET];
type CuisinesParams = GetCuisinesEndpoint[OperationsEnum.PARAMETERS]['query'];
export type CuisinesResponse = GetCuisinesEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];

export default function useCuisines() {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<CuisinesResponse>({
        url: GET_CUISINES,
        lazy: true,
    });

    const doFetchWithParams = useCallback(
        (queryParams?: CuisinesParams, newConfig?: Config) => {
            doFetch({
                params: queryParams,
                ...newConfig,
            });
        },
        [doFetch],
    );

    return { data, loading, error, doFetch: doFetchWithParams } as const;
}
