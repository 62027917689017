import { useMemo } from 'react';

import { FCX } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { SegmentCodesEnum } from '@core/models/Segments';
import { formatNumberByLocale } from '@core/utils/string';
import Collapse from '@components/utility/Collapse/Collapse';
import { WidgetType } from '@components/utility/Widget/Widget';
import { useGetAggregationsQuery } from '@graphql/generated/graphql';
import { OperatorAggregations } from '@core/models/OperatorAggregations';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import GroupedBarChart from '@components/utility/Charts/GroupedBarChart/GroupedBarChart';
import { FilterTypeEnum, OperatorTargetingResultsPhrasesEnum } from '@core/enums/localeEnum';
import { PERCENTS_ID } from '@pages/OperatorTargetingResults/hooks/useGetOperatorSummaryData';
import ComparisonBarChart from '@components/utility/Charts/ComparisonBarChart/ComparisonBarChart';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';
import GroupedHorizontalStackedBarChart from '@components/utility/Charts/HorizontalStackedBarChart/GroupedHorizontalStackedBarChart';

const RestaurantsAndBarsCharts: FCX = () => {
    const isCuisinesSummaryEnabled = useFeatureFlag(FeatureFlags.CuisinesSummary);
    const isRestaurantsChainsVsIndependentsEnabled = useFeatureFlag(FeatureFlags.RestaurantsChainsVsIndependents);

    const {
        t,
        locale
    } = useLocale();

    const {
        summaryData: {
            aggregations,
            selectedOptionId,
            searchFilters,
            drillDown: {
                onDrillDownSegments,
                onDrillDownCuisine,
                onDrillDownChainsVsIndependents
            },
        },
    } = useOperatorTargetingResultsContext();

    const filteredRestaurantsAggregations = useMemo(
        () => aggregations?.segments?.find((segment) => segment.id === SegmentCodesEnum.RestaurantsAndBars),
        [aggregations],
    );

    const { data } = useGetAggregationsQuery({
        variables: {
            filter: {
                ...searchFilters,
                and: [...(searchFilters?.and ?? []), { segmentation: { equals: SegmentCodesEnum.RestaurantsAndBars } }],
            },
        },
        skip: !searchFilters,
    });

    const rbAggregations = useMemo(() => {
        if (data?.aggregations) {
            return new OperatorAggregations(data.aggregations, undefined, locale, selectedOptionId === PERCENTS_ID);
        }
    }, [data?.aggregations, locale, selectedOptionId]);

    if (!filteredRestaurantsAggregations?.count || filteredRestaurantsAggregations?.count === 0) {
        return null;
    }

    return (
        <Collapse
            title={
                <Heading variant={HeadingVariant.H3}>
                    {t(FilterTypeEnum.RestaurantsAndBars)} (
                    {formatNumberByLocale(filteredRestaurantsAggregations?.count ?? 0, locale)})
                </Heading>
            }
            startCollapsed={false}
            widgetProps={{
                type: WidgetType.Section,
                className: 'section-small',
            }}
        >
            <div className="OperatorResultsSummary__charts">
                {filteredRestaurantsAggregations && (
                    <div className="OperatorResultsSummary__segments">
                        <Heading variant={HeadingVariant.H4}>{t(FilterTypeEnum.Segments)}</Heading>
                        <GroupedBarChart
                            groups={filteredRestaurantsAggregations.items ?? []}
                            onBarClick={onDrillDownSegments}
                        />
                    </div>
                )}

                {isRestaurantsChainsVsIndependentsEnabled && rbAggregations?.chainsVsIndependents && (
                    <div className="OperatorResultsSummary__units-comparison">
                        <Heading variant={HeadingVariant.H4}>
                            {t(OperatorTargetingResultsPhrasesEnum.ChainsVsIndependents)}
                        </Heading>
                        <ComparisonBarChart
                            data={rbAggregations.chainsVsIndependents}
                            showPercents={selectedOptionId === PERCENTS_ID}
                            onBarClick={onDrillDownChainsVsIndependents}
                        />
                    </div>
                )}
            </div>

            {isCuisinesSummaryEnabled && (
                <div className="OperatorResultsSummary__charts">
                    {aggregations?.cuisines?.map((cuisineSummary) => (
                        <div key={cuisineSummary.title} className="OperatorResultsSummary__cuisines">
                            <Heading variant={HeadingVariant.H4}>{cuisineSummary.title}</Heading>
                            <GroupedHorizontalStackedBarChart
                                groups={cuisineSummary.data}
                                onBarClick={onDrillDownCuisine}
                            />
                        </div>
                    ))}
                </div>
            )}
        </Collapse>
    );
};

export default RestaurantsAndBarsCharts;
