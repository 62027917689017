import { FC } from 'react';
import { IconClose } from '@assets/icons';

import './CloseButton.styles.scss';
import cn from 'classnames';

const CloseButton: FC<{
    onClick: () => void;
    isDark?: boolean;
}> = ({
    onClick,
    isDark = false
}) => {
    return (
        <div className={cn('CloseButton', { dark: isDark })} onClick={onClick} data-testid="closeButton">
            <IconClose height={10} />
        </div>
    );
};

export default CloseButton;
