import { FC } from 'react';

import { useTableSortReturn } from '@core/hooks/useTableSort';
import { TableProvider } from '@components/utility/Table/TableContext';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import TableWithMobileFallback from '@components/utility/Table/TableWithMobileFallback';

import SavedSearchResultsTableMobile from './SavedSearchResultsTableMobile';
import {
    SAVED_SEARCH_RESULTS_TABLE_TEST_ID,
    useSavedSearchResultsTableConfig,
} from './useSavedSearchResultsTableConfig';

const SavedSearchResultsTable: FC<{
    resultsData: SaveSearchResponse[];
    loading: boolean;
}> = ({
    resultsData,
    loading
}) => {
    const {
        config,
        sortConfig,
        sortedData
    } = useSavedSearchResultsTableConfig(resultsData);

    return (
        <div className="SavedSearch__table-body">
            <TableProvider sortConfig={sortConfig} config={config}>
                <TableWithMobileFallback
                    rowsCount={resultsData.length ?? 0}
                    testId={SAVED_SEARCH_RESULTS_TABLE_TEST_ID}
                    loading={loading}
                >
                    <SavedSearchResultsTableMobile
                        data={sortedData}
                        sortConfig={sortConfig as useTableSortReturn}
                        testId={SAVED_SEARCH_RESULTS_TABLE_TEST_ID}
                        loading={loading}
                    />
                </TableWithMobileFallback>
            </TableProvider>
        </div>
    );
};

export default SavedSearchResultsTable;
