import React, { FC, ReactNode, useCallback, useState } from 'react';

import { iNavigationItem } from './SearchNavigation';
import { NavigationItem } from './NavigationItem';

import './SearchNavigationMobile.styles.scss';
import Popover from '@components/utility/Popover/Popover';
import { Select } from '@mui/material';
import { DEFAULT_POPOVER_POSITION } from '@components/utility/Inputs/Inputs.helpers';

const SearchNavigationMobile: FC<{
    title: ReactNode;
    items: iNavigationItem[];
}> = ({
    items,
    title
}) => {
    const [inputValue, setInputValue] = useState<string>('Select any criteria to build a query');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleNavigationItemClick = useCallback((item: iNavigationItem) => {
        setInputValue(item.title as string);
        const hasSubItems = item?.subItems && item.subItems?.length > 0;

        if (hasSubItems) {
            return;
        }

        setIsDropdownOpen(false);
    }, []);

    const handleInputClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget);
        setIsDropdownOpen((prevOpen) => !prevOpen);
    };

    return (
        <div className="SearchNavigationMobile">
            <div className="SearchNavigationMobile__title">{title}</div>
            <Select
                placeholder="Select any criteria to build a query"
                displayEmpty={true}
                renderValue={() => inputValue}
                open={false}
                onClick={handleInputClick}
            >
                <option />
            </Select>
            <Popover
                {...DEFAULT_POPOVER_POSITION}
                open={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
                anchorEl={anchorEl}
            >
                <div
                    className="SearchNavigationMobile__popover"
                    style={{
                        width: anchorEl?.getBoundingClientRect()?.width ?? 'auto',
                    }}
                >
                    {items.map((item) => (
                        <NavigationItem
                            key={item.id}
                            item={item}
                            level={0}
                            testId={`${item.testId}Ul`}
                            onChange={handleNavigationItemClick}
                        />
                    ))}
                </div>
            </Popover>
        </div>
    );
};

export default SearchNavigationMobile;
