import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { HomePhrasesEnum } from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';
import QuickSearch, { QuickSearchTypes } from '@components/utility/Inputs/QuickSearch/QuickSearch';

import './Home.styles.scss';

const CAROUSEL_IMAGE_COUNT = 56;
const images: string[] = Array.from(
    { length: CAROUSEL_IMAGE_COUNT },
    (_, index) => `${process.env.PUBLIC_URL}/carousel/${index + 1}.jpeg`,
);

const getRandomStartIndex = (length: number): number => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] % length;
};

const Home: React.FC = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(getRandomStartIndex(images.length));
    const [hasAnimated, setHasAnimated] = React.useState<boolean>(false);

    const onAnimate = () => {
        setHasAnimated(true);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex: number) => (prevIndex + 1) % images.length);
        }, 9000);

        return () => clearInterval(interval);
    }, []);

    const { t } = useLocale();
    return (
        <div className="Home">
            <div className="Home__overlay"></div>
            <div className="Home__carousel">
                {images.map((image, index) => (
                    <img
                        key={image}
                        src={image}
                        alt={`Slide ${index}`}
                        className={cn('Home__image', {
                            'Home__image--visible': index === currentImageIndex,
                        })}
                    />
                ))}
            </div>
            <div className="Home__content">
                <div className={cn('Home__search', { 'Home__search--interacted': hasAnimated })}>
                    <QuickSearch
                        placeholder={t(HomePhrasesEnum.QuickSearchPlaceholder)}
                        type={QuickSearchTypes.LARGE}
                        hasAnimated={hasAnimated}
                        onAnimate={onAnimate}
                    />
                </div>
            </div>
        </div>
    );
};

export default Home;
