import { useState } from 'react';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_INITIAL_PAGE = 1;

function usePagination(pageSize = DEFAULT_PAGE_SIZE, initialPage = DEFAULT_INITIAL_PAGE) {
    const [perPage, setPerPage] = useState(pageSize);
    const [activePage, setActivePage] = useState(initialPage);

    const onResetPagination = () => {
        setActivePage(initialPage);
        setPerPage(pageSize);
    };

    return {
        perPage,
        onChangePerPage: (amountPerPage: number, totalResults?: number) => {
            if (totalResults) {
                const totalPages = Math.ceil(totalResults / amountPerPage);
                if (totalPages < activePage) {
                    setActivePage(totalPages);
                }

                return setPerPage(amountPerPage);
            }

            setPerPage(amountPerPage);
        },
        activePage,
        onChangePage: (page: number) => {
            setActivePage(page);
        },
        onResetPagination,
    };
}

export default usePagination;
