import { FC } from 'react';
import cn from 'classnames';

import Tooltip from '@components/utility/Tooltip/Tooltip';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import { CriteriaData, CriterionEnum, getCriterionEnumIcon } from '@models/Criterion';

import './CollapsedCriteria.styles.scss';

const CollapsedCriteria: FC<{
    data: CriteriaData;
}> = ({ data }) => {
    return (
        <div className={cn('CollapsedCriteria')}>
            <div className="CollapsedCriteria--criteria">
                {Object.entries(data)?.map(([criterionKey, criterion]) => {
                    if (criterion.CriterionParams?.length === 0) {
                        return;
                    }

                    return (
                        <Tooltip content={criterion.name} placement="bottom" key={criterion.id}>
                            <div>
                                <BadgeIcon
                                    icon={getCriterionEnumIcon(criterionKey as CriterionEnum)}
                                    baseColor={criterion.baseColor}
                                    countBadge={criterion?.CriterionParams?.length}
                                />
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        </div>
    );
};

export default CollapsedCriteria;
