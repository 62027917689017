import useFetch from '@hooks/useFetch';

const GET_CUSTOMER_METADATA = '/operators/companies/metadata';

type CustomerMetadataParams = {
    customer_metadata_type: CustomerMetadataType;
};
export type CustomerMetadataResponse = {
    customer_metadata_type: string;
    values: string[];
};

export enum CustomerMetadataType {
    STATUS = 'STATUS',
}

export default function useCustomerMetadata(type: string) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<CustomerMetadataResponse, CustomerMetadataParams>({
        url: GET_CUSTOMER_METADATA,
        method: 'GET',
        initialConfig: {
            params: {
                customer_metadata_type: type,
            },
        },
    });

    return { data, loading, error, doFetch } as const;
}
