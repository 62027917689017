import { useCallback, useEffect, useRef, useState } from 'react';

import { CountryCodesEnum } from '@core/enums/flagsEnum';
import useGetUserLocation from '@core/hooks/useGetUserLocation';
import { useInput, useSearchInput } from '@core/hooks/useInput';
import useDebouncedCallback from '@core/hooks/useDebouncedCallback';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';

const DEFAULT_PREDICTIONS = {
    predictions: [],
    status: '',
};

type SessionToken = google.maps.places.AutocompleteSessionToken;

type AddressPredictions = {
    predictions: SelectOption[];
    status: string;
};

type selectedAddressCoords = google.maps.LatLngLiteral & { name?: string };

const MIN_PREDICTION_LENGTH = 3;

export default function useSearchRadiusAddress(countryCode: CountryCodesEnum = CountryCodesEnum.US) {
    const google = window.google;
    const autoCompleteRef = useRef<null | google.maps.places.AutocompleteService>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addressPredictions, setAddressPredictions] = useState<AddressPredictions>(DEFAULT_PREDICTIONS);
    const [sessionToken, setSessionToken] = useState<SessionToken | null>(null);
    const [selectedAddressCoords, setSelectedAddressCoords] = useState<selectedAddressCoords | null>(null);

    const {
        onChange: onSearchInputTextChange,
        value: inputValue
    } = useInput();

    const {
        value: searchSelectedOption,
        onChange: onChangeSearchOption
    } = useSearchInput();

    const {
        location,
        error
    } = useGetUserLocation();

    useEffect(() => {
        if (google?.maps?.places) {
            if (!autoCompleteRef?.current) {
                autoCompleteRef.current = new google.maps.places.AutocompleteService();
            }

            if (!sessionToken) {
                setSessionToken(() => new google.maps.places.AutocompleteSessionToken());
            }
        }
    }, [google?.maps?.places, sessionToken]);

    const debouncedGetPlacePredictions = useDebouncedCallback((input: string | undefined) => {
        if (!input || input.length < MIN_PREDICTION_LENGTH || !sessionToken) {
            setAddressPredictions(DEFAULT_PREDICTIONS);
            setIsLoading(false);
            return;
        }

        autoCompleteRef?.current
            ?.getPlacePredictions(
                {
                    input,
                    componentRestrictions: { country: countryCode },
                    sessionToken: sessionToken,
                },
                (predictions, status) => {
                    if (status === 'OK') {
                        const predictionList: SelectOption[] =
                            predictions?.map((prediction) => {
                                return {
                                    id: prediction.place_id,
                                    label: prediction.description,
                                };
                            }) || [];
                        setAddressPredictions({
                            predictions: predictionList,
                            status: 'OK',
                        });
                    } else {
                        setAddressPredictions({
                            predictions: [],
                            status: status,
                        });
                    }
                },
            )
            .finally(() => {
                setIsLoading(false);
            });
    }, 700);

    useEffect(() => {
        if (sessionToken) {
            setIsLoading(true);
            debouncedGetPlacePredictions(inputValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, sessionToken]);

    const getPlaceDetails = useCallback(
        (placeId: string) => {
            if (!sessionToken || !google?.maps?.places) {
                return null;
            }

            const service = new google.maps.places.PlacesService(document.createElement('div'));

            service.getDetails(
                {
                    placeId,
                    fields: ['geometry', 'formatted_address'],
                    sessionToken: sessionToken,
                },
                (place, status) => {
                    if (status === 'OK' && place?.geometry?.location && place?.formatted_address) {
                        setSelectedAddressCoords({
                            lat: place.geometry.location.lat() || 0,
                            lng: place.geometry.location.lng() || 0,
                            name: place.formatted_address,
                        });
                    }
                },
            );

            setSessionToken(() => new google.maps.places.AutocompleteSessionToken());
        },
        [google?.maps?.places, sessionToken],
    );

    const onChangeSearchCallback = useCallback(
        (value: SelectOption | null) => {
            onChangeSearchOption(value);

            if (value?.id) {
                getPlaceDetails(value.id as string);
            }
        },
        [getPlaceDetails, onChangeSearchOption],
    );

    const onClickNearMe = useCallback(() => {
        if (location) {
            setSelectedAddressCoords({
                lat: location.latitude,
                lng: location.longitude,
            });
        }
    }, [location]);

    const onResetSelectedAddress = useCallback(() => {
        setSelectedAddressCoords(null);
        onChangeSearchOption(null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        predictions: addressPredictions.predictions,
        status: addressPredictions.status,
        isLoading,
        onSearchInputTextChange,
        onChangeSearchCallback,
        searchSelectedOption,
        nearMeError: !!error,
        onClickNearMe,
        selectedAddressCoords,
        onResetSelectedAddress,
    };
}
