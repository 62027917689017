import useFetch from '@hooks/useFetch';
import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content } from '@core/enums/api';
import { SaveSearchParams } from './useSaveSearch';

const numberValue = 0;

const DELETE_SAVE_SEARCH = `/saved_searches/${numberValue}` as const;

type GetSaveSearchEndpoint = paths[typeof DELETE_SAVE_SEARCH][MethodsEnum.DELETE];
type SaveSearchResponse = GetSaveSearchEndpoint[OperationsEnum.RESPONSES]['204'][Content]['application/json'];

function useDeleteSaveSearch(number: SaveSearchParams['id']) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<SaveSearchResponse>({
        url: `/saved_searches/${number}`,
        method: 'DELETE',
        lazy: true,
    });

    return { data, loading, error, doFetch } as const;
}

export default useDeleteSaveSearch;
