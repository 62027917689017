import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { CriteriaData } from '@core/models/Criterion';
import { LocalesEnum, PhrasesEnum, SavedSearchEnum } from '@core/enums/localeEnum';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { TableConfig, TableConfigItem } from '@components/utility/Table/Table.helpers';

import { useAppContext } from '../../../App.context';
import { formatDateISOtoDMY } from '@core/utils/string';
import TableActionsMenu from './TableActionsMenu';
import { ID } from '@core/models';
import useTableSort from '@core/hooks/useTableSort';
import useSavedSearchTableSort from '@core/hooks/useSavedSearchTableSort';

export const SAVED_SEARCH_RESULTS_TABLE_TEST_ID = 'SavedSearchResultsTable';
const SAVED_SEARCHES_DEFAULT_SORT_FIELD = 'last_updated';

export function useSavedSearchResultsTableConfig(
    data: SaveSearchResponse[],
): TableConfig<SavedSearchEnum> & { sortedData: SaveSearchResponse[] } {
    const navigate = useNavigate();
    const {
        t,
        locale
    } = useLocale();
    const {
        userInfo: { id },
    } = useAppContext();
    const {
        onMergeCriteria,
        savedSearch: { updateActiveSavedSearch },
    } = useAdvancedSearch();

    const sortConfig = useTableSort(SAVED_SEARCHES_DEFAULT_SORT_FIELD);
    const sortedResultsData = useSavedSearchTableSort(data, sortConfig?.sortedColumns ?? []);

    const onNavigateToSearchResults = useCallback(
        async (data: SaveSearchResponse) => {
            let parsedQuery: CriteriaData;
            try {
                parsedQuery = JSON.parse(data.query);
            } catch (error) {
                throw new Error('Invalid JSON format');
            }

            onMergeCriteria(parsedQuery);
            updateActiveSavedSearch(data);
            navigate(RoutesEnum.OperatorTargetingResults);
        },
        [navigate, onMergeCriteria, updateActiveSavedSearch],
    );

    return useMemo(
        () => ({
            config: getSavedSearchResultsTableConfig(sortedResultsData, onNavigateToSearchResults, t, locale, id),
            sortConfig,
            sortedData: sortedResultsData,
        }),
        [sortedResultsData, onNavigateToSearchResults, t, locale, id, sortConfig],
    );
}

function getSavedSearchResultsTableConfig(
    data: SaveSearchResponse[],
    onNavigateToSearchResults: (saveSearchObj: SaveSearchResponse) => void,
    t: (key: PhrasesEnum) => string,
    locale: LocalesEnum,
    id: string,
): TableConfigItem<SavedSearchEnum>[] {
    if (!data) {
        return [];
    }
    const noDataAvailable = !data || !data?.length;
    return noDataAvailable
        ? []
        : [
            {
                title: SavedSearchEnum.Name,
                field: 'name',
                Cell: ({ index }) => {
                    return <strong style={{ color: '#00866F', width: '400px' }}>{data?.[index]?.name}</strong>;
                },
                isSortable: true,
                onClick: (index: ID) => {
                    onNavigateToSearchResults(data?.[index as number] ?? '');
                },
            },
            {
                title: SavedSearchEnum.CreatedBy,
                field: 'created_by_name',
                Cell: ({ index }) => {
                    return <div style={{ width: '100px' }}>{data?.[index]?.created_by_name}</div>;
                },
                isSortable: true,
            },
            {
                title: SavedSearchEnum.Type,
                field: 'publicly_available',
                Cell: ({ index }) => {
                    return (
                        <div style={{ width: '100px' }}>
                            {data?.[index]?.publicly_available
                                ? t(SavedSearchEnum.Public)
                                : t(SavedSearchEnum.Private)}
                        </div>
                    );
                },
                isSortable: true,
            },
            {
                title: SavedSearchEnum.DateSaved,
                field: SAVED_SEARCHES_DEFAULT_SORT_FIELD,
                Cell: ({ index }) => {
                    return (
                        <div style={{ width: '100px' }}>
                            {formatDateISOtoDMY(data?.[index]?.last_updated || '', locale)}
                        </div>
                    );
                },
                isSortable: true,
            },
            {
                title: SavedSearchEnum.Invisible,
                field: '',
                Cell: ({ index }) => {
                    return data?.[index]?.user_external_id === id ? (
                        <TableActionsMenu data={data?.[index]} index={index} />
                    ) : null;
                },
                isSortable: false,
            },
        ];
}
