import { ReactNode } from 'react';

import { FCX } from '@core/models';

import './PageTitle.styles.scss';

const PageTitle: FCX<{
    title: string;
    rowContent?: ReactNode;
}> = ({
    title,
    rowContent,
    testId
}) => {
    return (
        <div className="PageTitle">
            <div className="PageTitle__title" data-testid={testId}>
                {title}
            </div>
            {rowContent}
        </div>
    );
};

export default PageTitle;
