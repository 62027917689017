import { type FC, useCallback, useMemo } from 'react';

import { MIMETypes } from '@core/utils/file';
import { useLocale } from '@core/utils/locale';
import { useExportOperatorsContext } from '@core/contexts';
import Input from '@components/utility/Inputs/Input/Input';
import useAnchorElement from '@core/hooks/useAnchorElement';
import { IconCsv, IconExcel, IconExport } from '@assets/icons';
import DropdownMenu from '@components/utility/DropdownMenu/DropdownMenu';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import NumberAnimation from '@components/utility/NumberAnimation/NumberAnimation';
import { CommonPhrasesEnum, ExportPhrasesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';

import { useAppContext } from '../../../../App.context';
import OperatorResultsTable from '../OperatorResultsTable/OperatorResultsTable';

const OperatorResultsList: FC = () => {
    const { t } = useLocale();

    const isExcelExportEnabled = useFeatureFlag(FeatureFlags.ExportExcel);

    const {
        userInfo: { exportAllowed },
    } = useAppContext();

    const {
        isExportLoading,
        fetchFilteredOperators
    } = useExportOperatorsContext();

    const {
        operatorResultsList: {
            listFilters,
            loading,
            resultsCount,
            setSearchTerm,
            searchTerm
        },
    } = useOperatorTargetingResultsContext();

    const {
        anchorEl,
        open,
        onAnchorClick,
        onClose
    } = useAnchorElement();

    const handleSearchChange = useCallback(
        (searchTermValue: string) => setSearchTerm(searchTermValue),
        [setSearchTerm],
    );

    const onExportOperators = useCallback(
        (exportType: MIMETypes) => {
            if (listFilters && !isExportLoading) {
                fetchFilteredOperators(listFilters, exportType);
            }
            onClose();
        },
        [listFilters, isExportLoading, fetchFilteredOperators, onClose],
    );

    const exportDropdownMenuOptions = useMemo(() => {
        return [
            {
                label: t(ExportPhrasesEnum.ExportCsv),
                onClick: () => onExportOperators(MIMETypes.CSV),
                icon: <IconCsv />,
            },
            ...(isExcelExportEnabled
                ? [
                    {
                        label: t(ExportPhrasesEnum.ExportToExcel),
                        onClick: () => onExportOperators(MIMETypes.XLSX),
                        icon: <IconExcel />,
                    },
                ]
                : []),
        ];
    }, [isExcelExportEnabled, onExportOperators, t]);

    return (
        <>
            <div className="OperatorResultsTab">
                <div className="OperatorResultsTab__content">
                    <div className="OperatorResultsTab__search-wrapper">
                        <Input
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={t(OperatorTargetingPhrasesEnum.SearchByInputPlaceholder)}
                            withClearIcon
                            isLoading={loading}
                            testId="TableSearchInput"
                        />
                    </div>

                    {exportAllowed && (
                        <>
                            <Button
                                onClick={onAnchorClick}
                                type={ButtonTypeEnum.Button}
                                modifiers={['tertiary']}
                                className="OperatorResultsTab__button"
                                isDisabled={isExportLoading}
                            >
                                {isExportLoading ? (
                                    <div className="OperatorResultsTab__button-loader small" />
                                ) : (
                                    <IconExport />
                                )}
                                {t(ExportPhrasesEnum.Export)}
                            </Button>
                            <DropdownMenu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={onClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                options={exportDropdownMenuOptions}
                            />
                        </>
                    )}

                    {/* Display Drawer is not included in scope */}
                    {/* <Button
                        onClick={() => setIsDrawerOpen(true)}
                        type={ButtonTypeEnum.Button}
                        modifiers={['dropdown']}
                        className={cn(isDrawerOpen && 'active')}
                    >
                        <IconColumns />
                        Display
                    </Button> */}
                </div>
                <div className="OperatorResultsTab__results-count" data-testid="tableResultsCount">
                    <NumberAnimation className="OperatorResultsTab__results-count-numbers" count={resultsCount} />
                    {t(CommonPhrasesEnum.Results)}
                </div>
            </div>

            <OperatorResultsTable />

            {/* Display Drawer is not included in scope */}
            {/* <DisplayDrawer
                columns={drawerColumnsData}
                isDrawerOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            /> */}
        </>
    );
};

export default OperatorResultsList;
