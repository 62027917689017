import cn from 'classnames';

import { useState } from 'react';
import { FCX, ID } from '@core/models';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

import './ComparisonBarChart.styles.scss';

export type ComparisonChartData = {
    id: ID;
    title: string;
    formattedValue: string;
    percentValue: string;
};

const ComparisonBarChart: FCX<{
    data: [ComparisonChartData, ComparisonChartData];
    showPercents?: boolean;
    onBarClick?: (barId: ID) => void;
}> = ({
    data,
    onBarClick,
    showPercents = false,
    testId
}) => {
    const [hoveredBar, setHoveredBar] = useState<number | null>(null);
    return (
        <div className="ComparisonBarChart" data-testid={testId}>
            <div className="ComparisonBarChart__labels" data-testid={testId && `text${testId}Title`}>
                {data.map((item) => (
                    <div key={item.title} className="ComparisonBarChart__label-container">
                        <Heading variant={HeadingVariant.H4}>
                            {showPercents ? item.percentValue : item.formattedValue}
                        </Heading>
                        <div className="ComparisonBarChart__title">{item.title}</div>
                    </div>
                ))}
            </div>
            <div
                className={cn('ComparisonBarChart__bar-container', {
                    'no-gap': data.some((bar) => bar.percentValue === '0'),
                })}
            >
                {data.map((item, i) => (
                    <div
                        key={item.title}
                        className={cn(i === 0 ? 'ComparisonBarChart__upper-bar' : 'ComparisonBarChart__lower-bar', {
                            dimmed: hoveredBar !== null && hoveredBar !== i,
                        })}
                        onClick={() => onBarClick?.(item.id)}
                        style={{ height: item.percentValue }}
                        onMouseEnter={() => setHoveredBar(i)}
                        onMouseLeave={() => setHoveredBar(null)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ComparisonBarChart;
