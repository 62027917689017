import { useEffect, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import useCuisines from '@core/api/useCuisines';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { filterAndParseSegmentsChannels, mapChannelListToSelectOptions } from '../helpers/Classification.helpers';

export function useCuisinesCriteria() {
    const { locale } = useLocale();
    const { getCriterionByFilterKey } = useAdvancedSearch();

    const activeCountryCriterion = getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Country)?.[0];
    const {
        data,
        doFetch
    } = useCuisines();

    useEffect(() => {
        if (activeCountryCriterion?.value) {
            doFetch({
                country: activeCountryCriterion.value as string,
                locale,
            });
        }
    }, [activeCountryCriterion?.value, doFetch, locale]);

    const channelList = useMemo(() => {
        return filterAndParseSegmentsChannels(data?.children) ?? [];
    }, [data?.children]);

    const searchInputOptions = useMemo(() => mapChannelListToSelectOptions(channelList), [channelList]);

    return {
        channelList,
        searchInputOptions,
    };
}
