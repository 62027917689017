import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { ZipValidation } from './Zip.helpers';

type ZipCountriesValidation = {
    [key in CountryCodesEnum]?: ZipValidation;
};

export const AlphaNumericSpaceAndHyphenRegex = new RegExp(/[^a-zA-Z0-9\s-]/g);
export const NumericSpaceAndHyphenRegex = new RegExp(/[^0-9\s-]/g);

export const ZIP_COUNTRIES: ZipCountriesValidation & { DEFAULT: ZipValidation } = {
    AE: {
        maxLength: 9,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    AR: {
        isAlphaNumeric: true,
        maxLength: 9,
        regex: new RegExp(/^[A-Za-z]?\d{4}([ ]?[A-Za-z]*)?$|^\d{4}$/),
    },
    AT: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    AU: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    BE: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    BG: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    CA: {
        isAlphaNumeric: true,
        maxLength: 6,
        pattern: '######',
        regex: new RegExp(/^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/),
    },
    CH: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    CL: {
        maxLength: 7,
        pattern: '#######',
        regex: new RegExp(/^\d{7}$/),
    },
    DE: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    DEFAULT: {
        isAlphaNumeric: true,
        maxLength: 16,
        regex: new RegExp(/^[a-zA-Z0-9\s-]+$/),
    },
    DK: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    ES: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    FI: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    FR: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    GR: {
        maxLength: 6,
        pattern: '### ##',
        regex: new RegExp(/^\d{3}\s\d{2}$/),
    },
    HR: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    IE: {
        isAlphaNumeric: true,
        maxLength: 7,
        pattern: '### ####',
        regex: new RegExp(/^[A-Za-z0-9]{3}\s[A-Za-z0-9]{4}$/),
    },
    IT: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    LU: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    MY: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    NL: {
        isAlphaNumeric: true,
        maxLength: 4,
        pattern: '#### ##',
        regex: new RegExp(/^\d{4}\s[A-Za-z]{2}$/),
    },
    NO: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    NZ: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    PH: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    PL: {
        maxLength: 6,
        pattern: '##-###',
        regex: new RegExp(/^\d{2}-\d{3}$/),
    },
    PT: {
        maxLength: 8,
        pattern: '####-###',
        regex: new RegExp(/^\d{4}-\d{3}$/),
    },
    SE: {
        maxLength: 6,
        pattern: '### ##',
        regex: new RegExp(/^\d{3}\s\d{2}$/),
    },
    SG: {
        maxLength: 6,
        pattern: '######',
        regex: new RegExp(/^\d{6}$/),
    },
    TR: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    UK: {
        isAlphaNumeric: true,
        maxLength: 8,
        regex: new RegExp(
            /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/,
        ),
    },
    US: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    UY: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    UZ: {
        maxLength: 7,
        pattern: '### ###',
        regex: new RegExp(/^\d{3} ?\d{3}$/),
    },
    CY: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    GE: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    HN: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    HU: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    SI: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    ZA: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{4}$/),
    },
    BS: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    CR: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    DO: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    EG: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    GT: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    KW: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    MA: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    ME: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    MX: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    PR: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    QA: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    RS: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    SV: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    TH: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    UA: {
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{5}$/),
    },
    IS: {
        maxLength: 3,
        pattern: '###',
        regex: new RegExp(/^\d{3}$/),
    },
    CO: {
        maxLength: 6,
        pattern: '######',
        regex: new RegExp(/^\d{6}$/),
    },
    KZ: {
        maxLength: 6,
        pattern: '######',
        regex: new RegExp(/^\d{6}$/),
    },
    RO: {
        maxLength: 6,
        pattern: '######',
        regex: new RegExp(/^\d{6}$/),
    },
    VN: {
        maxLength: 6,
        pattern: '######',
        regex: new RegExp(/^\d{6}$/),
    },
    BB: {
        maxLength: 7,
        pattern: '#######',
        regex: new RegExp(/^BB\d{5}|^\d{5}$/),
    },
    LT: {
        maxLength: 8,
        regex: new RegExp(/^(LT-\d{5}|\d{5})$/),
    },
    LV: {
        maxLength: 7,
        regex: new RegExp(/^(LV-\d{4}|\d{4})$/),
    },
    AZ: {
        maxLength: 7,
        regex: new RegExp(/^(AZ \d{4}|\d{4})$/),
    },
    BH: {
        maxLength: 4,
        pattern: '####',
        regex: new RegExp(/^\d{3,4}$/),
    },
    BR: {
        maxLength: 9,
        regex: new RegExp(/^\d{5}-\d{3}$|^\d{8}$/),
    },
    CZ: {
        maxLength: 6,
        pattern: '### ##',
        regex: new RegExp(/^\d{3} ?\d{2}$/),
    },
    EE: {
        isAlphaNumeric: true,
        maxLength: 5,
        pattern: '#####',
        regex: new RegExp(/^\d{4,5}[A-Za-z]?$/),
    },
    EC: {
        maxLength: 8,
        pattern: '########',
        regex: new RegExp(/^(EC)?\d{6}$/),
    },
    MT: {
        isAlphaNumeric: true,
        maxLength: 8,
        pattern: '### ####',
        regex: new RegExp(/^[A-Za-z]{3} ?\d{4}$/),
    },
    JP: {
        maxLength: 8,
        pattern: '###-####',
        regex: new RegExp(/^\d{3}-\d{4}$/),
    },
    HK: {
        isAlphaNumeric: true,
        maxLength: 6,
        pattern: '######',
        regex: new RegExp(/^[A-Za-z]\d{3}$|^\d{6}$/),
    },
    IL: {
        maxLength: 8,
        pattern: '##### ##',
        regex: new RegExp(/^\d{5}( \d{2})?$/),
    },
    LB: {
        maxLength: 8,
        pattern: '#### ####',
        regex: new RegExp(/^\d{4} ?\d{4}$/),
    },
    SK: {
        maxLength: 6,
        pattern: '### ##',
        regex: new RegExp(/^\d{3} ?\d{2}$/),
    },
};
