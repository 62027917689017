import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconFilterGeneral } from '@assets/icons';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { Maybe, OperatorSummary } from '@graphql/generated/graphql';
import { replaceRouteParam, truncateString } from '@core/utils/string';

import { buildQuickSearchAddress } from '../QuickSearch.helpers';

const SearchResultsList: FC<{
    operators: OperatorSummary[];
}> = ({ operators }) => {
    const navigate = useNavigate();

    const onNavigateToProfile = useCallback(
        (operatorId: string) => navigate(replaceRouteParam(RoutesEnum.OperatorProfile, ':id', operatorId)),
        [navigate],
    );

    const addSeparatorOnString = useCallback((str?: Maybe<string>) => {
        return str ? ` · ${str}` : '';
    }, []);

    const getSubtitle = useCallback(
        (operator: OperatorSummary) => {
            const {
                segment,
                sub_cuisine: subCuisine
            } = operator;

            const subtitle = `${segment ?? ''}${addSeparatorOnString(subCuisine as string)}${addSeparatorOnString(
                buildQuickSearchAddress(operator?.location),
            )}`;

            return truncateString(subtitle, 100);
        },
        [addSeparatorOnString],
    );

    return (
        <>
            {operators?.map((operator, index) => {
                return (
                    <div
                        className="SearchCategory__item"
                        key={index}
                        onClick={() => {
                            onNavigateToProfile(operator?.operator_identifier ?? '');
                        }}
                    >
                        <div className="SearchCategory__item-logo" data-testid="QuickSearchItemIcon">
                            <IconFilterGeneral />
                        </div>
                        <div className="SearchCategory__item-details">
                            <div className="SearchCategory__item-title" data-testid="QuickSearchItemName">
                                {operator?.business_name}
                            </div>
                            <div className="SearchCategory__item-subtitle" data-testid="QuickSearchItemDescription">
                                {getSubtitle(operator)}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default SearchResultsList;
