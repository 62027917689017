import { FC, memo } from 'react';
import { CountryCodesEnum } from '@core/enums/flagsEnum';

import * as icons from '@assets/flags/index';

const FlagIcon: FC<{
    countryCode: CountryCodesEnum;
    height?: number;
    width?: number;
}> = ({
    countryCode,
    height,
    width
}) => {
    const ImportedIcon = icons[countryCode];

    if (!ImportedIcon) {
        return null;
    }

    return <ImportedIcon height={height} width={width} />;
};

export default memo(FlagIcon);
