import { FC, useCallback } from 'react';
import cn from 'classnames';

import {
    CriteriaData,
    CriterionEnum,
    CriterionParam,
    CriterionParamFilter,
    getCriterionEnumIcon,
} from '@models/Criterion';
import { ID } from '@core/models';
import { useLocale } from '@utils/locale';
import { groupBy } from '@core/helpers/helpers';
import Chip from '@components/utility/Chips/Chip';
import Tooltip from '@components/utility/Tooltip/Tooltip';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import { CommonPhrasesEnum, FilterTypeEnum } from '@enums/localeEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import HierarchyIndicator from '@components/utility/HierarchyIndicator/HierarchyIndicator';

import './Criteria.styles.scss';
import CriteriaChipsGroup from '../CriteriaChipsGroup/CriteriaChipsGroup';
import { CONDENSABLE_FILTERS, MAP_FILTER_KEY_TO_TRANSLATION } from './Criteria.constants';

const Criteria: FC<{
    data: CriteriaData;
    onDelete: (criterionKey: keyof CriteriaData, criterionParamId: ID) => void;
}> = ({
    data,
    onDelete
}) => {
    const { t } = useLocale();
    const { onResetCriteriaByFilterKey } = useAdvancedSearch();

    const renderCondensableCriteria = useCallback(
        (entryKey: string, entryValue: CriterionParam[], criterionKey: string, criterionColor: GradientColorsEnum) => {
            const groupPhraseEnum =
                MAP_FILTER_KEY_TO_TRANSLATION[entryKey] || FilterTypeEnum.OperatorsWithoutEmployeeCount;
            const onClearChipsGroup = () =>
                onResetCriteriaByFilterKey(criterionKey as CriterionEnum, entryKey as CriterionParamFilter);

            return (
                <CriteriaChipsGroup
                    key={entryKey}
                    testId={groupPhraseEnum}
                    criteriaList={entryValue}
                    onDeleteCriteria={onDelete}
                    onClearGroup={onClearChipsGroup}
                    criterionKey={criterionKey}
                    criterionColor={criterionColor}
                    groupName={`${t(groupPhraseEnum)} (${entryValue.length}) `}
                />
            );
        },
        [onDelete, onResetCriteriaByFilterKey, t],
    );

    const renderIndividualCriteria = useCallback(
        (criterionParam: CriterionParam, criterionKey: string, criterionColor: GradientColorsEnum) => {
            const onChipDelete = criterionParam.isPermanent
                ? undefined
                : () => onDelete(criterionKey as keyof CriteriaData, criterionParam.value);

            return (
                <Chip
                    key={criterionParam.value}
                    name={criterionParam.name}
                    id={criterionParam.value}
                    description={criterionParam.description}
                    baseColor={criterionColor}
                    onChipDelete={onChipDelete}
                />
            );
        },
        [onDelete],
    );

    const renderCriterionParams = useCallback(
        (criterionParams: CriterionParam[], criterionKey: string, criterionColor: GradientColorsEnum) => {
            const criterionGroupedByFilterKey = groupBy(criterionParams, (c) => c.filterKey);

            return Object.entries(criterionGroupedByFilterKey).flatMap(([entryKey, entryValue]) => {
                const isCondensable =
                    CONDENSABLE_FILTERS.includes(entryKey as CriterionParamFilter) ||
                    entryKey === FilterTypeEnum.OperatorsWithoutEmployeeCount;

                if (isCondensable && entryValue.length > 1) {
                    return renderCondensableCriteria(entryKey, entryValue, criterionKey, criterionColor);
                }

                return entryValue.map((criterionParam) =>
                    renderIndividualCriteria(criterionParam, criterionKey, criterionColor),
                );
            });
        },
        [renderCondensableCriteria, renderIndividualCriteria],
    );

    return (
        <div className={cn('Criteria')}>
            {Object.keys(data).length > 1 && <div className="Criteria--and-separator">{t(CommonPhrasesEnum.And)}</div>}
            <div>
                <HierarchyIndicator>
                    {Object.entries(data)?.map(([criterionKey, criterion], i, { length }) => {
                        const isLastCriterion = i + 1 === length;

                        if (criterion.CriterionParams?.length === 0) {
                            return;
                        }

                        return (
                            <div className={cn('Criterion')} key={criterion.id}>
                                <div data-testid={`badgeIconCriterion${criterion.name}`}>
                                    <Tooltip content={t(criterion.name)} placement="bottom">
                                        <div>
                                            <BadgeIcon
                                                key={criterion.id}
                                                icon={getCriterionEnumIcon(criterionKey as CriterionEnum)}
                                                baseColor={criterion.baseColor}
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className={cn('Criterion__chips', isLastCriterion && 'Criterion__chips--last')}>
                                    {renderCriterionParams(
                                        criterion.CriterionParams,
                                        criterionKey,
                                        criterion.baseColor,
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </HierarchyIndicator>
            </div>
        </div>
    );
};

export default Criteria;
