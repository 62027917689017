import { Skeleton } from '@mui/material';
import './OperatorProfile.styles.scss';

import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { useLocale } from '@core/utils/locale';
import Tabs from '@components/utility/Tabs/Tabs';
import Breadcrumbs from '@components/ui/Breadcrumbs/Breadcrumbs';
import PageTitleSkeleton from '@components/ui/PageTitle/PageTitle.skeleton';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { IconLocation, IconPhone } from '@assets/icons';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

const OperatorProfileSkeleton = () => {
    const { t } = useLocale();

    return (
        <div className="OperatorProfile">
            <Breadcrumbs
                breadcrumbs={[
                    {
                        label: t(OperatorProfilePhrasesEnum.SearchResults),
                        link: RoutesEnum.OperatorTargetingResults,
                    },
                ]}
            />
            <PageTitleSkeleton />

            <Widget type={WidgetType.Section}>
                <div className="OperatorProfile__header">
                    <section className="OperatorProfile__header-content">
                        <div className="OperatorProfile__profile-info">
                            <Skeleton variant="circular" width={30} height={30} />
                            <Skeleton variant="text" width={200} height={30} />

                            <div className="OperatorProfile__profile-links">
                                <Skeleton variant="text" width={120} height={20} />
                            </div>
                        </div>
                        <div className="OperatorProfile__profile-tags">
                            <Skeleton variant="rectangular" width={280} height={32} />
                        </div>
                    </section>
                    <div className="OperatorProfile__mobile-divider" />
                    <section className="OperatorProfile__header-content">
                        <div>
                            <Skeleton variant="text" width={180} height={20} />
                            <Skeleton variant="text" width={60} height={32} />
                        </div>
                    </section>
                </div>
            </Widget>

            <div className="OperatorProfile__location_skeleton">
                <Widget type={WidgetType.Section}>
                    <div className="OperatorProfile__location_container_skeleton">
                        <section className="OperatorProfile__data_section_skeleton">
                            <div className="OperatorProfile__info_skeleton">
                                <div className="OperatorProfile__info-column-skeleton body-2 body-2--black">
                                    <div className="flex" style={{ whiteSpace: 'pre-line' }}>
                                        <div className="OperatorProfileLocation__icon">
                                            <IconLocation />
                                        </div>
                                        <span data-testid="textAddress">
                                            <Skeleton variant="rectangle" width={200} height={38} />
                                        </span>
                                    </div>
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconLocation />
                                        </div>
                                        <span>
                                            <Skeleton variant="text" width={180} height={20} />
                                        </span>
                                    </div>
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconLocation />
                                        </div>
                                        <span>
                                            <Skeleton variant="text" width={180} height={20} />
                                        </span>
                                    </div>
                                    <div className="flex">
                                        <div className="OperatorProfileLocation__icon">
                                            <IconPhone />
                                        </div>
                                        <span>
                                            <Skeleton variant="text" width={180} height={20} />
                                        </span>
                                    </div>
                                </div>
                                <div className="OperatorProfile__info-column-skeleton">
                                    <div className="flex">
                                        <Skeleton variant="text" width={180} height={20} />
                                    </div>
                                    <div className="flex">
                                        <span>
                                            <Skeleton variant="text" width={180} height={20} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="OperatorProfile__separator_skeleton"></div>
                            <div className="OperatorProfile__info_skeleton">
                                <div className="WorkingHours">
                                    <Heading variant={HeadingVariant.H5} testId="OperatingHours">
                                        {t(OperatorProfilePhrasesEnum.OperatingHours)}
                                    </Heading>
                                    <div>
                                        <div className="WorkingHours__day">
                                            <Text variant={TextVariant.B1} style={{ lineHeight: '24px' }}>
                                                <Skeleton variant="text" width={310} height={24} />
                                                <Skeleton variant="text" width={310} height={24} />
                                                <Skeleton variant="text" width={310} height={24} />
                                                <Skeleton variant="text" width={310} height={24} />
                                                <Skeleton variant="text" width={310} height={24} />
                                                <Skeleton variant="text" width={310} height={24} />
                                                <Skeleton variant="text" width={310} height={24} />
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="OperatorProfileLocation__map-section_skeleton">
                            <div className="OperatorProfileLocation__map_container_skeleton">
                                <div className="OperatorProfileLocation__map_content_skeleton">
                                    <Skeleton variant="rectangle" width={600} height={260} />
                                </div>
                            </div>
                        </section>
                    </div>
                </Widget>
            </div>
            <Tabs
                tabsContent={[
                    {
                        label: <span data-testid="headingDetails">{t(OperatorProfilePhrasesEnum.Details)}</span>,
                        content: (
                            <Widget type={WidgetType.Section} className="section-small">
                                <div className="ProfileDetails">
                                    <div className="ProfileDetails__section col-4">
                                        <Skeleton variant="text" width={310} height={24} />
                                    </div>
                                </div>
                            </Widget>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default OperatorProfileSkeleton;
