import { FC, useCallback, useEffect, useRef, useState } from 'react';

import './Map.styles.scss';
import { useLocale } from '@core/utils/locale';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

const Map: FC<{
    center: google.maps.LatLngLiteral;
    marker: google.maps.LatLngLiteral;
    zoom: number;
    operatorName: string;
}> = ({
    center,
    marker,
    operatorName,
    zoom
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const [modeText, setModeText] = useState<null | string>(null);
    const { t } = useLocale();

    const toggleStreetView = useCallback(() => {
        const toggle = mapRef.current?.getStreetView()?.getVisible();

        if (toggle) {
            mapRef.current?.getStreetView()?.setVisible(false);
            setModeText(t(OperatorProfilePhrasesEnum.StreetView));
        } else {
            mapRef.current?.getStreetView()?.setVisible(true);
            setModeText(t(OperatorProfilePhrasesEnum.MapView));
        }
    }, [t]);

    useEffect(() => {
        if (ref.current) {
            const map = new window.google.maps.Map(ref.current, {
                center: center,
                zoom: zoom,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
            });

            mapRef.current = map;

            new google.maps.Marker({
                position: marker,
                map,
                label: {
                    text: operatorName,
                    className: 'Map__marker-label',
                    color: '#dd463c',
                    fontWeight: '500',
                    fontSize: '14px',
                },
            });

            const streetView = map.getStreetView();
            streetView.setOptions({
                position: marker,
                zoomControl: false,
            });
        }
    }, [center, marker, operatorName, ref, zoom]);

    return (
        <div className="Map">
            <div data-testid="googleMapsContainer" ref={ref} className="Map__content" />
            <div className="Map__mode-button link" onClick={toggleStreetView} data-testid="buttonMapMode">
                {modeText ?? t(OperatorProfilePhrasesEnum.StreetView)}
            </div>
        </div>
    );
};

export default Map;
