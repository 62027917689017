import { FC, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { getCurrencyCode } from '@core/utils/currency';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { IconFoamHand, IconPercentage } from '@assets/icons';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { NumberAnimation } from '@components/utility/NumberAnimation/NumberAnimation';
import ToggleButton, { ToggleOption } from '@components/utility/ToggleButton/ToggleButton';
import { CommonPhrasesEnum, OperatorTargetingResultsPhrasesEnum } from '@core/enums/localeEnum';
import { useLocationCriteria } from '@pages/OperatorTargetingCriteria/hooks/useLocationCriteria';
import { COUNTS_ID, PERCENTS_ID } from '@pages/OperatorTargetingResults/hooks/useGetOperatorSummaryData';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';

import './OperatorResultsSummary.styles.scss';
import AllSegmentsCharts from './AllSegmentsCharts';
import RestaurantsAndBarsCharts from './RestaurantsAndBarsCharts';

const OperatorResultsSummary: FC = () => {
    const { t } = useLocale();
    const { selectedCountry } = useLocationCriteria();
    const isRestaurantsSummaryEnabled = useFeatureFlag(FeatureFlags.RestaurantsSummary);

    const countryCurrencyCode = useMemo(
        () => getCurrencyCode(selectedCountry?.value as CountryCodesEnum),
        [selectedCountry?.value],
    );

    const {
        summaryData: {
            resultsCount,
            selectedOptionId,
            setSelectedOptionId
        },
    } = useOperatorTargetingResultsContext();

    const toggleButtonOptions: [ToggleOption, ToggleOption] = useMemo(() => {
        return [
            { id: COUNTS_ID, title: t(OperatorTargetingResultsPhrasesEnum.Counts), icon: <IconFoamHand /> },
            { id: PERCENTS_ID, title: t(OperatorTargetingResultsPhrasesEnum.Percents), icon: <IconPercentage /> },
        ];
    }, [t]);

    return (
        <div className="OperatorResultsSummary">
            <div className="OperatorResultsTab">
                <div className="OperatorResultsTab__content">
                    <ToggleButton
                        options={toggleButtonOptions}
                        selectedOptionId={selectedOptionId}
                        onToggle={setSelectedOptionId}
                        testId="CountsPercents"
                    />
                </div>
                <div className="OperatorResultsTab__results-count" data-testid="tableResultsCount">
                    <NumberAnimation className="OperatorResultsTab__results-count-numbers" count={resultsCount} />
                    {t(CommonPhrasesEnum.Results)}
                </div>
            </div>
            <section className="OperatorResultsSummary__segmentation-section">
                <AllSegmentsCharts currencyCode={countryCurrencyCode} />
                {isRestaurantsSummaryEnabled && <RestaurantsAndBarsCharts />}
            </section>
        </div>
    );
};

export default OperatorResultsSummary;
