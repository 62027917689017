import { FC } from 'react';
import { useMediaQuery } from '@mui/material';

import { useLocale } from '@utils/locale';
import { OperatorTargetingPhrasesEnum } from '@enums/localeEnum';
import SearchNavigation from '@components/ui/SearchNavigation/SearchNavigation';

import './OperatorTargetingCriteria.styles.scss';
import useSearchNavigation from './hooks/useSearchNavigation';
import OperatorTargetingSearchHeader from './components/OperatorTargetingSearchHeader/OperatorTargetingSearchHeader';

const OperatorTargetingCriteria: FC = () => {
    const { t } = useLocale();
    const isSmallScreen = useMediaQuery('(max-width:1200px)');

    const {
        content,
        sidebarNavigationItems
    } = useSearchNavigation();

    return (
        <div className="OperatorTargetingCriteria">
            <OperatorTargetingSearchHeader />
            <div className="OperatorTargetingCriteria__main">
                <div className="OperatorTargetingCriteria__sidebar">
                    <SearchNavigation
                        isSmallScreen={isSmallScreen}
                        title={t(OperatorTargetingPhrasesEnum.ChooseYourCriteria)}
                        items={sidebarNavigationItems}
                    />
                </div>
                <div className="OperatorTargetingCriteria__content" id="observer-root">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default OperatorTargetingCriteria;
