export enum LocalesEnum {
    En = 'en_US',
    Fr = 'fr_FR',
}

export function getLocaleFromString(locale: string): LocalesEnum {
    if (locale === LocalesEnum.Fr) return LocalesEnum.Fr;

    return LocalesEnum.En;
}

export enum SidebarPhrasesEnum {
    QuickSearch = 'Quick Search',
    Home = 'Home',
    OperatorTargeting = 'Operator Targeting',
    SavedSearches = 'Saved Searches',
    HeadquarterTargeting = 'Headquarter Targeting',
    Logout = 'Logout',
}

export enum ErroPagePhrasesEnum {
    NotFound = 'NotFound',
    NotFoundTitle = 'NotFoundTitle',
    NotFoundDescription = 'NotFoundDescription',
    AccessDenied = 'AccessDenied',
    AccessDeniedTitle = 'AccessDeniedTitle',
    AccessDeniedDescription = 'AccessDeniedDescription',
    GoBackHome = 'GoBackHome',
}

export enum OperatorTargetingPhrasesEnum {
    Operators = 'Operators',
    Criteria = 'Criteria',
    SearchByInputPlaceholder = 'SearchByInputPlaceholder',
    SearchByAddressPlaceholder = 'SearchByAddressPlaceholder',
    SearchByRadiusPlaceholder = 'SearchByRadiusPlaceholder',
    ChooseYourCriteria = 'Choose your criteria',
    FindASegment = 'Find a segment',
    FindACuisine = 'Find a cuisine',
    SaveSearchModalPlaceholder = 'SaveSearchModalPlaceholder',
    SaveSearchSuccess = 'SaveSearchSuccess',
    SeeResults = 'See Results',
    Country = 'Country',
    RegionalCriteria = 'Regional Criteria',
    Select = 'Select',
    SelectCountry = 'Select Country',
    SelectPlural = '(s)',
    SelectCriteriaFromSidebar = 'SelectCriteriaFromSidebar',
    SelectAll = 'Select All',
    Selected = 'Selected',
    ResetAll = 'Reset All',
    ClearAll = 'Clear All',
    Zip = 'ZIP',
    Zips = 'ZIPs',
    ZipCode = 'ZIP Code',
    PostalCode = 'Postal Code',
    PostalCodes = 'Postal Codes',
    SelectPostalCodes = 'Select Postal Codes',
    InvalidZip = 'Invalid ZIP',
    DuplicateZip = 'Duplicate ZIP',
    InvalidZipPermission = 'InvalidZipPermission',
    InvalidPostalCode = 'Invalid Postal Code',
    DuplicatePostalCode = 'Duplicate Postal Code',
    InvalidPostalCodePermission = 'InvalidPostalCodePermission',
    EnterZip = 'Enter ZIP',
    Enter = 'Enter',
    Back = 'Back',
    Loading = 'Loading',
    OperatorTargetingSearches = 'Operator Targeting Searches',
    NearMe = 'Near Me',
    SelectRadius = 'Select Radius',
    DragMap = 'DragMap',
    RadiusCriteriaWithin = 'RadiusCriteriaWithin',
    RadiusCriteriaOf = 'RadiusCriteriaOf',
    RadiusCriteriaCurrentLocation = 'RadiusCriteriaCurrentLocation',
    CommercialEmployees = 'CommercialEmployees',
    NonCommercialEmployees = 'NonCommercialEmployees',
    FilterFoodTagPlaceholder = 'FilterFoodTagPlaceholder',
    FilterPlaceTagPlaceholder = 'FilterPlaceTagPlaceholder',
    AverageCheck = 'Average Check',
}

export enum OperatorTargetingResultsPhrasesEnum {
    List = 'List',
    Summary = 'Summary',
    Map = 'Map',
    Counts = 'Counts',
    Percents = 'Percents',
    ChainUnits = 'Chain Units',
    IndependentUnits = 'Independent Units',
    ChainsVsIndependents = 'Chains vs Independents',
    ViewFullProfile = 'ViewFullProfile',
    FoodAndBeverageCuisines = 'Food & Beverage Cuisines',
    RegionalCuisines = 'Regional Cuisines',
}

export enum OperatorProfilePhrasesEnum {
    SearchResults = 'SearchResults',
    ChainUnit = 'ChainUnit',
    ConfidenceLevel = 'ConfidenceLevel',
    Hours = 'Hours',
    OperatingHours = 'Operating Hours',
    OpenNow = 'OpenNow',
    ClosedNow = 'ClosedNow',
    ThirdPartyDelivery = 'ThirdPartyDelivery',
    Details = 'Details',
    Menu = 'Menu',
    IndependentUnit = 'Independent Unit',
    DistrictSystemUnit = 'District/System Unit',
    McoUnit = 'Multi-Concept Unit (MCO Unit)',
    Area = 'Area',
    Urban = 'Urban',
    Suburban = 'Suburban',
    Rural = 'Rural',
    OpBusiness = 'OpBusiness',
    FoundedIn = 'Founded In',
    YearsinBusiness = 'Years in Business',
    Employees = 'Employees',
    PriceLevel = 'Price Level',
    AverageDailyMeals = 'Average Daily Meals',
    EstimatedAnnualSales = 'Estimated Annual Sales',
    RoomCount = 'Room Count',
    HotelClass = 'Hotel Class',
    Star = 'Star',
    RoomPrice = 'RoomPrice',
    NumberofBeds = 'Number of Beds',
    PatientDailyCensus = 'Patient Daily Census',
    PurchasePotential = 'Purchase Potential',
    Total = 'Total',
    FoodRelated = 'FoodRelated',
    FoodCuisine = 'FoodCuisine',
    FoodTags = 'FoodTags',
    Place = 'Place',
    PlaceTags = 'PlaceTags',
    Beverage = 'Beverage',
    Disposable = 'Disposable',
    Alcohol = 'Alcohol',
    AlcoholUnspecified = 'Serves Alcohol (unspecified)',
    NonAlcohol = 'Non-Alcohol',
    Dry = 'Dry',
    Fresh = 'Fresh',
    Frozen = 'Frozen',
    Protein = 'Protein',
    Service = 'Service',
    Offers = 'Offers',
    BanquetFacility = 'Banquet Facility',
    ComplimentaryBreakfast = 'Complimentary Breakfast',
    MeetingRooms = 'Meeting Rooms',
    RoomService = 'Room Service',
    DineIn = 'Dine In',
    Takeout = 'Takeout',
    Cafeteria = 'Cafeteria',
    Delivery = 'Delivery',
    DriveThru = 'Drive-Thru',
    Curbside = 'Curbside',
    Catering = 'Catering',
    Reservations = 'Reservations',
    KidsMenu = 'Kids Menu',
    Eatertainment = 'Eatertainment',
    DayParts = 'Day Parts',
    Breakfast = 'Breakfast',
    Lunch = 'Lunch',
    Dinner = 'Dinner',
    LateNight = 'Late Night',
    BeerWineOnly = 'Beer & Wine Only',
    FullBar = 'Full Bar',
    Management = 'Management',
    ContractManaged = 'Contract-Managed',
    SelfOperated = 'Self-Operated',
    GPO = 'Group Purchasing Organization',
    GasStation = 'Gas Station',
    PetrolLocation = 'Petrol Location',
    NonPetrolLocation = 'Non-Petrol Location',
    Experience = 'Experience',
    AverageReviewRating = 'Average Review Rating',
    TotalReviewCount = 'Total Review Count',
    Reviews = 'Reviews',
    PopularityScore = 'Popularity Score',
    Units = 'Units',
    VenueCapacity = 'Venue Capacity',
    EducationSector = 'Education Sector',
    NonCommercialSector = 'Non-Commercial Sector',
    Enrollment = 'Enrollment',
    GradeLevels = 'Grade Levels',
    HospitalType = 'Hospital Type',
    DetailedBusinessId = 'Detailed Business Id',
    GeneralBusinessId = 'General Business Id',
    EducationFreeMeals = 'Education Free Meals',
    EducationReducedMeals = 'Education Reduced Meals',
    EducationReducedFreeMeals = 'Education Reduced + Free Meals',
    HigherEducationStudentsMealplans = 'Higher Education Students on Mealplans',
    HigherEducationHousing = 'Higher Education Housing',
    ActiveBasePopulation = 'Active Base Population',
    Open24Hours = 'Open 24 Hours',
    PastTwoMonths = 'Past 60 days',
    HostLocation = 'Host Location',
    StreetView = 'Street View',
    MapView = 'Map View',
    SocialLinks = 'Social Links',
    High = 'High',
    Medium = 'Medium',
    Low = 'Low',
    EstMealsPerDay = 'EstMealsPerDay',
}

export enum HomePhrasesEnum {
    QuickSearchPlaceholder = 'QuickSearchPlaceholder',
    All = 'All',
    SeeAll = 'See All',
    Change = 'Change',
    DontSeeCountry = 'DontSeeCountry',
    ContactAdmin = 'ContactAdmin',
}

export enum CommonPhrasesEnum {
    Ok = 'Ok',
    Save = 'Save',
    Cancel = 'Cancel',
    Search = 'Search',
    SaveSearch = 'Save Search',
    EditName = 'Edit Name',
    NewSearch = 'New Search',
    Results = 'Results',
    Filters = 'Filters',
    Display = 'Display',
    Edit = 'Edit',
    Delete = 'Delete',
    And = 'And',
    Apply = 'Apply',
    NoMin = 'No Min',
    NoMax = 'No Max',
    CustomRange = 'Custom Range',
    NoResultsFound = 'No Results Found',
    NoResultsFoundDescription = 'No Results Found Description',
    NoSavedSearch = 'You have no saved searches yet',
    NoSavedSearchDescription = 'Your saved searches will appear here',
    UpdateExistingSavedSearch = 'Update Existing',
    SaveANewSearch = 'Save as a New Search',
    WouldYouLikeToUpdate = 'Would you like to update your existing saved search',
    SaveThisNewSearch = 'or save this as a new search?',
    SearchSaved = 'SearchSaved',
    ConfidenceLevelToolTipDesc = 'ConfidenceLevelToolTipDesc',
    PopularityScoreToolTipDesc = 'PopularityScoreToolTipDesc',
    ShowAll = 'ShowAll',
    CollapseAll = 'CollapseAll',
}

export enum DisplayDrawerEnum {
    AvailableColumns = 'Available Columns',
    SelectedColumns = 'Selected Columns',
    DragToReorder = 'Drag to reorder',
    SaveAsDefaultColumns = 'Save as default columns',
    ResetToDefaultColumns = 'Reset to default columns',
}

export enum TableColumnsEnum {
    Name = 'Name',
    Address = 'Address',
    Segment = 'Segment',
    Cuisine = 'Cuisine',
    UnitsInArea = 'Units in Area',
    TotalUnits = 'Total Units',
    ClientStatus = 'Table Client Status',
}

export enum SavedSearchEnum {
    Name = 'Name',
    CreatedBy = 'Created By',
    Type = 'Type',
    DateSaved = 'Date Saved',
    Invisible = '\u00A0',
    MySavedSearchOnly = 'My searches only',
    NameAlreadyExists = 'Oops. It looks like a search with that name already exists',
    Public = 'Public',
    Private = 'Private',
    Me = 'Me',
    DeleteConfirmation = 'Are you sure you want to delete this saved search',
    DeleteSearch = 'Delete Search',
    ResultsFor = 'Results for',
}

export enum FilterTypeEnum {
    Location = 'Location',
    Segments = 'Segments',
    SegmentsCriteria = 'SegmentsCriteria',
    RestaurantsAndBars = 'Restaurants & Bars',
    LodgingAndRecreation = 'Lodging & Recreation',
    Retail = 'Retail',
    NonCommercial = 'Non-Commercial',
    Commercial = 'Commercial',
    Tags = 'Tags',
    General = 'General',
    ChainIndependent = 'Chain / Independent',
    Business = 'Business',
    RecentlyAdded = 'Recently Added',
    NumberOfEmployees = 'Number of Employees',
    SavedSearches = 'Saved Searches',
    OtherFoodservice = 'Other Foodservice',
    CuisineTypes = 'Cuisine Types',
    TotalPurchasePotential = 'TotalPurchasePotential',
    OperatorsWithoutEmployeeCount = 'Include operators without employee count data',
    OperatorsWithNoEmployeeData = 'Include operators with no employment data',
    Regional = 'Regional',
    Radius = 'Radius',
    NumberofRooms = 'Number of Rooms',
    ServicesOffered = 'Services Offered',
    Recreation = 'Recreation',
    EventVenueCapacity = 'Event Venue Capacity',
    Lodging = 'Lodging',
    ClientStatus = 'Client Status',
    ClientFilters = 'Client Filters',
}

export enum RangeFiltersEnum {
    MoreThan = 'More Than',
    LessThan = 'Less Than',
    GreaterThan = 'Greater Than',
    To = 'To',
    Over = 'Over',
    Chains = 'Chains',
    Independents = 'Independents',
    Year = 'Year',
    Years = 'Years',
}

export enum ExportPhrasesEnum {
    Export = 'Export',
    ExportModalTitle = 'ExportModalTitle',
    ExportModalHeading = 'ExportModalHeading',
    ExportModalText = 'ExportModalText',
    ProcessingExport = 'ProcessingExport',
    ProcessingComplete = 'ProcessingComplete',
    ExportFileName = 'ExportFileName',
    ExportToExcel = 'ExportToExcel',
    ExportCsv = 'ExportCsv',
}

export enum ExportColumnHeadersEnum {
    ENTITY_TYPE = 'ENTITY_TYPE',
    CONFIDENCE_LEVEL = 'CONFIDENCE_LEVEL',
    OPERATOR_STATUS = 'OPERATOR_STATUS',
    BUSINESS_NAME = 'BUSINESS_NAME',
    PHONE = 'PHONE',
    WEBSITE = 'WEBSITE',
    SUB_CHANNEL = 'SUB_CHANNEL',
    CHANNEL = 'CHANNEL',
    COUNTRY = 'COUNTRY',
    ADDRESS_1 = 'ADDRESS_1',
    ADDRESS_2 = 'ADDRESS_2',
    ADDRESS_3 = 'ADDRESS_3',
    CITY = 'CITY',
    ADMINISTRATIVE_AREA_1 = 'ADMINISTRATIVE_AREA_1',
    ADMINISTRATIVE_AREA_2 = 'ADMINISTRATIVE_AREA_2',
    ADMINISTRATIVE_AREA_3 = 'ADMINISTRATIVE_AREA_3',
    ADMINISTRATIVE_AREA_4 = 'ADMINISTRATIVE_AREA_4',
    POSTAL_CODE_1 = 'POSTAL_CODE_1',
    LEGAL_NAME = 'LEGAL_NAME',
    ID_BUSINESS_DETAILED = 'ID_BUSINESS_DETAILED',
    ID_BUSINESS_GENERAL = 'ID_BUSINESS_GENERAL',
    YEARS_IN_BUSINESS_RANGE = 'YEARS_IN_BUSINESS_RANGE',
    NUMBER_OF_EMPLOYEES_RANGE = 'NUMBER_OF_EMPLOYEES_RANGE',
    ANNUAL_SALES_RANGE = 'ANNUAL_SALES_RANGE',
    ANNUAL_SALES = 'ANNUAL_SALES',
    ANNUAL_SALES_TYPE = 'ANNUAL_SALES_TYPE',
    OPERATING_HOURS = 'OPERATING_HOURS',
    CHAIN_SYSTEM_ID = 'CHAIN_SYSTEM_ID',
    CHAIN_SYSTEM_NAME = 'CHAIN_SYSTEM_NAME',
    CHAIN_SIZE_RANGE = 'CHAIN_SIZE_RANGE',
    HOST_ID = 'HOST_ID',
    HOST_NAME = 'HOST_NAME',
    HOST_NUMBER_OF_UNITS_LOCATED_WITHIN = 'HOST_NUMBER_OF_UNITS_LOCATED_WITHIN',
    CUISINE_PRIMARY_DESC = 'CUISINE_PRIMARY_DESC',
    CUISINE_SECONDARY_DESC = 'CUISINE_SECONDARY_DESC',
    ESTIMATED_MEALS_PER_DAY_RANGE = 'ESTIMATED_MEALS_PER_DAY_RANGE',
    AVERAGE_CHECK_RANGE = 'AVERAGE_CHECK_RANGE',
    OFFERS_DELIVERY = 'OFFERS_DELIVERY',
    OFFERS_TAKEOUT = 'OFFERS_TAKEOUT',
    NUMBER_OF_BEDS_RANGE = 'NUMBER_OF_BEDS_RANGE',
    LODGING_STAR_LEVEL = 'LODGING_STAR_LEVEL',
    NUMBER_OF_ROOMS_RANGE = 'NUMBER_OF_ROOMS_RANGE',
    NON_COMMERCIAL_SECTOR = 'NON_COMMERCIAL_SECTOR',
    EDUCATION_LEVELS = 'EDUCATION_LEVELS',
    NUMBER_OF_STUDENTS_RANGE = 'NUMBER_OF_STUDENTS_RANGE',
    REVIEW_RATING = 'REVIEW_RATING',
    REVIEW_COUNT = 'REVIEW_COUNT',
    DELIVERY_LINKS = 'DELIVERY_LINKS',
    RESERVATION_LINKS = 'RESERVATION_LINKS',
    BOOKING_DOT_COM = 'BOOKING_DOT_COM',
    HOTELS_DOT_COM = 'HOTELS_DOT_COM',
    POPULARITY_SCORE = 'POPULARITY_SCORE',
    MCO_NAME = 'MCO_NAME',
    HOURS_MONDAY = 'HOURS_MONDAY',
    HOURS_TUESDAY = 'HOURS_TUESDAY',
    HOURS_WEDNESDAY = 'HOURS_WEDNESDAY',
    HOURS_THURSDAY = 'HOURS_THURSDAY',
    HOURS_FRIDAY = 'HOURS_FRIDAY',
    HOURS_SATURDAY = 'HOURS_SATURDAY',
    HOURS_SUNDAY = 'HOURS_SUNDAY',
    PURCHASE_POTENTIAL_SUM_TOTAL = 'PURCHASE_POTENTIAL_SUM_TOTAL',
    PURCHASE_POTENTIAL_SUM_FOOD = 'PURCHASE_POTENTIAL_SUM_FOOD',
    PURCHASE_POTENTIAL_SUM_BEVERAGE = 'PURCHASE_POTENTIAL_SUM_BEVERAGE',
    PURCHASE_POTENTIAL_SUM_FOOD_AND_BEVERAGE = 'PURCHASE_POTENTIAL_SUM_FOOD_AND_BEVERAGE',
    PURCHASE_POTENTIAL_DISPOSABLE = 'PURCHASE_POTENTIAL_DISPOSABLE',
    PURCHASE_POTENTIAL_DRY = 'PURCHASE_POTENTIAL_DRY',
    PURCHASE_POTENTIAL_FRESH = 'PURCHASE_POTENTIAL_FRESH',
    PURCHASE_POTENTIAL_FROZEN = 'PURCHASE_POTENTIAL_FROZEN',
    PURCHASE_POTENTIAL_NON_ALCOHOL = 'PURCHASE_POTENTIAL_NON_ALCOHOL',
    PURCHASE_POTENTIAL_ALCOHOL = 'PURCHASE_POTENTIAL_ALCOHOL',
    TAGS_FOOD = 'TAGS_FOOD',
    TAGS_PLACE = 'TAGS_PLACE',
    CLIENT_STATUS = 'CLIENT_STATUS',
}

export type PhrasesEnum =
    | CommonPhrasesEnum
    | SidebarPhrasesEnum
    | OperatorTargetingPhrasesEnum
    | OperatorTargetingResultsPhrasesEnum
    | TableColumnsEnum
    | FilterTypeEnum
    | DisplayDrawerEnum
    | OperatorProfilePhrasesEnum
    | HomePhrasesEnum
    | ErroPagePhrasesEnum
    | SavedSearchEnum
    | RangeFiltersEnum
    | ExportPhrasesEnum
    | ExportColumnHeadersEnum;

type TranslateDictionary = {
    [key in PhrasesEnum]: {
        [key in LocalesEnum]: string;
    };
};

export const Dictionary: TranslateDictionary = {
    [HomePhrasesEnum.QuickSearchPlaceholder]: {
        [LocalesEnum.En]: 'Search by operator name',
        [LocalesEnum.Fr]: 'Recherche par nom d\'opérateur',
    },
    [HomePhrasesEnum.All]: {
        [LocalesEnum.En]: 'All',
        [LocalesEnum.Fr]: 'Tout',
    },
    [HomePhrasesEnum.SeeAll]: {
        [LocalesEnum.En]: 'See All',
        [LocalesEnum.Fr]: 'Voir tous les',
    },
    [HomePhrasesEnum.Change]: {
        [LocalesEnum.En]: 'Change',
        [LocalesEnum.Fr]: 'Changer',
    },
    [HomePhrasesEnum.DontSeeCountry]: {
        [LocalesEnum.En]: 'Don\'t see the country you need?',
        [LocalesEnum.Fr]: 'Vous ne voyez pas le pays dont vous avez besoin?',
    },
    [HomePhrasesEnum.ContactAdmin]: {
        [LocalesEnum.En]: 'Contact your admin for access.',
        [LocalesEnum.Fr]: 'Contactez votre administrateur pour obtenir un accès',
    },
    [ExportPhrasesEnum.Export]: {
        [LocalesEnum.En]: 'Export',
        [LocalesEnum.Fr]: 'Exporter',
    },
    [ExportPhrasesEnum.ExportModalTitle]: {
        [LocalesEnum.En]: 'Operator Targeting Export',
        [LocalesEnum.Fr]: 'Export de votre sélection d\'opérateurs',
    },
    [ExportPhrasesEnum.ExportModalHeading]: {
        [LocalesEnum.En]: 'Your export request is processing and may take a few minutes.',
        [LocalesEnum.Fr]: 'Votre export est en cours et peut prendre quelques minutes',
    },
    [ExportPhrasesEnum.ExportModalText]: {
        [LocalesEnum.En]: 'Once processing completes, your file will automatically download.',
        [LocalesEnum.Fr]: 'Une fois que l\'export est complété, votre fichier sera automatiquement téléchargé.',
    },
    [ExportPhrasesEnum.ProcessingExport]: {
        [LocalesEnum.En]: 'Processing export...',
        [LocalesEnum.Fr]: 'Export en cours...',
    },
    [ExportPhrasesEnum.ProcessingComplete]: {
        [LocalesEnum.En]: 'Processing complete.',
        [LocalesEnum.Fr]: 'Export terminé.',
    },
    [ExportPhrasesEnum.ExportFileName]: {
        [LocalesEnum.En]: 'Operator_Results',
        [LocalesEnum.Fr]: 'Operateur_Resultats',
    },
    [ExportPhrasesEnum.ExportToExcel]: {
        [LocalesEnum.En]: 'Export to Excel',
        [LocalesEnum.Fr]: 'Exporter vers Excel',
    },
    [ExportPhrasesEnum.ExportCsv]: {
        [LocalesEnum.En]: 'Export CSV',
        [LocalesEnum.Fr]: 'Exporter CSV',
    },
    [SidebarPhrasesEnum.QuickSearch]: {
        [LocalesEnum.En]: 'Quick Search',
        [LocalesEnum.Fr]: 'Recherche rapide',
    },
    [SidebarPhrasesEnum.Home]: {
        [LocalesEnum.En]: 'Home',
        [LocalesEnum.Fr]: 'Accueil',
    },
    [SidebarPhrasesEnum.OperatorTargeting]: {
        [LocalesEnum.En]: 'Operator Targeting',
        [LocalesEnum.Fr]: 'Identifier des opérateurs',
    },
    [SidebarPhrasesEnum.HeadquarterTargeting]: {
        [LocalesEnum.En]: 'Headquarter Targeting',
        [LocalesEnum.Fr]: 'Identifier des chaînes',
    },
    [SidebarPhrasesEnum.Logout]: {
        [LocalesEnum.En]: 'Logout',
        [LocalesEnum.Fr]: 'Se déconnecter',
    },
    [OperatorTargetingPhrasesEnum.Operators]: {
        [LocalesEnum.En]: 'Operators',
        [LocalesEnum.Fr]: 'Opérateurs',
    },
    [OperatorTargetingPhrasesEnum.SelectCriteriaFromSidebar]: {
        [LocalesEnum.En]: 'Select criteria from the left to build your search query, or use an existing search below',
        [LocalesEnum.Fr]:
            'Choisissez parmi les critères à gauche pour construire votre requête de recherche, ou utilisez une requête existante ci-dessous',
    },
    [OperatorTargetingPhrasesEnum.Criteria]: {
        [LocalesEnum.En]: 'Criteria',
        [LocalesEnum.Fr]: 'Critères',
    },
    [OperatorTargetingPhrasesEnum.SearchByInputPlaceholder]: {
        [LocalesEnum.En]: 'Search by name or address',
        [LocalesEnum.Fr]: 'Recherche par nom ou adresse',
    },
    [OperatorTargetingPhrasesEnum.SearchByAddressPlaceholder]: {
        [LocalesEnum.En]: 'Enter an address, city, or ZIP',
        [LocalesEnum.Fr]: 'Entrez une adresse, une ville ou un code postal',
    },
    [OperatorTargetingPhrasesEnum.SearchByRadiusPlaceholder]: {
        [LocalesEnum.En]: 'Enter an address, city or postal code',
        [LocalesEnum.Fr]: 'Entrer une adresse, une ville ou un code postal',
    },
    [OperatorTargetingPhrasesEnum.SeeResults]: {
        [LocalesEnum.En]: 'See Results',
        [LocalesEnum.Fr]: 'Voir les résultats',
    },
    [OperatorTargetingPhrasesEnum.SelectAll]: {
        [LocalesEnum.En]: 'select all',
        [LocalesEnum.Fr]: 'tout sélectionner',
    },
    [OperatorTargetingPhrasesEnum.Selected]: {
        [LocalesEnum.En]: 'selected',
        [LocalesEnum.Fr]: 'sélectionnées',
    },
    [OperatorTargetingPhrasesEnum.ClearAll]: {
        [LocalesEnum.En]: 'clear all',
        [LocalesEnum.Fr]: 'tout désélectionner',
    },
    [OperatorTargetingPhrasesEnum.ResetAll]: {
        [LocalesEnum.En]: 'reset all',
        [LocalesEnum.Fr]: 'remettre à zéro',
    },
    [OperatorTargetingPhrasesEnum.Zip]: {
        [LocalesEnum.En]: 'ZIP',
        [LocalesEnum.Fr]: 'Codes postaux',
    },
    [OperatorTargetingPhrasesEnum.Zips]: {
        [LocalesEnum.En]: 'ZIPs',
        [LocalesEnum.Fr]: 'Codes postaux',
    },
    [OperatorTargetingPhrasesEnum.ZipCode]: {
        [LocalesEnum.En]: 'ZIP Code',
        [LocalesEnum.Fr]: 'Code postal',
    },
    [OperatorTargetingPhrasesEnum.PostalCode]: {
        [LocalesEnum.En]: 'Postal Code',
        [LocalesEnum.Fr]: 'Code postal',
    },
    [OperatorTargetingPhrasesEnum.PostalCodes]: {
        [LocalesEnum.En]: 'Postal Codes',
        [LocalesEnum.Fr]: 'Codes postaux',
    },
    [OperatorTargetingPhrasesEnum.SelectPostalCodes]: {
        [LocalesEnum.En]: 'Select Postal Code(s)',
        [LocalesEnum.Fr]: 'Saisissez un ou plusieurs codes postaux',
    },
    [OperatorTargetingPhrasesEnum.InvalidZip]: {
        [LocalesEnum.En]: 'Oops. Invalid ZIP Code. Please re-enter.',
        [LocalesEnum.Fr]: 'Oups. ZIP invalide. Merci de recommencer votre saisie.',
    },
    [OperatorTargetingPhrasesEnum.DuplicateZip]: {
        [LocalesEnum.En]: 'Oops. Duplicate ZIP Code.',
        [LocalesEnum.Fr]: 'Oups. ZIP en doublon.',
    },
    [OperatorTargetingPhrasesEnum.InvalidZipPermission]: {
        [LocalesEnum.En]: 'This ZIP Code is outside your current permissions.',
        [LocalesEnum.Fr]: 'Ce ZIP est en dehors de vos autorisations actuelles.',
    },
    [OperatorTargetingPhrasesEnum.InvalidPostalCode]: {
        [LocalesEnum.En]: 'Oops. Invalid Postal Code. Please re-enter.',
        [LocalesEnum.Fr]: 'Oups. Code postal invalide. Merci de recommencer votre saisie.',
    },
    [OperatorTargetingPhrasesEnum.DuplicatePostalCode]: {
        [LocalesEnum.En]: 'Oops. Duplicate Postal Code.',
        [LocalesEnum.Fr]: 'Oups. Code postal en doublon.',
    },
    [OperatorTargetingPhrasesEnum.InvalidPostalCodePermission]: {
        [LocalesEnum.En]: 'This Postal Code is outside your current permissions.',
        [LocalesEnum.Fr]: 'Ce Code Postal est en dehors de vos autorisations actuelles.',
    },
    [OperatorTargetingPhrasesEnum.EnterZip]: {
        [LocalesEnum.En]: 'Enter ZIP Code(s)',
        [LocalesEnum.Fr]: 'Saisissez un ou plusieurs codes postaux',
    },
    [OperatorTargetingPhrasesEnum.Enter]: {
        [LocalesEnum.En]: 'Enter',
        [LocalesEnum.Fr]: 'Saisissez',
    },
    [OperatorTargetingPhrasesEnum.Back]: {
        [LocalesEnum.En]: 'Back',
        [LocalesEnum.Fr]: 'Retour',
    },
    [OperatorTargetingPhrasesEnum.Loading]: {
        [LocalesEnum.En]: 'Loading',
        [LocalesEnum.Fr]: 'En cours de chargement',
    },
    [OperatorTargetingPhrasesEnum.OperatorTargetingSearches]: {
        [LocalesEnum.En]: 'Operator Targeting Searches',
        [LocalesEnum.Fr]: 'Requêtes sur les opérateurs',
    },
    [OperatorTargetingPhrasesEnum.NearMe]: {
        [LocalesEnum.En]: 'Near Me',
        [LocalesEnum.Fr]: 'Autour de moi',
    },
    [OperatorTargetingPhrasesEnum.DragMap]: {
        [LocalesEnum.En]: 'Drag map to change area',
        [LocalesEnum.Fr]: 'Faites glisser la carte pour changer de périmètre',
    },
    [OperatorTargetingPhrasesEnum.RadiusCriteriaWithin]: {
        [LocalesEnum.En]: 'Within',
        [LocalesEnum.Fr]: 'Dans un périmètre de',
    },
    [OperatorTargetingPhrasesEnum.RadiusCriteriaOf]: {
        [LocalesEnum.En]: 'of',
        [LocalesEnum.Fr]: 'autour de',
    },
    [OperatorTargetingPhrasesEnum.RadiusCriteriaCurrentLocation]: {
        [LocalesEnum.En]: 'current location',
        [LocalesEnum.Fr]: 'la localisation actuelle',
    },
    [OperatorTargetingPhrasesEnum.SelectRadius]: {
        [LocalesEnum.En]: 'Select Radius',
        [LocalesEnum.Fr]: 'Sélectionner le périmètre',
    },
    [OperatorTargetingPhrasesEnum.CommercialEmployees]: {
        [LocalesEnum.En]: 'Commercial Employees',
        [LocalesEnum.Fr]: 'Commercial Nombre d\'employés',
    },
    [OperatorTargetingPhrasesEnum.NonCommercialEmployees]: {
        [LocalesEnum.En]: 'Non-Commercial Employees',
        [LocalesEnum.Fr]: 'Collectivités Nombre d\'employés',
    },
    [CommonPhrasesEnum.Results]: {
        [LocalesEnum.En]: 'Results',
        [LocalesEnum.Fr]: 'Résultats',
    },
    [CommonPhrasesEnum.Ok]: {
        [LocalesEnum.En]: 'OK',
        [LocalesEnum.Fr]: 'OK',
    },
    [CommonPhrasesEnum.Save]: {
        [LocalesEnum.En]: 'Save',
        [LocalesEnum.Fr]: 'Sauvegarder',
    },
    [CommonPhrasesEnum.Apply]: {
        [LocalesEnum.En]: 'Apply',
        [LocalesEnum.Fr]: 'Appliquer',
    },
    [CommonPhrasesEnum.Cancel]: {
        [LocalesEnum.En]: 'Cancel',
        [LocalesEnum.Fr]: 'Annuler',
    },
    [CommonPhrasesEnum.Search]: {
        [LocalesEnum.En]: 'Search',
        [LocalesEnum.Fr]: 'Requête',
    },
    [CommonPhrasesEnum.SaveSearch]: {
        [LocalesEnum.En]: 'Save Search',
        [LocalesEnum.Fr]: 'Sauvegarder la requête',
    },
    [CommonPhrasesEnum.ConfidenceLevelToolTipDesc]: {
        [LocalesEnum.En]:
            'The foodservice landscape shifts rapidly and our database is constantly being updated as operators open and close. \n An operator\'s Confidence Level reflects its level of verification for open/active status (based on several factors such as date of last customer review or rating, etc.).',
        [LocalesEnum.Fr]:
            'Le marché de la restauration hors domicile évolue rapidement et notre database est constamment mise à jour, au fur et à mesure des ouvertures et fermetures de points de vente. Le niveau de confiance d\'un opérateur reflète notre degré de certitude concernant le statut ouvert / fermé du point de vente (sur la base de plusieurs critères dont la date du dernier commentaire client).',
    },
    [CommonPhrasesEnum.PopularityScoreToolTipDesc]: {
        [LocalesEnum.En]:
            'A measure of traffic; based on a 100-point scale where higher scores = more popular. \n The Popularity Score considers the number of consumer reviews on the sources, as well as the average rating of that operator.',
        [LocalesEnum.Fr]:
            'Ce score est une mesure de fréquentation, basé sur une échelle de 100 où le score le plus élevé reflète une plus grande popularité. Le score de popularité prend en compte le nombre de commentaires de consommateurs sur les sources et la note moyenne de l\'établissement.',
    },
    [CommonPhrasesEnum.SearchSaved]: {
        [LocalesEnum.En]: 'Search Saved',
        [LocalesEnum.Fr]: 'Requête sauvegardée',
    },
    [CommonPhrasesEnum.EditName]: {
        [LocalesEnum.En]: 'Edit name ',
        [LocalesEnum.Fr]: 'Modifier le nom',
    },
    [CommonPhrasesEnum.NewSearch]: {
        [LocalesEnum.En]: 'New Search',
        [LocalesEnum.Fr]: 'Nouvelle requête',
    },
    [CommonPhrasesEnum.Filters]: {
        [LocalesEnum.En]: 'Filters',
        [LocalesEnum.Fr]: 'Filtres',
    },
    [CommonPhrasesEnum.Display]: {
        [LocalesEnum.En]: 'Display',
        [LocalesEnum.Fr]: 'Afficher',
    },
    [CommonPhrasesEnum.Edit]: {
        [LocalesEnum.En]: 'Edit',
        [LocalesEnum.Fr]: 'Modifier',
    },
    [CommonPhrasesEnum.Delete]: {
        [LocalesEnum.En]: 'Delete',
        [LocalesEnum.Fr]: 'Supprimer',
    },
    [CommonPhrasesEnum.And]: {
        [LocalesEnum.En]: 'And',
        [LocalesEnum.Fr]: 'Et',
    },
    [CommonPhrasesEnum.NoMax]: {
        [LocalesEnum.En]: 'No Max',
        [LocalesEnum.Fr]: 'Pas de Maximum',
    },
    [CommonPhrasesEnum.NoMin]: {
        [LocalesEnum.En]: 'No Min',
        [LocalesEnum.Fr]: 'Pas de Minimum',
    },
    [CommonPhrasesEnum.CustomRange]: {
        [LocalesEnum.En]: 'Custom Range',
        [LocalesEnum.Fr]: 'Tranche personnalisée',
    },
    [CommonPhrasesEnum.NoResultsFound]: {
        [LocalesEnum.En]: 'No Results Found',
        [LocalesEnum.Fr]: 'Aucun résultat trouvé',
    },
    [CommonPhrasesEnum.NoResultsFoundDescription]: {
        [LocalesEnum.En]: 'Check your spelling or try a different keyword',
        [LocalesEnum.Fr]: 'Merci de vérifier votre saisie ou de taper un autre mot-clé',
    },
    [CommonPhrasesEnum.NoSavedSearch]: {
        [LocalesEnum.En]: 'You have no saved searches yet',
        [LocalesEnum.Fr]: 'Vous n\'avez aucune requête sauvegardée pour l\'instant',
    },
    [CommonPhrasesEnum.NoSavedSearchDescription]: {
        [LocalesEnum.En]: 'Your saved searches will appear here',
        [LocalesEnum.Fr]: 'Vos requêtes sauvegardées apparaîtront ici',
    },
    [CommonPhrasesEnum.UpdateExistingSavedSearch]: {
        [LocalesEnum.En]: 'Update Existing',
        [LocalesEnum.Fr]: 'Mettre à jour la requête existante',
    },
    [CommonPhrasesEnum.SaveANewSearch]: {
        [LocalesEnum.En]: 'Save as a New Search',
        [LocalesEnum.Fr]: 'Sauvegarder une nouvelle requête',
    },
    [CommonPhrasesEnum.WouldYouLikeToUpdate]: {
        [LocalesEnum.En]: 'Would you like to update your existing saved search',
        [LocalesEnum.Fr]: 'Voulez-vous mettre à jour votre requête sauvegardée existante',
    },
    [CommonPhrasesEnum.SaveThisNewSearch]: {
        [LocalesEnum.En]: 'or save this as a new search?',
        [LocalesEnum.Fr]: 'ou la sauvegarder en tant que nouvelle requête?',
    },
    [CommonPhrasesEnum.ShowAll]: {
        [LocalesEnum.En]: 'show all',
        [LocalesEnum.Fr]: 'Tout afficher',
    },
    [CommonPhrasesEnum.CollapseAll]: {
        [LocalesEnum.En]: 'collapse all',
        [LocalesEnum.Fr]: 'Tout réduire',
    },
    [TableColumnsEnum.Name]: {
        [LocalesEnum.En]: 'Name',
        [LocalesEnum.Fr]: 'Nom',
    },
    [TableColumnsEnum.Address]: {
        [LocalesEnum.En]: 'Address',
        [LocalesEnum.Fr]: 'Adresse',
    },
    [TableColumnsEnum.Segment]: {
        [LocalesEnum.En]: 'Segment',
        [LocalesEnum.Fr]: 'Segment de marché',
    },
    [TableColumnsEnum.Cuisine]: {
        [LocalesEnum.En]: 'Cuisine',
        [LocalesEnum.Fr]: 'Cuisine',
    },
    [TableColumnsEnum.UnitsInArea]: {
        [LocalesEnum.En]: 'Units in Area',
        [LocalesEnum.Fr]: 'Nombre d\'unités dans la zone',
    },
    [TableColumnsEnum.TotalUnits]: {
        [LocalesEnum.En]: 'Total Units',
        [LocalesEnum.Fr]: 'Nombre total d\'unités',
    },
    [TableColumnsEnum.ClientStatus]: {
        [LocalesEnum.En]: 'Client Status',
        [LocalesEnum.Fr]: 'Statut client',
    },
    [SavedSearchEnum.CreatedBy]: {
        [LocalesEnum.En]: 'Created By',
        [LocalesEnum.Fr]: 'Créé par',
    },
    [SavedSearchEnum.DateSaved]: {
        [LocalesEnum.En]: 'Date Saved',
        [LocalesEnum.Fr]: 'Date sauvegardée',
    },
    [SavedSearchEnum.Type]: {
        [LocalesEnum.En]: 'Type',
        [LocalesEnum.Fr]: 'Type',
    },
    [SavedSearchEnum.Invisible]: {
        [LocalesEnum.En]: '\u00A0',
        [LocalesEnum.Fr]: '\u00A0',
    },
    [SavedSearchEnum.MySavedSearchOnly]: {
        [LocalesEnum.En]: 'My searches only',
        [LocalesEnum.Fr]: 'Seulement mes requêtes',
    },
    [SavedSearchEnum.NameAlreadyExists]: {
        [LocalesEnum.En]: 'Oops. It looks like a search with that name already exists',
        [LocalesEnum.Fr]: 'Oups, il semblerait qu\'une requête avec le même nom existe déjà',
    },
    [SavedSearchEnum.Public]: {
        [LocalesEnum.En]: 'Public',
        [LocalesEnum.Fr]: 'Publique',
    },
    [SavedSearchEnum.Private]: {
        [LocalesEnum.En]: 'Private',
        [LocalesEnum.Fr]: 'Privé',
    },
    [SavedSearchEnum.Me]: {
        [LocalesEnum.En]: 'Me',
        [LocalesEnum.Fr]: 'Moi',
    },
    [SavedSearchEnum.DeleteConfirmation]: {
        [LocalesEnum.En]: 'Are you sure you want to delete this saved search',
        [LocalesEnum.Fr]: 'Êtes-vous sûr de vouloir supprimer',
    },
    [SavedSearchEnum.DeleteSearch]: {
        [LocalesEnum.En]: 'Delete Search',
        [LocalesEnum.Fr]: 'Supprimer la requête',
    },
    [SavedSearchEnum.ResultsFor]: {
        [LocalesEnum.En]: 'Results for',
        [LocalesEnum.Fr]: 'Résultats pour',
    },
    [OperatorTargetingPhrasesEnum.ChooseYourCriteria]: {
        [LocalesEnum.En]: 'Choose your criteria',
        [LocalesEnum.Fr]: 'Choix des critères',
    },
    [OperatorTargetingPhrasesEnum.FindASegment]: {
        [LocalesEnum.En]: 'Find a segment',
        [LocalesEnum.Fr]: 'Recherche par segment',
    },
    [OperatorTargetingPhrasesEnum.RegionalCriteria]: {
        [LocalesEnum.En]: 'Regional Criteria',
        [LocalesEnum.Fr]: 'Critères géographiques',
    },
    [OperatorTargetingPhrasesEnum.FindACuisine]: {
        [LocalesEnum.En]: 'Find a cuisine',
        [LocalesEnum.Fr]: 'Rechercher un menu',
    },
    [OperatorTargetingPhrasesEnum.SaveSearchModalPlaceholder]: {
        [LocalesEnum.En]: 'Enter a name for your search',
        [LocalesEnum.Fr]: 'Saisissez le nom de votre requête',
    },
    [OperatorTargetingPhrasesEnum.SaveSearchSuccess]: {
        [LocalesEnum.En]: 'Search saved successfully!',
        [LocalesEnum.Fr]: 'Requête sauvegardée avec succès !',
    },
    [OperatorTargetingPhrasesEnum.Country]: {
        [LocalesEnum.En]: 'Country',
        [LocalesEnum.Fr]: 'pays',
    },
    [OperatorTargetingPhrasesEnum.Select]: {
        [LocalesEnum.En]: 'Select',
        [LocalesEnum.Fr]: 'Sélectionner une ou plusieurs',
    },
    [OperatorTargetingPhrasesEnum.SelectCountry]: {
        [LocalesEnum.En]: 'Select Country',
        [LocalesEnum.Fr]: 'Sélectionnez un pays',
    },
    [OperatorTargetingPhrasesEnum.SelectPlural]: {
        [LocalesEnum.En]: '(s)',
        [LocalesEnum.Fr]: 's',
    },
    [OperatorTargetingPhrasesEnum.FilterFoodTagPlaceholder]: {
        [LocalesEnum.En]: 'Search all food tags',
        [LocalesEnum.Fr]: 'Chercher dans tous les mots-clés cuisine',
    },
    [OperatorTargetingPhrasesEnum.FilterPlaceTagPlaceholder]: {
        [LocalesEnum.En]: 'Search all place tags',
        [LocalesEnum.Fr]: 'Chercher dans tous les mots-clés lieu',
    },
    [OperatorTargetingPhrasesEnum.AverageCheck]: {
        [LocalesEnum.En]: 'Average Check',
        [LocalesEnum.Fr]: 'Ticket moyen repas',
    },
    [OperatorTargetingResultsPhrasesEnum.List]: {
        [LocalesEnum.En]: 'List',
        [LocalesEnum.Fr]: 'Liste',
    },
    [OperatorTargetingResultsPhrasesEnum.Map]: {
        [LocalesEnum.En]: 'Map',
        [LocalesEnum.Fr]: 'Carte',
    },
    [OperatorTargetingResultsPhrasesEnum.Summary]: {
        [LocalesEnum.En]: 'Summary',
        [LocalesEnum.Fr]: 'Résumé',
    },
    [OperatorTargetingResultsPhrasesEnum.Counts]: {
        [LocalesEnum.En]: 'Counts',
        [LocalesEnum.Fr]: 'Décomptes',
    },
    [OperatorTargetingResultsPhrasesEnum.Percents]: {
        [LocalesEnum.En]: 'Percents',
        [LocalesEnum.Fr]: 'Pourcentages',
    },
    [OperatorTargetingResultsPhrasesEnum.ChainUnits]: {
        [LocalesEnum.En]: 'Chain Units',
        [LocalesEnum.Fr]: 'Unités de chaîne',
    },
    [OperatorTargetingResultsPhrasesEnum.IndependentUnits]: {
        [LocalesEnum.En]: 'Independent Units',
        [LocalesEnum.Fr]: 'Unités indépendantes',
    },
    [OperatorTargetingResultsPhrasesEnum.ChainsVsIndependents]: {
        [LocalesEnum.En]: 'Chains vs Independents',
        [LocalesEnum.Fr]: 'Chaînes vs Indépendants',
    },
    [OperatorTargetingResultsPhrasesEnum.ViewFullProfile]: {
        [LocalesEnum.En]: 'view full profile',
        [LocalesEnum.Fr]: 'voir le profil complet',
    },
    [OperatorTargetingResultsPhrasesEnum.FoodAndBeverageCuisines]: {
        [LocalesEnum.En]: 'Food & Beverage Cuisines',
        [LocalesEnum.Fr]: 'Types de cuisine',
    },
    [OperatorTargetingResultsPhrasesEnum.RegionalCuisines]: {
        [LocalesEnum.En]: 'Regional Cuisines',
        [LocalesEnum.Fr]: 'Cuisines régionales',
    },
    [FilterTypeEnum.Location]: {
        [LocalesEnum.En]: 'Location',
        [LocalesEnum.Fr]: 'Localisation',
    },
    [FilterTypeEnum.Segments]: {
        [LocalesEnum.En]: 'Segments',
        [LocalesEnum.Fr]: 'Segments',
    },
    [FilterTypeEnum.RestaurantsAndBars]: {
        [LocalesEnum.En]: 'Restaurants & Bars',
        [LocalesEnum.Fr]: 'Restaurants & Bars',
    },
    [FilterTypeEnum.SegmentsCriteria]: {
        [LocalesEnum.En]: 'Segment Criteria',
        [LocalesEnum.Fr]: 'Critères',
    },
    [FilterTypeEnum.LodgingAndRecreation]: {
        [LocalesEnum.En]: 'Lodging & Recreation',
        [LocalesEnum.Fr]: 'Hébergement & loisirs',
    },
    [FilterTypeEnum.Retail]: {
        [LocalesEnum.En]: 'Retail',
        [LocalesEnum.Fr]: 'Revente au détail',
    },
    [FilterTypeEnum.NonCommercial]: {
        [LocalesEnum.En]: 'Non-Commercial',
        [LocalesEnum.Fr]: 'Collectivités',
    },
    [FilterTypeEnum.Commercial]: {
        [LocalesEnum.En]: 'Commercial',
        [LocalesEnum.Fr]: 'Commercial',
    },
    [FilterTypeEnum.Tags]: {
        [LocalesEnum.En]: 'Tags',
        [LocalesEnum.Fr]: 'Mots-clés',
    },
    [FilterTypeEnum.General]: {
        [LocalesEnum.En]: 'General',
        [LocalesEnum.Fr]: 'Général',
    },
    [FilterTypeEnum.ChainIndependent]: {
        [LocalesEnum.En]: 'Chain / Independent',
        [LocalesEnum.Fr]: 'Chaine / Indépendant',
    },
    [FilterTypeEnum.RecentlyAdded]: {
        [LocalesEnum.En]: 'Recently Added',
        [LocalesEnum.Fr]: 'Ajouté récemment',
    },
    [FilterTypeEnum.Business]: {
        [LocalesEnum.En]: 'Business',
        [LocalesEnum.Fr]: 'Informations sur l\'établissement',
    },
    [FilterTypeEnum.NumberOfEmployees]: {
        [LocalesEnum.En]: 'Number of Employees',
        [LocalesEnum.Fr]: 'Nombre d\'employés',
    },
    [FilterTypeEnum.SavedSearches]: {
        [LocalesEnum.En]: 'Saved Searches',
        [LocalesEnum.Fr]: 'Requêtes sauvegardées',
    },
    [FilterTypeEnum.OtherFoodservice]: {
        [LocalesEnum.En]: 'Other Foodservice',
        [LocalesEnum.Fr]: 'Divers',
    },
    [FilterTypeEnum.CuisineTypes]: {
        [LocalesEnum.En]: 'Cuisine Types',
        [LocalesEnum.Fr]: 'Menu Types',
    },
    [FilterTypeEnum.NumberofRooms]: {
        [LocalesEnum.En]: 'Number of Rooms',
        [LocalesEnum.Fr]: 'Nombre de chambres',
    },
    [FilterTypeEnum.ServicesOffered]: {
        [LocalesEnum.En]: 'Services Offered',
        [LocalesEnum.Fr]: 'Services offerts',
    },
    [FilterTypeEnum.Recreation]: {
        [LocalesEnum.En]: 'Recreation',
        [LocalesEnum.Fr]: 'Loisirs',
    },
    [FilterTypeEnum.EventVenueCapacity]: {
        [LocalesEnum.En]: 'Event Venue Capacity',
        [LocalesEnum.Fr]: 'Capacité de l\'événement',
    },
    [FilterTypeEnum.Lodging]: {
        [LocalesEnum.En]: 'Lodging',
        [LocalesEnum.Fr]: 'Hébergement',
    },
    [FilterTypeEnum.ClientStatus]: {
        [LocalesEnum.En]: 'Client Status',
        [LocalesEnum.Fr]: 'Statut client',
    },
    [FilterTypeEnum.ClientFilters]: {
        [LocalesEnum.En]: 'Client Filters',
        [LocalesEnum.Fr]: 'Filtres client',
    },
    [FilterTypeEnum.TotalPurchasePotential]: {
        [LocalesEnum.En]: 'Total Purchase Potential',
        [LocalesEnum.Fr]: 'Potentiel d\'achat total',
    },
    [FilterTypeEnum.OperatorsWithoutEmployeeCount]: {
        [LocalesEnum.En]: 'Include operators without employee count data',
        [LocalesEnum.Fr]: 'Inclure les établissements sans nombre d\'employés',
    },
    [FilterTypeEnum.OperatorsWithNoEmployeeData]: {
        [LocalesEnum.En]: 'Include operators with no employment data',
        [LocalesEnum.Fr]: 'Inclure les établissements sans nombre d\'employés',
    },
    [FilterTypeEnum.Regional]: {
        [LocalesEnum.En]: 'Regional',
        [LocalesEnum.Fr]: 'Régions',
    },
    [FilterTypeEnum.Radius]: {
        [LocalesEnum.En]: 'Radius',
        [LocalesEnum.Fr]: 'Périmètre',
    },
    [DisplayDrawerEnum.AvailableColumns]: {
        [LocalesEnum.En]: 'Available Columns',
        [LocalesEnum.Fr]: 'Available Columns [fr]',
    },
    [DisplayDrawerEnum.SelectedColumns]: {
        [LocalesEnum.En]: 'Selected Columns',
        [LocalesEnum.Fr]: 'Selected Columns [fr]',
    },
    [DisplayDrawerEnum.DragToReorder]: {
        [LocalesEnum.En]: 'Drag to reorder',
        [LocalesEnum.Fr]: 'Drag to reorder [fr]',
    },
    [DisplayDrawerEnum.SaveAsDefaultColumns]: {
        [LocalesEnum.En]: 'Save as default columns',
        [LocalesEnum.Fr]: 'Save as default columns [fr]',
    },
    [DisplayDrawerEnum.ResetToDefaultColumns]: {
        [LocalesEnum.En]: 'Reset to default columns',
        [LocalesEnum.Fr]: 'Reset to default columns [fr]',
    },
    [ErroPagePhrasesEnum.NotFound]: {
        [LocalesEnum.En]: 'Page Not Found',
        [LocalesEnum.Fr]: 'Page Introuvable',
    },
    [ErroPagePhrasesEnum.NotFoundTitle]: {
        [LocalesEnum.En]: 'Oops! Page Not Found',
        [LocalesEnum.Fr]: 'Oups! Page introuvable',
    },
    [ErroPagePhrasesEnum.NotFoundDescription]: {
        [LocalesEnum.En]: 'We’re sorry, the page you are looking for is not available.',
        [LocalesEnum.Fr]: 'Nous sommes désolés, la page que vous cherchez n\'existe pas',
    },
    [ErroPagePhrasesEnum.AccessDenied]: {
        [LocalesEnum.En]: 'Access Denied',
        [LocalesEnum.Fr]: 'Accès Refusé',
    },
    [ErroPagePhrasesEnum.AccessDeniedTitle]: {
        [LocalesEnum.En]: 'Oops! Access Denied',
        [LocalesEnum.Fr]: 'Oups! Accès refusé',
    },
    [ErroPagePhrasesEnum.AccessDeniedDescription]: {
        [LocalesEnum.En]: 'We’re sorry, you do not have access to this page or resource.',
        [LocalesEnum.Fr]: 'Nous sommes désolés, vous n\'avez pas accès à cette page ou à cette information',
    },
    [ErroPagePhrasesEnum.GoBackHome]: {
        [LocalesEnum.En]: 'Return Home',
        [LocalesEnum.Fr]: 'Retourner à la page d\'accueil',
    },
    [OperatorProfilePhrasesEnum.SearchResults]: {
        [LocalesEnum.En]: 'Search Results',
        [LocalesEnum.Fr]: 'Résultats de recherche',
    },
    [OperatorProfilePhrasesEnum.ChainUnit]: {
        [LocalesEnum.En]: 'Chain Unit',
        [LocalesEnum.Fr]: 'Point de vente chaîné',
    },
    [OperatorProfilePhrasesEnum.ConfidenceLevel]: {
        [LocalesEnum.En]: 'Confidence Level',
        [LocalesEnum.Fr]: 'Niveau de confiance',
    },
    [OperatorProfilePhrasesEnum.Hours]: {
        [LocalesEnum.En]: 'Hours',
        [LocalesEnum.Fr]: 'Horaires',
    },
    [OperatorProfilePhrasesEnum.OperatingHours]: {
        [LocalesEnum.En]: 'Operating Hours',
        [LocalesEnum.Fr]: 'Horaires d\'ouverture',
    },
    [OperatorProfilePhrasesEnum.OpenNow]: {
        [LocalesEnum.En]: 'Open Now',
        [LocalesEnum.Fr]: 'Ouvert maintenant',
    },
    [OperatorProfilePhrasesEnum.ClosedNow]: {
        [LocalesEnum.En]: 'Closed Now',
        [LocalesEnum.Fr]: 'Fermé maintenant',
    },
    [OperatorProfilePhrasesEnum.ThirdPartyDelivery]: {
        [LocalesEnum.En]: '3rd Party Delivery',
        [LocalesEnum.Fr]: 'Liens de livraison',
    },
    [OperatorProfilePhrasesEnum.Details]: {
        [LocalesEnum.En]: 'Details',
        [LocalesEnum.Fr]: 'Détails',
    },
    [OperatorProfilePhrasesEnum.Menu]: {
        [LocalesEnum.En]: 'Menu',
        [LocalesEnum.Fr]: 'Menu',
    },
    [OperatorProfilePhrasesEnum.IndependentUnit]: {
        [LocalesEnum.En]: 'Independent Unit',
        [LocalesEnum.Fr]: 'Point de vente indépendant',
    },
    [OperatorProfilePhrasesEnum.DistrictSystemUnit]: {
        [LocalesEnum.En]: 'District/System Unit',
        [LocalesEnum.Fr]: 'Groupement de collectivité unité',
    },
    [OperatorProfilePhrasesEnum.McoUnit]: {
        [LocalesEnum.En]: 'MCO Unit',
        [LocalesEnum.Fr]: 'Unité multi-concepts',
    },
    [OperatorProfilePhrasesEnum.Area]: {
        [LocalesEnum.En]: 'Area',
        [LocalesEnum.Fr]: 'Zone',
    },
    [OperatorProfilePhrasesEnum.Urban]: {
        [LocalesEnum.En]: 'Urban',
        [LocalesEnum.Fr]: 'Urbain',
    },
    [OperatorProfilePhrasesEnum.Suburban]: {
        [LocalesEnum.En]: 'Suburban',
        [LocalesEnum.Fr]: 'Suburbain',
    },
    [OperatorProfilePhrasesEnum.Rural]: {
        [LocalesEnum.En]: 'Rural',
        [LocalesEnum.Fr]: 'Rural',
    },
    [OperatorProfilePhrasesEnum.OpBusiness]: {
        [LocalesEnum.En]: 'Business',
        [LocalesEnum.Fr]: 'Informations sur l\'établissement',
    },
    [OperatorProfilePhrasesEnum.FoundedIn]: {
        [LocalesEnum.En]: 'Founded In',
        [LocalesEnum.Fr]: 'Fondé en',
    },
    [OperatorProfilePhrasesEnum.YearsinBusiness]: {
        [LocalesEnum.En]: 'Years in Business',
        [LocalesEnum.Fr]: 'Ans d\'existence',
    },
    [OperatorProfilePhrasesEnum.Employees]: {
        [LocalesEnum.En]: 'Employees',
        [LocalesEnum.Fr]: 'Nombre d\'employés',
    },
    [OperatorProfilePhrasesEnum.PriceLevel]: {
        [LocalesEnum.En]: 'Price Level',
        [LocalesEnum.Fr]: 'Ticket moyen repas',
    },
    [OperatorProfilePhrasesEnum.AverageDailyMeals]: {
        [LocalesEnum.En]: 'Average Daily Meals',
        [LocalesEnum.Fr]: 'Nombre de repas par jour',
    },
    [OperatorProfilePhrasesEnum.EstimatedAnnualSales]: {
        [LocalesEnum.En]: 'Estimated Annual Sales',
        [LocalesEnum.Fr]: 'Chiffre d\'affaires estimé',
    },
    [OperatorProfilePhrasesEnum.RoomCount]: {
        [LocalesEnum.En]: 'Room Count',
        [LocalesEnum.Fr]: 'Nombre de chambres',
    },
    [OperatorProfilePhrasesEnum.HotelClass]: {
        [LocalesEnum.En]: 'Hotel Class',
        [LocalesEnum.Fr]: 'Classe de l\'hôtel',
    },
    [OperatorProfilePhrasesEnum.Star]: {
        [LocalesEnum.En]: 'Star',
        [LocalesEnum.Fr]: 'Étoile',
    },
    [OperatorProfilePhrasesEnum.RoomPrice]: {
        [LocalesEnum.En]: 'Room Price',
        [LocalesEnum.Fr]: 'Prix de la chambre',
    },
    [OperatorProfilePhrasesEnum.NumberofBeds]: {
        [LocalesEnum.En]: 'Number of Beds',
        [LocalesEnum.Fr]: 'Nombre de lits',
    },
    [OperatorProfilePhrasesEnum.PatientDailyCensus]: {
        [LocalesEnum.En]: 'Patient Daily Census',
        [LocalesEnum.Fr]: 'Recensement quotidien des patients',
    },
    [OperatorProfilePhrasesEnum.PurchasePotential]: {
        [LocalesEnum.En]: 'Purchase Potential',
        [LocalesEnum.Fr]: 'Potentiel d\'achat',
    },
    [OperatorProfilePhrasesEnum.Total]: {
        [LocalesEnum.En]: 'Total',
        [LocalesEnum.Fr]: 'Total',
    },
    [OperatorProfilePhrasesEnum.FoodRelated]: {
        [LocalesEnum.En]: 'Food',
        [LocalesEnum.Fr]: 'Alimentaire',
    },
    [OperatorProfilePhrasesEnum.FoodCuisine]: {
        [LocalesEnum.En]: 'Food',
        [LocalesEnum.Fr]: 'Cuisine',
    },
    [OperatorProfilePhrasesEnum.FoodTags]: {
        [LocalesEnum.En]: 'Food Tags',
        [LocalesEnum.Fr]: 'Cuisine Tags',
    },
    [OperatorProfilePhrasesEnum.Place]: {
        [LocalesEnum.En]: 'Place',
        [LocalesEnum.Fr]: 'Lieu',
    },
    [OperatorProfilePhrasesEnum.PlaceTags]: {
        [LocalesEnum.En]: 'Place Tags',
        [LocalesEnum.Fr]: 'Lieu Tags',
    },
    [OperatorProfilePhrasesEnum.Beverage]: {
        [LocalesEnum.En]: 'Beverage',
        [LocalesEnum.Fr]: 'Boissons',
    },
    [OperatorProfilePhrasesEnum.Disposable]: {
        [LocalesEnum.En]: 'Disposable',
        [LocalesEnum.Fr]: 'Equipements jetables',
    },
    [OperatorProfilePhrasesEnum.Alcohol]: {
        [LocalesEnum.En]: 'Alcohol',
        [LocalesEnum.Fr]: 'Alcool',
    },
    [OperatorProfilePhrasesEnum.AlcoholUnspecified]: {
        [LocalesEnum.En]: 'Serves Alcohol (unspecified)',
        [LocalesEnum.Fr]: 'Service d\'alcool (indéterminé)',
    },
    [OperatorProfilePhrasesEnum.NonAlcohol]: {
        [LocalesEnum.En]: 'Non-Alcohol',
        [LocalesEnum.Fr]: 'Non-Alcool',
    },
    [OperatorProfilePhrasesEnum.Dry]: {
        [LocalesEnum.En]: 'Dry',
        [LocalesEnum.Fr]: 'Sec',
    },
    [OperatorProfilePhrasesEnum.Fresh]: {
        [LocalesEnum.En]: 'Fresh',
        [LocalesEnum.Fr]: 'Frais',
    },
    [OperatorProfilePhrasesEnum.Frozen]: {
        [LocalesEnum.En]: 'Frozen',
        [LocalesEnum.Fr]: 'Congelé',
    },
    [OperatorProfilePhrasesEnum.Protein]: {
        [LocalesEnum.En]: 'Protein',
        [LocalesEnum.Fr]: 'Protéine',
    },
    [OperatorProfilePhrasesEnum.Service]: {
        [LocalesEnum.En]: 'Service',
        [LocalesEnum.Fr]: 'Services',
    },
    [OperatorProfilePhrasesEnum.Offers]: {
        [LocalesEnum.En]: 'Offers',
        [LocalesEnum.Fr]: 'Cet établissement propose',
    },
    [OperatorProfilePhrasesEnum.BanquetFacility]: {
        [LocalesEnum.En]: 'Banquet Facility',
        [LocalesEnum.Fr]: 'Salle de banquet',
    },
    [OperatorProfilePhrasesEnum.ComplimentaryBreakfast]: {
        [LocalesEnum.En]: 'Complimentary Breakfast',
        [LocalesEnum.Fr]: 'Petit-déjeuner gratuit',
    },
    [OperatorProfilePhrasesEnum.MeetingRooms]: {
        [LocalesEnum.En]: 'Meeting Rooms',
        [LocalesEnum.Fr]: 'Salles de réunion',
    },
    [OperatorProfilePhrasesEnum.RoomService]: {
        [LocalesEnum.En]: 'Room Service',
        [LocalesEnum.Fr]: 'Service en chambre',
    },
    [OperatorProfilePhrasesEnum.DineIn]: {
        [LocalesEnum.En]: 'Dine In',
        [LocalesEnum.Fr]: 'Sur place',
    },
    [OperatorProfilePhrasesEnum.Takeout]: {
        [LocalesEnum.En]: 'Takeout',
        [LocalesEnum.Fr]: 'A emporter',
    },
    [OperatorProfilePhrasesEnum.Cafeteria]: {
        [LocalesEnum.En]: 'Cafeteria',
        [LocalesEnum.Fr]: 'Cafétéria',
    },
    [OperatorProfilePhrasesEnum.Delivery]: {
        [LocalesEnum.En]: 'Delivery',
        [LocalesEnum.Fr]: 'En livraison',
    },
    [OperatorProfilePhrasesEnum.DriveThru]: {
        [LocalesEnum.En]: 'Drive-Thru',
        [LocalesEnum.Fr]: 'Service au volant',
    },
    [OperatorProfilePhrasesEnum.Curbside]: {
        [LocalesEnum.En]: 'Curbside',
        [LocalesEnum.Fr]: 'Curbside',
    },
    [OperatorProfilePhrasesEnum.High]: {
        [LocalesEnum.En]: 'High',
        [LocalesEnum.Fr]: 'Fort',
    },
    [OperatorProfilePhrasesEnum.Low]: {
        [LocalesEnum.En]: 'Low',
        [LocalesEnum.Fr]: 'Faible',
    },
    [OperatorProfilePhrasesEnum.Medium]: {
        [LocalesEnum.En]: 'Medium',
        [LocalesEnum.Fr]: 'Moyen',
    },
    [OperatorProfilePhrasesEnum.EstMealsPerDay]: {
        [LocalesEnum.En]: 'Estimated Meals Per Day',
        [LocalesEnum.Fr]: 'Repas estimés par jour',
    },
    [OperatorProfilePhrasesEnum.Catering]: {
        [LocalesEnum.En]: 'Catering',
        [LocalesEnum.Fr]: 'Traiteur',
    },
    [OperatorProfilePhrasesEnum.Reservations]: {
        [LocalesEnum.En]: 'Reservations',
        [LocalesEnum.Fr]: 'Réservation',
    },
    [OperatorProfilePhrasesEnum.KidsMenu]: {
        [LocalesEnum.En]: 'Kids Menu',
        [LocalesEnum.Fr]: 'Menu pour enfant',
    },
    [OperatorProfilePhrasesEnum.Eatertainment]: {
        [LocalesEnum.En]: 'Eatertainment',
        [LocalesEnum.Fr]: 'Eatertainment',
    },
    [OperatorProfilePhrasesEnum.DayParts]: {
        [LocalesEnum.En]: 'Day Parts',
        [LocalesEnum.Fr]: 'Moments de la journée',
    },
    [OperatorProfilePhrasesEnum.Breakfast]: {
        [LocalesEnum.En]: 'Breakfast',
        [LocalesEnum.Fr]: 'Petit-déjeuner',
    },
    [OperatorProfilePhrasesEnum.Lunch]: {
        [LocalesEnum.En]: 'Lunch',
        [LocalesEnum.Fr]: 'Déjeuner',
    },
    [OperatorProfilePhrasesEnum.Dinner]: {
        [LocalesEnum.En]: 'Dinner',
        [LocalesEnum.Fr]: 'Dîner',
    },
    [OperatorProfilePhrasesEnum.LateNight]: {
        [LocalesEnum.En]: 'Late Night',
        [LocalesEnum.Fr]: 'Tard dans la nuit',
    },
    [OperatorProfilePhrasesEnum.BeerWineOnly]: {
        [LocalesEnum.En]: 'Beer & Wine Only',
        [LocalesEnum.Fr]: 'Bière et vin uniquement',
    },
    [OperatorProfilePhrasesEnum.FullBar]: {
        [LocalesEnum.En]: 'Full Bar',
        [LocalesEnum.Fr]: 'Tout type d\'alcool',
    },
    [OperatorProfilePhrasesEnum.Management]: {
        [LocalesEnum.En]: 'Management',
        [LocalesEnum.Fr]: 'Gestion',
    },
    [OperatorProfilePhrasesEnum.ContractManaged]: {
        [LocalesEnum.En]: 'Contract-Managed',
        [LocalesEnum.Fr]: 'Concédé',
    },
    [OperatorProfilePhrasesEnum.SelfOperated]: {
        [LocalesEnum.En]: 'Self-Operated',
        [LocalesEnum.Fr]: 'Autogéré',
    },
    [OperatorProfilePhrasesEnum.GPO]: {
        [LocalesEnum.En]: 'Group Purchasing Organization',
        [LocalesEnum.Fr]: 'Centrale d\'achat',
    },
    [OperatorProfilePhrasesEnum.GasStation]: {
        [LocalesEnum.En]: 'Gas Station',
        [LocalesEnum.Fr]: 'Station service',
    },
    [OperatorProfilePhrasesEnum.PetrolLocation]: {
        [LocalesEnum.En]: 'Petrol Location',
        [LocalesEnum.Fr]: 'Avec station service',
    },
    [OperatorProfilePhrasesEnum.NonPetrolLocation]: {
        [LocalesEnum.En]: 'Non-Petrol Location',
        [LocalesEnum.Fr]: 'Sans station service',
    },
    [OperatorProfilePhrasesEnum.Experience]: {
        [LocalesEnum.En]: 'Experience',
        [LocalesEnum.Fr]: 'Expérience',
    },
    [OperatorProfilePhrasesEnum.AverageReviewRating]: {
        [LocalesEnum.En]: 'Average Review Rating',
        [LocalesEnum.Fr]: 'Score moyen des avis',
    },
    [OperatorProfilePhrasesEnum.TotalReviewCount]: {
        [LocalesEnum.En]: 'Total Review Count',
        [LocalesEnum.Fr]: 'Nombre total d\'avis',
    },
    [OperatorProfilePhrasesEnum.Reviews]: {
        [LocalesEnum.En]: 'reviews',
        [LocalesEnum.Fr]: 'avis',
    },
    [OperatorProfilePhrasesEnum.PopularityScore]: {
        [LocalesEnum.En]: 'Popularity Score',
        [LocalesEnum.Fr]: 'Score de popularité',
    },
    [OperatorProfilePhrasesEnum.Units]: {
        [LocalesEnum.En]: 'Units',
        [LocalesEnum.Fr]: 'Unités',
    },
    [OperatorProfilePhrasesEnum.VenueCapacity]: {
        [LocalesEnum.En]: 'Venue Capacity',
        [LocalesEnum.Fr]: 'Venue Capacity [fr]',
    },
    [OperatorProfilePhrasesEnum.EducationSector]: {
        [LocalesEnum.En]: 'Education Sector',
        [LocalesEnum.Fr]: 'Education Sector [fr]',
    },
    [OperatorProfilePhrasesEnum.NonCommercialSector]: {
        [LocalesEnum.En]: 'Non-Commercial Sector',
        [LocalesEnum.Fr]: 'Non-Commercial Sector [fr]',
    },
    [OperatorProfilePhrasesEnum.Enrollment]: {
        [LocalesEnum.En]: 'Enrollment',
        [LocalesEnum.Fr]: 'Enrollment [fr]',
    },
    [OperatorProfilePhrasesEnum.GradeLevels]: {
        [LocalesEnum.En]: 'Grade Levels',
        [LocalesEnum.Fr]: 'Type d\'école',
    },
    [OperatorProfilePhrasesEnum.HospitalType]: {
        [LocalesEnum.En]: 'Hospital Type',
        [LocalesEnum.Fr]: 'Type de soins',
    },
    [OperatorProfilePhrasesEnum.DetailedBusinessId]: {
        [LocalesEnum.En]: 'Detail Business Id',
        [LocalesEnum.Fr]: 'Siren',
    },
    [OperatorProfilePhrasesEnum.GeneralBusinessId]: {
        [LocalesEnum.En]: 'General Business Id',
        [LocalesEnum.Fr]: 'Siret',
    },
    [OperatorProfilePhrasesEnum.EducationFreeMeals]: {
        [LocalesEnum.En]: 'Education Free Meals',
        [LocalesEnum.Fr]: 'Education Free Meals [fr]',
    },
    [OperatorProfilePhrasesEnum.EducationReducedMeals]: {
        [LocalesEnum.En]: 'Education Reduced Meals',
        [LocalesEnum.Fr]: 'Education Reduced Meals [fr]',
    },
    [OperatorProfilePhrasesEnum.EducationReducedFreeMeals]: {
        [LocalesEnum.En]: 'Education Reduced + Free Meals',
        [LocalesEnum.Fr]: 'Education Reduced + Free Meals [fr]',
    },
    [OperatorProfilePhrasesEnum.HigherEducationStudentsMealplans]: {
        [LocalesEnum.En]: 'Higher Education Students on Mealplans',
        [LocalesEnum.Fr]: 'Higher Education Students on Mealplans [fr]',
    },
    [OperatorProfilePhrasesEnum.HigherEducationHousing]: {
        [LocalesEnum.En]: 'Higher Education Housing',
        [LocalesEnum.Fr]: 'Higher Education Housing [fr]',
    },
    [OperatorProfilePhrasesEnum.Open24Hours]: {
        [LocalesEnum.En]: '24 hours service',
        [LocalesEnum.Fr]: '24 hours service [fr]',
    },
    [OperatorProfilePhrasesEnum.ActiveBasePopulation]: {
        [LocalesEnum.En]: 'Active Base Population',
        [LocalesEnum.Fr]: 'Nombre de militaires actifs',
    },
    [OperatorProfilePhrasesEnum.PastTwoMonths]: {
        [LocalesEnum.En]: 'Past 60 days',
        [LocalesEnum.Fr]: 'Past 60 days [fr]',
    },
    [OperatorProfilePhrasesEnum.HostLocation]: {
        [LocalesEnum.En]: 'Host Location',
        [LocalesEnum.Fr]: 'Localisation de l\'hôte',
    },
    [OperatorProfilePhrasesEnum.StreetView]: {
        [LocalesEnum.En]: 'Street View',
        [LocalesEnum.Fr]: 'Vue de la rue',
    },
    [OperatorProfilePhrasesEnum.MapView]: {
        [LocalesEnum.En]: 'Map View',
        [LocalesEnum.Fr]: 'Vue de la carte',
    },
    [OperatorProfilePhrasesEnum.SocialLinks]: {
        [LocalesEnum.En]: 'Social Links',
        [LocalesEnum.Fr]: 'Liens Sociaux',
    },
    [RangeFiltersEnum.MoreThan]: {
        [LocalesEnum.En]: 'More than',
        [LocalesEnum.Fr]: 'Plus de',
    },
    [RangeFiltersEnum.LessThan]: {
        [LocalesEnum.En]: 'Less than',
        [LocalesEnum.Fr]: 'Moins de',
    },
    [RangeFiltersEnum.GreaterThan]: {
        [LocalesEnum.En]: 'Greater than',
        [LocalesEnum.Fr]: 'Plus de',
    },
    [RangeFiltersEnum.To]: {
        [LocalesEnum.En]: 'to',
        [LocalesEnum.Fr]: 'à',
    },
    [RangeFiltersEnum.Over]: {
        [LocalesEnum.En]: 'Over',
        [LocalesEnum.Fr]: 'Plus de',
    },
    [RangeFiltersEnum.Chains]: {
        [LocalesEnum.En]: 'Chains',
        [LocalesEnum.Fr]: 'Chaînes',
    },
    [RangeFiltersEnum.Independents]: {
        [LocalesEnum.En]: 'Independents',
        [LocalesEnum.Fr]: 'Indépendants',
    },
    [RangeFiltersEnum.Year]: {
        [LocalesEnum.En]: 'Year',
        [LocalesEnum.Fr]: 'an',
    },
    [RangeFiltersEnum.Years]: {
        [LocalesEnum.En]: 'Years',
        [LocalesEnum.Fr]: 'ans',
    },
    [ExportColumnHeadersEnum.ENTITY_TYPE]: {
        [LocalesEnum.En]: 'ENTITY_TYPE',
        [LocalesEnum.Fr]: 'TYPE_ENTITE',
    },
    [ExportColumnHeadersEnum.CONFIDENCE_LEVEL]: {
        [LocalesEnum.En]: 'CONFIDENCE_LEVEL',
        [LocalesEnum.Fr]: 'NIVEAU_DE_CONFIDENCE',
    },
    [ExportColumnHeadersEnum.OPERATOR_STATUS]: {
        [LocalesEnum.En]: 'OPERATOR_STATUS',
        [LocalesEnum.Fr]: 'STATUT_OPERATEUR',
    },
    [ExportColumnHeadersEnum.BUSINESS_NAME]: {
        [LocalesEnum.En]: 'BUSINESS_NAME',
        [LocalesEnum.Fr]: 'NOM_COMMERCIAL',
    },
    [ExportColumnHeadersEnum.PHONE]: {
        [LocalesEnum.En]: 'PHONE',
        [LocalesEnum.Fr]: 'TELEPHONE',
    },
    [ExportColumnHeadersEnum.WEBSITE]: {
        [LocalesEnum.En]: 'WEBSITE',
        [LocalesEnum.Fr]: 'SITE_INTERNET',
    },
    [ExportColumnHeadersEnum.SUB_CHANNEL]: {
        [LocalesEnum.En]: 'SUB_CHANNEL',
        [LocalesEnum.Fr]: 'SOUS_SECTEUR',
    },
    [ExportColumnHeadersEnum.CHANNEL]: {
        [LocalesEnum.En]: 'CHANNEL',
        [LocalesEnum.Fr]: 'SECTEUR',
    },
    [ExportColumnHeadersEnum.COUNTRY]: {
        [LocalesEnum.En]: 'COUNTRY',
        [LocalesEnum.Fr]: 'PAYS',
    },
    [ExportColumnHeadersEnum.ADDRESS_1]: {
        [LocalesEnum.En]: 'ADDRESS_1',
        [LocalesEnum.Fr]: 'ADRESSE_1',
    },
    [ExportColumnHeadersEnum.ADDRESS_2]: {
        [LocalesEnum.En]: 'ADDRESS_2',
        [LocalesEnum.Fr]: 'ADRESSE_2',
    },
    [ExportColumnHeadersEnum.ADDRESS_3]: {
        [LocalesEnum.En]: 'ADDRESS_3',
        [LocalesEnum.Fr]: 'ADRESSE_3',
    },
    [ExportColumnHeadersEnum.CITY]: {
        [LocalesEnum.En]: 'CITY',
        [LocalesEnum.Fr]: 'VILLE',
    },
    [ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_1]: {
        [LocalesEnum.En]: 'ADMINISTRATIVE_AREA_1',
        [LocalesEnum.Fr]: 'ZONE_ADMINISTRATIVE_1',
    },
    [ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_2]: {
        [LocalesEnum.En]: 'ADMINISTRATIVE_AREA_2',
        [LocalesEnum.Fr]: 'ZONE_ADMINISTRATIVE_2',
    },
    [ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_3]: {
        [LocalesEnum.En]: 'ADMINISTRATIVE_AREA_3',
        [LocalesEnum.Fr]: 'ZONE_ADMINISTRATIVE_3',
    },
    [ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_4]: {
        [LocalesEnum.En]: 'ADMINISTRATIVE_AREA_4',
        [LocalesEnum.Fr]: 'ZONE_ADMINISTRATIVE_4',
    },
    [ExportColumnHeadersEnum.POSTAL_CODE_1]: {
        [LocalesEnum.En]: 'POSTAL_CODE_1',
        [LocalesEnum.Fr]: 'CODE_POSTAL_1',
    },
    [ExportColumnHeadersEnum.LEGAL_NAME]: {
        [LocalesEnum.En]: 'LEGAL_NAME',
        [LocalesEnum.Fr]: 'NOM_LEGAL',
    },
    [ExportColumnHeadersEnum.ID_BUSINESS_DETAILED]: {
        [LocalesEnum.En]: 'ID_BUSINESS_DETAILED',
        [LocalesEnum.Fr]: 'SIRET',
    },
    [ExportColumnHeadersEnum.ID_BUSINESS_GENERAL]: {
        [LocalesEnum.En]: 'ID_BUSINESS_GENERAL',
        [LocalesEnum.Fr]: 'SIREN',
    },
    [ExportColumnHeadersEnum.YEARS_IN_BUSINESS_RANGE]: {
        [LocalesEnum.En]: 'YEARS_IN_BUSINESS_RANGE',
        [LocalesEnum.Fr]: 'ANNEES_EXISTENCE_TRANCHE',
    },
    [ExportColumnHeadersEnum.NUMBER_OF_EMPLOYEES_RANGE]: {
        [LocalesEnum.En]: 'NUMBER_OF_EMPLOYEES_RANGE',
        [LocalesEnum.Fr]: 'NOMBRE_EMPLOYES_TRANCHE',
    },
    [ExportColumnHeadersEnum.ANNUAL_SALES_RANGE]: {
        [LocalesEnum.En]: 'EST_ANNUAL_SALES_RANGE',
        [LocalesEnum.Fr]: 'CHIFFRE_AFFAIRES_ESTIME_TRANCHE',
    },
    [ExportColumnHeadersEnum.ANNUAL_SALES]: {
        [LocalesEnum.En]: 'ANNUAL_SALES',
        [LocalesEnum.Fr]: 'CHIFFRE_D_AFFAIRES',
    },
    [ExportColumnHeadersEnum.ANNUAL_SALES_TYPE]: {
        [LocalesEnum.En]: 'ANNUAL_SALES_TYPE',
        [LocalesEnum.Fr]: 'CHIFFRE_D_AFFAIRES_TYPE',
    },
    [ExportColumnHeadersEnum.OPERATING_HOURS]: {
        [LocalesEnum.En]: 'OPERATING_HOURS',
        [LocalesEnum.Fr]: 'HORAIRES',
    },
    [ExportColumnHeadersEnum.CHAIN_SYSTEM_ID]: {
        [LocalesEnum.En]: 'CHAIN_SYSTEM_ID',
        [LocalesEnum.Fr]: 'CHAINE_ID',
    },
    [ExportColumnHeadersEnum.CHAIN_SYSTEM_NAME]: {
        [LocalesEnum.En]: 'CHAIN_SYSTEM_NAME',
        [LocalesEnum.Fr]: 'CHAINE_NOM',
    },
    [ExportColumnHeadersEnum.CHAIN_SIZE_RANGE]: {
        [LocalesEnum.En]: 'CHAIN_SIZE_RANGE',
        [LocalesEnum.Fr]: 'CHAINE_TAILLE_TRANCHE',
    },
    [ExportColumnHeadersEnum.HOST_ID]: {
        [LocalesEnum.En]: 'HOST_ID',
        [LocalesEnum.Fr]: 'HOTE_ID',
    },
    [ExportColumnHeadersEnum.HOST_NAME]: {
        [LocalesEnum.En]: 'HOST_NAME',
        [LocalesEnum.Fr]: 'HOTE_NOM',
    },
    [ExportColumnHeadersEnum.HOST_NUMBER_OF_UNITS_LOCATED_WITHIN]: {
        [LocalesEnum.En]: 'HOST_NUMBER_OF_UNITS_LOCATED_WITHIN',
        [LocalesEnum.Fr]: 'HOTE_NOMBRE_UNITES_LOCALISEES_DEDANS',
    },
    [ExportColumnHeadersEnum.CUISINE_PRIMARY_DESC]: {
        [LocalesEnum.En]: 'CUISINE_PRIMARY_DESC',
        [LocalesEnum.Fr]: 'CUISINE_PRIMAIRE_DESC',
    },
    [ExportColumnHeadersEnum.CUISINE_SECONDARY_DESC]: {
        [LocalesEnum.En]: 'CUISINE_SECONDARY_DESC',
        [LocalesEnum.Fr]: 'CUISINE_SECONDAIRE_DESC',
    },
    [ExportColumnHeadersEnum.ESTIMATED_MEALS_PER_DAY_RANGE]: {
        [LocalesEnum.En]: 'EST_MEALS_PER_DAY_RANGE',
        [LocalesEnum.Fr]: 'NOMBRE_REPAS_PAR_JOUR_ESTIME',
    },
    [ExportColumnHeadersEnum.AVERAGE_CHECK_RANGE]: {
        [LocalesEnum.En]: 'AVERAGE_CHECK_RANGE',
        [LocalesEnum.Fr]: 'TICKET_MOYEN_REPAS',
    },
    [ExportColumnHeadersEnum.OFFERS_DELIVERY]: {
        [LocalesEnum.En]: 'OFFERS_DELIVERY',
        [LocalesEnum.Fr]: 'LIVRAISON',
    },
    [ExportColumnHeadersEnum.OFFERS_TAKEOUT]: {
        [LocalesEnum.En]: 'OFFERS_TAKEOUT',
        [LocalesEnum.Fr]: 'A_EMPORTER',
    },
    [ExportColumnHeadersEnum.NUMBER_OF_BEDS_RANGE]: {
        [LocalesEnum.En]: 'NUMBER_OF_BEDS_RANGE',
        [LocalesEnum.Fr]: 'NOMBRE_LITS_TRANCHE',
    },
    [ExportColumnHeadersEnum.LODGING_STAR_LEVEL]: {
        [LocalesEnum.En]: 'LODGING_STAR_LEVEL',
        [LocalesEnum.Fr]: 'NOMBRE_ETOILES_HEBERGEMENT',
    },
    [ExportColumnHeadersEnum.NUMBER_OF_ROOMS_RANGE]: {
        [LocalesEnum.En]: 'NUMBER_OF_ROOMS_RANGE',
        [LocalesEnum.Fr]: 'NOMBRE_CHAMBRES_TRANCHE',
    },
    [ExportColumnHeadersEnum.NON_COMMERCIAL_SECTOR]: {
        [LocalesEnum.En]: 'NON_COMMERCIAL_SECTOR',
        [LocalesEnum.Fr]: 'TYPE_COLLECTIVITES',
    },
    [ExportColumnHeadersEnum.EDUCATION_LEVELS]: {
        [LocalesEnum.En]: 'EDUCATION_LEVELS',
        [LocalesEnum.Fr]: 'NIVEAUX_EDUCATION',
    },
    [ExportColumnHeadersEnum.NUMBER_OF_STUDENTS_RANGE]: {
        [LocalesEnum.En]: 'NUMBER_OF_STUDENTS_RANGE',
        [LocalesEnum.Fr]: 'NOMBRE_ETUDIANTS_TRANCHE',
    },
    [ExportColumnHeadersEnum.REVIEW_RATING]: {
        [LocalesEnum.En]: 'REVIEW_RATING',
        [LocalesEnum.Fr]: 'AVIS_NOTE',
    },
    [ExportColumnHeadersEnum.REVIEW_COUNT]: {
        [LocalesEnum.En]: 'REVIEW_COUNT',
        [LocalesEnum.Fr]: 'AVIS_NOMBRE',
    },
    [ExportColumnHeadersEnum.DELIVERY_LINKS]: {
        [LocalesEnum.En]: 'DELIVERY_LINKS',
        [LocalesEnum.Fr]: 'LIENS_LIVRAISON',
    },
    [ExportColumnHeadersEnum.RESERVATION_LINKS]: {
        [LocalesEnum.En]: 'RESERVATION_LINKS',
        [LocalesEnum.Fr]: 'LIENS_RESERVATION',
    },
    [ExportColumnHeadersEnum.BOOKING_DOT_COM]: {
        [LocalesEnum.En]: 'BOOKING_DOT_COM',
        [LocalesEnum.Fr]: 'BOOKING',
    },
    [ExportColumnHeadersEnum.HOTELS_DOT_COM]: {
        [LocalesEnum.En]: 'HOTELS_DOT_COM',
        [LocalesEnum.Fr]: 'HOTELS_POINT_COM',
    },
    [ExportColumnHeadersEnum.POPULARITY_SCORE]: {
        [LocalesEnum.En]: 'POPULARITY_SCORE',
        [LocalesEnum.Fr]: 'SCORE_POPULARITE',
    },
    [ExportColumnHeadersEnum.MCO_NAME]: {
        [LocalesEnum.En]: 'MCO_NAME',
        [LocalesEnum.Fr]: 'MCO_NOM',
    },
    [ExportColumnHeadersEnum.HOURS_MONDAY]: {
        [LocalesEnum.En]: 'HOURS_MONDAY',
        [LocalesEnum.Fr]: 'HORAIRES_LUNDI',
    },
    [ExportColumnHeadersEnum.HOURS_TUESDAY]: {
        [LocalesEnum.En]: 'HOURS_TUESDAY',
        [LocalesEnum.Fr]: 'HORAIRES_MARDI',
    },
    [ExportColumnHeadersEnum.HOURS_WEDNESDAY]: {
        [LocalesEnum.En]: 'HOURS_WEDNESDAY',
        [LocalesEnum.Fr]: 'HORAIRES_MERCREDI',
    },
    [ExportColumnHeadersEnum.HOURS_THURSDAY]: {
        [LocalesEnum.En]: 'HOURS_THURSDAY',
        [LocalesEnum.Fr]: 'HORAIRES_JEUDI',
    },
    [ExportColumnHeadersEnum.HOURS_FRIDAY]: {
        [LocalesEnum.En]: 'HOURS_FRIDAY',
        [LocalesEnum.Fr]: 'HORAIRES_VENDREDI',
    },
    [ExportColumnHeadersEnum.HOURS_SATURDAY]: {
        [LocalesEnum.En]: 'HOURS_SATURDAY',
        [LocalesEnum.Fr]: 'HORAIRES_SAMEDI',
    },
    [ExportColumnHeadersEnum.HOURS_SUNDAY]: {
        [LocalesEnum.En]: 'HOURS_SUNDAY',
        [LocalesEnum.Fr]: 'HORAIRES_DIMANCHE',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_TOTAL]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_SUM_TOTAL',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_SOMME_TOTALE',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_FOOD]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_SUM_FOOD',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_SOMME_ALIMENTAIRE',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_BEVERAGE]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_SUM_BEVERAGE',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_SOMME_BOISSONS',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_FOOD_AND_BEVERAGE]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_SUM_FOOD_AND_BEVERAGE',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_SOMME_ALIMENTAIRE_ET_BOISSONS',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DISPOSABLE]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_DISPOSABLE',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_EQUIPEMENT_JETABLE',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DRY]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_DRY',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_SEC',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FRESH]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_FRESH',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_FRAIS',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FROZEN]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_FROZEN',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_SURGELE',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_NON_ALCOHOL]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_NON_ALCOHOL',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_BOISSONS_SANS_ALCOOL',
    },
    [ExportColumnHeadersEnum.PURCHASE_POTENTIAL_ALCOHOL]: {
        [LocalesEnum.En]: 'PURCHASE_POTENTIAL_ALCOHOL',
        [LocalesEnum.Fr]: 'POTENTIEL_ACHAT_BOISSONS_AVEC_ALCOOL',
    },
    [ExportColumnHeadersEnum.TAGS_FOOD]: {
        [LocalesEnum.En]: 'TAGS_FOOD',
        [LocalesEnum.Fr]: 'TAGS_CUISINE',
    },
    [ExportColumnHeadersEnum.TAGS_PLACE]: {
        [LocalesEnum.En]: 'TAGS_PLACE',
        [LocalesEnum.Fr]: 'TAGS_LIEU',
    },
    [ExportColumnHeadersEnum.CLIENT_STATUS]: {
        [LocalesEnum.En]: 'CLIENT_STATUS',
        [LocalesEnum.Fr]: 'STATUT_CLIENT',
    },
};
