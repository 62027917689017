import { useLocale } from '@core/utils/locale';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { usePermissions } from '@core/hooks/usePermissions';
import { capitalize, generateAnchorId } from '@core/utils/string';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { FilterTypeEnum, OperatorTargetingPhrasesEnum } from '@enums/localeEnum';
import DropdownSelect from '@components/utility/Inputs/Dropdowns/DropdownSelect/DropdownSelect';

import PostalCode from './PostalCode';
import AdminAreaOne from './AdminAreaOne';
import AdminAreaTwo from './AdminAreaTwo';
import { LocationFieldsEnum, useLocationCriteria } from '../../../hooks/useLocationCriteria';

const RegionalCriteria = () => {
    const { t } = useLocale();
    const { hasPermission } = usePermissions();
    const {
        countryDropdownOptions,
        locationFields,
        resetCriterionParamsOnCountryChange,
        selectedCountry
    } =
        useLocationCriteria();

    const isAdminArea2Enabled =
        locationFields?.[LocationFieldsEnum.ADMIN_AREA_2] &&
        hasPermission('administrative_area2') &&
        CountryCodesEnum.US != selectedCountry?.value &&
        CountryCodesEnum.CA != selectedCountry?.value;

    return (
        <div className="CriteriaContent__section">
            <Widget
                type={WidgetType.Section}
                className="criteria"
                id={generateAnchorId(t(FilterTypeEnum.Regional))}
                dataObservable
            >
                <Heading variant={HeadingVariant.H4}>{t(OperatorTargetingPhrasesEnum.RegionalCriteria)}</Heading>
                <div className="CriteriaContent__columns col-1">
                    <div className="CriteriaContent__column col-1 LocationContent__input-container">
                        <DropdownSelect
                            options={countryDropdownOptions}
                            placeholder={capitalize(t(OperatorTargetingPhrasesEnum.Country))}
                            selectedOption={selectedCountry}
                            onOptionSelected={resetCriterionParamsOnCountryChange}
                            inputLabel={capitalize(t(OperatorTargetingPhrasesEnum.Country))}
                            testId="DropdownSelectCountry"
                        />
                    </div>
                </div>
                {selectedCountry && (
                    <>
                        <hr />
                        <div className="CriteriaContent__columns col-2">
                            {locationFields?.[LocationFieldsEnum.ADMIN_AREA_1] &&
                                hasPermission('administrative_area1') && (
                                <div className="LocationContent__input-container">
                                    <AdminAreaOne
                                        inputLabel={locationFields[LocationFieldsEnum.ADMIN_AREA_1]}
                                        country={selectedCountry?.value}
                                    />
                                </div>
                            )}
                            {isAdminArea2Enabled && (
                                <div className="LocationContent__input-container">
                                    <AdminAreaTwo
                                        inputLabel={locationFields[LocationFieldsEnum.ADMIN_AREA_2]}
                                        country={selectedCountry?.value}
                                    />
                                </div>
                            )}
                            {locationFields?.[LocationFieldsEnum.POSTAL_CODE] && (
                                <div className="LocationContent__input-container">
                                    <PostalCode
                                        inputLabel={locationFields[LocationFieldsEnum.POSTAL_CODE]}
                                        country={selectedCountry?.value as CountryCodesEnum}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Widget>
        </div>
    );
};

export default RegionalCriteria;
