import { FC } from 'react';

import Text, { TextVariant } from '@components/ui/Typography/Text';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

const ServiceList: FC<{
    services: string[];
    title: string;
}> = ({
    services,
    title
}) => {
    if (!services.length) return null;

    return (
        <div style={{ display: 'inline-flex', marginRight: '32px' }}>
            <div>
                <Text variant={TextVariant.B2} testId={`heading${title}`}>
                    {title}
                </Text>
                <ul className="ProfileDetails__service-list">
                    {services.map((service) => (
                        <li key={service}>
                            <Heading variant={HeadingVariant.H5} testId={`testService${service}`}>
                                {service}
                            </Heading>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ServiceList;
