import { createContext, ReactNode, useContext } from 'react';

import useOperatorResultsList, { useOperatorResultsListReturnType } from './hooks/useOperatorResultsList';
import useGetOperatorSummaryData, { useGetOperatorSummaryDataReturnType } from './hooks/useGetOperatorSummaryData';

type OperatorTargetingResultsProviderType = {
    operatorResultsList: useOperatorResultsListReturnType;
    summaryData: useGetOperatorSummaryDataReturnType;
};

export const OperatorTargetingResultsContext = createContext<OperatorTargetingResultsProviderType | undefined>(
    undefined,
);

export function OperatorTargetingResultsProvider({ children }: { children: ReactNode }) {
    const operatorResultsList = useOperatorResultsList();
    const summaryData = useGetOperatorSummaryData();

    return (
        <OperatorTargetingResultsContext.Provider value={{ operatorResultsList, summaryData }}>
            {children}
        </OperatorTargetingResultsContext.Provider>
    );
}

export function useOperatorTargetingResultsContext() {
    const context = useContext(OperatorTargetingResultsContext);
    if (context === undefined) {
        throw new Error('useOperatorTargetingResultsContext must be used within a OperatorTargetingResultsProvider');
    }
    return context;
}
