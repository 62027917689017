import { FC, useMemo } from 'react';

import { useLocale } from '@core/utils/locale';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

import { OperatorDetails } from '@graphql/generated/graphql';
import { IconRatingStar, IconRatingStarEmpty } from '@assets/icons';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { OperatorRangesEnum, useOperatorRanges } from '@pages/OperatorProfile/OperatorProfile.helpers';

import BusinessInfo from './BusinessInfo';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';
import { replaceRouteParam } from '@core/utils/string';
import { RoutesEnum } from '@core/enums/RoutesEnum';

const BUSINESS_FIELDS: Array<{
    field: keyof OperatorDetails;
    title: OperatorProfilePhrasesEnum;
}> = [
    { field: 'est_meals_per_day', title: OperatorProfilePhrasesEnum.AverageDailyMeals },
    { field: 'lodging_room_count', title: OperatorProfilePhrasesEnum.RoomCount },
    { field: 'venue_capacity', title: OperatorProfilePhrasesEnum.VenueCapacity },
    { field: 'non_commercial_sector', title: OperatorProfilePhrasesEnum.NonCommercialSector },
    { field: 'education_enrollment', title: OperatorProfilePhrasesEnum.Enrollment },
    { field: 'education_grade_levels', title: OperatorProfilePhrasesEnum.GradeLevels },
    { field: 'hospital_type', title: OperatorProfilePhrasesEnum.HospitalType },
    { field: 'healthcare_number_of_beds', title: OperatorProfilePhrasesEnum.NumberofBeds },
    { field: 'healthcare_daily_patient_count', title: OperatorProfilePhrasesEnum.PatientDailyCensus },
    { field: 'business_general_id', title: OperatorProfilePhrasesEnum.GeneralBusinessId },
    { field: 'business_detail_id', title: OperatorProfilePhrasesEnum.DetailedBusinessId },
    { field: 'education_free_meals', title: OperatorProfilePhrasesEnum.EducationFreeMeals },
    { field: 'education_reduced_meals', title: OperatorProfilePhrasesEnum.EducationReducedMeals },
    { field: 'education_free_and_reduced_meals', title: OperatorProfilePhrasesEnum.EducationReducedFreeMeals },
    {
        field: 'higher_education_students_on_mealplans',
        title: OperatorProfilePhrasesEnum.HigherEducationStudentsMealplans,
    },
    { field: 'active_base_population', title: OperatorProfilePhrasesEnum.ActiveBasePopulation },
];

const Business: FC<{
    data: OperatorDetails;
}> = ({ data }) => {
    const { t } = useLocale();

    const renderSimpleBusinessInfo = useMemo(() => {
        return BUSINESS_FIELDS.map(({
            field,
            title
        }) => {
            const fieldData = data?.[field];

            return (
                <BusinessInfo key={fieldData} title={t(title)} testId={title} renderCondition={!!fieldData}>
                    {fieldData}
                </BusinessInfo>
            );
        });
    }, [data, t]);

    const operatorRanges = useOperatorRanges(data?.ranges, data?.location?.country as CountryCodesEnum);
    const yearFounded = data?.date_founded ? new Date(data.date_founded).getUTCFullYear() : null;

    const calculatedYearsInBusiness = () => {
        const foundedDate = data?.date_founded ? new Date(data.date_founded) : null;
        const currentYear = new Date().getUTCFullYear();
        const foundedYear = foundedDate?.getUTCFullYear() ?? 0;

        if (!foundedDate || !foundedYear) {
            return 0;
        }

        const yearsInBusiness =
            currentYear -
            foundedYear -
            (new Date(currentYear, foundedDate.getUTCMonth(), foundedDate.getUTCDate()) > new Date() ? 1 : 0);

        return yearsInBusiness < 1 ? '< 1' : yearsInBusiness;
    };

    return (
        <>
            <Heading variant={HeadingVariant.H4} className="ProfileDetails__section-title" testId="DetailsBusiness">
                {t(OperatorProfilePhrasesEnum.OpBusiness)}
            </Heading>
            <div className="ProfileDetails__business">
                <BusinessInfo
                    title={t(OperatorProfilePhrasesEnum.FoundedIn)}
                    testId={OperatorProfilePhrasesEnum.FoundedIn}
                    renderCondition={!!yearFounded}
                >
                    {yearFounded}
                </BusinessInfo>
                <BusinessInfo
                    title={t(OperatorProfilePhrasesEnum.YearsinBusiness)}
                    testId={OperatorProfilePhrasesEnum.YearsinBusiness}
                    renderCondition={!!yearFounded}
                >
                    {calculatedYearsInBusiness()}
                </BusinessInfo>
                <BusinessInfo
                    title={t(OperatorProfilePhrasesEnum.Employees)}
                    testId={OperatorProfilePhrasesEnum.Employees}
                    renderCondition={!!operatorRanges?.[OperatorRangesEnum.NumberOfEmployeesRange]}
                >
                    {operatorRanges?.[OperatorRangesEnum.NumberOfEmployeesRange]}
                </BusinessInfo>

                <BusinessInfo
                    title={t(OperatorProfilePhrasesEnum.HotelClass)}
                    testId={OperatorProfilePhrasesEnum.HotelClass}
                    renderCondition={!!data?.lodging_star_level}
                >
                    <div className="ProfileDetails__rating-stars">
                        <Heading variant={HeadingVariant.H5} style={{ marginRight: '6px' }}>
                            {data?.lodging_star_level} Star
                        </Heading>
                        {Array.from({ length: 5 }).map((_, index) => {
                            if (index < (data?.lodging_star_level ?? 0)) {
                                return <IconRatingStar key={index} />;
                            }
                            return <IconRatingStarEmpty key={index} />;
                        })}
                    </div>
                </BusinessInfo>

                <BusinessInfo
                    title={t(OperatorProfilePhrasesEnum.PriceLevel)}
                    testId={OperatorProfilePhrasesEnum.PriceLevel}
                    renderCondition={!!operatorRanges?.[OperatorRangesEnum.AverageCheckRange]}
                >
                    {operatorRanges?.[OperatorRangesEnum.AverageCheckRange]}
                </BusinessInfo>

                <BusinessInfo
                    title={t(OperatorProfilePhrasesEnum.EstimatedAnnualSales)}
                    testId={OperatorProfilePhrasesEnum.EstimatedAnnualSales}
                    renderCondition={!!operatorRanges?.[OperatorRangesEnum.AnnualSalesRange]}
                >
                    {operatorRanges?.[OperatorRangesEnum.AnnualSalesRange]}
                </BusinessInfo>

                <BusinessInfo
                    title={t(OperatorProfilePhrasesEnum.HostLocation)}
                    testId={OperatorProfilePhrasesEnum.HostLocation}
                    renderCondition={!!(data?.host?.operator_identifier && data?.host?.business_name)}
                >
                    <Button
                        type={ButtonTypeEnum.Link}
                        modifiers={['naked']}
                        style={{ justifyContent: 'flex-start' }}
                        link={replaceRouteParam(
                            RoutesEnum.OperatorProfile,
                            ':id',
                            data?.host?.operator_identifier ?? '',
                        )}
                    >
                        {data?.host?.business_name}
                    </Button>
                </BusinessInfo>

                {renderSimpleBusinessInfo}
            </div>
        </>
    );
};

export default Business;
