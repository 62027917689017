import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content, ContentType } from '@core/enums/api';

import useFetch, { Config } from '@hooks/useFetch';
import { useCallback } from 'react';

const GET_FIELDS_URL = '/fields';

type GetFieldsEndpoint = paths[typeof GET_FIELDS_URL][MethodsEnum.GET];
type FieldsResponse = GetFieldsEndpoint[OperationsEnum.RESPONSES]['200'][Content][ContentType];
type FieldsParams = GetFieldsEndpoint[OperationsEnum.PARAMETERS]['query'];

export default function useFields(query?: FieldsParams) {
    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<FieldsResponse>({
        url: GET_FIELDS_URL,
        lazy: true,
        initialConfig: {
            params: query,
        },
    });

    const doFetchWithParams = useCallback(
        (queryParams?: FieldsParams, newConfig?: Config) => {
            doFetch({
                params: queryParams,
                ...newConfig,
            });
        },
        [doFetch],
    );

    return { data: data?.fields, loading, error, doFetch: doFetchWithParams } as const;
}
