import { FC } from 'react';
import { OutlinedInput, Select as MuiSelect, SelectChangeEvent, InputLabel, MenuItem } from '@mui/material';

import '../Inputs.styles.scss';
import { SelectOption } from '../Inputs.helpers';
import SelectIcon from '../SelectIcon';

const DEFAULT_PLACEHOLDER = 'Select...';

const Select: FC<{
    hasError?: boolean;
    isDisabled?: boolean;
    inputLabel?: string;
    options: SelectOption[];
    value?: string | null;
    placeholder?: string;
    height?: string;
    testId?: string;
    onChange: (value: string | null) => void;
}> = ({
    hasError = false,
    isDisabled = false,
    options = [],
    inputLabel,
    value,
    onChange,
    placeholder = DEFAULT_PLACEHOLDER,
    height,
    testId,
}) => {
    return (
        <div className="Select">
            {!!inputLabel && <InputLabel error={hasError}>{inputLabel}</InputLabel>}
            <div style={{ position: 'relative' }}>
                <MuiSelect
                    fullWidth
                    value={value ?? ''}
                    onChange={(e: SelectChangeEvent<string>) => {
                        onChange(e.target.value);
                    }}
                    open={isDisabled ? false : undefined}
                    input={
                        <OutlinedInput
                            sx={{ height: height ?? 'auto' }}
                            error={hasError}
                            disabled={isDisabled}
                            fullWidth
                            inputProps={{
                                'data-testid': testId,
                            }}
                        />
                    }
                    MenuProps={{
                        classes: {
                            paper: 'Inputs__dropdown Inputs__dropdown--spaced',
                            list: 'Inputs__options-list',
                        },
                        MenuListProps: {
                            // This is necessary because the MuiSelect component does not have a data-testid prop, but we need to add it to the MenuList element for testing purposes.
                            // @ts-expect-error "data-testid" does not exist on type 'Partial<MenuListProps>'
                            'data-testid': `${testId}Ul`,
                        },
                    }}
                    endAdornment={<SelectIcon isOpen />}
                    IconComponent={() => null}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                            classes={{
                                root: 'Inputs__option',
                            }}
                            data-testid={`${testId}Ul`}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </MuiSelect>
                {!value && (
                    <div className="Inputs__select-placeholder">
                        <span>{placeholder}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Select;
