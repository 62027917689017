/* eslint-disable camelcase */
import {
    IconBooking,
    IconFacebook,
    IconFoursquare,
    IconGoogle,
    IconHotels,
    IconInstagram,
    IconMichelin,
    IconPinterest,
    IconTripadvisor,
    IconTwitterX,
    IconYelp,
    IconYoutube,
} from '@assets/icons';
import Tag from '@components/utility/Chips/Tag/Tag';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { LocalesEnum, OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';
import { formatUnifiedRange } from '@core/utils/currency';
import { useLocale } from '@core/utils/locale';
import {
    Maybe,
    MetadataRange,
    OperatorDetails,
    OperatorLocationDetails,
    OperatorSocialMedia,
} from '@graphql/generated/graphql';

export const mapApiEntityTypes: Record<EntityTypes, OperatorProfilePhrasesEnum> = {
    CHAIN: OperatorProfilePhrasesEnum.ChainUnit,
    UNIT: OperatorProfilePhrasesEnum.IndependentUnit,
    DISTRICT_SYSTEM_UNIT: OperatorProfilePhrasesEnum.DistrictSystemUnit,
};

export enum EntityTypes {
    UNIT = 'UNIT',
    CHAIN = 'CHAIN',
    DISTRICT_SYSTEM_UNIT = 'DISTRICT_SYSTEM_UNIT',
}

export function renderTags(...args: Array<string | undefined | null>): JSX.Element {
    const tags: string[] = args.filter((arg) => !!arg) as string[];

    return (
        <>
            {tags.map((tag) => (
                <Tag testId={tag} key={tag} name={tag} />
            ))}
        </>
    );
}

export function buildAddress(location: OperatorLocationDetails = {}, countryCode: string): string {
    let parsedAddress = '';

    switch (countryCode) {
        case CountryCodesEnum.US:
        case CountryCodesEnum.CA:
        case CountryCodesEnum.AU:
        case CountryCodesEnum.NZ:
            parsedAddress = `${location?.address_1} ${location?.address_2 ?? ''} \n ${
                location?.locality_1
            }, ${location?.administrative_area_1?.toUpperCase()} ${location?.postal_code_1?.toUpperCase()}`;
            break;

        case CountryCodesEnum.UK:
            parsedAddress = `${location?.address_1} ${location?.address_2 ?? ''} ${location?.address_2 ?? ''} \n ${
                location?.locality_1
            } ${location?.postal_code_1?.toUpperCase()}`;
            break;

        case CountryCodesEnum.MY:
            parsedAddress = `${location?.address_1 ?? ''}\n ${location?.postal_code_1?.toUpperCase() ?? ''} ${
                location?.locality_1 ?? ''
            }, \n ${location?.administrative_area_1 ?? ''} ${location?.country ?? ''}`;
            break;

        case CountryCodesEnum.SG:
            parsedAddress = `${location?.address_1}\n ${
                location?.locality_1
            },  ${location?.postal_code_1?.toUpperCase()}`;
            break;

        default:
            parsedAddress = `${location?.address_1} ${location?.address_2 ?? ''} ${
                location?.address_3 ?? ''
            } \n ${location?.postal_code_1?.toUpperCase()} ${location?.locality_1}`;
            break;
    }

    return parsedAddress;
}

export const mapSocialMediaTypesToIcons: Record<string, JSX.Element> = {
    FACEBOOK: <IconFacebook />,
    INSTAGRAM: <IconInstagram />,
    TWITTER: <IconTwitterX />,
    YOUTUBE: <IconYoutube />,
    PINTEREST: <IconPinterest />,
    BOOKING_DOT_COM: <IconBooking />,
    FOURSQUARE: <IconFoursquare />,
    GOOGLE: <IconGoogle />,
    HOTELS_DOT_COM: <IconHotels />,
    MICHELIN: <IconMichelin />,
    TRIPADVISOR: <IconTripadvisor />,
    YELP: <IconYelp />,
};

type SocialMediaItem = {
    icon: JSX.Element;
    type?: string;
    url: string;
};

export function parseSocialMedia(socialMedia: OperatorSocialMedia[] | null): SocialMediaItem[] {
    const icons: SocialMediaItem[] = [];

    if (socialMedia) {
        const availableSocialMedia = socialMedia.filter((media) => !!media.url);

        availableSocialMedia.forEach((media) => {
            const mediaIcon = mapSocialMediaTypesToIcons[media?.type ?? ''];
            if (mediaIcon) {
                icons.push({
                    icon: mediaIcon,
                    url: media.url as string,
                    type: media?.type as string | undefined,
                });
            }
        });
    }

    return icons;
}

export enum OperatorRangesEnum {
    AverageCheckRange = 'AVERAGE_CHECK_RANGE',
    NumberOfBedsRange = 'NUMBER_OF_BEDS_RANGE',
    NumberOfEmployeesRange = 'NUMBER_OF_EMPLOYEES_RANGE',
    ChainSizeRange = 'CHAIN_SIZE_RANGE',
    AnnualSalesRange = 'ANNUAL_SALES_RANGE',
    NumberOfStudentsRange = 'NUMBER_OF_STUDENTS_RANGE',
    EstimatedMealsPerDayRange = 'ESTIMATED_MEALS_PER_DAY_RANGE',
    NumberOfRoomsRange = 'NUMBER_OF_ROOMS_RANGE',
    YearsInBusinessRange = 'YEARS_IN_BUSINESS_RANGE',
}

export function getOperatorRange(
    range: OperatorDetails['ranges'],
    rangeType: OperatorRangesEnum,
): MetadataRange | null {
    return range?.find((range) => range?.type === rangeType) ?? null;
}

export function useOperatorRanges(ranges: OperatorDetails['ranges'], countryCode: CountryCodesEnum) {
    const { locale } = useLocale();
    const parsedRange = ranges?.reduce(
        (acc, range) => {
            const rangeType = range?.type as OperatorRangesEnum;
            const parsedRange = parseOperatorRange(range, rangeType, countryCode, locale);

            if (parsedRange) {
                acc[rangeType] = parsedRange;
            }

            return acc;
        },
        {} as Record<OperatorRangesEnum, string>,
    );

    return parsedRange;
}

function parseOperatorRange(
    range: Maybe<MetadataRange>,
    rangeType: OperatorRangesEnum,
    countryCode: CountryCodesEnum,
    locale: LocalesEnum,
): string | null {
    if (!range || (!range.display_min && !range.display_max)) {
        return null;
    }

    if (rangeType === OperatorRangesEnum.AnnualSalesRange || rangeType === OperatorRangesEnum.AverageCheckRange) {
        return formatUnifiedRange(countryCode, range.display_min, range.display_max, locale);
    }

    if (!range.display_max) {
        return `> ${range.display_min}`;
    }

    if (!range.display_min) {
        return `< ${range.display_max}`;
    }

    return `${range.display_min} - ${range.display_max}`;
}

export const mapApiDayPartsServices: Record<string, OperatorProfilePhrasesEnum> = {
    offers_breakfast: OperatorProfilePhrasesEnum.Breakfast,
    offers_lunch: OperatorProfilePhrasesEnum.Lunch,
    offers_dinner: OperatorProfilePhrasesEnum.Dinner,
};

export const mapApiOffersServices: Record<string, OperatorProfilePhrasesEnum> = {
    offers_takeout: OperatorProfilePhrasesEnum.Takeout,
    offers_delivery: OperatorProfilePhrasesEnum.Delivery,
    offers_drive_thru: OperatorProfilePhrasesEnum.DriveThru,
    offers_catering: OperatorProfilePhrasesEnum.Catering,
    offers_reservations: OperatorProfilePhrasesEnum.Reservations,
    offers_kids_menu: OperatorProfilePhrasesEnum.KidsMenu,
    offers_eatertainment: OperatorProfilePhrasesEnum.Eatertainment,
    offers_banquet_facility: OperatorProfilePhrasesEnum.BanquetFacility,
    offers_complimentary_breakfast: OperatorProfilePhrasesEnum.ComplimentaryBreakfast,
    offers_meeting_rooms: OperatorProfilePhrasesEnum.MeetingRooms,
    offers_room_service: OperatorProfilePhrasesEnum.RoomService,
    offers_cafeteria: OperatorProfilePhrasesEnum.Cafeteria,
    offers_higher_education_housing: OperatorProfilePhrasesEnum.HigherEducationHousing,
    offers_dine_in: OperatorProfilePhrasesEnum.DineIn,
    offers_24_hours_service: OperatorProfilePhrasesEnum.Open24Hours,
    offers_fuel: OperatorProfilePhrasesEnum.PetrolLocation,
};
