import { FC, useCallback, useEffect, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import { IconFilterGeneral } from '@assets/icons';
import { RangesTypesEnum } from '@core/api/useRanges';
import { generateAnchorId } from '@core/utils/string';
import { GradientColorsEnum } from '@enums/gradientEnum';
import BadgeIcon from '@components/utility/BadgeIcon/BadgeIcon';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import { FilterTypeEnum, OperatorProfilePhrasesEnum } from '@enums/localeEnum';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import { FILTER_ID_PREFIX } from '@pages/OperatorTargetingCriteria/hooks/useSearchNavigation';
import { CriterionEnum, CriterionParam, CriterionParamFilter, NoEmployeesCountDataEnum } from '@core/models/Criterion';

import './GeneralCriteria.styles.scss';
import { RangesData } from './General.helpers';
import GeneralSwitchList from './GeneralSwitchList';

const GeneralCriteria: FC<{
    ranges: RangesData | null;
}> = ({ ranges }) => {
    const [commercialCheckBoxEnabled, setCommercialCheckBoxEnabled] = useState<boolean>(false);
    const [nonCommercialCheckBoxEnabled, setNonCommercialCheckBoxEnabled] = useState<boolean>(false);
    const [isToggleSelectedCommercial, setIsToggleSelectedCommercial] = useState<boolean>(false);
    const [isToggleSelectedNonCommercial, setIsToggleSelectedNonCommercial] = useState<boolean>(false);

    const { t } = useLocale();

    const {
        getCriterionByFilterKey,
        addCriterionOnOptionSelected,
        onDeleteCriterionParam
    } = useAdvancedSearch();

    const isPurchasePotEnabled = useFeatureFlag(FeatureFlags.PurchasePotentialFilter);

    useEffect(() => {
        const nonCommercialRangeCriterion = getCriterionByFilterKey(
            CriterionEnum.General,
            CriterionParamFilter.NumberOfEmployeesRangeNonCommercial,
        );

        const commercialRangeCriterion = getCriterionByFilterKey(
            CriterionEnum.General,
            CriterionParamFilter.NumberOfEmployeesRangeCommercial,
        );

        setNonCommercialCheckBoxEnabled(!!nonCommercialRangeCriterion);
        setCommercialCheckBoxEnabled(!!commercialRangeCriterion);

        if (
            nonCommercialRangeCriterion?.length === 1 &&
            nonCommercialRangeCriterion[0].value === NoEmployeesCountDataEnum.NonCommercial
        ) {
            onDeleteCriterionParam(CriterionEnum.General, NoEmployeesCountDataEnum.NonCommercial);
        }

        if (
            commercialRangeCriterion?.length === 1 &&
            commercialRangeCriterion[0].value === NoEmployeesCountDataEnum.Commercial
        ) {
            onDeleteCriterionParam(CriterionEnum.General, NoEmployeesCountDataEnum.Commercial);
        }

        setIsToggleSelectedNonCommercial(() => {
            const isNonCommercialEmployeeCountSelect = nonCommercialRangeCriterion?.find(
                (criterion: CriterionParam) => criterion.value === NoEmployeesCountDataEnum.NonCommercial,
            );

            return !!isNonCommercialEmployeeCountSelect;
        });

        setIsToggleSelectedCommercial(() => {
            const isCommercialEmployeeCountSelect = commercialRangeCriterion?.find(
                (criterion: CriterionParam) => criterion.value === NoEmployeesCountDataEnum.Commercial,
            );

            return !!isCommercialEmployeeCountSelect;
        });
    }, [getCriterionByFilterKey, onDeleteCriterionParam]);

    const onToggleNoCountDataCheckbox = useCallback(
        (id: NoEmployeesCountDataEnum, filterKey: CriterionParamFilter) => {
            const rangePill: DropdownOption = {
                id,
                label: `${t(FilterTypeEnum.OperatorsWithoutEmployeeCount)}`,
                value: id,
            };
            addCriterionOnOptionSelected(CriterionEnum.General, filterKey)(rangePill);
        },
        [addCriterionOnOptionSelected, t],
    );

    return (
        <div className="GeneralCriteria CriteriaContent with-gap" id={generateAnchorId(t(FilterTypeEnum.General))}>
            <div className="CriteriaContent__header">
                <BadgeIcon icon={<IconFilterGeneral />} baseColor={GradientColorsEnum.PURPLE} />
                <Heading testId="GeneralSection">{t(FilterTypeEnum.General)}</Heading>
            </div>
            <div className="CriteriaContent__section">
                {!!ranges?.[RangesTypesEnum.ChainSizeRangeType] && (
                    <Widget
                        type={WidgetType.Section}
                        className="criteria"
                        id={generateAnchorId(t(FilterTypeEnum.ChainIndependent), FILTER_ID_PREFIX)}
                        dataObservable
                    >
                        <GeneralSwitchList
                            range={ranges[RangesTypesEnum.ChainSizeRangeType]}
                            testId={FilterTypeEnum.ChainIndependent}
                            title={t(FilterTypeEnum.ChainIndependent)}
                            criterionFilterKey={CriterionParamFilter.ChainSizeRange}
                        />
                    </Widget>
                )}
                {!!ranges?.[RangesTypesEnum.YearsInBusinessRangeType] && (
                    <Widget
                        type={WidgetType.Section}
                        className="criteria GeneralCriteria__section"
                        id={generateAnchorId(t(OperatorProfilePhrasesEnum.YearsinBusiness), FILTER_ID_PREFIX)}
                        dataObservable
                    >
                        <GeneralSwitchList
                            range={ranges[RangesTypesEnum.YearsInBusinessRangeType]}
                            testId={OperatorProfilePhrasesEnum.YearsinBusiness}
                            title={OperatorProfilePhrasesEnum.YearsinBusiness}
                            criterionFilterKey={CriterionParamFilter.YearsInBusinessRange}
                        />
                    </Widget>
                )}
                {!!ranges?.[RangesTypesEnum.NumberOfEmployeesCommercialRangeType] &&
                    !!ranges?.[RangesTypesEnum.NumberOfEmployeesNonCommercialRangeType] && (
                    <Widget
                        type={WidgetType.Section}
                        className="criteria GeneralCriteria__section"
                        id={generateAnchorId(t(FilterTypeEnum.NumberOfEmployees), FILTER_ID_PREFIX)}
                        dataObservable
                    >
                        <Heading variant={HeadingVariant.H5} testId="NumberOfEmployeesFilterTitle">
                            {t(FilterTypeEnum.NumberOfEmployees)}
                        </Heading>

                        <GeneralSwitchList
                            range={ranges[RangesTypesEnum.NumberOfEmployeesCommercialRangeType]}
                            testId={FilterTypeEnum.Commercial}
                            title={t(FilterTypeEnum.Commercial)}
                            criterionFilterKey={CriterionParamFilter.NumberOfEmployeesRangeCommercial}
                        />
                        <Toggle
                            type={ToggleType.Checkbox}
                            isChecked={isToggleSelectedCommercial}
                            setIsChecked={() => {
                                setIsToggleSelectedCommercial((prevState) => !prevState);
                                onToggleNoCountDataCheckbox(
                                    NoEmployeesCountDataEnum.Commercial,
                                    CriterionParamFilter.NumberOfEmployeesRangeCommercial,
                                );
                            }}
                            isDisabled={!commercialCheckBoxEnabled}
                            testId="CheckboxCommercialWithoutEmployeeCount"
                        >
                            {t(FilterTypeEnum.OperatorsWithoutEmployeeCount)}
                        </Toggle>
                        <GeneralSwitchList
                            range={ranges[RangesTypesEnum.NumberOfEmployeesNonCommercialRangeType]}
                            testId={FilterTypeEnum.NonCommercial}
                            title={t(FilterTypeEnum.NonCommercial)}
                            criterionFilterKey={CriterionParamFilter.NumberOfEmployeesRangeNonCommercial}
                        />
                        <Toggle
                            type={ToggleType.Checkbox}
                            isChecked={isToggleSelectedNonCommercial}
                            setIsChecked={() => {
                                setIsToggleSelectedNonCommercial((prevState) => !prevState);
                                onToggleNoCountDataCheckbox(
                                    NoEmployeesCountDataEnum.NonCommercial,
                                    CriterionParamFilter.NumberOfEmployeesRangeNonCommercial,
                                );
                            }}
                            isDisabled={!nonCommercialCheckBoxEnabled}
                            testId="CheckboxNonCommercialWithoutEmployeeCount"
                        >
                            {t(FilterTypeEnum.OperatorsWithoutEmployeeCount)}
                        </Toggle>
                    </Widget>
                )}

                {!!ranges?.[RangesTypesEnum.AnnualSalesRangeType] && (
                    <Widget
                        type={WidgetType.Section}
                        className="criteria GeneralCriteria__section"
                        id={generateAnchorId(t(OperatorProfilePhrasesEnum.EstimatedAnnualSales), FILTER_ID_PREFIX)}
                        dataObservable
                    >
                        <GeneralSwitchList
                            range={ranges[RangesTypesEnum.AnnualSalesRangeType]}
                            testId={OperatorProfilePhrasesEnum.EstimatedAnnualSales}
                            title={t(OperatorProfilePhrasesEnum.EstimatedAnnualSales)}
                            criterionFilterKey={CriterionParamFilter.AnnualSalesRange}
                        />
                    </Widget>
                )}

                {isPurchasePotEnabled && !!ranges?.[RangesTypesEnum.PurchasePotentialTotalRangeType] && (
                    <Widget
                        type={WidgetType.Section}
                        className="criteria GeneralCriteria__section"
                        id={generateAnchorId(t(FilterTypeEnum.TotalPurchasePotential), FILTER_ID_PREFIX)}
                        dataObservable
                    >
                        <GeneralSwitchList
                            range={ranges[RangesTypesEnum.PurchasePotentialTotalRangeType]}
                            testId={FilterTypeEnum.TotalPurchasePotential}
                            title={t(FilterTypeEnum.TotalPurchasePotential)}
                            criterionFilterKey={CriterionParamFilter.PotentialPurchaseTotal}
                        />
                    </Widget>
                )}

                {!!ranges?.[RangesTypesEnum.ConfidenceLevelRangeType] && (
                    <Widget
                        type={WidgetType.Section}
                        className="criteria GeneralCriteria__section"
                        id={generateAnchorId(t(OperatorProfilePhrasesEnum.ConfidenceLevel), FILTER_ID_PREFIX)}
                        dataObservable
                    >
                        <GeneralSwitchList
                            range={ranges?.[RangesTypesEnum.ConfidenceLevelRangeType]}
                            testId={OperatorProfilePhrasesEnum.ConfidenceLevel}
                            title={t(OperatorProfilePhrasesEnum.ConfidenceLevel)}
                            criterionFilterKey={CriterionParamFilter.ConfidenceLevel}
                        />
                    </Widget>
                )}
            </div>
        </div>
    );
};
export default GeneralCriteria;
