import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IconArrowCollapse } from '@assets/icons';
import { removeWhitespaces } from '@core/utils/string';
import Button, { ButtonTypeEnum } from '@components/utility/Button/Button';

import './Breadcrumbs.styles.scss';

type Breadcrumb = {
    label: string;
    link: string;
};

const Breadcrumbs: React.FC<{
    breadcrumbs: Breadcrumb[];
    returnLink?: string;
}> = ({
    returnLink,
    breadcrumbs
}) => {
    const navigate = useNavigate();
    const onReturn = () => navigate(returnLink || breadcrumbs[0].link);

    return (
        <div className="Breadcrumbs">
            {breadcrumbs.map((breadcrumb, index) => {
                return (
                    <React.Fragment key={index}>
                        <div>
                            <Button
                                type={ButtonTypeEnum.Link}
                                modifiers={['naked']}
                                className="caret"
                                link={breadcrumb.link}
                                testId={`breadcrumbButton${removeWhitespaces(breadcrumb.label)}`}
                            >
                                {index === 0 && (
                                    <div className="Breadcrumbs__return-icon" onClick={onReturn}>
                                        <IconArrowCollapse />
                                    </div>
                                )}
                                {breadcrumb.label}
                            </Button>
                        </div>
                        {index !== breadcrumbs.length - 1 && <span className="Breadcrumbs__separator">/</span>}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
