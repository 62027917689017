import { useState } from 'react';
import VerticalProgressBar, { VerticalProgressBarData } from '../VerticalProgressBar/VerticalProgressBar';
import { FCX, ID } from '@core/models';

import './GroupedVerticalProgressBar.style.scss';

type GroupedVerticalProgressBarProps = {
    data: VerticalProgressBarData[];
    onBarClick: (barId: ID, barTitle: string) => void;
};

const GroupedVerticalProgressBar: FCX<GroupedVerticalProgressBarProps> = ({
    data,
    onBarClick
}) => {
    const [hoveredBar, setHoveredBar] = useState<ID | null>(null);

    return (
        <div className="GroupedVerticalProgressBar">
            {data.map((barData, index) => (
                <div
                    key={index}
                    onMouseEnter={() => setHoveredBar(barData.id)}
                    onMouseLeave={() => setHoveredBar(null)}
                >
                    <VerticalProgressBar
                        barKey={barData.id}
                        progress={barData.progress}
                        title={barData.title}
                        value={barData.value}
                        disabled={hoveredBar !== null && hoveredBar !== barData.id}
                        onBarClick={onBarClick}
                    />
                </div>
            ))}
        </div>
    );
};

export default GroupedVerticalProgressBar;
