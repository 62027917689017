import { FC, useCallback, useEffect } from 'react';

import { useLocale } from '@core/utils/locale';
import { CriteriaData } from '@core/models/Criterion';
import { CommonPhrasesEnum } from '@core/enums/localeEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import useUpdateSaveSearch from '@core/api/SaveSearch/useUpdateSaveSearch';

import Modal from '@components/utility/Modal/Modal';
import Button from '@components/utility/Button/Button';
import Text, { TextVariant } from '@components/ui/Typography/Text';

import './UpdateSearchModal.styles.scss';
import { SuccessComponent } from './SaveSearchSuccess';

const UpdateSaveSearchModal: FC<{
    isOpen: boolean;
    handleClose: () => void;
    title: string;
    criteria?: CriteriaData;
    handleOpenSaveModal: () => void;
}> = ({
    isOpen,
    handleClose: handleCloseUpdate,
    title,
    criteria,
    handleOpenSaveModal
}) => {
    const { t } = useLocale();

    const {
        savedSearch: {
            activeSaveSearch,
            updateIsDirty
        },
    } = useAdvancedSearch();

    const {
        success: updateSuccess,
        error: updateError,
        doFetch: doUpdateFetch
    } = useUpdateSaveSearch();

    useEffect(() => {
        if (!updateSuccess && !updateError) return;

        if (updateSuccess) {
            updateIsDirty(false);
            setTimeout(handleCloseUpdate, 2000);
        }
    }, [handleCloseUpdate, updateError, updateIsDirty, updateSuccess]);

    const onSave = () => {
        handleCloseUpdate();
        handleOpenSaveModal();
    };

    const onUpdate = useCallback(() => {
        if (activeSaveSearch) {
            doUpdateFetch({
                ...activeSaveSearch,
                query: JSON.stringify(criteria),
            });
        }
    }, [activeSaveSearch, criteria, doUpdateFetch]);

    return (
        <Modal title={updateSuccess ? '' : title} isOpen={isOpen} handleClose={handleCloseUpdate}>
            <div className="UpdateSearchModal">
                {updateSuccess ? (
                    <SuccessComponent value={activeSaveSearch?.name ?? ''} t={t} />
                ) : (
                    <>
                        <Text
                            variant={TextVariant.B2}
                            style={{ marginTop: '24px', display: 'block' }}
                            testId="textUpdateSaveSearchHeader"
                        >
                            <Text
                                variant={TextVariant.B2}
                                style={{ marginTop: '24px', display: 'block' }}
                                testId="textUpdateSaveSearchQuestion"
                            >
                                {`${t(CommonPhrasesEnum.WouldYouLikeToUpdate)} "${
                                    activeSaveSearch?.name ?? ''
                                }" ${t(CommonPhrasesEnum.SaveThisNewSearch)}`}
                            </Text>
                        </Text>
                        <div className="UpdateSearchModal__footer">
                            <Button modifiers={['primary']} onClick={onUpdate} testId="buttonSavedSearchUpdateExisting">
                                {t(CommonPhrasesEnum.UpdateExistingSavedSearch)}
                            </Button>
                            <Button modifiers={['secondary']} onClick={onSave} testId="buttonSavedSearchNew">
                                {t(CommonPhrasesEnum.SaveANewSearch)}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UpdateSaveSearchModal;
