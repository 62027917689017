import { useCallback, useLayoutEffect, useState } from 'react';

import useDebouncedCallback from '@core/hooks/useDebouncedCallback';

type useListenToActiveMenuItemArgs = (
    cb: (id: string) => void,
    querySelector: string,
    rootId: string,
) => {
    observe: () => void;
};

type FilterItem = {
    id: string;
    top: number;
    bottom: number;
};

const BOTTOM_OFFSET = 80;

const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil;

const findNearestElement = (array: FilterItem[], position: number): FilterItem | null => {
    let nearestFilter: FilterItem | null = null;
    let minDifference = Infinity;

    for (const filter of array) {
        const topDifference = Math.abs(filter.top - position);
        const bottomDifference = Math.abs(filter.bottom - position);

        if (topDifference < minDifference) {
            minDifference = topDifference;
            nearestFilter = filter;
        }

        if (bottomDifference < minDifference) {
            minDifference = bottomDifference;
            nearestFilter = filter;
        }
    }

    return nearestFilter;
};

const useListenToActiveMenuItem: useListenToActiveMenuItemArgs = (cb, querySelector, rootId) => {
    const [ids, setIds] = useState<string[]>([]);

    const onListen = useDebouncedCallback(() => {
        const root = document.querySelector(rootId);
        const scroll = root?.scrollTop ?? 0;
        const offset = root?.getBoundingClientRect().top ?? 0;
        const position = ids.map((id) => {
            const element = document.getElementById(id);

            if (!element) return { id, top: -1, bottom: -1 };

            const rect = element.getBoundingClientRect();
            const top = clamp(rect.top + scroll - offset);

            const bottom = clamp(rect.bottom + scroll - offset - BOTTOM_OFFSET);

            return { id, top, bottom };
        });

        const visibleSection =
            position.find(({
                top,
                bottom
            }) => {
                return isBetween(scroll, top, bottom);
            }) ?? findNearestElement(position, scroll);

        if (visibleSection?.id) {
            cb(visibleSection.id);
        }
    }, 150);

    useLayoutEffect(() => {
        const root = document.querySelector(rootId);
        root?.addEventListener('scroll', onListen);

        return () => {
            root?.removeEventListener('scroll', onListen);
        };
    }, [onListen, rootId]);

    const observe = useCallback(() => {
        const sectionIds: string[] = [];
        document.querySelectorAll(querySelector).forEach((section) => {
            sectionIds.push(section.id);
        });

        setIds(sectionIds);
    }, [querySelector]);

    return {
        observe,
    };
};

export default useListenToActiveMenuItem;
