import { SVGProps } from 'react';

type StarProps = SVGProps<SVGSVGElement> & {
    percentage: number;
    starid: string;
};

const Star = (props: StarProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16.7} height={16} viewBox="0 0 14 12" fill="none" {...props}>
        <path
            fill={`url(#${props.starid})`}
            d="M13.084 4.805c.063-.314-.188-.692-.503-.692L8.997 3.61 7.362.34A.491.491 0 0 0 7.11.09C6.796-.1 6.418.026 6.23.34L4.657 3.61l-3.584.503c-.189 0-.315.063-.378.19a.608.608 0 0 0 0 .88l2.579 2.515-.629 3.585c0 .125 0 .251.063.377.189.314.566.44.88.251l3.208-1.698 3.207 1.698c.063.063.188.063.314.063h.126a.652.652 0 0 0 .503-.754l-.629-3.585 2.579-2.515a.346.346 0 0 0 .188-.315Z"
        />
        <defs>
            <linearGradient id={props.starid} x2={13.6} gradientUnits="userSpaceOnUse">
                <stop offset="0%" stopColor="#8700FF" />
                <stop offset={`${props.percentage}%`} stopColor="#D200D7" />
                <stop offset="0%" stopColor="#eeeeee" />
            </linearGradient>
        </defs>
    </svg>
);
export default Star;
