import { useEffect, useRef } from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import { FCX } from '@core/models';
import loadingAnimation from '@assets/lottie/loading-animation.json';

const AnimatedLoader: FCX = ({
    className,
    style
}) => {
    const lottieRef = useRef<LottieRefCurrentProps | null>(null);

    useEffect(() => {
        if (lottieRef?.current) {
            lottieRef.current.setSpeed(2);
            lottieRef.current.play();
        }
    }, [lottieRef]);

    return (
        <div className={className} style={style}>
            <Lottie lottieRef={lottieRef} autoplay={false} animationData={loadingAnimation} loop={true} />
        </div>
    );
};

export default AnimatedLoader;
