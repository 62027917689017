import { ReactNode } from 'react';
import cn from 'classnames';

import { FCX, ID } from '@core/models';
import { IconPadlockFilled } from '@assets/icons';
import { formatTestIds } from '@core/utils/string';
import { GradientColorsEnum } from '@enums/gradientEnum';

import './SwitchPill.styles.scss';

const SwitchPill: FCX<{
    title: string | ReactNode;
    subtitle?: string | ReactNode;
    onChange: (id?: ID) => void;
    color: GradientColorsEnum;
    isChecked: boolean;
    hasIcon?: boolean;
    className?: string;
    isDisabled?: boolean;
}> = ({
    id,
    title,
    hasIcon,
    onChange,
    color,
    isChecked,
    className,
    isDisabled = false,
    testId
}) => {
    return (
        <label
            id={id}
            className={cn(
                'SwitchPill',
                `is-${color}`,
                { 'is-active': !isDisabled && isChecked },
                { 'is-disabled': isDisabled },
                className,
            )}
            data-testid={`SwitchPill${formatTestIds(testId)}`}
        >
            <input type="checkbox" onChange={() => !isDisabled && onChange()} checked={isChecked} />
            {title}
            {isDisabled && <IconPadlockFilled className="SwitchPill__padlock-icon" />}
            {!isDisabled && hasIcon && <div className="SwitchPill__check-icon" />}
        </label>
    );
};

export default SwitchPill;
