import { FC } from 'react';
import { ID, iOption } from '@core/models';
import Toggle, { ToggleType } from '@components/utility/Toggle/Toggle';
import './RadioGroup.styles.scss';

const RadioGroup: FC<{
    options: iOption[];
    value: ID;
    onChange: (id: ID) => void;
}> = ({
    options,
    value,
    onChange
}) => {
    const handleChange = (id: ID) => (isChecked: boolean) => {
        if (isChecked) {
            onChange(id);
        }
    };

    return (
        <div className="RadioGroup">
            {options.map((option) => (
                <Toggle
                    key={option.id}
                    type={ToggleType.Radio}
                    isChecked={value === option.id}
                    setIsChecked={handleChange(option.id)}
                >
                    {option.title}
                </Toggle>
            ))}
        </div>
    );
};

export default RadioGroup;
