import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ID } from '@core/models';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';

import {
    filterAndSortSegments,
    groupSegmentLevelsByIdsList,
    ClassificationDTO,
    mapSegmentToDropdownOption,
} from '../../../helpers/Classification.helpers';
import CuisinesSubChannel from './CuisinesSubChannel';

const CuisinesChannel: FC<{
    channel: ClassificationDTO;
    onClearSearch: () => void;
    searchSelectedValue: SelectOption | null;
}> = ({
    channel,
    onClearSearch,
    searchSelectedValue
}) => {
    const [selectedSegments, setSelectedSegments] = useState<ID[][]>([]);
    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey,
        onDeleteCriterionParam
    } = useAdvancedSearch();

    const channelLevelExternalIds = useMemo(() => {
        const subChannels = channel?.children?.map((subChannel) => subChannel?.external_id as string)?.sort() ?? [];
        const segments =
            channel?.children
                ?.map((subChannel) => {
                    return subChannel?.children?.map((segment) => segment?.external_id as string) ?? [];
                })
                ?.flat()
                ?.sort() ?? [];

        return [...subChannels, ...segments];
    }, [channel]);

    useEffect(() => {
        const segmentParams = getCriterionByFilterKey(CriterionEnum.CuisineTypes, CriterionParamFilter.Cuisine);
        const isChannelSelected = segmentParams?.some(
            (segment) => segment?.value === channelLevelExternalIds.join(','),
        );

        setSelectedSegments(
            isChannelSelected ? getAllSegmentIds(channel) : getSegmentIdsFromSubChannels(channel, segmentParams),
        );
    }, [channel, channelLevelExternalIds, getCriterionByFilterKey]);

    const onClearAllChannelSegments = useCallback(
        (channel: ClassificationDTO) => {
            const subChannels = channel?.children?.map((subChannel) => subChannel?.external_id as string) ?? [];
            const segments =
                channel?.children
                    ?.map((subChannel) => {
                        return subChannel?.children?.map((segment) => segment?.external_id as string) ?? [];
                    })
                    ?.flat() ?? [];

            const allChannelExernalIds = [channelLevelExternalIds.join(',') ?? '', ...subChannels, ...segments];

            allChannelExernalIds?.forEach((channelSegmentId) => {
                onDeleteCriterionParam(CriterionEnum.CuisineTypes, channelSegmentId);
            });
        },
        [channelLevelExternalIds, onDeleteCriterionParam],
    );

    const onAddChannelSegments = useCallback(
        (channelSegmentsList: ClassificationDTO[]) => {
            channelSegmentsList?.forEach((channelSegment) => {
                if (channelSegment.level === 'GROUP') {
                    const groupId = channelLevelExternalIds.join(',');

                    const groupSegmentPill: DropdownOption = {
                        id: groupId ?? '',
                        label: channelSegment.description ?? '',
                        value: groupId ?? '',
                    };

                    return addCriterionOnOptionSelected(
                        CriterionEnum.CuisineTypes,
                        CriterionParamFilter.Cuisine,
                    )(groupSegmentPill);
                }

                const channelSegmentPill: DropdownOption = mapSegmentToDropdownOption(channelSegment);
                addCriterionOnOptionSelected(
                    CriterionEnum.CuisineTypes,
                    CriterionParamFilter.Cuisine,
                )(channelSegmentPill);
            });
        },
        [addCriterionOnOptionSelected, channelLevelExternalIds],
    );

    const allowedChannelList = useMemo(() => {
        const channelList = filterAndSortSegments(channel?.children) || [];
        return channelList.filter((channel) => channel.children && channel.children.length > 0);
    }, [channel?.children]);

    const onUpdateSelectedSegments = useCallback(
        (index: number) => (value: ID[]) => {
            const newSelectedSegments = [...selectedSegments];
            newSelectedSegments[index] = value;
            setSelectedSegments(newSelectedSegments);

            onClearAllChannelSegments(channel);

            const flattenSelectedSegments = newSelectedSegments?.flat();
            const groupedSegments = groupSegmentLevelsByIdsList(channel, flattenSelectedSegments);
            onAddChannelSegments(groupedSegments);
        },
        [channel, onAddChannelSegments, onClearAllChannelSegments, selectedSegments],
    );

    return (
        <div className="CriteriaContent__section">
            <div className="CriteriaContent__horizontal-divider-title">{channel?.description}</div>
            <div className="CriteriaContent__segment-columns">
                {allowedChannelList?.map((subChannel, index) => {
                    return (
                        <div key={subChannel?.external_id} className="CriteriaContent__segment-column">
                            <CuisinesSubChannel
                                searchSelectedValue={searchSelectedValue}
                                onClearSearch={onClearSearch}
                                onChangeCuisines={onUpdateSelectedSegments(index)}
                                selectedValues={selectedSegments[index] ?? []}
                                subChannel={subChannel}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

function getAllSegmentIds(channel: ClassificationDTO): ID[][] {
    return (
        channel?.children?.map((subChannel) => {
            return subChannel?.children?.map((segment) => segment?.external_id as ID) ?? [];
        }) ?? []
    );
}

function getSegmentIdsFromSubChannels(channel: ClassificationDTO, segmentParams: CriterionParam[] | undefined): ID[][] {
    return (
        channel?.children?.map((subChannel) => {
            const isSubChannelSelected = segmentParams?.some((segment) => segment?.value === subChannel?.external_id);

            const allSubChannelsSegmentsIds = subChannel?.children?.map((segment) => segment?.external_id as ID) ?? [];

            if (isSubChannelSelected) {
                return allSubChannelsSegmentsIds;
            }

            const selectedSegmentsFromSubChannel = allSubChannelsSegmentsIds?.filter((segmentId) => {
                return segmentParams?.some((segment) => segment?.value === segmentId);
            });

            return selectedSegmentsFromSubChannel;
        }) ?? []
    );
}

export default CuisinesChannel;
