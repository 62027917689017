import Button from '@components/utility/Button/Button';
import FlagIcon from '@components/utility/FlagIcon/FlagIcon';
import { Country } from '@core/enums/flagsEnum';
import { HomePhrasesEnum } from '@core/enums/localeEnum';
import { useLocale } from '@core/utils/locale';
import { FC } from 'react';

const QuickSearchCountryFooter: FC<{
    selectedCountry?: Country;
    onOpenModal: () => void;
}> = ({
    selectedCountry,
    onOpenModal
}) => {
    const { t } = useLocale();

    return (
        <div className="QuickSearchDropdown__footer">
            <div className="QuickSearchDropdown__footer-country" data-testid="QuickSearchSelectedCountry">
                {selectedCountry && (
                    <>
                        <FlagIcon countryCode={selectedCountry?.code} height={14} />
                        {selectedCountry?.name}
                    </>
                )}
            </div>
            <div>
                <Button onClick={onOpenModal} modifiers={['naked']} testId="QuickSearchButtonChangeCountry">
                    {t(HomePhrasesEnum.Change)}
                </Button>
            </div>
        </div>
    );
};

export default QuickSearchCountryFooter;
