import React from 'react';

interface InformationTooltipProps {
    title: string;
    description: string;
}
import './Tooltip.styles.scss';

const InformationTooltip: React.FC<InformationTooltipProps> = ({
    title,
    description
}) => {
    const descriptionLines = description.split('\n');
    return (
        <div className="InformationTooltip">
            <strong>
                <h3 className="InformationTooltip__title">{title}</h3>
            </strong>
            <p>
                {descriptionLines.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </p>
        </div>
    );
};

export default InformationTooltip;
