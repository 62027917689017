import React from 'react';
import { useRoutes } from 'react-router-dom';

import { RoutesEnum } from '@enums/RoutesEnum';
import Layout from '@components/ui/Layout/Layout';

import Home from '@pages/Home/Home';
import TestPage from '@pages/TestPage/TestPage';
import HeadquarterTargeting from '@pages/HeadquarterTargeting/HeadquarterTargeting';
import OperatorProfile from '@pages/OperatorProfile/OperatorProfile';
import OperatorTargetingResults from '@pages/OperatorTargetingResults/OperatorTargetingResults';
import OperatorTargetingCriteria from '@pages/OperatorTargetingCriteria/OperatorTargetingCriteria';
import NotFound from '@pages/Error/NotFound';
import AccessDenied from '@pages/Error/AccessDenied';
import SavedSearch from '@pages/SavedSearches/SavedSearch';
import { SkeletonProvider } from '@components/utility/Skeleton/SkeletonProvider';

const Router: React.FC = (): JSX.Element => {
    const mainRoutes = {
        path: '/',
        element: <Layout />,
        children: [
            { path: '*', element: <NotFound /> },
            { path: RoutesEnum.Home, element: <Home /> },
            { path: RoutesEnum.OperatorTargetingCriteria, element: <OperatorTargetingCriteria /> },
            { path: RoutesEnum.OperatorTargetingResults, element: <OperatorTargetingResults /> },
            { path: RoutesEnum.HeadquarterTargeting, element: <HeadquarterTargeting /> },
            {
                path: RoutesEnum.OperatorProfile,
                element: (
                    <SkeletonProvider>
                        <OperatorProfile />
                    </SkeletonProvider>
                ),
            },
            { path: RoutesEnum.SavedSearches, element: <SavedSearch /> },
            { path: RoutesEnum.NotFound, element: <NotFound /> },
            { path: RoutesEnum.AccessDenied, element: <AccessDenied /> },
            { path: RoutesEnum.Test, element: <TestPage /> },
        ],
    };

    const routing = useRoutes([mainRoutes]);

    return <>{routing}</>;
};

export default Router;
