import { useMemo, type FC } from 'react';

import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CommonPhrasesEnum, SavedSearchEnum, SidebarPhrasesEnum } from '@core/enums/localeEnum';

import PageTitle from '@components/ui/PageTitle/PageTitle';
import Breadcrumbs from '@components/ui/Breadcrumbs/Breadcrumbs';
import HeaderContent from './HeaderContent';
import SearchCriteriaResults from '../SearchCriteriaResults/SearchCriteriaResults';

const OperatorTargetingResultsHeader: FC = () => {
    const { t } = useLocale();

    const {
        criteria,
        onDeleteCriterionParam,
        savedSearch: { activeSaveSearch },
    } = useAdvancedSearch();

    const pageTitle = useMemo(() => {
        if (activeSaveSearch) {
            return t(SavedSearchEnum.ResultsFor) + ` "${activeSaveSearch.name}"`;
        }
        return t(CommonPhrasesEnum.Results);
    }, [activeSaveSearch, t]);

    return (
        <div className="OperatorTargetingResults__header">
            <Breadcrumbs
                breadcrumbs={[
                    { label: t(SidebarPhrasesEnum.OperatorTargeting), link: RoutesEnum.OperatorTargetingCriteria },
                ]}
            />
            <PageTitle testId="ResultsPageTitle" title={pageTitle} rowContent={<HeaderContent />} />

            {criteria && Object.keys(criteria).length > 0 && (
                <SearchCriteriaResults criteria={criteria} onDeleteCriterionParam={onDeleteCriterionParam} />
            )}
        </div>
    );
};

export default OperatorTargetingResultsHeader;
