import { FCX } from '@core/models';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';

const BusinessInfo: FCX<{
    title: string;
    renderCondition: boolean;
}> = ({
    children,
    title,
    renderCondition,
    testId
}) => {
    if (!renderCondition) return null;

    return (
        <div className="ProfileDetails__business-info">
            <Text variant={TextVariant.B2} testId={`textBusinessInfo${testId}`}>
                {title}
            </Text>
            <Heading variant={HeadingVariant.H5} testId={`BusinessInfoValue${testId}`}>
                {children}
            </Heading>
        </div>
    );
};

export default BusinessInfo;
