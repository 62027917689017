import { FC, ReactNode } from 'react';
import './HierarchyIndicator.style.scss';

const HierarchyIndicator: FC<{
    children: ReactNode[];
}> = ({ children }) => {
    return (
        <div className="HierarchyIndicator">
            {children.map((child, index) => (
                <div key={index} className="HierarchyIndicator__item">
                    {child}
                </div>
            ))}
        </div>
    );
};

export default HierarchyIndicator;
