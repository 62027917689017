export type Content = 'content';
export type ContentType = '*/*';

export enum MethodsEnum {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
}

export enum OperationsEnum {
    RESPONSES = 'responses',
    PARAMETERS = 'parameters',
    REQUEST = 'requestBody',
}
