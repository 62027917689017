import { useCallback, useEffect, useState } from 'react';

import useFields from '@core/api/useFields';
import { useLocale } from '@core/utils/locale';
import { useDropdownInput } from '@core/hooks/useInput';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { usePermissions } from '@core/hooks/usePermissions';
import FlagIcon from '@components/utility/FlagIcon/FlagIcon';
import { AllCountriesResponse } from '@core/api/useCountries';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { CriterionEnum, CriterionParamFilter } from '@core/models/Criterion';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';

export const LOCATION_CRITERION = CriterionEnum.Location;

export enum LocationFieldsEnum {
    ADMIN_AREA_1 = 'ADMINISTRATIVE_AREA_1',
    ADMIN_AREA_2 = 'ADMINISTRATIVE_AREA_2',
    REGION_1 = 'REGION_1',
    POSTAL_CODE = 'POSTAL_CODE',
}

type LocationFields = {
    [key in LocationFieldsEnum]: string;
};

export function useLocationCriteria() {
    const { locale } = useLocale();
    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey,
        countriesList,
        onResetCriterionParamsExceptByFilterKey,
    } = useAdvancedSearch();
    const { countryDropdownOptions } = useGetCountryDropdownOptions(countriesList);
    const {
        selectedOption: selectedCountry,
        onOptionSelected: onSelectCountry
    } = useDropdownInput();

    const {
        locationFields,
        fetchLocationFields
    } = useGetLocationFields();

    useEffect(() => {
        const countryCriterionParam = getCriterionByFilterKey(LOCATION_CRITERION, CriterionParamFilter.Country)?.[0];

        if (countryCriterionParam && countryDropdownOptions && countryDropdownOptions.length > 0) {
            const selectedCountryInUserCountries = countryDropdownOptions.find(
                (country) => country.id === countryCriterionParam.value,
            );

            if (selectedCountryInUserCountries) {
                onSelectCountry(selectedCountryInUserCountries);
            }
        }
    }, [countryDropdownOptions, getCriterionByFilterKey, onSelectCountry, selectedCountry]);

    useEffect(() => {
        if (selectedCountry) {
            fetchLocationFields({
                country: selectedCountry.id.toString(),
                type: 'LOCATION',
                locale,
            });
        }
    }, [fetchLocationFields, locale, selectedCountry]);

    const resetCriterionParamsOnCountryChange = useCallback(
        (option: DropdownOption) => {
            onSelectCountry(option);
            addCriterionOnOptionSelected(LOCATION_CRITERION, CriterionParamFilter.Country)(option);
            onResetCriterionParamsExceptByFilterKey(LOCATION_CRITERION, CriterionParamFilter.Country);
        },
        [addCriterionOnOptionSelected, onResetCriterionParamsExceptByFilterKey, onSelectCountry],
    );

    return {
        selectedCountry,
        resetCriterionParamsOnCountryChange,
        countryDropdownOptions,
        locationFields,
    };
}

function useGetCountryDropdownOptions(countriesList: AllCountriesResponse['countries']) {
    const { getAllowedItemsFromArray } = usePermissions();

    const [countryDropdownOptions, setCountryDropdownOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        if (countriesList && countriesList.length > 0) {
            const plainCountriesList = countriesList.map((country) => country.abbreviation ?? '').filter(Boolean);

            const availableCountries = getAllowedItemsFromArray('countries', plainCountriesList);
            const mappedCountries: DropdownOption[] = countriesList.map((country) => ({
                renderLabel: (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '8px',
                        }}
                    >
                        <FlagIcon countryCode={country.abbreviation as CountryCodesEnum} height={14} />
                        {country.name}
                    </div>
                ),
                label: country?.name ?? '',
                value: country.abbreviation ?? '',
                id: country.abbreviation ?? '',
                isDisabled: !availableCountries.includes(country.abbreviation ?? ''),
            }));

            setCountryDropdownOptions(mappedCountries);
        }
    }, [countriesList, getAllowedItemsFromArray]);

    return { countryDropdownOptions };
}

function useGetLocationFields() {
    const {
        data: locationFieldsData,
        doFetch
    } = useFields();
    const [locationFields, setLocationFields] = useState<LocationFields>();

    useEffect(() => {
        if (locationFieldsData && locationFieldsData.length > 0) {
            const parsedLocationFields = locationFieldsData.reduce((acc, field) => {
                if (field.type && field.label) {
                    acc[field.type as LocationFieldsEnum] = field.label;
                }

                return acc;
            }, {} as LocationFields);

            setLocationFields(parsedLocationFields);
        }
    }, [locationFieldsData]);

    return {
        locationFields,
        fetchLocationFields: doFetch,
    };
}
