import { ID } from '@core/models';
import { IconCheckmark } from '@assets/icons';
import { containsSubstring } from '@utils/string';

export type DropdownOption = {
    label: string;
    renderLabel?: JSX.Element;
    value: string;
    id: ID;
    isDisabled?: boolean;
    additionalData?: Record<string, unknown>;
};

export type DropdownCommonProps = {
    hasError?: boolean;
    errorMessage?: string;
    isDisabled?: boolean;
    placeholder: string;
    options: DropdownOption[];
    selectedOption?: DropdownOption | undefined;
    selectedOptions?: DropdownOption[] | undefined;
    onOptionSelected: (item: DropdownOption) => void;
};

export function renderOptionLabel(option: DropdownOption, isSelected = false) {
    if (isSelected) {
        return (
            <div className="Dropdowns__selected-option">
                <IconCheckmark width={14} />
                {option.label}
            </div>
        );
    }

    if (option?.renderLabel) {
        return option.renderLabel;
    }

    return <div className="Dropdowns__option">{option.label}</div>;
}

export function filterDropdownOptions(options: DropdownOption[], searchQuery: string | undefined) {
    return options.filter((option) => containsSubstring(option.label, searchQuery ?? ''));
}
