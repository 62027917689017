import { FC } from 'react';
import { FormHelperText } from '@mui/material';
import { IconAlert } from '@assets/icons';

const InputHelperText: FC<{
    hasError: boolean;
    errorMessage?: string;
}> = ({
    hasError,
    errorMessage
}) => {
    return (
        <FormHelperText error={hasError}>
            {hasError && (
                <>
                    <IconAlert /> {errorMessage ?? 'Ooops. Required field. Please re-enter.'}
                </>
            )}
        </FormHelperText>
    );
};

export default InputHelperText;
