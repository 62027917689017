import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem/MenuItem';

import { useLocale } from '@core/utils/locale';
import BasicMenu from '@components/utility/Menu/Menu';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';
import { CommonPhrasesEnum, SavedSearchEnum } from '@core/enums/localeEnum';

import EditSearchModal from '../../SavedSearches/SaveSearchModal/EditSearchModal';
import DeleteSearchModal from '../../SavedSearches/SaveSearchModal/DeleteSearchModal';

interface TableActionsMenuProps {
    index: number;
    data: SaveSearchResponse;
}

const TableActionsMenu: React.FC<TableActionsMenuProps> = ({
    index,
    data
}) => {
    const [modalRow, setModalRow] = useState(0);
    const [isMenuOpen, setisMenuOpen] = useState(false);
    const [modal, setModal] = useState({
        type: '',
        index: 0,
    });

    const { t } = useLocale();

    return (
        <div style={{ textAlign: 'right' }}>
            <BasicMenu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                isMenuOpen={isMenuOpen}
                setisMenuOpen={setisMenuOpen}
            >
                <MenuItem
                    onClick={() => {
                        setModal({ type: 'edit', index });
                        setModalRow(index);
                        setisMenuOpen(false);
                    }}
                >
                    <Text variant={TextVariant.B2} testId="buttonSavedSearchEdit">
                        {t(CommonPhrasesEnum.Edit)}
                    </Text>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setModal({ type: 'delete', index });
                        setModalRow(index);
                        setisMenuOpen(false);
                    }}
                >
                    <Text variant={TextVariant.B2} testId="buttonSavedSearchDelete">
                        {t(CommonPhrasesEnum.Delete)}
                    </Text>
                </MenuItem>
            </BasicMenu>

            {modal.type === 'edit' && modalRow === index && (
                <EditSearchModal
                    title={t(CommonPhrasesEnum.EditName)}
                    isOpen={modal.type === 'edit'}
                    handleClose={() => {
                        setModal({ type: '', index: 0 });
                    }}
                    data={data}
                />
            )}
            {modal.type === 'delete' && modalRow === index && (
                <DeleteSearchModal
                    title={t(SavedSearchEnum.DeleteSearch)}
                    isOpen={modal.type === 'delete'}
                    handleClose={() => {
                        setModal({ type: '', index: 0 });
                    }}
                    data={data}
                />
            )}
        </div>
    );
};

export default TableActionsMenu;
