import { Skeleton } from '@mui/material';
import { GoogleMap, Libraries, useJsApiLoader } from '@react-google-maps/api';
import { ReactElement, FC, useCallback, useState, useRef, useEffect, memo } from 'react';

import './Map.styles.scss';
import { CustomZoomControl } from './CustomZoomControl';

const REACT_APP_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
    zoom: 17,
    center: { lat: 0, lng: 0 },
    minZoom: 2,
    maxZoom: 19,
    disableDefaultUI: true,
};

const DEFAULT_LIBRARIES: Libraries = ['places', 'marker'];

const Map: FC<{
    libraries?: Libraries;
    options: google.maps.MapOptions;
    children?: ReactElement;
    getMapCallback?: (map: google.maps.Map) => void;
}> = ({
    children,
    libraries,
    options,
    getMapCallback
}) => {
    const [, setMap] = useState<google.maps.Map | null>(null);
    const [showCustomZoomControl, setShowCustomZoomControl] = useState<boolean>(false);

    const {
        isLoaded,
        loadError
    } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
        libraries: libraries || DEFAULT_LIBRARIES,
    });

    const mergedOptions = useRef<google.maps.MapOptions | null>(null);

    useEffect(() => {
        if (mergedOptions.current === null && options) {
            mergedOptions.current = {
                ...DEFAULT_MAP_OPTIONS,
                ...(options || {}),
                ...(options?.zoomControl === true && { zoomControl: false }),
            };

            if (options?.zoomControl === true) {
                setShowCustomZoomControl(true);
            }
        }
    }, [options]);

    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            setTimeout(() => {
                setMap(map);
                getMapCallback && getMapCallback(map);
            }, 400);
        },
        [getMapCallback],
    );

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    if (!isLoaded || loadError || mergedOptions.current === null) {
        return <Skeleton className="Map" animation="pulse" />;
    }

    return (
        <div className="Map" data-testid="googleMapsContainer">
            <GoogleMap
                mapContainerClassName="Map__container"
                options={mergedOptions.current}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
            >
                {children}
                {showCustomZoomControl && <CustomZoomControl />}
            </GoogleMap>
        </div>
    );
};

export default memo(Map);
