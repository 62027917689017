import { FC } from 'react';

import Page from '@components/ui/Page/Page';

import './Layout.styles.scss';
import SidebarSkeleton from '../Sidebar/Sidebar.skeleton';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';

const LayoutSkeleton: FC = (): JSX.Element => {
    return (
        <div className="Layout">
            <SidebarSkeleton />
            <Page isMenuOpen={false}>
                <AnimatedLoader className="Layout__loader" />
            </Page>
        </div>
    );
};

export default LayoutSkeleton;
