import React, { useCallback, useState } from 'react';
import { InputLabel, OutlinedInput, Select } from '@mui/material';
import cn from 'classnames';

import { FCX } from '@core/models';
import Popover from '@components/utility/Popover/Popover';

import SelectIcon from '../../SelectIcon';
import InputHelperText from '../../InputHelperText';
import { DEFAULT_POPOVER_POSITION } from '../../Inputs.helpers';
import { DropdownCommonProps, DropdownOption } from '../Dropdowns.helpers';
import { useLocale } from '@core/utils/locale';
import { OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';

const INPUT_HEIGHT = 42;

const SelectWithCustomDropdown: FCX<
    DropdownCommonProps & {
        inputLabel?: string;
        dropdownPlaceholder?: string;
        DropdownComponent: FCX<DropdownCommonProps>;
        unscrollable?: boolean;
        isMulti?: boolean;
    }
> = ({
    options,
    inputLabel,
    placeholder,
    hasError = false,
    isDisabled = false,
    DropdownComponent,
    dropdownPlaceholder,
    onOptionSelected,
    unscrollable,
    selectedOption,
    selectedOptions,
    isMulti,
    testId,
    errorMessage,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const { t } = useLocale();

    const handleOptionSelected = useCallback(
        (item: DropdownOption) => {
            onOptionSelected(item);

            if (!isMulti) {
                setIsDropdownOpen(false);
            }
        },
        [isMulti, onOptionSelected],
    );

    const onSelectClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (isDisabled) return;

            setAnchorEl((prevAnchor) => prevAnchor ?? e.currentTarget);
            setIsDropdownOpen((prevOpen) => !prevOpen);
        },
        [isDisabled],
    );

    const renderValue = useCallback(() => {
        if (isMulti && selectedOptions && selectedOptions.length > 0) {
            return selectedOptions.length > 1
                ? `${selectedOptions.length} ${t(OperatorTargetingPhrasesEnum.Selected)}`
                : selectedOptions[0].label;
        }

        if (selectedOption?.label) {
            return selectedOption?.label;
        }

        return placeholder;
    }, [isMulti, selectedOptions, selectedOption?.label, placeholder, t]);

    return (
        <div className="SelectWithCustomDropdown">
            {!!inputLabel && (
                <InputLabel data-testid={`label${testId}`} error={hasError}>
                    {inputLabel}
                </InputLabel>
            )}
            <Select
                fullWidth
                open={false}
                displayEmpty={true}
                onClick={onSelectClick}
                renderValue={renderValue}
                IconComponent={() => null}
                sx={{ height: INPUT_HEIGHT }}
                className={cn(isDropdownOpen && 'Mui-focused')}
                endAdornment={<SelectIcon isOpen={isDropdownOpen} />}
                input={
                    <OutlinedInput
                        error={hasError}
                        disabled={isDisabled}
                        placeholder={placeholder}
                        fullWidth
                        data-testid={testId}
                    />
                }
            >
                <option />
            </Select>
            <InputHelperText
                hasError={hasError}
                errorMessage={errorMessage ?? 'Ooops. Required field. Please select.'}
            />
            <Popover
                {...DEFAULT_POPOVER_POSITION}
                open={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
                anchorEl={anchorEl}
                unscrollable={unscrollable}
            >
                <div style={{ width: anchorEl?.clientWidth ?? 300 }}>
                    <DropdownComponent
                        options={options}
                        onOptionSelected={handleOptionSelected}
                        selectedOption={selectedOption}
                        selectedOptions={selectedOptions}
                        hasError={hasError}
                        isDisabled={isDisabled}
                        placeholder={dropdownPlaceholder ?? 'Search'}
                        testId={testId}
                    />
                </div>
            </Popover>
        </div>
    );
};

export default SelectWithCustomDropdown;
