import { memo, useEffect, useState } from 'react';

import { useLocale } from '@core/utils/locale';
import useAdminAreaTwo from '@core/api/useAdminAreaTwo';
import { usePermissions } from '@core/hooks/usePermissions';
import { CriterionParamFilter } from '@core/models/Criterion';
import { useDropdownMultiSelect } from '@core/hooks/useInput';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { LocalesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';
import { LOCATION_CRITERION } from '@pages/OperatorTargetingCriteria/hooks/useLocationCriteria';
import DropdownMultiSelect from '@components/utility/Inputs/Dropdowns/DropdownMultiSelect/DropdownMultiSelect';
import { mapCriterionParamsToDropdownOptions } from '@pages/OperatorTargetingCriteria/helpers/Criteria.helpers';

type AdminAreaTwoProps = {
    inputLabel: string;
    country: string;
};

const AdminAreaTwo = memo<AdminAreaTwoProps>(function AdminAreaTwo({
    inputLabel,
    country
}) {
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
    const { getAllowedItemsFromArray } = usePermissions();

    const {
        t,
        locale
    } = useLocale();

    const {
        addCriterionOnOptionSelected,
        getCriterionByFilterKey
    } = useAdvancedSearch();

    const {
        data,
        error
    } = useAdminAreaTwo(country);

    const {
        selectedOptions,
        onOptionSelected,
        onMultipleSelectedOptions
    } = useDropdownMultiSelect(
        addCriterionOnOptionSelected(LOCATION_CRITERION, CriterionParamFilter.AdministrativeArea2),
    );

    useEffect(() => {
        if (data && data.length > 0) {
            const plainAdminAreaTwoList = data
                .map((adminAreaOption) => adminAreaOption.search_value ?? '')
                .filter(Boolean);

            const availableAdminAreaTwo = getAllowedItemsFromArray(
                'administrative_area2',
                plainAdminAreaTwoList,
                country,
            );

            const mappedOptions = data.map((adminAreaOption) => ({
                id: adminAreaOption.id?.toString() ?? '',
                label: adminAreaOption.display_value ?? '',
                value: adminAreaOption.search_value ?? '',
                isDisabled: !availableAdminAreaTwo.includes(adminAreaOption.search_value ?? ''),
            }));

            setDropdownOptions(mappedOptions);
        }
    }, [country, data, getAllowedItemsFromArray]);

    useEffect(() => {
        if (error) {
            setDropdownOptions([]);
        }
    }, [error]);

    useEffect(() => {
        const adminAreaParams = getCriterionByFilterKey(LOCATION_CRITERION, CriterionParamFilter.AdministrativeArea2);
        const optionsList = mapCriterionParamsToDropdownOptions(adminAreaParams ?? []);
        onMultipleSelectedOptions(optionsList);
    }, [country, onMultipleSelectedOptions, getCriterionByFilterKey]);

    if (error) {
        return null;
    }

    return (
        <DropdownMultiSelect
            selectedOptions={selectedOptions}
            onOptionSelected={onOptionSelected}
            options={dropdownOptions}
            inputLabel={inputLabel}
            placeholder={`${t(OperatorTargetingPhrasesEnum.Select).replace('une', 'un')} ${
                locale === LocalesEnum.Fr ? inputLabel.toLowerCase() : inputLabel
            }${t(OperatorTargetingPhrasesEnum.SelectPlural)}`}
            dropdownPlaceholder="Search..."
            testId="DropdownMultiSelectAdminAreaTwo"
            hasError={!!error}
            isDisabled={!!error}
            errorMessage={`Error. ${inputLabel} options not found.`}
        />
    );
});

export default AdminAreaTwo;
