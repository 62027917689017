import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react';

import { OperatorFilter } from '@graphql/generated/graphql';
import useTableSort, { useTableSortReturn } from '@hooks/useTableSort';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';

import useAdvancedSearchCriteria, {
    onAddCriterionParamArgs,
    onDeleteCriterionParamArgs,
    getCriterionByFilterKeyArgs,
    onResetCriteriaParams,
    onResetCriterionParamsByKeyArgs,
    onResetCriteriaByFilterKeyArgs,
    onMergeCriteriaParams,
    SavedSearchType,
} from '@core/hooks/useAdvancedSearchCriteria';
import { useLocale } from '@core/utils/locale';
import usePagination from '@core/hooks/usePagination';
import useAdvancedSearchFilters from '@core/hooks/useAdvancedSearchFilters';
import useCountries, { AllCountriesResponse } from '@core/api/useCountries';
import { CriteriaData, CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import { OP_TARGETING_DEFAULT_SORT_FIELD } from '@pages/OperatorTargetingResults/hooks/useOperatorResultsTable';

type AdvancedSearchContextType = {
    criteria: CriteriaData | undefined;
    onDeleteCriterionParam: onDeleteCriterionParamArgs;
    onAddCriterionParam: onAddCriterionParamArgs;
    onResetCriteria: onResetCriteriaParams;
    onMergeCriteria: onMergeCriteriaParams;
    onResetCriterionParamsByKey: onResetCriterionParamsByKeyArgs;
    onResetCriteriaByFilterKey: onResetCriteriaByFilterKeyArgs;
    onResetCriterionParamsExceptByFilterKey: onResetCriteriaByFilterKeyArgs;
    getCriterionByFilterKey: getCriterionByFilterKeyArgs;
    searchFilters?: OperatorFilter;
    advancedSearchSort: useTableSortReturn;
    savedSearch: SavedSearchType;
    addCriterionOnOptionSelected: (
        criterionKey: CriterionEnum,
        filterKey: CriterionParamFilter,
    ) => (option: DropdownOption) => void;
    countriesList?: AllCountriesResponse['countries'];
    pagination: ReturnType<typeof usePagination>;
};

export const AdvancedSearchContext = createContext<AdvancedSearchContextType | undefined>(undefined);

export function AdvancedSearchProvider({ children }: { children: ReactNode }) {
    const { locale } = useLocale();
    const {
        criteria,
        onDeleteCriterionParam,
        onAddCriterionParam,
        getCriterionByFilterKey,
        onResetCriteria,
        onMergeCriteria,
        savedSearch,
        onResetCriterionParamsByKey,
        onResetCriteriaByFilterKey,
        onResetCriterionParamsExceptByFilterKey,
    } = useAdvancedSearchCriteria();
    const { searchFilters } = useAdvancedSearchFilters(criteria);
    const advancedSearchSort = useTableSort(OP_TARGETING_DEFAULT_SORT_FIELD);

    const {
        data: countriesList,
        defaultOrFirstAllowedCountry,
        loading: allCountriesLoading,
        doFetch
    } = useCountries();

    useEffect(() => {
        doFetch({
            locale,
        });
    }, [doFetch, locale]);

    const pagination = usePagination();

    const setDefaultCountryParam = useCallback(() => {
        if (defaultOrFirstAllowedCountry?.abbreviation && defaultOrFirstAllowedCountry?.name) {
            const criterionParam: CriterionParam = {
                value: defaultOrFirstAllowedCountry.abbreviation,
                name: defaultOrFirstAllowedCountry.name,
                filterKey: CriterionParamFilter.Country,
            };

            onAddCriterionParam(CriterionEnum.Location, criterionParam);
        }
    }, [defaultOrFirstAllowedCountry?.abbreviation, defaultOrFirstAllowedCountry?.name, onAddCriterionParam]);

    useEffect(() => {
        const countryCriterion = getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Country)?.[0];

        if (!countryCriterion && allCountriesLoading === false) {
            setDefaultCountryParam();
        }
    }, [allCountriesLoading, getCriterionByFilterKey, setDefaultCountryParam]);

    function addCriterionOnOptionSelected(criterionKey: CriterionEnum, filterKey: CriterionParamFilter) {
        return (option: DropdownOption) => {
            const criterionParam = {
                value: option.value,
                name: option.label,
                filterKey,
                additionalData: option?.additionalData,
            };

            onAddCriterionParam(criterionKey, criterionParam);
        };
    }

    return (
        <AdvancedSearchContext.Provider
            value={{
                criteria,
                onDeleteCriterionParam,
                onAddCriterionParam,
                searchFilters,
                onResetCriteria,
                advancedSearchSort,
                onMergeCriteria,
                savedSearch,
                getCriterionByFilterKey,
                addCriterionOnOptionSelected,
                countriesList,
                onResetCriterionParamsByKey,
                onResetCriteriaByFilterKey,
                onResetCriterionParamsExceptByFilterKey,
                pagination,
            }}
        >
            {children}
        </AdvancedSearchContext.Provider>
    );
}

export function useAdvancedSearch() {
    const context = useContext(AdvancedSearchContext);
    if (context === undefined) {
        throw new Error('useAdvancedSearch must be used within a AdvancedSearchProvider');
    }
    return context;
}
