import { memo, useMemo } from 'react';
import { useSpring, animated } from 'react-spring';

import { useLocale } from '@core/utils/locale';
import { formatNumberByLocale } from '@core/utils/string';

import './NumberAnimation.styles.scss';

function useNumberSpring(count: number) {
    const { number } = useSpring({
        from: { number: 0 },
        number: count,
        delay: 100,
        config: { duration: 1000 },
    });

    return number;
}

export function NumberAnimation({
    count,
    className
}: { count: number; className?: string }) {
    const number = useNumberSpring(count);
    const locale = useLocale().locale;

    const formattedNumber = useMemo(() => number.to((n) => formatNumberByLocale(n, locale)), [locale, number]);

    return (
        <div className={className}>
            <animated.div className="NumberAnimation" data-testid="textOperatorsCount">
                {formattedNumber}
            </animated.div>
        </div>
    );
}

export default memo(NumberAnimation);
