import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { onError } from '@apollo/client/link/error';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';

import Router from '@core/routes';
import { LocaleProvider } from '@utils/locale';
import AxiosInterceptor from '@core/api/AxiosInterceptors';
import { ExportOperatorsProvider, AdvancedSearchProvider } from '@core/contexts';

import { theme } from './styles';
import { AppProvider } from './App.context';

const App: React.FC = () => {
    const link = createHttpLink({
        uri: `${process.env.REACT_APP_API}/operators/search/advanced`,
        credentials: 'include',
    });

    const errorLink = onError(({ networkError }) => {
        // @ts-expect-error ServerParseError
        if (networkError?.statusCode === 401 || networkError?.message === 'Failed to fetch') {
            window.location.href = `${process.env.REACT_APP_API}/oauth2/authorization/snap?returnUrl=${window.location.href}`;
        }
    });

    const client = new ApolloClient({
        link: errorLink.concat(link),
        cache: new InMemoryCache({
            resultCaching: false,
        }),
    });

    return (
        <BrowserRouter>
            <ApolloProvider client={client}>
                <AxiosInterceptor>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <AppProvider>
                                <LocaleProvider>
                                    <AdvancedSearchProvider>
                                        <ExportOperatorsProvider>
                                            <Router />
                                        </ExportOperatorsProvider>
                                    </AdvancedSearchProvider>
                                </LocaleProvider>
                            </AppProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </AxiosInterceptor>
            </ApolloProvider>
        </BrowserRouter>
    );
};

export default App;
