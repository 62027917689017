import useFetch, { Config } from '@hooks/useFetch';
import { paths } from '@core/api/api';
import { MethodsEnum, OperationsEnum, Content } from '@core/enums/api';
import { useCallback, useEffect, useState } from 'react';

const POST_SAVE_SEARCH = '/saved_searches';

type GetSaveSearchEndpoint = paths[typeof POST_SAVE_SEARCH][MethodsEnum.POST];
export type SaveSearchResponse = GetSaveSearchEndpoint[OperationsEnum.RESPONSES]['201'][Content]['application/json'];
export type SaveSearchParams = GetSaveSearchEndpoint[OperationsEnum.REQUEST]['content']['application/json'];

function useSaveSearch() {
    const [success, setSuccess] = useState(false);

    const {
        data,
        loading,
        error,
        doFetch
    } = useFetch<SaveSearchResponse>({
        url: POST_SAVE_SEARCH,
        method: 'POST',
        lazy: true,
    });

    useEffect(() => {
        if (data) {
            setSuccess(true);
        }
    }, [data]);

    const doFetchWithParams = useCallback(
        (payload?: SaveSearchParams, newConfig?: Config) => {
            return doFetch({
                data: payload,
                ...newConfig,
            });
        },
        [doFetch],
    );
    return { data, loading, success, error, doFetch: doFetchWithParams } as const;
}

export default useSaveSearch;
