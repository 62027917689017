import type { FC, ReactNode } from 'react';

import { ID } from '@core/models';
import { capitalize } from '@core/utils/string';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import { OperatorSummary } from '@graphql/generated/graphql';
import { getFormattedPhoneNumber } from '@core/helpers/helpers';
import type { useTableSortReturn } from '@core/hooks/useTableSort';

export type TableConfig<T> = {
    config: Array<TableConfigItem<T>>;
    rowConfig?: TableConfigRow;
    sortConfig?: useTableSortReturn;
};

export type TableConfigRow = {
    anchorHref?: (index: ID) => string;
};

export type TableConfigItem<T> = {
    onClick?: (index: ID) => void;
    title: T;
    field: string;
    align?: 'left' | 'right';
    cellStyles?: React.CSSProperties;
    Cell: FC<{ index: number }>;
    isSortable: boolean;
};

type SanitizeConfig = {
    withLineBreak?: boolean;
    withSpace?: boolean;
    capsAll?: boolean;
};

const defaultSanitizeConfig: SanitizeConfig = {
    withLineBreak: false,
    withSpace: true,
    capsAll: false,
};

function sanitizeAddress(value: string | null | undefined, config?: SanitizeConfig): string | null {
    const {
        withLineBreak,
        withSpace,
        capsAll
    } = { ...defaultSanitizeConfig, ...config };

    return value ? `${withLineBreak ? '\n' : withSpace ? ' ' : ''}${capitalize(value, capsAll)}` : '';
}

function parseNorthAmericanAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return `${sanitizeAddress(location?.address_1, { withSpace: false })}${sanitizeAddress(
        location?.address_2,
    )}${sanitizeAddress(location?.locality_1, { withLineBreak: true })},${sanitizeAddress(
        location?.administrative_area_1,
        { capsAll: true },
    )}${sanitizeAddress(location?.postal_code_1, { capsAll: true })}${sanitizeAddress(
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
        { withLineBreak: true },
    )}`;
}

function parseEmeaAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return `${sanitizeAddress(location?.address_1, { withSpace: false })}${sanitizeAddress(
        location?.address_2,
    )}${sanitizeAddress(location?.postal_code_1, { withLineBreak: true, capsAll: true })}${sanitizeAddress(
        location?.locality_1,
    )}${sanitizeAddress(getFormattedPhoneNumber(phone ?? '', location?.country ?? ''), { withLineBreak: true })}`;
}

function parseMalaysiaAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return `${sanitizeAddress(location?.address_1, { withSpace: false })}${sanitizeAddress(location?.postal_code_1, {
        capsAll: true,
        withLineBreak: true,
    })}${sanitizeAddress(location?.locality_1)}${sanitizeAddress(location?.administrative_area_1, {
        withLineBreak: true,
    })}${sanitizeAddress(location?.country)}${sanitizeAddress(
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
        { withLineBreak: true },
    )}`;
}

function parseSingaporeAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return `${sanitizeAddress(location?.address_1, { withSpace: false })}${sanitizeAddress(location?.locality_1, {
        withLineBreak: true,
    })}${sanitizeAddress(location?.postal_code_1)}${sanitizeAddress(
        getFormattedPhoneNumber(phone ?? '', location?.country ?? ''),
        { withLineBreak: true },
    )}`;
}

function parseUnitedKingdomAddress(location: OperatorSummary['location'], phone?: string | null): string {
    return `${sanitizeAddress(location?.address_1, { withSpace: false })}${sanitizeAddress(
        location?.address_2,
    )}${sanitizeAddress(location?.locality_1, { withLineBreak: true })}${sanitizeAddress(location?.postal_code_1, {
        capsAll: true,
    })}${sanitizeAddress(getFormattedPhoneNumber(phone ?? '', location?.country ?? ''), { withLineBreak: true })}`;
}

export function buildOperatorAddress(location: OperatorSummary['location'], phone?: string | null): ReactNode {
    let parsedAddress = '';

    switch (location?.country) {
        case CountryCodesEnum.US:
        case CountryCodesEnum.CA:
        case CountryCodesEnum.AU:
        case CountryCodesEnum.NZ:
            parsedAddress = parseNorthAmericanAddress(location, phone);
            break;

        case CountryCodesEnum.UK:
            parsedAddress = parseUnitedKingdomAddress(location, phone);
            break;

        case CountryCodesEnum.MY:
            parsedAddress = parseMalaysiaAddress(location, phone);
            break;

        case CountryCodesEnum.SG:
            parsedAddress = parseSingaporeAddress(location, phone);
            break;

        default:
            parsedAddress = parseEmeaAddress(location, phone);
            break;
    }

    if (!parsedAddress) return null;

    return (
        <div
            style={{
                whiteSpace: 'pre-wrap',
            }}
            data-testid="tableCellAddress"
        >
            {parsedAddress}
        </div>
    );
}
