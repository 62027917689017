import { FC } from 'react';
import cn from 'classnames';
import PaginationButtons from './PaginationButtons';
import PaginationSelect from './PaginationSelect';

import './Pagination.styles.scss';

const Pagination: FC<{
    activePage: number;
    perPage: number;
    totalPagesCount: number;
    totalResultsCount: number;
    onChangePage: (page: number) => void;
    onChangePerPage: (perPage: number, totalResults?: number) => void;
}> = ({
    totalPagesCount,
    totalResultsCount,
    activePage,
    perPage,
    onChangePage,
    onChangePerPage
}) => {
    return (
        <div className={cn('Pagination')}>
            <PaginationSelect
                perPage={perPage}
                onChangePerPage={onChangePerPage}
                totalResultsCount={totalResultsCount}
            />
            <PaginationButtons totalPagesCount={totalPagesCount} page={activePage} onChangePage={onChangePage} />
        </div>
    );
};

export default Pagination;
