import { FCX, ID } from '@core/models';

import './VerticalProgressBar.styles.scss';

export type VerticalProgressBarData = {
    id: ID;
    progress: number;
    title: string;
    value: string;
};

const RANGE_MAX = 100;
const RANGE_MIN = 0;

const VerticalProgressBar: FCX<{
    barKey?: ID;
    progress: number;
    title: string;
    value: string;
    disabled?: boolean;
    onBarClick?: (barId: ID, barTitle: string) => void;
}> = ({
    barKey,
    progress,
    title,
    value,
    disabled = false,
    testId,
    onBarClick
}) => {
    const parsedProgress = `${Math.min(Math.max(RANGE_MIN, progress), RANGE_MAX)}%`;
    const handleClick = () => {
        if (barKey && onBarClick) {
            onBarClick(barKey, title);
        }
    };

    const hasValue = value.length > 0 && value !== '0.0%' && value !== '0';
    const opacity = hasValue ? 1 : 0.3;

    return (
        <div className={`VerticalProgressBar ${disabled ? 'disabled' : ''}`}>
            <div className="VerticalProgressBar__container">
                <div
                    className={`VerticalProgressBar__bar ${disabled ? 'disabled' : ''}`}
                    style={{ height: parsedProgress, opacity: opacity }}
                />
                {hasValue && (
                    <div
                        className={`VerticalProgressBar__bubble ${disabled ? 'disabled' : ''}`}
                        style={{ bottom: parsedProgress }}
                    >
                        <span
                            className={`VerticalProgressBar__bubble-text ${onBarClick ? 'VerticalProgressBar__bubble-text--clickable' : ''} ${disabled ? 'disabled' : ''}`}
                            data-testid={testId && `text${testId}Value`}
                            onClick={handleClick}
                        >
                            {value}
                        </span>
                    </div>
                )}
            </div>
            <div
                className={`VerticalProgressBar__title ${disabled ? 'disabled' : ''}`}
                data-testid={testId && `text${testId}Title`}
            >
                {title}
            </div>
        </div>
    );
};

export default VerticalProgressBar;
