import { useMemo } from 'react';
import { useTableSortReturn, SortOrder } from './useTableSort';
import { SaveSearchResponse } from '@core/api/SaveSearch/useSaveSearch';

type SortableTextFields = 'created_by_name' | 'name';

const useSavedSearchTableSort = (
    data: SaveSearchResponse[],
    sortedColumns: useTableSortReturn['sortedColumns'],
): SaveSearchResponse[] => {
    const sortedResults: SaveSearchResponse[] = useMemo(() => {
        const dataCopy = [...data];
        const columnToSort = sortedColumns[0];
        const field = columnToSort?.field;

        if (!columnToSort) {
            return data;
        }

        const sortFunction =
            mapSortingFunction?.[field as keyof typeof mapSortingFunction] ?? mapSortingFunction.default;

        return dataCopy.sort((a, b) => {
            if (columnToSort.order === SortOrder.Asc) {
                return sortFunction(a, b, field as SortableTextFields);
            }

            return sortFunction(b, a, field as SortableTextFields);
        });
    }, [data, sortedColumns]);

    return sortedResults;
};

const onSortLastUpdated = (a: SaveSearchResponse, b: SaveSearchResponse) => {
    return new Date(b.last_updated ?? '').getTime() - new Date(a.last_updated ?? '').getTime();
};

const onSortType = (a: SaveSearchResponse) => {
    return a.publicly_available ? -1 : 1;
};

const onSortText = (a: SaveSearchResponse, b: SaveSearchResponse, field: SortableTextFields) => {
    const valueA = a[field] || '';
    const valueB = b[field] || '';
    return valueA.localeCompare(valueB);
};

const mapSortingFunction = {
    last_updated: onSortLastUpdated,
    publicly_available: onSortType,
    default: onSortText,
};

export default useSavedSearchTableSort;
