import { OperatorProfilePhrasesEnum } from '@core/enums/localeEnum';

export type ParsedPurchasePotentialData = Record<
    OperatorProfilePhrasesEnum,
    {
        name: string;
        value: string;
        percentage: number;
    }[]
>;

export enum PotentialsEnum {
    PURCHASE_POTENTIAL_SUM_FOOD = 'PURCHASE_POTENTIAL_SUM_FOOD',
    PURCHASE_POTENTIAL_SUM_TOTAL = 'PURCHASE_POTENTIAL_SUM_TOTAL',
    PURCHASE_POTENTIAL_DISPOSABLE = 'PURCHASE_POTENTIAL_DISPOSABLE',
    PURCHASE_POTENTIAL_SUM_BEVERAGE = 'PURCHASE_POTENTIAL_SUM_BEVERAGE',
    PURCHASE_POTENTIAL_DRY = 'PURCHASE_POTENTIAL_DRY',
    PURCHASE_POTENTIAL_FRESH = 'PURCHASE_POTENTIAL_FRESH',
    PURCHASE_POTENTIAL_FROZEN = 'PURCHASE_POTENTIAL_FROZEN',
    PURCHASE_POTENTIAL_ALCOHOL = 'PURCHASE_POTENTIAL_ALCOHOL',
    PURCHASE_POTENTIAL_NON_ALCOHOL = 'PURCHASE_POTENTIAL_NON_ALCOHOL',
}

export const DEFAULT_PURCHASE_POTENTIAL_GROUPS = [
    {
        name: OperatorProfilePhrasesEnum.FoodRelated,
        items: [
            PotentialsEnum.PURCHASE_POTENTIAL_SUM_FOOD,
            PotentialsEnum.PURCHASE_POTENTIAL_SUM_BEVERAGE,
            PotentialsEnum.PURCHASE_POTENTIAL_DISPOSABLE,
        ],
    },
];

export const DEFAULT_SORT_ORDER: {
    [key: string]: number;
} = {
    [OperatorProfilePhrasesEnum.FoodRelated]: 1,
    [OperatorProfilePhrasesEnum.Beverage]: 2,
    [OperatorProfilePhrasesEnum.Disposable]: 3,
};

export const EXPANDED_PURCHASE_POTENTIAL_GROUPS = [
    {
        name: OperatorProfilePhrasesEnum.FoodRelated,
        items: [
            PotentialsEnum.PURCHASE_POTENTIAL_DRY,
            PotentialsEnum.PURCHASE_POTENTIAL_FRESH,
            PotentialsEnum.PURCHASE_POTENTIAL_FROZEN,
        ],
    },
    {
        name: OperatorProfilePhrasesEnum.Beverage,
        items: [PotentialsEnum.PURCHASE_POTENTIAL_ALCOHOL, PotentialsEnum.PURCHASE_POTENTIAL_NON_ALCOHOL],
    },
    {
        name: OperatorProfilePhrasesEnum.Disposable,
        items: [PotentialsEnum.PURCHASE_POTENTIAL_DISPOSABLE],
    },
];

export const mapPurchasePotentialNames = {
    [PotentialsEnum.PURCHASE_POTENTIAL_SUM_FOOD]: OperatorProfilePhrasesEnum.FoodRelated,
    [PotentialsEnum.PURCHASE_POTENTIAL_SUM_TOTAL]: OperatorProfilePhrasesEnum.Total,
    [PotentialsEnum.PURCHASE_POTENTIAL_DISPOSABLE]: OperatorProfilePhrasesEnum.Disposable,
    [PotentialsEnum.PURCHASE_POTENTIAL_SUM_BEVERAGE]: OperatorProfilePhrasesEnum.Beverage,
    [PotentialsEnum.PURCHASE_POTENTIAL_DRY]: OperatorProfilePhrasesEnum.Dry,
    [PotentialsEnum.PURCHASE_POTENTIAL_FRESH]: OperatorProfilePhrasesEnum.Fresh,
    [PotentialsEnum.PURCHASE_POTENTIAL_FROZEN]: OperatorProfilePhrasesEnum.Frozen,
    [PotentialsEnum.PURCHASE_POTENTIAL_ALCOHOL]: OperatorProfilePhrasesEnum.Alcohol,
    [PotentialsEnum.PURCHASE_POTENTIAL_NON_ALCOHOL]: OperatorProfilePhrasesEnum.NonAlcohol,
};
