import { FC } from 'react';
import cn from 'classnames';

import { Country } from '@core/enums/flagsEnum';
import Modal from '@components/utility/Modal/Modal';

import './SelectCountryModal.styles.scss';
import FlagIcon from '@components/utility/FlagIcon/FlagIcon';
import { IconCheckmark } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { HomePhrasesEnum, OperatorTargetingPhrasesEnum } from '@core/enums/localeEnum';

const SelectCountryModal: FC<{
    isOpen: boolean;
    handleClose: () => void;
    onChangeCountry: (country: Country) => void;
    countries: Country[];
    selectedCountry?: Country;
}> = ({
    isOpen,
    handleClose,
    countries,
    onChangeCountry,
    selectedCountry
}) => {
    const { t } = useLocale();

    return (
        <Modal
            data-testid="SelectCountryModal"
            title={`${t(OperatorTargetingPhrasesEnum.SelectCountry)}`}
            isOpen={isOpen}
            handleClose={handleClose}
            modalProps={{ keepMounted: true }}
        >
            <div className="SelectCountry">
                <div className="SelectCountry__body">
                    {countries.map((country) => {
                        const isActive = country.code === selectedCountry?.code;

                        return (
                            <div
                                className={cn('SelectCountry__selector', isActive && 'is-active')}
                                key={country.code}
                                onClick={() => onChangeCountry(country)}
                                data-testid="ModalButtonCountrySelector"
                            >
                                <FlagIcon countryCode={country.code} height={16} width={16} />
                                <span data-testid="ModalButtonTextCountrySelector">{country.name}</span>
                                {isActive && (
                                    <div className="SelectCountry__selector-check">
                                        <IconCheckmark width={10} color="white" />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="SelectCountry__footer" data-testid="ModalWarningText">
                    <strong>{t(HomePhrasesEnum.DontSeeCountry)}</strong>
                    {t(HomePhrasesEnum.ContactAdmin)}
                </div>
            </div>
        </Modal>
    );
};

export default SelectCountryModal;
