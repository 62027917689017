import { useMemo } from 'react';
import cn from 'classnames';

import { FCX, ID } from '@core/models';

import { iNavigationItem } from './SearchNavigation';
import BadgeIcon, { BadgeIconSizeEnum } from '@components/utility/BadgeIcon/BadgeIcon';
import { IconArrowCollapse } from '@assets/icons';

export const NavigationItem: FCX<{
    level: number;
    item: iNavigationItem;
    onChange?: (item: iNavigationItem) => void;
}> = ({
    item,
    level,
    onChange,
    testId
}) => {
    const shouldShowCaret: boolean = useMemo(() => {
        if (item.subItems && item.subItems.length > 0) {
            return true;
        }

        if (item.filters && item.filters.length > 0) {
            return true;
        }

        return false;
    }, [item]);

    const scrollToElement = (id: ID) => {
        const itemToScroll = document.getElementById(id as string);
        itemToScroll?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    return (
        <div className="SearchNavigation__item-wrapper">
            <div
                data-testid={testId}
                className={cn('SearchNavigation__item', `lvl-${level}`)}
                onClick={() => {
                    onChange?.(item);

                    if (item?.onClick) {
                        item.onClick(item);
                    }

                    scrollToElement(item.id);
                }}
            >
                {item?.icon && item?.baseColor && (
                    <BadgeIcon size={BadgeIconSizeEnum.Small} icon={item.icon} baseColor={item.baseColor} />
                )}

                <div
                    className={cn('SearchNavigation__item-text', `lvl-${level}`, {
                        'is-active': item.isActive,
                    })}
                >
                    {item.title}
                </div>

                {shouldShowCaret && (
                    <IconArrowCollapse
                        className={cn('SearchNavigation__item-caret', {
                            'is-active': item.isActive,
                        })}
                    />
                )}
            </div>

            {item.isActive && item.subItems && item.subItems.length > 0 && (
                <div className="SearchNavigation__subitems">
                    {item.subItems.map((subItem) => (
                        <NavigationItem
                            key={`${item.id}-${subItem.id}`}
                            level={level + 1}
                            item={subItem}
                            onChange={onChange}
                            testId={subItem?.testId}
                        />
                    ))}
                </div>
            )}

            {item.isActive && item.filters && item.filters.length > 0 && (
                <div className="SearchNavigation__filters">
                    {item.filters.map((filter) => (
                        <div
                            key={filter.id}
                            data-testid={filter.testId}
                            className="SearchNavigation__filter-item"
                            onClick={() => {
                                scrollToElement(filter.id);
                                onChange?.(filter);
                            }}
                        >
                            {filter.title}
                            <div
                                className={cn('SearchNavigation__filter-indicator', {
                                    'is-active': filter.isActive,
                                })}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
