import { useCallback, useState } from 'react';
import cn from 'classnames';

import { FCX, ID } from '@core/models';
import Chip from '@components/utility/Chips/Chip';
import { removeWhitespaces } from '@core/utils/string';
import Popover from '@components/utility/Popover/Popover';
import { IconArrowCollapse, IconClose } from '@assets/icons';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import { CriteriaData, CriterionParam } from '@core/models/Criterion';

import './CriteriaChipsGroup.styles.scss';

const CriteriaChipsGroup: FCX<{
    onClearGroup: () => void;
    criteriaList: CriterionParam[];
    onDeleteCriteria: (criterionKey: keyof CriteriaData, criterionParamId: ID) => void;
    criterionKey: string;
    criterionColor: GradientColorsEnum;
    groupName: string;
}> = ({
    onClearGroup,
    criteriaList,
    onDeleteCriteria,
    criterionKey,
    criterionColor,
    groupName,
    testId
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const onSelectClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl((prevAnchor) => prevAnchor ?? e.currentTarget);
        setIsDropdownOpen((prevOpen) => !prevOpen);
    }, []);

    return (
        <div className="CriteriaChipsGroup">
            <div
                className="CriteriaChipsGroup__name"
                onClick={onSelectClick}
                data-testid={`chip${removeWhitespaces(testId ?? '')}`}
            >
                {!!criterionColor && <div className={cn('CriteriaChipsGroup__dot', `is-${criterionColor}`)} />}

                {groupName}
                <IconArrowCollapse
                    className={cn('CriteriaChipsGroup__dropdown-icon', {
                        'is-open': isDropdownOpen,
                    })}
                />
            </div>
            <div className="CriteriaChipsGroup__divider" />
            <div
                className="CriteriaChipsGroup__delete-button"
                data-testid={`chipDelete${removeWhitespaces(testId ?? '')}`}
                onClick={onClearGroup}
            >
                <IconClose width={8} />
            </div>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: -12,
                }}
                transformOrigin={{
                    vertical: -14,
                    horizontal: 'left',
                }}
                open={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
                anchorEl={anchorEl}
                unscrollable={true}
            >
                <div className="CriteriaChipsGroup__dropdown">
                    {criteriaList?.map((criterionParam) => {
                        const chipLabel = (criterionParam?.additionalData?.condensedLabel ??
                            criterionParam.name) as string;

                        const onChipDelete = criterionParam.isPermanent
                            ? undefined
                            : () => onDeleteCriteria(criterionKey as keyof CriteriaData, criterionParam.value);

                        return (
                            <Chip
                                key={criterionParam.value}
                                name={chipLabel}
                                id={criterionParam.value}
                                description={criterionParam?.description}
                                onChipDelete={onChipDelete}
                            />
                        );
                    })}
                </div>
            </Popover>
        </div>
    );
};

export default CriteriaChipsGroup;
